/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';

import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';

import { CrudInterface } from 'domain/entity/interface/crud-interface';

import { UseAlert } from 'presentation/hook/alert-hook';
import { UseAlertModal } from 'presentation/hook/alert-modal-hook';

import { SCDesctiption } from 'presentation/style/typography/titlePage';

import { Footer } from 'presentation/components/footer/footer-component';
import { Headers } from 'presentation/components/header/headers-component';
import { Loading } from 'presentation/components/loading/loading-component';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { SelectProvider } from 'presentation/components/select/provider-select-component';
import { SectorEntity, SectorInputs } from 'domain/entity/sector-entity';
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled';
import { RestUseCase } from 'data/useCase/rest-usecase';
import { getEnv } from 'main/helper/window-helper';
import { TitlePageComponent } from '../../components/title/title-page-component';
import { TitleGroupComponent } from '../../components/title/title-group-component';

type Props = {
  modelMethods: CrudInterface<SectorEntity>;
  providerOption: SelectOptionInterface[];
};

const tableDescriptions: {
  label: string;
  align: 'center' | 'left' | 'right';
}[] = [
  { label: 'Código no Fornecedor', align: 'center' },
  { label: 'Nome', align: 'left' },
];

const titlePage = 'Cadastrar Setor';

const SectorPage: React.FC<Props> = ({
                                       modelMethods,
                                       providerOption,
                                     }: Props) => {
  /**
   *
   * ALERT / ALERT MODAL
   *
   */
  const { showAlert } = UseAlert();
  const { messageAlertModal, showAlertModal } = UseAlertModal();
  const cleanAlertModal = () =>
    showAlertModal({ show: false, content: '', color: '' });

  function AlertModal() {
    if (messageAlertModal?.show) {
      return (
        <div
          className={
            'alert alert-' +
            messageAlertModal.color +
            ' fade show mx-5 mt-2 alert-dismissible'
          }
          role='alert'
        >
          {messageAlertModal.content}
          {!messageAlertModal.time && (
            <button
              type='button'
              className='ms-1 btn-close btn-sm'
              data-bs-dismiss='alert'
              aria-label='Close'
              onClick={() => cleanAlertModal()}
            ></button>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  /**
   *
   * FORM HOOK
   *
   */
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm<SectorInputs>();

  function clearField(): void {
    // LIMPA OS CAMPOS
    reset({
      idRegional: 0,
      codigo: '',
      nome: '',
      idUsuarioConsultor: 0,
      dataCadastro: '',
      estados: [],
    });
  }

  /**
   *
   * ERROR
   *
   */
  const [listError, updateListError] = useState<string[]>([]);

  /**
   *
   * CRUD MODEL / MODELS
   *
   */
  const [listModel, updateListModel] = useState<SectorEntity[]>([]);

  async function handleListModel(params?: {
    pagination?: string;
    idProvider?: number;
  }): Promise<void> {
    updateIdProvider(params?.idProvider);

    var modelRequest = {
      idProvider: params === undefined ? idProvider : params?.idProvider,
    };

    updateLoading(true);
    // BUSCA LISTAGEM DE MODELS
    await modelMethods
      .getAll(modelRequest)
      .then((response) => {
        updateListModel(response.body);
      })
      .catch((error) => updateListError([...listError, error]));

    updateLoading(false);
    handleCloseModal();
  }

  async function handleModel(id: number): Promise<void> {
    // BUSCA MODEL
    await modelMethods
      .getById(id)
      .then((response) => {
        // SETAR OUTROS CAMPOS
        setValue('id', response.id!);
        setValue('idRegional', response.idRegional);
        setValue('codigo', response.codigo);
        setValue('nome', response.nome);
        setValue('idUsuarioConsultor', response.idUsuarioConsultor);
        setValue('dataCadastro', response.dataCadastro);
        setValue('estados', response.estados);
      })
      .catch((error) => updateListError([...listError, error]));
  }

  async function handleCreteOrEdit(modelFields: SectorInputs): Promise<void> {
    // EDITA OU CRIA
    const model: SectorInputs = {
      idRegional: modelFields.idRegional,
      codigo: modelFields.codigo,
      nome: modelFields.nome,
      idUsuarioConsultor: modelFields.idUsuarioConsultor,
      dataCadastro: '2022-08-09T17:14:56.303Z',
      estados: modelFields.estados,
    };

    if (inEditing === true)
      await modelMethods
        .edit(model, modelFields.id!)
        .then(() =>
          showAlert({
            show: true,
            content: 'Editado com sucesso',
            color: 'success',
            time: 5000,
          }),
        )
        .catch((error) => updateListError([...listError, error]));
    else
      await modelMethods
        .create(model)
        .then(() =>
          showAlert({
            show: true,
            content: 'Salvo com sucesso',
            color: 'success',
            time: 5000,
          }),
        )
        .catch((error) => updateListError([...listError, error]));

    await handleListModel({ idProvider: idProvider });
  }

  async function handleDelete(): Promise<void> {
    // DELETA
    let id: number = getValues('id')!;

    await modelMethods
      .remove(id)
      .then((response) => {
        if (response === true)
          showAlert({
            show: true,
            content: 'Deletado com sucesso',
            color: 'success',
            time: 5000,
          });
        else
          showAlert({
            show: true,
            content:
              'Não Foi possível deletar, verifique suas credenciais',
            color: 'danger',
            time: 5000,
          });
      })
      .catch((error) => updateListError([...listError, error]));

    await handleListModel({ idProvider: idProvider });
  }

  /**
   *
   * MODAL
   *
   */
  const [inEditing, updateInEditing] = useState<boolean>(false);
  const [openModal, updateOpenModal] = useState<boolean>(false);

  async function handleModalEdit(id: number): Promise<void> {
    // ABRE MODAL PARA EDICAO
    await handleModel(id);
    updateInEditing(true);
    updateOpenModal(true);
  }

  function handleModalCreate() {
    // ABRE MODAL PARA CRIACAO
    updateLoading(false);
    updateOpenModal(true);
    updateInEditing(false);
  }

  function handleCloseModal() {
    // FECHA MODAL
    clearField();
    updateInEditing(false);
    updateOpenModal(false);
  }

  /**
   *
   * LOADING
   *
   */
  const [loading, updateLoading] = useState<boolean>(false);

  /**
   *
   * PROVIDER
   *
   */
  const [idProvider, updateIdProvider] = useState<number>();
  const [modelProvider, updateModelProvider] =
    useState<SelectOptionInterface>();

  function handleSelectProvider(filial: {
    value: number;
    label: string
  }) {
    updateModelProvider(filial);
    updateIdProvider(filial.value);
    handleListModel({ idProvider: filial.value });
    handleSelectConsultor(filial.value);
    handleSelectRegional(filial.value);
  }

  function CreateSelectProvider() {
    return (
      <div className='m-3'>
        <Row className='d-flex align-items-center justify-content-start'>
          <Col md={1}>
            <Form.Label>Fornecedor:</Form.Label>
          </Col>
          <Col md={3}>
            <SelectProvider
              passOnTheValue={handleSelectProvider}
              defaultValue={modelProvider}
            />
          </Col>
        </Row>
      </div>
    );
  }

  const [consultorOption, updateConsultorOption] =
    useState<SelectOptionInterface[]>();
  const ENDPOINT_USERS = getEnv('REACT_APP_END_POINT_USER_PROVIDER')!;
  const handleSelectConsultor = async (id: number) => {
    await new RestUseCase(`${ENDPOINT_USERS}/${id}`)
      .Get()
      .then((response) => {
        updateConsultorOption(
          response.body.map((value: any) => {
            return {
              value: value.id,
              label: value.nome,
              selected: false,
            };
          }),
        );
      });
  };

  const [regionalOption, updateRegionalOption] =
    useState<SelectOptionInterface[]>();
  const ENDPOINT_REGIONAL = getEnv('REACT_APP_END_POINT_REGIONAL')!;
  const handleSelectRegional = async (id: number) => {
    await new RestUseCase(`${ENDPOINT_REGIONAL}/?idFornecedor=${id}`)
      .Get()
      .then((response) => {
        updateRegionalOption(
          response.body.map((value: any) => {
            return {
              value: value.id,
              label: value.nome,
              selected: false,
            };
          }),
        );
      });
  };

  /**
   *
   * INIT
   *
   */
  useEffect(() => {
  }, []);

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />

      {loading && <Loading />}
      {!loading && <CreatePageContent />}

      <Modal
        show={openModal}
        onHide={handleCloseModal}
        size={'xl'}
        fullscreen={'md-down'}
      >
        <Container>
          <form
            onSubmit={handleSubmit(handleCreteOrEdit)}
            className='mt-0'
            noValidate
          >
            <Modal.Header closeButton>
              <Modal.Title
                className='ms-3 lead'
                style={{ fontSize: '30px' }}
              >
                {inEditing ? 'EDITAR' : 'NOVO'}
              </Modal.Title>
            </Modal.Header>
            <AlertModal />
            <Modal.Body>
              <Form.Control type='hidden' {...register('id')} />
              <Row className='mt-3'>
                <Col md={2}>
                  <Form.Label column>Codigo:</Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('codigo')}
                    />
                  </Col>
                </Col>
                <Col md={4}>
                  <Form.Label>Nome:</Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('nome')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Regional:</Form.Label>
                  <Col>
                    <Controller
                      name='idRegional'
                      control={control}
                      render={({
                                 field: { onChange, value },
                               }) => {
                        let defaultValueOptions =
                          regionalOption?.filter(
                            (item) =>
                              item.value === value,
                          );

                        return (
                          <Select
                            defaultValue={
                              defaultValueOptions
                            }
                            options={regionalOption}
                            onChange={(val) => {
                              onChange(
                                val?.value,
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Consultor:</Form.Label>
                  <Col>
                    <Controller
                      name='idUsuarioConsultor'
                      control={control}
                      render={({
                                 field: { onChange, value },
                               }) => {
                        let defaultValueOptions =
                          consultorOption?.filter(
                            (item) =>
                              item.value === value,
                          );

                        return (
                          <Select
                            defaultValue={
                              defaultValueOptions
                            }
                            options={
                              consultorOption
                            }
                            onChange={(val) => {
                              onChange(
                                val?.value,
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </Col>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={2}>
                  <Form.Check
                    inline
                    label='Acre'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Alagoas'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Amapá ZF'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Amazonas'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Amazonas ZF'
                    {...register('estados')}
                    type='checkbox'
                  />
                </Col>
                <Col md={2}>
                  <Form.Check
                    inline
                    label='Bahia'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Ceará'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Distrito Federal'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Espirito Santo'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Goiáis'
                    {...register('estados')}
                    type='checkbox'
                  />
                </Col>

                <Col md={2}>
                  <Form.Check
                    inline
                    label='Maranhão'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Mato Grosso'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Mato Grosso do sul'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Minas Gerais'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Minas Gerais DS'
                    {...register('estados')}
                    type='checkbox'
                  />
                </Col>
                <Col md={2}>
                  <Form.Check
                    inline
                    label='Pará'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Paraíba'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Paraná'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Pernambuco'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Piauí'
                    {...register('estados')}
                    type='checkbox'
                  />
                </Col>
                <Col md={2}>
                  <Form.Check
                    inline
                    label='Rio de Janeiro'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Rio Grande do Norte'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Rio Grande do Sul'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Rondônia'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Rondônia ZF'
                    {...register('estados')}
                    type='checkbox'
                  />
                </Col>
                <Col md={2}>
                  <Form.Check
                    inline
                    label='Roraima'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Santa Catarina'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='São Paulo'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Sergipe'
                    {...register('estados')}
                    type='checkbox'
                  />
                  <Form.Check
                    inline
                    label='Tocantins'
                    {...register('estados')}
                    type='checkbox'
                  />
                </Col>
                <Col md={3}></Col>

                <Col md={3}></Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-start'>
              <Button variant='primary' size='sm' type='submit'>
                Salvar
              </Button>
              <Button
                variant='outline-primary'
                size='sm'
                onClick={clearField}
              >
                Limpar
              </Button>
              {inEditing && (
                <Button
                  variant='danger'
                  size='sm'
                  onClick={handleDelete}
                >
                  Deletar
                </Button>
              )}
            </Modal.Footer>
          </form>
        </Container>
      </Modal>
    </>
  );

  /**
   *
   * CREATE TABLE
   *
   */
  function CreateTable() {
    if (listModel !== undefined)
      return (
        <TableHeaderFixedStyled>
          <Table>
            <thead>
            <tr>
              {tableDescriptions.map((value, index) => {
                return (
                  <th className='px-1' key={index}>
                    <div>
                      <p
                        style={{
                          whiteSpace: 'nowrap',
                          textAlign: value.align,
                        }}
                        className='m-1'
                      >
                        {value.label}
                      </p>
                    </div>
                  </th>
                );
              })}
            </tr>
            </thead>
            <tbody>
            {listModel.map((value, index) => {
              return (
                <tr
                  key={index}
                  onClick={() =>
                    handleModalEdit(value.id!)
                  }
                >
                  {/* <td align='center' >{value.id}</td> */}
                  <td align='center'>{value.codigo}</td>
                  <td align='left'>{value.nome}</td>
                </tr>
              );
            })}
            </tbody>
          </Table>
        </TableHeaderFixedStyled>
      );
    else
      return (
        <TableHeaderFixedStyled>
          <Table>
            <thead>
            <tr>
              {tableDescriptions.map((value, index) => {
                return (
                  <th className='px-1' key={index}>
                    <div>
                      <p
                        style={{
                          whiteSpace: 'nowrap',
                          textAlign: value.align,
                        }}
                        className='m-1'
                      >
                        {value.label}
                      </p>
                    </div>
                  </th>
                );
              })}
            </tr>
            </thead>
          </Table>
        </TableHeaderFixedStyled>
      );
  }

  /**
   *
   * CREATE INITIAL PAGE
   *
   */
  function CreatePageContent() {
    return (
      <>
        <div>
          <div className='mx-4'>
            <TitleGroupComponent titlePage={titlePage}>
              <Row>
                <Col className='d-flex align-items-center justify-content-end'>
                  <Button
                    variant='primary'
                    onClick={handleModalCreate}
                    size='sm'
                  >
                    Novo
                  </Button>
                </Col>
              </Row>
            </TitleGroupComponent>

          </div>
          <div className='mx-2'>
            <Card>
              <CreateSelectProvider />

              {listModel.length > 0 && (
                <Card.Body>
                  <div>
                    <CreateTable />
                  </div>
                </Card.Body>
              )}

              {listModel.length === 0 && (
                <div className='d-flex align-items-center justify-content-center'>
                  <p className='m-5'>
                    Não há nada a ser exibido, clique em
                    processar ou tente mais tarde
                  </p>
                </div>
              )}
            </Card>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default SectorPage;
