/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select'
import { useEffect, useState } from 'react'
import { RestUseCase } from 'data/useCase/rest-usecase'
import { Button, Col, Form, Row } from "react-bootstrap"
import { EntityField } from 'domain/entity/field/crud/field-crud-entity'
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface'
import ErrorNotAuthorizedPage from 'presentation/pages/error/error-not-authorized-page'
import { UseAlert } from 'presentation/hook/alert-hook'
import QueryScrapPage from 'presentation/pages/scrap/query-scrap-page'
import { getParameterUrlByName, parseLinkHeader } from 'main/helper/request-helper'
import { getEnv } from 'main/helper/window-helper'

interface LinkHeader {
    first: string;
    last: string;
    next: string;
    prev: string;
}

export const QueryScrapFactory: React.FC = () => {

    /** ENDPOINT */
    const ENDPOINT_LIST = `${getEnv("REACT_APP_END_POINT_SCRAP")!}/search`

    /**
     * 
     * 
     * 
     * 
     *  alerta */
    const { showAlert } = UseAlert()

    /** TITULO DA PAGINA */
    const titlePage = "Consulta de Scrap"

    /** LOADING */
    const [loading, updateLoading] = useState<boolean>(false);

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([])
    const [tableBody, updateTableBody] = useState([])

    /** FILTRO DA TABELA */
    const [providerSelect, updateProviderSelect] = useState<SelectOptionInterface[]>()
    const [provider, updateProvider] = useState<SelectOptionInterface>()
    const ENDPOINT_PROVIDER = getEnv("REACT_APP_END_POINT_PROVIDER")!
    const onChangeSelectProvider = (providerOptionValue: SelectOptionInterface) => { updateProvider(providerOptionValue) }
    const handleSelectProvider = async () => {
        updateLoading(true)
        await new RestUseCase(ENDPOINT_PROVIDER)
            .Get()
            .then(response => {
                updateProviderSelect(response.body.map((value: any) => {
                    return { value: value.id, label: value.nome }
                }))
            })
            .catch((error) => console.log(error))
        updateLoading(false)
    }

    const [regionalSelect, updateRegionalSelect] = useState<SelectOptionInterface[]>()
    const [regional, updateRegional] = useState<SelectOptionInterface[]>([])
    const ENDPOINT_REGIONAL = getEnv("REACT_APP_END_POINT_REGIONAL")! + ``
    const handleSelectRegional = async () => {
        updateLoading(true)
        if (provider !== null && provider !== undefined) {
            await new RestUseCase(ENDPOINT_REGIONAL + `?idFornecedor=${provider.value}`)
                .Get()
                .then(response => {
                    updateRegionalSelect(response.body.map((value: any) => { return { value: value.id, label: value.nome } }))
                })
                .catch((error) => console.log(error))
        }
        updateLoading(false)
    }

    const [companieSelect, updateCompanieSelect] = useState<SelectOptionInterface[]>()
    const [companie, updateCompanie] = useState<SelectOptionInterface>()
    const ENDPOINT_COMPANIES = getEnv("REACT_APP_END_POINT_COMPANIES")! + ``
    const onChangeSelectCompanie = (providerOptionValue: SelectOptionInterface) => { updateCompanie(providerOptionValue) }
    const handleSelectCompanie = async () => {
        await new RestUseCase(ENDPOINT_COMPANIES)
            .Get()
            .then(response => {
                updateCompanieSelect(response.body.map((value: any) => { return { value: value.id, label: value.nome } }))
            })
            .catch((error) => console.log(error))
    }

    const [justCurrentSelect, ] = useState<SelectOptionInterface[]>([{ label: "SIM", value: true }, { label: "NÃO", value: false }])
    const [justCurrent, updateJustCurrent] = useState<SelectOptionInterface>()
    const onChangeSelectJustCurrent = (providerOptionValue: SelectOptionInterface) => { updateJustCurrent(providerOptionValue) }

    const [filialSelect, updateFilialSelect] = useState<SelectOptionInterface[]>()
    const [filial, updateFilial] = useState<number[]>()
    const ENDPOINT_FILIAL = getEnv("REACT_APP_END_POINT_FILIAL")! + ``
    const onChangeSelectFilial = (providerOptionValue: number[]) => { updateFilial(providerOptionValue) }
    const handleSelectFilial = async () => {
        await new RestUseCase(ENDPOINT_FILIAL)
            .Get()
            .then(response => {
                updateFilialSelect(response.body.map((value: any) => { return { value: value.id, label: value.nome } }))
            })
            .catch((error) => console.log(error))
    }

    const [sectorsSelect, updateSectorsSelect] = useState<SelectOptionInterface[]>()
    const [sectors, updateSectors] = useState<SelectOptionInterface[]>()
    const ENDPOINT_SECTOR = getEnv("REACT_APP_END_POINT_SECTOR")! + ``
    const onChangeSelectSectors = (providerOptionValue: SelectOptionInterface[]) => { updateSectors(providerOptionValue) }
    const handleSelectSectors = async (regionais?: SelectOptionInterface[]) => {
        updateLoading(true)
        if (provider !== null && provider !== undefined) {
            await new RestUseCase(ENDPOINT_SECTOR + `?idFornecedor=${provider.value}`)
                .Get()
                .then(response => {
                    if (regionais?.length !== 0) {
                        let regionaisId = regionais?.map(regional => regional.value)
                        updateSectorsSelect(response.body.filter((value: any) => regionaisId?.includes(value.idRegional)).map((value: any) => {
                                return {
                                    value: value.id, label: value.nome
                                }
                            })
                        )
                    } else {
                        updateSectorsSelect(response.body.map((value: any) => {
                            return {
                                value: value.id, label: value.nome
                            }
                        }))
                    }
                })
                .catch((error) => console.log(error))
        }
        updateLoading(false)
    }

    const [statusSelect, ] = useState<SelectOptionInterface[]>([
        { label: "Aguardando Auditor", value: "Aguardando Auditor" },
        { label: "Aguardando Concessionária inserir NF(XML)", value: "Aguardando Concessionária inserir NF(XML)" },
        { label: "Aguardando CPV(Sem Auditor)", value: "Aguardando CPV(Sem Auditor)" },
        { label: "Aguardando escolha de Auditor", value: "Aguardando escolha de Auditor" },
        { label: "Cancelado", value: "Cancelado" },
        { label: "Criado", value: "Criado" },
        { label: "Pago(Creditado em Conta movimento do Dealer)", value: "Pago(Creditado em Conta movimento do Dealer)" },
        { label: "Processo em análise do Financeiro(Fábrica)", value: "Processo em análise do Financeiro(Fábrica)" },
        { label: "Processo Entregue(Em análise pela Fábrica)", value: "Processo Entregue(Em análise pela Fábrica)" },
        { label: "Programado para pagamento à Concessionária", value: "Programado para pagamento à Concessionária" },
    ])
    const [status, updateStatus] = useState<number[]>()
    const onChangeSelectStatus = (providerOptionValue: number[]) => { updateStatus(providerOptionValue) }

    const [initialDate, updateInitialDate] = useState<string>()
    const [finalDate, updateJustFinalDate] = useState<string>()


    /** CHAMADA ENDPOINT */
    const handleScrap = async (pagination?: { page: string; size: string }) => {
        updateLoading(true)
        let querie = `?`
        if (provider)
            querie += `idFornecedor=${provider.value}&`
        else {
            showAlert({ show: true, content: "Selecione um Fornecedor", color: "danger", time: 2000 })
            updateLoading(false)
            return
        }

        if (companie)
            querie += `empresa=${companie.value}&`
        if (justCurrent)
            querie += `apenasVigente=${justCurrent.value}&`
        if (filial)
            filial.forEach(item => querie += `fil=${item}&`)
        if (sectors?.length !== 0){
            sectors?.forEach(item => querie += `sets=${item.value}&`)
        } else {
            sectorsSelect?.forEach(item => querie += `sets=${item.value}&`)
        }
        if (status)
            status.forEach(item => querie += `sts=${item}&`)
        if (initialDate)
            querie += `dataInicio=${initialDate}&`
        if (finalDate)
            querie += `dataFim=${finalDate}&`
        if (pagination)
            querie += `&page=${pagination.page}&size=${totalPageSize}`

            

        await new RestUseCase(`${ENDPOINT_LIST}${querie}`)
            .Get()
            .then(response => {
                if (response.statusCode === 200) {
                    if (response.headers["link"]) {
                        let links: LinkHeader = parseLinkHeader(
                        response.headers["link"]
                        ) as any;
                        
                        updateFirstPagination(links.first);
                        updateLastPagination(links.last);
                        updateNextPagination(links.next);
                        updatePrevPagination(links.prev);
                        if(pagination) {
                            setValuePaginations(
                                Number(pagination.page),
                                parseInt(
                                    getParameterUrlByName("page", links.first)!
                                ),
                                parseInt(
                                    getParameterUrlByName("page", links.last)!
                                )
                            );
                        }
                    }
                }

                updateTableHeader(entityFields.map((value) => { return { name: value.name, labelTable: value.labelTable, align: value.align, columnVisible: value.tableField.columnVisible } }))
                updateTableBody(response.body.map((value: any) => {
                    return value
                }))
            })
        updateLoading(false)
    }

    /** CAMPOS PARA MODAL E TABELA */
    const entityFields: EntityField[] = [
        { name: "id", labelField: "ID:", labelTable: "ID", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "ID", columnVisible: true } },
        { name: "idAuditor", labelField: "ID Auditor:", labelTable: "ID Auditor", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "ID Auditor", columnVisible: true } },
        { name: "nomeFilialFornecedor", labelField: "Nome Filial Fornecedor:", labelTable: "Nome Filial Fornecedor", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Nome Filial Fornecedor", columnVisible: true } },
        { name: "nomeAuditor", labelField: "Nome Auditor:", labelTable: "Nome Auditor", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Nome Auditor", columnVisible: true } },
        { name: "codigoFilialFornecedor", labelField: "Codigo Filial Fornecedor:", labelTable: "Codigo Filial Fornecedor", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Codigo Filial Fornecedor", columnVisible: true } },
        { name: "status", labelField: "Status:", labelTable: "Status", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Status", columnVisible: true } },
        { name: "notaFiscalAtrelada", labelField: "Nota Fiscal Atrelada:", labelTable: "Nota Fiscal Atrelada", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Nota Fiscal Atrelada", columnVisible: true } },

        { name: "dataCadastro", labelField: "Data Cadastro:", labelTable: "Data Cadastro", typeField: "date", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Data Cadastro", columnVisible: true } },
        { name: "dataAgendamento", labelField: "Data Agendamento:", labelTable: "Data Agendamento", typeField: "date", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Data Agendamento", columnVisible: true } },
    ]

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** PAGINACAO */
    var totalPageSize: number = 50;
    const [firstPagination, updateFirstPagination] = useState<string>("");
    const [lastPagination, updateLastPagination] = useState<string>("");
    const [nextPagination, updateNextPagination] = useState<string>("");
    const [prevPagination, updatePrevPagination] = useState<string>("");
    const [paginationItemList, updatePaginationItemList] =
        useState<{ item: number; active: boolean }[]>();
    const setValuePaginations = (
        paginationsActive: number,
        prev: number,
        next: number
    ) => {
        let modelPaginationList: any[] = [];
        for (
            let index = paginationsActive;
            index < 6 + paginationsActive;
            index++
        ) {
            if (!(index > next))
                modelPaginationList.push({
                    item: index,
                    active: index === paginationsActive ? true : false,
                });
        }
        updatePaginationItemList(modelPaginationList);
    };
    const onClickItemPagination = async (pagination: any) => {
        await handleScrap({
            page: pagination.item,
            size: totalPageSize.toString(),
        });
    };
    const onClickFirstPagination = async () => {
            const page: string = getParameterUrlByName(
                "page",
                firstPagination
            )!;
            const size: string = getParameterUrlByName(
                "size",
                firstPagination
            )!;
            await handleScrap({ page: "0", size: size });
        
    };
    const onClickLastPagination = async () => {
            const page: string = getParameterUrlByName("page", lastPagination)!;
            const size: string = getParameterUrlByName("size", lastPagination)!;
            await handleScrap({ page: page, size: size });
        
    };
    const onClickNextPagination = async () => {
            const page: string = getParameterUrlByName("page", nextPagination)!;
            const size: string = getParameterUrlByName("size", nextPagination)!;
            await handleScrap({ page: page, size: size });
        
    };
    const onClickPrevPagination = async () => {
            const page: string = getParameterUrlByName("page", prevPagination)!;
            const size: string = getParameterUrlByName("size", prevPagination)!;
            await handleScrap({ page: page, size: size });
        
    };

    /** VERIFICA PERMISSAO DE ACESSO */
    const [showScreen,] = useState<boolean>(true)

    useEffect(() => { handleSelectProvider() }, [])

    useEffect(() => {
        updateLoading(true)
        updateCompanieSelect([])
        updateSectorsSelect([])
        updateSectors([])
        updateRegionalSelect([])
        updateRegional([])
        updateFilialSelect([])

        handleSelectFilial()
        handleSelectCompanie()
        handleSelectRegional()
        updateLoading(false)
    }, [provider])

    useEffect(() => {
        updateSectorsSelect([])
        updateSectors([])
        if (regional.length !== 0)
            handleSelectSectors(regional)
    }, [regional])

    return (
        <>
            {showScreen ?
                <QueryScrapPage
                    titlePage={titlePage}
                    loading={loading}
                    tableHeader={tableHeader}
                    tableBody={tableBody}
                    children={
                        {
                            filterTable:
                                <Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label>Fornecedor:</Form.Label>
                                            <Col>
                                                <Select
                                                    isClearable={true}
                                                    value={provider}
                                                    options={providerSelect}
                                                    onChange={val => {
                                                        updateProvider(val? val : undefined)
                                                        onChangeSelectProvider(val!)
                                                        }
                                                    }
                                                    placeholder="Selecione o Fornecedor"
                                                />
                                            </Col>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label>Regionais:</Form.Label>
                                            <Select
                                                key={provider?.value}
                                                isMulti
                                                value={regional}
                                                defaultValue={regional}
                                                isClearable={true}
                                                onChange={val => {
                                                    let data = val.map((elements: SelectOptionInterface) => elements)
                                                    updateRegional(data)
                                                }}
                                                options={regionalSelect}
                                                placeholder="Selecione a regional"
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label>Setores:</Form.Label>
                                            <Col>
                                                <Select isMulti
                                                    key={regional[0] ? regional[0].value : 0}
                                                    value={sectors}
                                                    options={sectorsSelect}
                                                    onChange={(val: any) => {
                                                        let data = val.map((elements: SelectOptionInterface) => elements)
                                                        onChangeSelectSectors(data)
                                                    }}
                                                    placeholder="Selecione o Setor"
                                                />
                                            </Col>
                                        </Col>
                                       
                                    </Row>
                                    <Row className="my-3">
                                        <Col md={3}>
                                            <Form.Label>Filiais:</Form.Label>
                                            <Col>
                                                <Select isMulti
                                                    options={filialSelect}
                                                    onChange={(e: any) => {
                                                        let data = e.map((elements: any) => elements.value)
                                                        onChangeSelectFilial(data)
                                                    }}
                                                    placeholder="Selecione a Filial"
                                                />
                                            </Col>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Status:</Form.Label>
                                            <Col>
                                                <Select isMulti
                                                    options={statusSelect}
                                                    onChange={(e: any) => {
                                                        let data = e.map((elements: any) => elements.value)
                                                        onChangeSelectStatus(data)
                                                    }}
                                                    placeholder="Selecione o Status"
                                                />
                                            </Col>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label>Empresa:</Form.Label>
                                            <Col>
                                                <Select
                                                    defaultValue={companie}
                                                    onChange={val => onChangeSelectCompanie(val!)}
                                                    options={companieSelect}
                                                    placeholder="Selecione a Empresa"
                                                />
                                            </Col>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label>Apenas Vigente:</Form.Label>
                                            <Col>
                                                <Select
                                                    defaultValue={justCurrent}
                                                    onChange={val => onChangeSelectJustCurrent(val!)}
                                                    options={justCurrentSelect}
                                                    placeholder="Selecione ..."
                                                />
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Data Inicial:</Form.Label>
                                            <Form.Control type="date" onChange={(event) => updateInitialDate(event.target.value)} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Data Final:</Form.Label>
                                            <Form.Control type="date" onChange={(event) => updateJustFinalDate(event.target.value)} />
                                        </Col>
                                    </Row>
                                    <div className='d-flex align-items-center justify-content-end mt-4'>
                                        <Button variant="primary" size="sm" className='mb-4' onClick={() => handleScrap()}>Pesquisar</Button>
                                    </div>
                                </Row>
                        }
                    }
                    onClickFirstPagination={onClickFirstPagination}
                    onClickLastPagination={onClickLastPagination}
                    onClickNextPagination={onClickNextPagination}
                    onClickPrevPagination={onClickPrevPagination}
                    itemList={paginationItemList}
                    onClickItem={onClickItemPagination}
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )

}
