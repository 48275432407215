import { EntityField } from "domain/entity/field/crud/field-crud-entity"
import { ParseDateToBrV2 } from "main/helper/format-date-helper"
import { generateRandomId } from "main/helper/randon-helper"
import { TableHeaderFixedStyled } from "presentation/style/table/table-header-fixed-styled"
import { useEffect, useMemo, useState } from "react"
import { Badge, Form, Table } from "react-bootstrap"
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa"

type PropsTable = {
    tableHeader: any[]
    tableBody: any[]
    entityFields: EntityField[]
    openModalCrud?: (id: number, model: any) => any
    valueId?: string
}

let sortedList: any[] = [];
let filteredList: any[] = []

const CreateTableCrud: React.FC<PropsTable> = ({ tableHeader, tableBody, entityFields, openModalCrud, valueId }: PropsTable) => {

    const valueIdCrud = valueId ? valueId : "id"

    const [sortedFilteredBody, updateSortedFilteredBody] = useState(tableBody)
    const [sortType, updatesSortType] = useState("")
    const [filterValue, updatesFilterValue] = useState("")
    const [columnValue, updatesColumnValue] = useState(tableHeader[0])

    /** FILTRO DA TABELA **/
    useMemo(() => {
        let filterValueLowerCase = filterValue.toLowerCase()
        sortedList = []
        if (filterValue === "") {
            updateSortedFilteredBody(tableBody)
            tableBody.forEach(value => sortedList.push(value))
        } else {
            filteredList = tableBody.filter(value =>
                String(value[columnValue.name]).toLowerCase().includes(filterValueLowerCase)
            )
            updateSortedFilteredBody(filteredList)
            filteredList.forEach(value => sortedList.push(value))
        }
    }, [filterValue])

    /** ATUALIZANDO CORPO DA TABELA **/
    useEffect(() => {
        updateSortedFilteredBody(tableBody)
        updatesSortType("NONE")
        tableBody.forEach(value => {
            sortedList.push(value)
            filteredList.push(value)
        })
    }, [tableBody])

    /** ORDENAÇÃO DA TABELA **/
    function sortColumn(value: any) {
        if (sortType === "NONE") {
            sortedList.sort((a, b) => {
                return (a[value.name] < b[value.name] ? -1 : 1)
            })
            updateSortedFilteredBody(sortedList)
            updatesSortType("asc")
        }
        else if (sortType === "asc") {
            sortedList.sort((a, b) => {
                return (a[value.name] < b[value.name] ? 1 : -1)
            })
            updateSortedFilteredBody(sortedList)
            updatesSortType("desc")
        }
        else if (sortType === "desc") {
            updateSortedFilteredBody(filteredList)
            updatesSortType("NONE")
        }
    }

    /** ICONES DE ORDENAÇÃO **/
    const CreateIconOrder = (props: { colunmName: string }) => {
        if ((columnValue !== undefined && columnValue !== null) && props.colunmName == columnValue.name) {
            if (sortType === "asc")
                return <FaSortUp color="#FFC007" size={11} key={generateRandomId(100) + generateRandomId(300)} />
            else if (sortType === "desc")
                return <FaSortDown color="#FFC007" size={11} key={generateRandomId(100) + generateRandomId(300)} />
            else
                return <FaSort size={11} key={generateRandomId(100) + generateRandomId(300)} />
        }
        return <FaSort size={11} key={generateRandomId(100) + generateRandomId(300)} />
    }

    return (
        <TableHeaderFixedStyled>
            <Table>
                <thead>
                    <tr>
                        {tableHeader.map((value, index) => {
                            if (value.columnVisible) {
                                return (
                                    <>
                                        {
                                            (value.sort === true && value.filter === true) ?
                                                <>
                                                    <th className="px-1 align-top" key={index} >
                                                        <div>
                                                            <div className="d-flex align-items-center justify-content-start mx-1" onClick={() => { updatesColumnValue(value); sortColumn(value) }}>
                                                                <p style={{ whiteSpace: "nowrap", textAlign: value.align }} className="m-1">{value.labelTable}</p>
                                                                <CreateIconOrder colunmName={value === undefined ? "" : value.name} />
                                                            </div>
                                                            <Form.Control size="sm" onChange={(e) => { updatesColumnValue(value); updatesFilterValue(e.target.value) }} />
                                                        </div>
                                                    </th>
                                                </>
                                                :
                                                value.filter === true ?
                                                    <>
                                                        <th className="px-1 align-top" key={index} >
                                                            <div className="d-flex align-items-center justify-content-start mx-1" onClick={() => sortColumn(value)}>
                                                                <p style={{ whiteSpace: "nowrap", textAlign: value.align }} className="m-1">{value.labelTable}</p>
                                                            </div>
                                                            <Form.Control size="sm" onChange={(e) => { updatesColumnValue(value); updatesFilterValue(e.target.value) }} />
                                                        </th>
                                                    </>
                                                    :
                                                    value.sort === true ?
                                                        <>
                                                            <th className="px-1 align-top" key={index} >
                                                                <div className="d-flex align-items-center justify-content-start mx-1" onClick={() => sortColumn(value)}>
                                                                    <p style={{ whiteSpace: "nowrap", textAlign: value.align }} className="m-1">{value.labelTable}</p>
                                                                    <CreateIconOrder colunmName={value === undefined ? "" : value.name} />
                                                                </div>
                                                            </th>
                                                        </>
                                                        :
                                                        <>
                                                            <th className="px-1 align-top" key={index} >
                                                                <div>
                                                                    <p style={{ whiteSpace: "nowrap", textAlign: value.align }} className="m-1">{value.labelTable}</p>
                                                                </div>
                                                            </th>
                                                        </>
                                        }
                                    </>
                                )
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {sortedFilteredBody.map((value, index) => {
                        return <tr
                            key={index}
                            onClick={() => {
                                if (openModalCrud)
                                    openModalCrud(value[valueIdCrud], value)
                            }}
                            className="text-center"
                        >
                            {tableHeader.map((v, i) => {

                                if (v.columnVisible) {

                                    if (entityFields[i] !== undefined) {

                                        let typeField = entityFields[i].typeField
                                        let fieldValue = value[v.name]
                                        let align = entityFields[i].align

                                        let classNameTd = `m-0 p-0 mx-1`
                                        let classNameDiv = `d-flex align-items-center justify-content-${align} m-0 p-0 mx-2`
                                        if (align === "left")
                                            classNameDiv = `d-flex align-items-center justify-content-start m-0 p-0 mx-2`
                                        if (align === "right")
                                            classNameDiv = `d-flex align-items-center justify-content-end m-0 p-0 mx-2`

                                        if (typeField === "date") {
                                            return (
                                                <td key={i} className={classNameTd} style={{ textAlign: align, whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    <div className={classNameDiv}>
                                                        {fieldValue === null ? "" : ParseDateToBrV2(fieldValue)}
                                                    </div>
                                                </td>
                                            )
                                        }
                                        else if (typeField === "text")
                                            return (
                                                <td key={i} className={classNameTd} style={{ whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    <div className={classNameDiv}>
                                                        {fieldValue}
                                                    </div>
                                                </td>
                                            )
                                        else if (typeField === "text-area") {
                                            return (
                                                <td key={i} className={classNameTd} style={{ textAlign: align, whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    <div className={classNameDiv}>
                                                        {fieldValue}
                                                    </div>
                                                </td>
                                            )
                                        }

                                        else if (typeField === "radio") {
                                            return (
                                                <td key={i} className={classNameTd} style={{ textAlign: align, whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    <div className={classNameDiv}>
                                                        {fieldValue}
                                                    </div>
                                                </td>
                                            )
                                        }

                                        else if (typeField === "number")
                                            return (
                                                <td key={i} className={classNameTd} style={{ textAlign: align, whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    <div className={classNameDiv}>
                                                        {fieldValue}
                                                    </div>
                                                </td>
                                            )
                                        else if (typeField === "html")
                                            return (
                                                <td key={i} className={classNameTd} style={{ textAlign: align, whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    <div dangerouslySetInnerHTML={{ __html: fieldValue ? fieldValue : "" }} />
                                                </td>
                                            )
                                        else if (typeField === "button")
                                            return (
                                                <td key={i} className={classNameTd} style={{ textAlign: align, whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    <Badge bg="secondary">{fieldValue}</Badge>
                                                </td>
                                            )
                                        else if (typeField === "icon")
                                            return (
                                                <td key={i} className={classNameTd} style={{ textAlign: align, whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    {(fieldValue !== null && fieldValue !== undefined) ? fieldValue : ""}
                                                </td>
                                            )
                                        else if (typeField === "select") {
                                            let valueSelected = ""

                                            if (entityFields[i].listValueField?.filter(fil => fil.value === fieldValue)[0]) {
                                                valueSelected = entityFields[i].listValueField?.filter(fil => fil.value === fieldValue)[0].label
                                            }

                                            return (
                                                <td key={i} className={classNameTd} style={{ textAlign: align, whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    {valueSelected}
                                                </td>
                                            )
                                        }
                                        else
                                            return (
                                                <td key={i} className={classNameTd} style={{ textAlign: align, whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    <div className={classNameDiv}>
                                                        {fieldValue}
                                                    </div>
                                                </td>
                                            )
                                    }

                                    return <></>
                                }

                            })}
                        </tr>
                    })}
                </tbody>
            </Table>
        </TableHeaderFixedStyled>

    )
}

export default CreateTableCrud