export function transformStringToSnakeCase(input: string): string {
  let snakeCase = "";
  for (let i = 0; i < input.length; i++) {
      const char = input[i];
      if (char === char.toUpperCase()) {
          snakeCase += "_";
      }
      snakeCase += char.toUpperCase();
  }
  return snakeCase;
}