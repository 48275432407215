/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import { ModalComponent } from "presentation/components/modal/modal-component"
import { Button, Card, Carousel, Col, Container, Form, Row } from "react-bootstrap"
import { Loading } from "presentation/components/loading/loading-component"

interface InputsLogin { login: string, password: string, email: string }
interface Caurosel { image: string, title: string, content: string }

type Props = {
    authentication: (params: { login: string, password: string }) => Promise<any>
    resetPassword: (params: { email: string, login?: string }) => Promise<any>
    error: boolean
    waiting: boolean
}

const LoginPage: React.FC<Props> = ({ authentication, resetPassword, error, waiting }: Props) => {

    /** CARROSEL */
    const TIME_INTERVAL_CAROUSEL = 5000
    const carousel: Caurosel[] = [
        {
            image: require("../../images/imagen-montadora-login-1.png"),
            title: "Intelligent Supply",
            content: "Transformando Informação em Conhecimento Estratégico!"
        },
        {
            image: require("../../images/imagen-montadora-login-2.png"),
            title: "IS-COMPRAS",
            content: "Sistema integrado para análise e planejamento de estoque e compras. Customizável para todos os segmentos do varejo: Concessionárias, Supermercados, Farmácias, entre outros"
        },
        {
            image: require("../../images/imagen-montadora-login-3.png"),
            title: "LUME",
            content: "O Intelligent Supply fornece informações essenciais para auxiliar sua empresa nas tomadas de decisões estratégicas, visando o aumento da lucratividade, redução de custos e otimização de recursos"
        }
    ]

    /** ALERTA */
    function Alert() {
        if (error)
            return (
                <div className={"alert alert-danger fade show mx-5 mt-2 alert-dismissible"} role="alert">
                    Ops!!!, algo deu errado ou seu usuário pode estar bloqueado, tente novamente
                </div>
            )

        return (<></>)
    }

    /** MODAL ESQUECI MINHA SENHA */
    const [showEmailNeeded, updateShowEmailNeeded] = useState<boolean>(false)
    const [showModalForgotPassword, updateShowModalForgotPassword] = useState<boolean>(false)
    const openModalForgotPassword = () => { updateShowModalForgotPassword(true)
                                            unregister("login") }
    const closeModalForgotPassword = () => { updateShowModalForgotPassword(false) }

    /** FORM HOOK */
    const { register, getValues, setFocus, unregister } = useForm<InputsLogin>()

    /** LOGIN */
    const handleLogin = async () => {
        await authentication({ login: getValues("login"), password: getValues("password") })
    }

    /** TROCA DE SENHA */
    const handleForgotPassword = async () => {
        if(getValues("email") === "" || getValues("email") === null || getValues("email") === undefined){
            updateShowEmailNeeded(true)
        } else {
            await resetPassword({ email: getValues("email"), login: getValues("login") })
            closeModalForgotPassword()
            updateShowEmailNeeded(false)
        }
    }

    /** INICIO */
    useEffect(() => {
        setFocus("login")
    }, [])

    /** RENDER */
    return (
        <>
            <div style={{ overflow: "hidden" }}>
                <Row>
                    <Col md={7} >
                        <Carousel>
                            {carousel.map((value, index) => {
                                return (
                                    <Carousel.Item interval={TIME_INTERVAL_CAROUSEL} style={{ height: "100vh" }} key={index}>
                                        <img height={"100%"} width={"100%"} src={value.image} alt="First slide" />
                                        <Carousel.Caption>
                                            <Card className="opacity-75 text-dark">
                                                <Card.Body>
                                                    <Card.Title>{value.title}</Card.Title>
                                                    <Card.Text>{value.content}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                    </Col>
                    <Col md={5} sm={12} className="d-flex justify-content-center align-items-center">

                        {waiting && <Loading />}

                        {!waiting &&
                            <Container>
                                <Alert />
                                <div className="card border-0">
                                    <div className="card-body">
                                        <h2 className="card-title text-center">Faça seu login</h2>
                                        <Container className="mt-5">
                                            <Row>
                                                <Col>
                                                    <Form.Label>Login</Form.Label>
                                                    <Form.Control type="text" {...register("login")} placeholder="Digite o seu usuário" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Senha</Form.Label>
                                                    <Form.Control type="password" {...register("password")} placeholder="Digite a sua senha" />
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col xs={4} sm={5} md={4}>
                                                    <Button size="sm" onClick={handleLogin}>Entrar</Button>
                                                </Col>
                                                <Col xs={8} sm={7} md={8} className="d-flex justify-content-end">
                                                    <Button variant="outline-primary" onClick={openModalForgotPassword} size="sm">Esqueci a senha</Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </Container>
                        }
                    </Col>
                </Row>
            </div>
            <ModalComponent
                isOpen={showModalForgotPassword}
                title={"Esqueci minha senha"}
                children={{
                    header: undefined,
                    body:
                        <>
                            <Row>
                                <Col>
                                    <Form.Label>E-mail:</Form.Label>
                                    <Form.Control type="text" {...register("email")} placeholder="Digite o seu email" />
                                </Col>
                            </Row>
                            {showEmailNeeded && 
                                <p style={{ color: "red"}}>*Email necessário</p>
                            }
                            <Row className="mt-3">
                                <Col>
                                    <Form.Label>Login (Opcional):</Form.Label>
                                    <Form.Control type="text" {...register("login")} placeholder="Digite o seu usuário" />
                                </Col>
                            </Row>
                        </>,
                    footer:
                        <>
                            <div className="d-flex align-items-center justify-content-end">
                                <Button variant="primary" size="sm" onClick={handleForgotPassword}>
                                    Enviar
                                </Button>
                            </div>
                        </>
                }}
                open={openModalForgotPassword}
                close={closeModalForgotPassword}
            />
        </>
    )
}

export default LoginPage
