import { Loading } from "presentation/components/loading/loading-component";
import { Button, Col, Container, Form, Navbar, Row } from "react-bootstrap";
import { BiHide, BiShow } from "react-icons/bi";
import { ReactComponent as SVGIcon } from "presentation/images/logo-is-compras.svg";
import { useState } from "react";

type Props = {
    togglePassword: () => any;
    handleGoToLogin: () => any;
    resetPassword: (params: {
        password: string;
        verificationPassword: string;
    }) => Promise<any>;
    isPasswordOk: (params: {
        password: string;
        verificationPassword: string;
    }) => any;
    passwordOK: boolean;
    isShowPassword: boolean;
    error: boolean;
    success: boolean;
    isLoading: boolean;
};

interface Password {
    password: string;
    verificationPassword: string;
}

var newPass: Password = {
    password: "",
    verificationPassword: "",
};

const ResetPasswordPage: React.FC<Props> = ({
    togglePassword,
    handleGoToLogin,
    resetPassword,
    isPasswordOk,
    passwordOK,
    isShowPassword,
    error,
    success,
    isLoading,
}: Props) => {
    const [newPassword, updateNewPassword] = useState<Password>(newPass);
    const [errorList, updateErrorList] = useState<string[]>([]);

    const handleIsPasswordOk = () => {
        isPasswordOk({
            password: newPass.password,
            verificationPassword: newPass.verificationPassword,
        });
        updateNewPassword({
            password: newPass.password,
            verificationPassword: newPass.verificationPassword,
        });
    };

    const handleResetPassword = async () => {
        updateErrorList([]);

        if (
            validarSenha(newPassword.password) &&
            validarSenha(newPassword.verificationPassword)
        ) {
            await resetPassword(newPassword);
        } else {
            updateErrorList(["A senha esta no padrão errado"]);
        }
    };

    function validarSenha(senha: string): boolean {
        // Verificar se a senha tem pelo menos 8 caracteres
        if (senha.length < 8) {
            return false;
        }

        // Verificar se a senha tem pelo menos 1 caractere especial
        const caractereEspecialRegex = /[!@#$%^&*(),.?":{}|<>]/;
        if (!caractereEspecialRegex.test(senha)) {
            return false;
        }

        // Verificar se a senha tem pelo menos 1 dígito numérico
        const digitoNumericoRegex = /\d/;
        if (!digitoNumericoRegex.test(senha)) {
            return false;
        }

        // A senha passou por todas as validações
        return true;
    }

    /** RENDER */
    return (
        <>
            <Navbar
                expand="lg"
                style={{ backgroundColor: "rgba(202, 220, 251, 1)" }}
            >
                <Container>
                    <Navbar.Brand>
                        <SVGIcon height={"40px"} />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <div
                style={{
                    overflow: "hidden",
                    height: "75%",
                    width: "100%",
                    position: "absolute",
                }}
                className="d-flex align-items-center justify-content-center"
            >
                <Col md={5} sm={12}>
                    {isLoading && <Loading />}

                    {!isLoading && (
                        <Container>
                            <div className="card border-0">
                                <div className="card-body">
                                    <h2 className="card-title text-center">
                                        Digite sua Nova Senha
                                    </h2>
                                    <Container className="mt-5">
                                        <Row>
                                            <Col md={8} className="mt-1">
                                                <Form.Label>
                                                    Nova Senha:
                                                </Form.Label>
                                                <Form.Control
                                                    type={
                                                        isShowPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    value={newPass.password}
                                                    onChange={(event) => {
                                                        newPass.password =
                                                            event.target.value;
                                                        handleIsPasswordOk();
                                                    }}
                                                    placeholder="Digite a nova senha"
                                                />
                                            </Col>
                                            <Col className="mt-1 pb-1 d-flex align-items-end">
                                                <Button
                                                    className="m-0 p-0 bg-white border-0"
                                                    onClick={() =>
                                                        togglePassword()
                                                    }
                                                >
                                                    {isShowPassword && (
                                                        <BiShow
                                                            size={30}
                                                            color="#017BFE"
                                                        />
                                                    )}
                                                    {!isShowPassword && (
                                                        <BiHide
                                                            size={30}
                                                            color="#017BFE"
                                                        />
                                                    )}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8} className="mt-4">
                                                <Form.Label>
                                                    Confirme a Nova Senha:
                                                </Form.Label>
                                                <Form.Control
                                                    type={
                                                        isShowPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    value={
                                                        newPass.verificationPassword
                                                    }
                                                    onChange={(event) => {
                                                        newPass.verificationPassword =
                                                            event.target.value;
                                                        handleIsPasswordOk();
                                                    }}
                                                    placeholder="Confirme a sua senha"
                                                />
                                                {!passwordOK &&
                                                    newPass.verificationPassword !==
                                                        undefined &&
                                                    newPass.verificationPassword !==
                                                        "" && (
                                                        <Form.Label
                                                            className="mt-2"
                                                            style={{
                                                                color: "#F90000",
                                                            }}
                                                        >
                                                            As senhas devem ser
                                                            iguais
                                                        </Form.Label>
                                                    )}
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center justify-content-center">
                                            {error && (
                                                <Col
                                                    md={"auto"}
                                                    className="mt-3 card border-0 bg-danger text-white"
                                                >
                                                    <Form.Label className="mt-2">
                                                        Houve um problema, tente
                                                        novamente mais tarde.
                                                    </Form.Label>
                                                </Col>
                                            )}
                                            {errorList.length > 0 && (
                                                <Col
                                                    md={"auto"}
                                                    className="mt-3 card border-0 bg-danger text-white"
                                                >
                                                    {errorList.map((error) => {
                                                        return (
                                                            <Form.Label
                                                                className="mt-2"
                                                                key={error}
                                                            >
                                                                {error}
                                                            </Form.Label>
                                                        );
                                                    })}
                                                </Col>
                                            )}
                                            {success && (
                                                <Col
                                                    md={"auto"}
                                                    className="mt-3 card border-0 bg-success text-white"
                                                >
                                                    <Form.Label className="mt-2">
                                                        Senha resetada com
                                                        sucesso.
                                                    </Form.Label>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={4} sm={5} md={4}>
                                                <Button
                                                    size="sm"
                                                    disabled={!passwordOK}
                                                    onClick={
                                                        handleResetPassword
                                                    }
                                                >
                                                    Confirmar
                                                </Button>
                                            </Col>
                                            <Col
                                                xs={8}
                                                sm={7}
                                                md={8}
                                                className="d-flex justify-content-end mb-3"
                                            >
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    onClick={handleGoToLogin}
                                                >
                                                    Ir para o Login
                                                </Button>
                                            </Col>
                                            <p className="mt-3 text-muted">
                                                A senha deve seguir o seguinte
                                                padrão:
                                            </p>
                                            <ul className="ms-3">
                                                <li className="text-muted">
                                                    <small>
                                                        Mínimo 8 caractéres (sem
                                                        máximo)
                                                    </small>
                                                </li>
                                                <li className="text-muted">
                                                    <small>
                                                        Mínimo 1 caractér
                                                        especial
                                                    </small>
                                                </li>
                                                <li className="text-muted">
                                                    <small>
                                                        Mínimo 1 dígito numérico
                                                    </small>
                                                </li>
                                            </ul>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </Container>
                    )}
                </Col>
            </div>
        </>
    );
};

export default ResetPasswordPage;
