import { Card, Carousel, Row } from "react-bootstrap"

type Props = {
    panel?: any[]
    controls?: boolean
}

export function Painel({ panel, controls }: Props) {

    const TIME_INTERVAL_CAROUSEL = 5000

    if (panel) {
        return (
            <Card style={{ width: "1000px" }}>
                <Carousel variant="dark" indicators={false} controls={controls === undefined ? true : false}>
                    {panel.map((value, index) => {
                        return (
                            <Carousel.Item key={index} interval={TIME_INTERVAL_CAROUSEL}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <Row>
                                        {value.map((vl: any, idx: any) => {
                                            return (
                                                <Card
                                                    style={{ width: '20rem' }}
                                                    border="light border-0"
                                                    key={idx}
                                                    className='my-1'
                                                >
                                                    <Card.Body>
                                                        <Card.Title style={{ color: "#0433BF", fontSize: "15px", textAlign: "center" }}>{vl.number}</Card.Title>
                                                        <Card.Text style={{ fontSize: "10px", textAlign: "center" }}>
                                                            {vl.label}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </Card>
        )
    }

    return <></>
}
