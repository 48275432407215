import {Button, Container, Modal, Table} from "react-bootstrap"
import { Headers } from "presentation/components/header/headers-component"
import { UseAuth } from "presentation/hook/auth-hook"
import { useEffect, useState } from "react"
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter"
import { useNavigate } from "react-router-dom"
import { getEnv } from 'main/helper/window-helper';
import { RestUseCase } from 'data/useCase/rest-usecase';
import { MdInfo } from 'react-icons/md';
import ReactTooltip from "react-tooltip";

interface ErrorType {
  message: string;
}

interface CargaLogInterface {
    arqFilIntCod: number;
    filStrNmeFantasia: String;
    arqDtmCadastroUlt: string;
    statusExtrator: string;
    ultimaCompra: string;
    ultimaVenda: string;
  }

const Home: React.FC = () => {
    const ENDPOINT_CARGA_LOG = getEnv('REACT_APP_END_POINT_CARGA_LOG');

    // Estados para armazenar os dados de carga, carregamento e erro
    const [cargaLogs, setCargaLogs] = useState<CargaLogInterface[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<ErrorType | null>(null);
    const [showModal, setShowModal] = useState(false);

    /** VERIFICA PERMISSAO DE ACESSO A TELA GERAR SCRAP */
    const { loggedUserData } = UseAuth()
    let navigate = useNavigate()

    // useEffect para verificar permissões
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Scrap")[0].navItem.filter(item => item.title === "Gerar Scrap")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    navigate("/")
                //todo: voltando a tela de bem vindo apos o login
            })
        }

        fetchCargaLogs(loggedUserData?.companyId);
    }, [loggedUserData])

    const fetchCargaLogs = async (companyId?: number) => {
        setIsLoading(true);
        if((companyId !== null) && (companyId !== undefined)){
            try {
                const id = companyId;
                const response = await new RestUseCase(`${ENDPOINT_CARGA_LOG}/${id}`).Get();
                setCargaLogs(response.body);

            } catch (err: any) {
                setError({ message: err.message });
                console.error("Erro ao buscar dados de carga:", err);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <Headers />
            <Container>
                <h1 className="mt-3">Bem vindo ao novo IS-COMPRAS</h1>
                {isLoading && <p>Carregando dados...</p>}
                {error && <p>Erro ao carregar dados: {error.message}</p>}
                {cargaLogs && cargaLogs.length > 0 && (
                    <Table striped bordered hover className="mt-3">
                        <thead>
                            <tr>
                                <th colSpan={6} className="text-center">
                                    Validação dos últimos dados recebidos do seu ERP
                                </th>
                            </tr>
                            <tr>
                                <th className="text-center">Filial</th>
                                <th className="text-center">Extrator</th>
                                <th className="text-center">Último processamento</th>
                                <th className="text-center">Última compra</th>
                                <th className="text-center">Última venda</th>
                            </tr>
                        </thead>
                        <tbody className="text-center" >
                            {cargaLogs.map((cargaLog) => (
                                <tr key={cargaLog.arqFilIntCod}>
                                    <td>{cargaLog.filStrNmeFantasia}</td>
                                    <td>{cargaLog.statusExtrator}</td>
                                    <td>
                                        {cargaLog.arqDtmCadastroUlt}
                                    </td>
                                    <td>{cargaLog.ultimaCompra}</td>
                                    <td>{cargaLog.ultimaVenda}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Container>
        </>
    )
}

export default Home