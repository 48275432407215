/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Button, Card, Col, Collapse, Form, Modal, Nav, Row, Table } from 'react-bootstrap';

import { SCDesctiption } from 'presentation/style/typography/titlePage';

import { Footer } from 'presentation/components/footer/footer-component';
import { Headers } from 'presentation/components/header/headers-component';
import { FiltersDefault } from 'domain/interfaces/filter-interface';
import { useEffect, useState } from 'react';
import { SpinnerSC } from 'presentation/style/spinner/spinner-styled';
import { UseAlert } from 'presentation/hook/alert-hook';
import {
  Agrupamentos,
  Periodo,
  ResponseIndicator,
  Variacao,
  VariacaoAgrupamentos,
  VariacaoCurvas,
} from 'domain/types/indicator-request-type';
import { BsSliders } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';
import ContainerFilter from 'presentation/components/container/container-filter-component';
import { useForm } from 'react-hook-form';
import {
  BarChart,
  TooltipProps,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
} from 'recharts';
import { ParseDateToEn } from 'main/helper/format-date-helper';
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { getEnv } from '../../../main/helper/window-helper';
import { getLocalStorage, updateLocalStorege } from '../../../data/cache/localstorage-cache';
import { TitlePageComponent } from '../../components/title/title-page-component';
import { TitleGroupComponent } from '../../components/title/title-group-component';

type HandleRequest = {
  type: string;
  pagination?: string;
  model?: any;
  metric?: string;
  period?: string[];
  grouping?: string;
  checkByDateOfObsolescence?: string;
  quantityObsolescence?: number;
  sizeObsolescence?: number;
};

let colors = ['#8b7551', '#4f8567', '#767a3e', '#cb8273', '#2f637a', '#AEFFD1', '#ffd48e', '#F9FF9A', '#FF8C76', '#B8E9FF', '#B8E9FF', '#FF8C76'];

type Props = {
  optionsFiltersDefault: FiltersDefault[];
  list: (params?: any) => Promise<ResponseIndicator>;
  listByIdPlanning: (params?: any) => Promise<any>;
  listAllPlanning: (params?: any) => Promise<any>;
  createPlanning: (params?: any) => Promise<any>;
  editPlanning: (params?: any) => Promise<any>;
  removePlanning: (params?: any) => Promise<any>;
  exportFileExcel: (params?: any) => Promise<any>;
  filiais: SelectOptionInterface[];
  availableMonths: String[];
};

const letters: {
  letter: string;
  color: string
}[] = [
  { letter: 'A', color: '#AEFFD1' },
  { letter: 'B', color: '#ffd48e' },
  { letter: 'C', color: '#F9FF9A' },
  { letter: 'D', color: '#FF8C76' },
  { letter: 'E', color: '#B8E9FF' },
  { letter: 'F', color: '#B8E9FF' },
  { letter: 'N', color: '#FF8C76' },
];

const titlePage = 'Indicador';

const IndicatorPageV2: React.FC<Props> = ({
                                            optionsFiltersDefault,
                                            list,
                                            listByIdPlanning,
                                            listAllPlanning,
                                            createPlanning,
                                            editPlanning,
                                            removePlanning,
                                            exportFileExcel,
                                            filiais,
                                            availableMonths,
                                          }: Props) => {
  /**
   *
   * LOADING
   *
   */
  const [loading, updateLoading] = useState<boolean>(false);

  function Loading() {
    return (
      <SpinnerSC>
        <div className='spinner-border text-secondary' role='status'></div>
      </SpinnerSC>
    );
  }

  /**
   *
   * TABLE
   *
   */
  const [activeItem, updateActiveItem] = useState<string>('Estoque');
  const [listTableModelObsolescencia, updateListTableModelObsolescencia] = useState<any[]>([]);
  const [periodos, updatePeriodos] = useState<string[]>([]);
  const hoje = new Date();
  const [period, updatePeriod] = useState<string[]>(dataNosUltimosQuatroMeses(hoje)); //ajusatar ultimos 3 meses e atual

  function mesAnterior(date: string | number | Date, diff: number) {
    const d = new Date(date);
    d.setMonth(d.getMonth() + diff);
    return d.toString();
  }

  function dataNosUltimosQuatroMeses(data: string | number | Date) {
    const datas = [];
    for (let i = 1; i < 5; i++) {
      var dataFormatada = ParseDateToEn(mesAnterior(data, i * -1));
      datas.push(dataFormatada.substring(0, dataFormatada.length - 3));
    }
    return datas;
  }

  function parseDateIndicator(date: string) {
    var dataArray = date.split('-');
    return dataArray[1] + '/' + dataArray[0];
  }

  const [metric, updateMetric] = useState<string>('CUSTO_MEDIO');
  const [grouping, updateGrouping] = useState<string>('pontuacao');
  const [rangeOfMonthsForGrouping, updateRangeOfMonthsForGrouping] = useState<number>(3);
  const [numberOfRangeBreaks, updateNumberOfRangeBreaks] = useState<number>(12);
  const [checkByDateOf, updateCheckByDateOf] = useState<string>('obsolescencia-venda');
  const [listTableModel, updateListTableModel] = useState<ResponseIndicator>();

  async function handleActiveItem(item: string) {
    updateActiveItem(item);
  }

  function handleTotalByDate(params: any[], periodTotal: string[]) {
    let valores = periodTotal.reduce((accumulator, value) => {
      return { ...accumulator, [value]: 0 };
    }, []) as any;

    periodTotal.forEach((value, key) => {
      params.forEach((vl) => {
        if (vl.obsolescencias) {
          let obsolecenciaFilterByDate = vl.obsolescencias.filter((vl_f: {
            anoMes: string
          }) => vl_f.anoMes === value);

          let valorObsolevencia;

          if (obsolecenciaFilterByDate.length === 0) {
            valorObsolevencia = 0;
          } else {
            valorObsolevencia = obsolecenciaFilterByDate[0].quantidadeObsolescencia;
          }

          if (getValues().typeOfInformation! === 'CUSTO_MEDIO') {
            let obsolecenciaCustoMedioFilterByDate = vl.obsolescencias.filter((vl_f: {
              anoMes: string
            }) => vl_f.anoMes === value);

            if (obsolecenciaCustoMedioFilterByDate.length === 0) {
              valorObsolevencia = 0;
            } else {
              valorObsolevencia = obsolecenciaCustoMedioFilterByDate[0].custoMedio;
            }
          }

          if (getValues().typeOfInformation! === 'VALOR_REPOSICAO') {
            let obsolecenciaValorReposicaoFilterByDate = vl.obsolescencias.filter((vl_f: {
              anoMes: string
            }) => vl_f.anoMes === value);

            if (obsolecenciaValorReposicaoFilterByDate.length === 0) {
              valorObsolevencia = 0;
            } else {
              valorObsolevencia = obsolecenciaValorReposicaoFilterByDate[0].valorReposicao;
            }
          }

          if (getValues().typeOfInformation! === 'VALOR_VENDA') {
            let obsolecenciaValorVendaFilterByDate = vl.obsolescencias.filter((vl_f: {
              anoMes: string
            }) => vl_f.anoMes === value);

            if (obsolecenciaValorVendaFilterByDate.length === 0) {
              valorObsolevencia = 0;
            } else {
              valorObsolevencia = obsolecenciaValorVendaFilterByDate[0].valorVenda;
            }
          }

          if (getValues().typeOfInformation! === 'QUANTIDADE_ITENS') {
            let obsolecenciaQuantidadeItensFilterByDate = vl.obsolescencias.filter((vl_f: {
              anoMes: string
            }) => vl_f.anoMes === value);

            if (obsolecenciaQuantidadeItensFilterByDate.length === 0) {
              valorObsolevencia = 0;
            } else {
              valorObsolevencia = obsolecenciaQuantidadeItensFilterByDate[0].quantidadeObsolescencia;
            }
          }

          valores[value] += valorObsolevencia;
        }
      });
    });

    updateTotalTotalByDate(valores);
  }

  const [totalTotalByDate, updateTotalTotalByDate] = useState<any[]>([]);

  async function handleTable(params?: HandleRequest, filter?: any) {
    updateLoading(true);

    setValue('periodSearch', params?.period ? params?.period : period);
    setValue('rangeOfMonthsForGrouping', params?.quantityObsolescence ? params?.quantityObsolescence.toString() : rangeOfMonthsForGrouping.toString());
    setValue('numberOfRangeBreaks', params?.sizeObsolescence ? params?.sizeObsolescence.toString() : numberOfRangeBreaks.toString());
    setValue('checkByDateOf', params?.checkByDateOfObsolescence ? params?.checkByDateOfObsolescence : checkByDateOf);
    setValue('groupingChanged', params?.grouping ? params?.grouping : grouping);
    setValue('typeOfInformation', params?.metric ? params?.metric : metric);

    var p = '';
    getValues().periodSearch!.forEach((value) => {
      p += `&p=${value}`;
    });

    let modelRequest = {};

    if (params?.type !== 'OBS') {
      modelRequest = {
        type: 'GERAL',
        pagination: ``,
        model: {},
        metric: params?.metric ? params?.metric : metric,
        period: p.toString(),
        grouping: params?.grouping ? params?.grouping : grouping,
        checkByDateOf: '',
        quantity: 0,
        size: 0,
        filter: filter,
      };
    } else {
      modelRequest = {
        type: 'OBS',
        pagination: ``,
        model: {},
        metric: params?.metric ? params?.metric : metric,
        period: p.toString(),
        grouping: ``,
        checkByDateOf: params?.checkByDateOfObsolescence ? params?.checkByDateOfObsolescence : checkByDateOf,
        quantity: params?.quantityObsolescence ? params?.quantityObsolescence : rangeOfMonthsForGrouping,
        size: params?.sizeObsolescence ? params?.sizeObsolescence : numberOfRangeBreaks,
        filter: filter,
      };
    }

    await list(modelRequest)
      .then((response) => {
        if (params?.type === 'OBS') {
          updateListTableModelObsolescencia(response as any);
          handleTotalByDate(response as any, params?.period ? params?.period : period);
        } else {
          let periodoList: string[] = [];

          response.estoque.periodos.forEach((element) => {
            periodoList.push(element.periodo);
          });
          updatePeriodos(periodoList);

          updateListTableModel(response);
        }

        updatePeriod(params?.period ? params?.period.sort() : period.sort());
      })
      .catch((error) => console.log('error', error));

    updateLoading(false);
  }

  async function handleExcel(params?: HandleRequest, filter?: any) {
    updateLoading(true);

    setValue('periodSearch', params?.period ? params?.period : period);
    setValue('rangeOfMonthsForGrouping', params?.quantityObsolescence ? params?.quantityObsolescence.toString() : rangeOfMonthsForGrouping.toString());
    setValue('numberOfRangeBreaks', params?.sizeObsolescence ? params?.sizeObsolescence.toString() : numberOfRangeBreaks.toString());
    setValue('checkByDateOf', params?.checkByDateOfObsolescence ? params?.checkByDateOfObsolescence : checkByDateOf);
    setValue('groupingChanged', params?.grouping ? params?.grouping : grouping);
    setValue('typeOfInformation', params?.metric ? params?.metric : metric);

    var p = '';
    getValues().periodSearch!.forEach((value) => {
      p += `&p=${value}`;
    });

    let modelRequest = {};

    if (params?.type !== 'OBS') {
      modelRequest = {
        type: 'GERAL',
        pagination: ``,
        model: {},
        metric: params?.metric ? params?.metric : metric,
        period: p.toString(),
        grouping: params?.grouping ? params?.grouping : grouping,
        checkByDateOf: '',
        quantity: params?.quantityObsolescence ? params?.quantityObsolescence : rangeOfMonthsForGrouping,
        size: params?.sizeObsolescence ? params?.sizeObsolescence : numberOfRangeBreaks,
        filter: filter,
      };
    } else {
      modelRequest = {
        type: 'OBS',
        pagination: ``,
        model: {},
        metric: params?.metric ? params?.metric : metric,
        period: p.toString(),
        grouping: ``,
        checkByDateOf: params?.checkByDateOfObsolescence ? params?.checkByDateOfObsolescence : checkByDateOf,
        quantity: params?.quantityObsolescence ? params?.quantityObsolescence : rangeOfMonthsForGrouping,
        size: params?.sizeObsolescence ? params?.sizeObsolescence : numberOfRangeBreaks,
        filter: filter,
      };
    }

    await exportFileExcel(modelRequest)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.body]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Indicador.xlsx`);
        document.body.appendChild(link);
        link.click();
        link!.parentNode!.removeChild(link);
      })
      .catch((error) => console.log('error', error));

    updateLoading(false);
  }

  /**
   *
   * PLANNING
   *
   */
  const [showModalPlanning, updateShowModalPlanning] = useState(false);

  const closePlanning = () => updateShowModalPlanning(false);
  const showPlanning = () => updateShowModalPlanning(true);
  const [itemPlanning, updateItemPlanning] = useState<number>(0);
  const LOCALSTORAGE_PLANNING = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_INDICATOR')!;

  async function handleApplyPlanning(param: any) {
    updateLoading(true);
    handleTable({ type: 'GERAL' }, param);
    handleTable({ type: 'OBS' }, param);

    let countPlanning = param.length;

    /** salva no localstorage */
    updateLocalStorege(LOCALSTORAGE_PLANNING, param);
    updateItemPlanning(countPlanning);

    closePlanning();
    updateLoading(false);
  }

  function ModalPlanning() {
    return (
      <Modal show={showModalPlanning} onHide={closePlanning} size={'xl'} fullscreen={'md-down'}>
        <Modal.Header closeButton>
          <Modal.Title className='ms-3 lead' style={{ fontSize: '30px' }}>
            PLANEJAMENTO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='mt-3'>
            <Col>
              <ContainerFilter
                apply={handleApplyPlanning}
                filtersDefault={optionsFiltersDefault}
                listAllPlanning={listAllPlanning}
                listByIdPlanning={function(params?: any): Promise<any> {
                  throw new Error('Function not implemented.');
                }}
                createPlanning={createPlanning}
                editPlanning={function(params?: any): Promise<any> {
                  throw new Error('Function not implemented.');
                }}
                removePlanning={removePlanning}
                keyLocalStorage={LOCALSTORAGE_PLANNING}
                filterLocalStorage={getLocalStorage(LOCALSTORAGE_PLANNING)}
                isOnlineConsultant={false}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  /**
   *
   * VISUALIZATION
   *
   */
  const [showModalVisualization, updateShowModalVisualization] = useState(false);

  const closeVisualization = () => updateShowModalVisualization(false);
  const showColumnVisualization = () => updateShowModalVisualization(true);


  const currentYear = new Date().getFullYear();
  const calendar: {
    year: number;
    month: { label: string; value: string, enabled: boolean }[];
  }[] = [];

  for (let yearOffset = -5; yearOffset <= 0; yearOffset++) {
    const year = currentYear + yearOffset;
    calendar.push({
      year,
      month: [
        { enabled: availableMonths.includes(`${year}-01`), label: 'Janeiro', value: `${year}-01` },
        { enabled: availableMonths.includes(`${year}-02`), label: 'Fevereiro', value: `${year}-02` },
        { enabled: availableMonths.includes(`${year}-03`), label: 'Março', value: `${year}-03` },
        { enabled: availableMonths.includes(`${year}-04`), label: 'Abril', value: `${year}-04` },
        { enabled: availableMonths.includes(`${year}-05`), label: 'Maio', value: `${year}-05` },
        { enabled: availableMonths.includes(`${year}-06`), label: 'Junho', value: `${year}-06` },
        { enabled: availableMonths.includes(`${year}-07`), label: 'Julho', value: `${year}-07` },
        { enabled: availableMonths.includes(`${year}-08`), label: 'Agosto', value: `${year}-08` },
        { enabled: availableMonths.includes(`${year}-09`), label: 'Setembro', value: `${year}-09` },
        { enabled: availableMonths.includes(`${year}-10`), label: 'Outubro', value: `${year}-10` },
        { enabled: availableMonths.includes(`${year}-11`), label: 'Novembro', value: `${year}-11` },
        { enabled: availableMonths.includes(`${year}-12`), label: 'Dezembro', value: `${year}-12` },
      ],
    })
  }

  const { register, getValues, setValue } = useForm<{
    groupingChanged?: string;
    typeOfInformation?: string;
    periodSearch?: string[];
    rangeOfMonthsForGrouping: string;
    numberOfRangeBreaks: string;
    checkByDateOf: string;
  }>();

  function ModalVisualization() {
    return (
      <Modal show={showModalVisualization} onHide={closeVisualization} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Visualização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeItem !== 'Obsolescencia' && (
            <div>
              <Card>
                <Card.Body>
                  <div>
                    <Form.Label className='me-3'>Agrupamento:</Form.Label>
                  </div>
                  <div>
                    <Form.Check inline label='Pontuação' type='radio'
                                value='pontuacao' {...register('groupingChanged')} />
                    <Form.Check inline label='Filial' type='radio' value='filiais' {...register('groupingChanged')} />
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}

          {activeItem === 'Obsolescencia' && (
            <div>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Label className='me-3'>Intervalo de meses para agrupamento:</Form.Label>
                      <Form.Control type='number' {...register('rangeOfMonthsForGrouping')} />
                    </Col>
                    <Col md={4}>
                      <Form.Label column className='me-3'>
                        Verificar pela data da:
                      </Form.Label>
                      <Form.Check inline label='Última Compra' type='radio'
                                  value='obsolescencia-compra' {...register('checkByDateOf')} />
                      <Form.Check inline label='Última Venda' type='radio'
                                  value='obsolescencia-venda' {...register('checkByDateOf')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Label className='me-3'>Quantidade de quebras do intervalo:</Form.Label>
                      <Form.Control type='number' {...register('numberOfRangeBreaks')} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          )}

          <Card className='mt-2'>
            <Card.Body>
              <div>
                <Form.Label className='me-3'>Tipo de Informação:</Form.Label>
              </div>
              <div>
                <Form.Check inline label='Vlr. Custo Médio' type='radio'
                            value='CUSTO_MEDIO' {...register('typeOfInformation')} />
                <Form.Check inline label='Vlr. Reposição' type='radio'
                            value='VALOR_REPOSICAO' {...register('typeOfInformation')} />
                <Form.Check inline label='Vlr. Venda' type='radio'
                            value='VALOR_VENDA' {...register('typeOfInformation')} />
                <Form.Check inline label='Qtd. do Item em Estoque' type='radio'
                            value='QUANTIDADE_ITENS' {...register('typeOfInformation')} />
              </div>
            </Card.Body>
          </Card>

          <Card className='mt-2'>
            <Card.Body>
              <div>
                <Form.Label className='me-3'>Períodos:</Form.Label>
              </div>

              {calendar.map((vl, i) => {
                return (
                  <Card className='mt-2' key={i}>
                    <Card.Body>
                      <div>
                        <Form.Label className='me-3'>{vl.year}</Form.Label>
                      </div>

                      {vl.month.map((v, idx) => {
                        return <Form.Check key={idx} inline disabled={!v.enabled} label={v.label} type='checkbox'
                                           value={v.value} {...register('periodSearch')} />;
                      })}
                    </Card.Body>
                  </Card>
                );
              })}
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              updateMetric(getValues().typeOfInformation!);
              updateGrouping(getValues().groupingChanged!);
              updateRangeOfMonthsForGrouping(parseInt(getValues().rangeOfMonthsForGrouping!));
              updateNumberOfRangeBreaks(parseInt(getValues().numberOfRangeBreaks!));
              updateCheckByDateOf(getValues().checkByDateOf!);

              setValue('periodSearch', getValues().periodSearch!);
              setValue('rangeOfMonthsForGrouping', getValues().rangeOfMonthsForGrouping!);
              setValue('numberOfRangeBreaks', getValues().numberOfRangeBreaks!);
              setValue('checkByDateOf', getValues().checkByDateOf!);
              setValue('groupingChanged', getValues().groupingChanged!);
              setValue('typeOfInformation', getValues().typeOfInformation!);

              handleTable({
                type: 'OBS',
                pagination: ``,
                model: {},
                metric: getValues().typeOfInformation!,
                period: getValues().periodSearch!,
                grouping: '',
                checkByDateOfObsolescence: getValues().checkByDateOf!,
                quantityObsolescence: parseInt(getValues().rangeOfMonthsForGrouping!),
                sizeObsolescence: parseInt(getValues().numberOfRangeBreaks!),
              });

              handleTable({
                type: 'GERAL',
                pagination: ``,
                model: {},
                metric: getValues().typeOfInformation!,
                period: getValues().periodSearch!,
                grouping: getValues().groupingChanged!,
                checkByDateOfObsolescence: ``,
                quantityObsolescence: 0,
                sizeObsolescence: 0,
              });

              closeVisualization();
            }}>
            Aplicar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   *
   * GRAFICO
   *
   */

  var countGrafico = 0;

  const [curveChecked, updateCurveChecked] = useState<string[]>([]);

  function CollapseGraphic() {
    const [data, updateData] = useState<any[]>([]);
    const [dataPie, updateDataPie] = useState<any[]>([]);

    function changeCurve(params: any) {
      if (curveChecked?.includes(params)) updateCurveChecked(curveChecked.filter((e) => e !== params));
      else updateCurveChecked([...curveChecked!, params]);
      updateData([]);
    }

    function generateDataForTheChart() {
      var dataChange: any[] = [];
      let dataChangePie: any[] = [];

      if (activeItem.toLowerCase() !== 'obsolescencia') {
        if (curveChecked.length > 0) {
          if (curveChecked.length === 1) {
            periodos.forEach((prd) => {
              var dataChangeObj: any = {};
              var pie: any[] = [];

              dataChangeObj['name'] = prd;
              let agrupamento = getAgrupamentosByCurveAndPeriodo(prd, curveChecked[0]);
              agrupamento.forEach((agrp) => {
                dataChangeObj[agrp.agrupamento] = agrp.valor;

                let dataPieObj: any = {};

                dataPieObj['value'] = agrp.agrupamento;
                dataPieObj['name'] = agrp.valor;
                pie.push(dataPieObj);
              });
              dataChange.push(dataChangeObj);
              dataChangePie.push(pie);
            });
          } else {
            periodos.forEach((value) => {
              dataChange.push({
                name: value,
                A: curveChecked?.includes('A') ? getAgrupamentosByCurveAndPeriodo(value, 'A').reduce((sum, current) => sum + current.valor, 0) : 0,
                B: curveChecked?.includes('B') ? getAgrupamentosByCurveAndPeriodo(value, 'B').reduce((sum, current) => sum + current.valor, 0) : 0,
                C: curveChecked?.includes('C') ? getAgrupamentosByCurveAndPeriodo(value, 'C').reduce((sum, current) => sum + current.valor, 0) : 0,
                D: curveChecked?.includes('D') ? getAgrupamentosByCurveAndPeriodo(value, 'D').reduce((sum, current) => sum + current.valor, 0) : 0,
                E: curveChecked?.includes('E') ? getAgrupamentosByCurveAndPeriodo(value, 'E').reduce((sum, current) => sum + current.valor, 0) : 0,
                F: curveChecked?.includes('F') ? getAgrupamentosByCurveAndPeriodo(value, 'F').reduce((sum, current) => sum + current.valor, 0) : 0,
                N: curveChecked?.includes('N') ? getAgrupamentosByCurveAndPeriodo(value, 'N').reduce((sum, current) => sum + current.valor, 0) : 0,
              });
              var pie: any[] = [];
              curveChecked.map((letra: any) => {
                let teste: any = {};
                teste['name'] = letra;
                teste['value'] = getAgrupamentosByCurveAndPeriodo(value, letra).reduce((sum, current) => sum + current.valor, 0);
                pie.push(teste);
              });
              dataChangePie.push(pie);
            });
          }
          updateData(dataChange);
          updateDataPie(dataChangePie);
        }
      } else {
        periodos.forEach((value) => {
          var teste: any = {};
          var pie: any[] = [];
          teste['name'] = value;
          listTableModelObsolescencia.forEach((item: {
            periodo: number;
            obsolescencias: []
          }) => {
            var testePie: any = {};
            item.obsolescencias.forEach((itemObsolecencia: {
              anoMes: string;
              quantidadeObsolescencia: number
            }) => {
              if (itemObsolecencia.anoMes === value) {
                teste[item.periodo] = itemObsolecencia.quantidadeObsolescencia;
                testePie['name'] = item.periodo;
                testePie['value'] = itemObsolecencia.quantidadeObsolescencia;
                pie.push(testePie);
              }
            });
          });
          dataChange.push(teste);
          dataChangePie.push(pie);
          updateData(dataChange);
          updateDataPie(dataChangePie);
        });
      }
    }

    return (
      <div>
        <div>
          <Row className='mt-3'>
            <Col className='d-flex align-items-center justify-content-start'>
              <Button
                variant='outline-primary ms-1'
                onClick={() => {
                  generateDataForTheChart();
                }}
                size='sm'>
                Gerar Graficos
              </Button>
              {activeItem.toLowerCase() !== 'obsolescencia' ? (
                <Col>
                  <Form.Label className='mx-2'>Curvas:</Form.Label>
                  <Form.Check inline label='A' type='checkbox' value='A' checked={curveChecked?.includes('A')}
                              onChange={(e) => changeCurve(e.target.value)} />
                  <Form.Check inline label='B' type='checkbox' value='B' checked={curveChecked?.includes('B')}
                              onChange={(e) => changeCurve(e.target.value)} />
                  <Form.Check inline label='C' type='checkbox' value='C' checked={curveChecked?.includes('C')}
                              onChange={(e) => changeCurve(e.target.value)} />
                  <Form.Check inline label='D' type='checkbox' value='D' checked={curveChecked?.includes('D')}
                              onChange={(e) => changeCurve(e.target.value)} />
                  <Form.Check inline label='E' type='checkbox' value='E' checked={curveChecked?.includes('E')}
                              onChange={(e) => changeCurve(e.target.value)} />
                  <Form.Check inline label='F' type='checkbox' value='F' checked={curveChecked?.includes('F')}
                              onChange={(e) => changeCurve(e.target.value)} />
                  <Form.Check inline label='N' type='checkbox' value='N' checked={curveChecked?.includes('N')}
                              onChange={(e) => changeCurve(e.target.value)} />
                </Col>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>

        {data.length > 0 && (
          <>
            {activeItem.toLowerCase() !== 'obsolescencia' ? (
              <>
                <div>
                  <div className='d-flex align-items-center justify-content-center mt-5'>
                    <BarChart
                      width={1000}
                      height={300}
                      data={data}                      
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='name' tick={{ fontSize: 15 }} />
                      <YAxis tick={{ fontSize: 15 }}/>
                      <Tooltip content={<CustomTooltip />} />
                      <Legend />
                      {curveChecked.length === 1 ? (
                        <>
                          {Object.keys(data[0]).map((obj: any, idx: any) => {
                            if (obj !== 'name') {
                              return <Bar dataKey={obj.toString()} fill={colors[idx]} key={idx} />;
                            }
                          })}
                        </>
                      ) : (
                        <>
                          <Bar dataKey='A' fill={letters[0].color} />
                          <Bar dataKey='B' fill={letters[1].color} />
                          <Bar dataKey='C' fill={letters[2].color} />
                          <Bar dataKey='D' fill={letters[3].color} />
                          <Bar dataKey='E' fill={letters[4].color} />
                          <Bar dataKey='F' fill={letters[5].color} />
                          <Bar dataKey='N' fill={letters[6].color} />
                        </>
                      )}
                    </BarChart>
                  </div>

                  <div className='d-flex align-items-center justify-content-center mt-5'>
                    {dataPie.map((pie: any, index: number) => {
                      return (
                        <div>
                          <PieChart
                            width={350}
                            height={350}
                            key={index}
                            margin={{
                              top: 0,
                              right: 80,
                              bottom: 0,
                              left: 80,
                            }}>
                            <Tooltip content={<CustomTooltip />} />
                            <Pie
                              data={pie}
                              dataKey='value'
                              cx='50%'
                              cy='50%'
                              startAngle={90}
                              endAngle={-270}
                              labelLine={false}
                              paddingAngle={1}
                              fontSize={14}
                              isAnimationActive={false}
                              label={renderCustomizedLabel}>
                              {curveChecked.length === 1 ? (
                                <>
                                  {pie.map((mapPie: any, inx: number) => {
                                    return <Cell fill={colors[inx]} />;
                                  })}
                                </>
                              ) : (
                                <>
                                  {pie.map((mapPie: any, inx: number) => {
                                    return <Cell key={`cell-${index}`}
                                                 fill={letters.filter((letra) => letra.letter === mapPie.name)[0].color} />;
                                  })}
                                </>
                              )}
                            </Pie>
                          </PieChart>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className='d-flex align-items-center justify-content-center mt-5'>
                    <BarChart
                      width={1000}
                      height={300}
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='name' />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {Object.keys(data[0]).map((obj: any, idx: any) => {
                        countGrafico += rangeOfMonthsForGrouping;
                        if (obj !== 'name') {
                          return (
                            <Bar
                              name={`${countGrafico - rangeOfMonthsForGrouping} a ${countGrafico} MESES`}
                              dataKey={obj.toString()}
                              fill={colors[idx]}
                              key={idx}
                            />
                          );
                        }
                      })}
                    </BarChart>
                  </div>

                  <div className='d-flex align-items-center justify-content-center mt-5'>
                    {dataPie.map((pie: any, index: number) => {
                      return (
                        <div>
                          <PieChart
                            width={350}
                            height={350}
                            key={index}
                            margin={{
                              top: 0,
                              right: 80,
                              bottom: 0,
                              left: 80,
                            }}>
                            <Pie data={pie} dataKey='value' label>
                              {pie.map((mapPie: any, inx: number) => {
                                return <Cell fill={colors[inx]} />;
                              })}
                            </Pie>
                          </PieChart>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }

  const CustomTooltip = ({ active, payload, label }: TooltipProps<string, number>) => {
    if (active) {
      return (
        <div className='custom-tooltip' style={{ background: '#FFFFFF' }}>
          {label !== null && label !== undefined && label !== '' ? (
            <p
              className='label'
              style={{
                padding: '5px',
                textAlign: 'center',
                margin: '0px',
              }}>{`${label}`}</p>
          ) : (
            ''
          )}
          {payload?.map((valor) =>
            curveChecked.includes(String(valor.name)) ? (
              activeItem === 'Cobertura' ? (
                <p
                  style={{
                    background: escolherCorPorLetra(String(valor.name)),
                    margin: '0px',
                    fontWeight: 'bold',
                    padding: '5px',
                  }}
                  className='desc'>
                  {valor.name}: {Number(valor.value)?.toFixed(2)}%
                </p>
              ) : getValues().typeOfInformation! === 'QUANTIDADE_ITENS' ? (
                <p
                  style={{
                    background: escolherCorPorLetra(String(valor.name)),
                    margin: '0px',
                    fontWeight: 'bold',
                    padding: '5px',
                  }}
                  className='desc'>
                  {valor.name}: {Number(valor.value)}
                </p>
              ) : (
                <p
                  style={{
                    background: escolherCorPorLetra(String(valor.name)),
                    margin: '0px',
                    fontWeight: 'bold',
                    padding: '5px',
                  }}
                  className='desc'>
                  {valor.name}: {Number(valor.value)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </p>
              )
            ) : (
              ''
            ),
          )}
        </div>
      );
    }
    return null;
  };

  function escolherCorPorLetra(name: string) {
    switch (name) {
      case 'A':
        return letters[0].color;
      case 'B':
        return letters[1].color;
      case 'C':
        return letters[2].color;
      case 'D':
        return letters[3].color;
      case 'E':
        return letters[4].color;
      case 'F':
        return letters[5].color;
      case 'N':
        return letters[6].color;
    }
  }

  const renderCustomizedLabel = ({ payload, cx, cy, midAngle, outerRadius, percent, name, fontSize, index }: any) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(RADIAN * midAngle);
    const cos = Math.cos(RADIAN * midAngle);
    const startX = cx + outerRadius * cos;
    const startY = cy + outerRadius * -sin;
    const middleY = cy + (outerRadius + 20 * Math.abs(sin)) * -sin;
    let endX = startX + (cos >= 0 ? 1 : -1) * 10;
    let textAnchor = cos >= 0 ? 'start' : 'end';
    const mirrorNeeded = midAngle > -270 && midAngle < -210 && percent < 0.04 && index % 2 === 1;
    if (mirrorNeeded) {
      endX = startX + outerRadius * -cos * 2 + 10;
      textAnchor = 'start';
    }

    return (
      <g>
        <path
          d={`M${startX},${startY}L${startX},${middleY}L${endX},${middleY}`}
          fill='none'
          stroke={escolherCorPorLetra(String(payload.name))}
          strokeWidth={2}
        />
        <text x={endX + (cos >= 0 || mirrorNeeded ? 1 : -1) * 12} y={middleY + fontSize / 2} textAnchor={textAnchor}
              fontSize={fontSize} fontWeight='bold'>{`${name || ''
        }: ${(percent * 100).toFixed(2)}%`}</text>
      </g>
    );
  };

  /**
   *
   * INIT
   *
   */
  useEffect(() => {
    const namePage = "indicator";
    localStorage.setItem("actual_page", namePage);

    handleTable({
      type: 'GERAL',
    });

    handleTable({
      type: 'OBS',
    });

    updateActiveItem('Estoque');
    const planningLocalStorage = getLocalStorage(LOCALSTORAGE_PLANNING);
    if (planningLocalStorage) updateItemPlanning(planningLocalStorage.length - 1);

  }, []);

  /**
   * Collapse
   */
  const [openTableCollapse, updateOpenCollapse] = useState(false);

  /**
   * Tabela
   */
  function RenderVariacaoTotal(params: {
    type: 'valor' | 'porcentagem'
  }) {
    let listTableModelFiltered: Variacao | null = null;

    if (listTableModel !== undefined) {
      switch (activeItem.toLowerCase()) {
        case 'estoque':
          listTableModelFiltered = listTableModel.estoque.variacao;
          break;
        case 'excesso':
          listTableModelFiltered = listTableModel.excesso.variacao;
          break;
        case 'venda':
          listTableModelFiltered = listTableModel.venda.variacao;
          break;
        case 'cobertura':
          listTableModelFiltered = listTableModel.cobertura.variacao;
          break;
      }
    }

    if (params.type === 'valor') {
      return (
        <td
          style={{
            textAlign: 'center',
          }}>
          <b>
            {listTableModelFiltered?.valor.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </b>
        </td>
      );
    } else {
      return (
        <td
          style={{
            textAlign: 'center',
          }}>
          <b>{listTableModelFiltered?.percentual.toFixed(2)}%</b>
        </td>
      );
    }
  }

  function RenderTableCollapse(params: {
    curva: string;
    periodo: string;
    index: number
  }) {
    let getlistTableModelByPeriodo: Agrupamentos[] = getAgrupamentosByCurveAndPeriodo(params.periodo, params.curva);

    return (
      <>
        {getlistTableModelByPeriodo.map((item) => {
          return (
            <tr key={`${params.curva}-${params.periodo}-${params.index}-${item.agrupamento}`}>
              {activeItem.toLowerCase() === 'cobertura' ? (
                <>
                  {getValues().groupingChanged === 'filiais' ?
                    <th>{getFilialName(item.agrupamento)}</th> : <td>{item.agrupamento}</td>}
                  <td>{`${item.valor.toFixed(2)}%`}</td>
                </>
              ) : (
                <>
                  {getValues().groupingChanged === 'filiais' ?
                    <th>{getFilialName(item.agrupamento)}</th> : <td>{item.agrupamento}</td>}
                  {getValues().typeOfInformation! === 'QUANTIDADE_ITENS' ?
                    <td>
                      {item.valor}
                    </td>
                    :
                    <td>
                      {item.valor.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </td>
                  }
                  <td>{`${item.percentual.toFixed(2)}%`}</td>
                </>
              )}
            </tr>
          );
        })}
      </>
    );
  }

  function getFilialName(id: number): string {
    let filial: any = filiais.find((filial) => filial.value === id);
    return filial === undefined ? id : filial.label;
  }

  function getVariacaoByCurve(periodo: string, curva: string) {
    let listByVariacaoCurvas: VariacaoCurvas[] = [];

    switch (activeItem.toLowerCase()) {
      case 'estoque':
        listByVariacaoCurvas = listTableModel!.estoque.variacao.variacaoCurvas.filter((filter) => {
          if (filter.curva === curva) {
            return filter;
          }
        });
        break;
      case 'excesso':
        listByVariacaoCurvas = listTableModel!.excesso.variacao.variacaoCurvas.filter((filter) => {
          if (filter.curva === curva) {
            return filter;
          }
        });
        break;
      case 'venda':
        listByVariacaoCurvas = listTableModel!.venda.variacao.variacaoCurvas.filter((filter) => {
          if (filter.curva === curva) {
            return filter;
          }
        });
        break;
      case 'cobertura':
        listByVariacaoCurvas = listTableModel!.cobertura.variacao.variacaoCurvas.filter((filter) => {
          if (filter.curva === curva) {
            return filter;
          }
        });
        break;
    }

    if (listByVariacaoCurvas.length > 0) {
      return listByVariacaoCurvas[0].variacaoAgrupamentos;
    }
    return null;
  }

  const rangeCurve: {
    curve: string;
    min: number;
    max: number;
    length: number
  }[] = [
    { curve: 'A', min: 15, max: 21, length: 6 },
    { curve: 'B', min: 9, max: 15, length: 7 },
    { curve: 'C', min: 4, max: 8, length: 4 },
    { curve: 'D', min: 1, max: 3, length: 2 },
    { curve: 'E', min: 0, max: 21, length: 21 },
    { curve: 'F', min: 0, max: 21, length: 21 },
    { curve: 'N', min: 0, max: 0, length: 0 },
  ];

  function getAgrupamentosByCurveAndPeriodo(periodo: string, curva: string) {
    let getlistTableModelByPeriodo: Periodo[] = [];
    let getlistTableModelByPeriodoByCurvaAgrupamento: Agrupamentos[] = [];

    switch (activeItem.toLowerCase()) {
      case 'estoque':
        getlistTableModelByPeriodo = listTableModel!.estoque.periodos.filter((filter) => {
          if (filter.periodo === periodo) {
            return filter;
          }
        });
        break;
      case 'excesso':
        getlistTableModelByPeriodo = listTableModel!.excesso.periodos.filter((filter) => {
          if (filter.periodo === periodo) {
            return filter;
          }
        });
        break;
      case 'venda':
        getlistTableModelByPeriodo = listTableModel!.venda.periodos.filter((filter) => {
          if (filter.periodo === periodo) {
            return filter;
          }
        });
        break;
      case 'cobertura':
        getlistTableModelByPeriodo = listTableModel!.cobertura.periodos.filter((filter) => {
          if (filter.periodo === periodo) {
            return filter;
          }
        });
        break;
    }

    if (getlistTableModelByPeriodo.length > 0) {
      let getlistTableModelByPeriodoByCurva = getlistTableModelByPeriodo[0].curvas.filter((filter) => filter.curva === curva);
      if(getlistTableModelByPeriodoByCurva.length > 0){
        if (getlistTableModelByPeriodoByCurva.length > 0 && getValues().groupingChanged === 'pontuacao') {
          for (const range of rangeCurve) {
            if (getlistTableModelByPeriodoByCurva[0].curva === range.curve && getlistTableModelByPeriodoByCurva[0].agrupamentos.length !== range.length) {
              const agrupamentosExistentes = getlistTableModelByPeriodoByCurva[0].agrupamentos.map((objeto) => objeto.agrupamento);
  
              for (let agrupamento = range.min; agrupamento <= range.max; agrupamento++) {
                if (!agrupamentosExistentes.includes(agrupamento)) {
                  getlistTableModelByPeriodoByCurva[0].agrupamentos.push({ agrupamento, valor: 0, percentual: 0 });
                }
              }
  
              getlistTableModelByPeriodoByCurva[0].agrupamentos.sort((a, b) => b.agrupamento - a.agrupamento);
            }
          }
          getlistTableModelByPeriodoByCurvaAgrupamento = getlistTableModelByPeriodoByCurva[0].agrupamentos;
        } else if (getlistTableModelByPeriodoByCurva.length > 0) {
          getlistTableModelByPeriodoByCurva[0].agrupamentos.sort((a, b) => b.agrupamento - a.agrupamento);
          getlistTableModelByPeriodoByCurvaAgrupamento = getlistTableModelByPeriodoByCurva[0].agrupamentos;
        }
      }else{
          getlistTableModelByPeriodoByCurvaAgrupamento = [{
            agrupamento: 0,
            valor: 0,
            percentual: 0
        }];
      }
      
    }
    return getlistTableModelByPeriodoByCurvaAgrupamento;
  }

  function RenderVariacaoByCurve(params: {
    type: 'valor' | 'porcentagem';
    curva: string
  }) {
    let listTableModelFiltered: Variacao | null = null;

    if (listTableModel !== undefined) {
      switch (activeItem.toLowerCase()) {
        case 'estoque':
          listTableModelFiltered = listTableModel.estoque.variacao;
          break;
        case 'excesso':
          listTableModelFiltered = listTableModel.excesso.variacao;
          break;
        case 'venda':
          listTableModelFiltered = listTableModel.venda.variacao;
          break;
        case 'cobertura':
          listTableModelFiltered = listTableModel.cobertura.variacao;
          break;
      }
    }

    let listTableModelVariacaoCurvasFiltered: VariacaoCurvas[] | undefined = listTableModelFiltered?.variacaoCurvas.filter(
      (filter) => filter.curva.toLowerCase() === params.curva.toLowerCase(),
    );

    if (listTableModelVariacaoCurvasFiltered !== undefined && listTableModelVariacaoCurvasFiltered?.length > 0) {
      if (params.type === 'valor') {
        return (
          <td
            style={{
              textAlign: 'center',
              whiteSpace: 'nowrap',
            }}>
            {getValues().typeOfInformation! === 'QUANTIDADE_ITENS' ?
              <b>
                {listTableModelVariacaoCurvasFiltered[0]?.valor}
              </b>
              :
              <b>
                {listTableModelVariacaoCurvasFiltered[0]?.valor.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </b>
            }
          </td>
        );
      } else {
        return (
          <td
            style={{
              textAlign: 'center',
              whiteSpace: 'nowrap',
            }}>
            <b>
              {listTableModelVariacaoCurvasFiltered[0]?.percentual.toString() === 'NaN' ||
              listTableModelVariacaoCurvasFiltered[0]?.percentual.toString() === '-Infinity' ? (
                <>0</>
              ) : (
                <>{listTableModelVariacaoCurvasFiltered[0]?.percentual.toFixed(2) + '%'}</>
              )}
            </b>
          </td>
        );
      }
    } else {
      return <td>0</td>;
    }
  }

  function RenderVariacaoByCurveAndLetter(params: {
    type: 'valor' | 'porcentagem';
    curva: string;
    periodo: string;
    index: number
  }) {
    let list: VariacaoAgrupamentos[] | null = getVariacaoByCurve(params.periodo, params.curva);

    if (getValues().groupingChanged === 'pontuacao') {
      for (const range of rangeCurve) {
        const agrupamentosExistentes = list?.map((objeto) => objeto.agrupamento);

        if (params.curva === range.curve) {
          for (let agrupamento = range.min; agrupamento <= range.max; agrupamento++) {
            if (!agrupamentosExistentes?.includes(agrupamento)) {
              list?.push({ agrupamento, valor: 0, percentual: 0 });
            }
          }
        }
      }
    }

    list?.sort((a, b) => b.agrupamento - a.agrupamento);

    if (params.type === 'valor') {
      return (
        <>
          {list?.map((item, index) => {
            return (
              <tr
                style={{
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
                key={`${params.type}-${params.curva}-${params.index}-${item.valor}-${index}-${item.agrupamento}-${item.valor}-${item.percentual}`}>
                {getValues().typeOfInformation! === 'QUANTIDADE_ITENS' ?
                  <td>
                    {item.valor}
                  </td>
                  :
                  <td>
                    {item.valor.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                }
              </tr>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          {list?.map((item, index) => {
            return (
              <tr
                style={{
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
                key={`${params.type}-${params.curva}-${params.index}-${item.valor}-${index}-${item.agrupamento}-${item.valor}-${item.percentual}`}>
                {item.percentual.toString() === 'NaN' || item.percentual.toString() === '-Infinity' ? <td>0</td> :
                  <td>{`${item.percentual.toFixed(2)}%`}</td>}
              </tr>
            );
          })}
        </>
      );
    }
  }

  function TableRowWithCollapse(params: {
    letra: {
      letter: string;
      color: string;
    };
    index: number;
  }) {
    const [openTableCollapse, updateOpenCollapse] = useState(false);

    return (
      <>
        <tr
          onClick={() => {
            updateOpenCollapse(!openTableCollapse);
          }}
          style={{
            backgroundColor: params.letra.color,
          }}>
          <td
            style={{
              fontSize: '20px',
              textAlign: 'center',
            }}>
            <b>{params.letra.letter}</b>
          </td>
          {periodos.map((periodo: string) => {
            let getlistTableModelByPeriodo: Periodo[] = [];

            switch (activeItem.toLowerCase()) {
              case 'estoque':
                getlistTableModelByPeriodo = listTableModel!.estoque.periodos.filter((filter) => {
                  if (filter.periodo === periodo) {
                    return filter;
                  }
                });
                break;
              case 'excesso':
                getlistTableModelByPeriodo = listTableModel!.excesso.periodos.filter((filter) => {
                  if (filter.periodo === periodo) {
                    return filter;
                  }
                });
                break;
              case 'venda':
                getlistTableModelByPeriodo = listTableModel!.venda.periodos.filter((filter) => {
                  if (filter.periodo === periodo) {
                    return filter;
                  }
                });
                break;
              case 'cobertura':
                getlistTableModelByPeriodo = listTableModel!.cobertura.periodos.filter((filter) => {
                  if (filter.periodo === periodo) {
                    return filter;
                  }
                });
                break;
            }

            let total = 0;
            let percentual = 0;

            if (getlistTableModelByPeriodo.length > 0) {
              let getlistTableModelByLetter = getlistTableModelByPeriodo[0].curvas.filter((filter) => filter.curva === params.letra.letter);

              if (getlistTableModelByLetter.length > 0) {
                total = getlistTableModelByLetter[0].valor;
                percentual = activeItem.toLowerCase() === 'cobertura' ? getlistTableModelByLetter[0].valor : getlistTableModelByLetter[0].percentual;
              }
            }
            if (activeItem.toLowerCase() === 'cobertura') {
              return (
                <td key={`${params.letra.letter}-${params.index}-${periodo}`}>
                  <b>
                    <Row className='d-flex justify-content-between'>
                      <Col
                        style={{
                          textAlign: 'center',
                        }}>
                        {`${percentual.toFixed(2)}%`}
                      </Col>
                    </Row>
                  </b>
                </td>
              );
            } else {
              return (
                <>
                  <td key={`${params.letra.letter}-${params.index}-${periodo}`}>
                    {/* <th className="border">valor</th>
                                        <th>percent %</th> */}
                    <b></b>
                    <div className='d-flex justify-content-between'>
                      <div
                        style={{
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          width: '200px',
                        }}>
                        {getValues().typeOfInformation! === 'QUANTIDADE_ITENS' ?
                          <b>
                            {total}
                          </b>
                          :
                          <b>
                            {total.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </b>
                        }
                      </div>
                      <div
                        style={{
                          border: '1px solid #9A9D9E',
                        }}></div>
                      <div
                        style={{
                          textAlign: 'center',
                          width: '100px',
                        }}>
                        <b>{`${percentual.toFixed(2)}%`}</b>
                      </div>
                    </div>
                  </td>
                </>
              );
            }
          })}
          {activeItem.toLowerCase() === 'cobertura' ? (
            <RenderVariacaoByCurve type={'porcentagem'} curva={params.letra.letter.toLowerCase()} />
          ) : (
            <>
              <RenderVariacaoByCurve type={'valor'} curva={params.letra.letter.toLowerCase()} />
              <RenderVariacaoByCurve type={'porcentagem'} curva={params.letra.letter.toLowerCase()} />
            </>
          )}
        </tr>
        <tr style={{ backgroundColor: params.letra.color }}>
          <td className='p-0 m-0'></td>
          {periodos.map((periodo: string, index: number) => {
            return (
              <Collapse in={openTableCollapse} key={`${index}-${periodo}-${params.letra.letter}`}>
                <td colSpan={1} className='p-0 m-0'>
                  {activeItem.toLowerCase() === 'cobertura' ? (
                    <Table
                      style={{
                        textAlign: 'center',
                        minWidth: '400px',
                        cursor: 'pointer',
                      }}
                      bordered>
                      <thead>
                      <tr>
                        {getValues().groupingChanged === 'filiais' ?
                          <th>Filial</th> : <th>Pontuação</th>}
                        <th>{activeItem}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <RenderTableCollapse curva={params.letra.letter} periodo={periodo} index={index} />
                      </tbody>
                    </Table>
                  ) : (
                    <Table
                      style={{
                        textAlign: 'center',
                        minWidth: '400px',
                        cursor: 'pointer',
                      }}
                      bordered>
                      <thead>
                      <tr>
                        {getValues().groupingChanged === 'filiais' ?
                          <th>Filial</th> : <th>Pontuação</th>}
                        <th>{activeItem}</th>
                        <th>%</th>
                      </tr>
                      </thead>
                      <tbody>
                      <RenderTableCollapse curva={params.letra.letter} periodo={periodo} index={index} />
                      </tbody>
                    </Table>
                  )}
                </td>
              </Collapse>
            );
          })}
          {activeItem.toLowerCase() === 'cobertura' ? (
            <td className='p-0 m-0'>
              <Collapse in={openTableCollapse}>
                <td className='p-0 m-0'>
                  <Table
                    style={{
                      textAlign: 'center',
                      minWidth: '200px',
                      cursor: 'pointer',
                    }}
                    bordered>
                    <thead>
                    <tr>
                      <th>Variação %</th>
                    </tr>
                    </thead>
                    <tbody>
                    <RenderVariacaoByCurveAndLetter type={'porcentagem'} curva={params.letra.letter} periodo={''}
                                                    index={params.index} />
                    </tbody>
                  </Table>
                </td>
              </Collapse>
            </td>
          ) : (
            <>
              <td className='p-0 m-0'>
                <Collapse in={openTableCollapse}>
                  <td colSpan={2} className='p-0 m-0'>
                    <Table
                      style={{
                        textAlign: 'center',
                        minWidth: '200px',
                        cursor: 'pointer',
                      }}
                      bordered>
                      <thead>
                      <tr>
                        <th>Variação</th>
                      </tr>
                      </thead>
                      <tbody>
                      <RenderVariacaoByCurveAndLetter type={'valor'} curva={params.letra.letter} periodo={''}
                                                      index={params.index} />
                      </tbody>
                    </Table>
                  </td>
                </Collapse>
              </td>
              <td className='p-0 m-0'>
                <Collapse in={openTableCollapse}>
                  <td className='p-0 m-0'>
                    <Table
                      style={{
                        textAlign: 'center',
                        minWidth: '200px',
                        cursor: 'pointer',
                      }}
                      bordered>
                      <thead>
                      <tr>
                        <th>Variação %</th>
                      </tr>
                      </thead>
                      <tbody>
                      <RenderVariacaoByCurveAndLetter type={'porcentagem'} curva={params.letra.letter} periodo={''}
                                                      index={params.index} />
                      </tbody>
                    </Table>
                  </td>
                </Collapse>
              </td>
            </>
          )}
        </tr>
      </>
    );
  }

  var count = 0;

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />

      {loading && <Loading />}
      {!loading && (
        <div style={{ backgroundColor: '#F0F0F0' }}>
          <div className='mx-5'>
            <TitleGroupComponent titlePage={titlePage}>
              <Row>
                <Col className='d-flex align-items-center justify-content-end'>
                  <Button variant='outline-primary' className='d-flex align-items-center' size='sm'
                          onClick={showPlanning}>
                    {itemPlanning > 0 ? (
                      <Badge bg='danger' className='me-2'>
                        {itemPlanning}
                      </Badge>
                    ) : (
                      <></>
                    )
                    }
                    <BsSliders className='me-1' />
                    Planejamento
                  </Button>
                  <Button variant='outline-primary ms-1' className='d-flex align-items-center'
                          onClick={() => updateOpenCollapse(!openTableCollapse)} size='sm'>
                    {!openTableCollapse ? 'Mostrar ' : 'Ocultar '} Gráficos
                  </Button>
                  <Button variant='outline-primary ms-1' className='d-flex align-items-center' size='sm' onClick={() =>
                    handleExcel({
                      type: 'GERAL',
                    })}>
                    Exportar
                  </Button>
                </Col>
              </Row>
            </TitleGroupComponent>

          </div>

          <div className='mx-3'>
            <Card>
              <Card.Body>
                <div>
                  <Nav className='justify-content-center mt-1' variant='tabs' defaultActiveKey={activeItem}>
                    <Nav.Item>
                      <Nav.Link eventKey='Estoque' onClick={() => handleActiveItem('Estoque')}>
                        Estoque
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='Excesso' onClick={() => handleActiveItem('Excesso')}>
                        Excesso
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='Venda' onClick={() => handleActiveItem('Venda')}>
                        Venda
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='Cobertura' onClick={() => handleActiveItem('Cobertura')}>
                        Cobertura
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='Obsolescencia' onClick={() => handleActiveItem('Obsolescencia')}>
                        Obsolescência
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div>
                  <Row>
                    <Col className='d-flex align-items-center justify-content-start ms-0 mt-3'>
                      <Button variant='outline-primary' onClick={showColumnVisualization} size={'sm'}
                              className='d-flex align-items-center'>
                        <FaRegEye />
                      </Button>
                    </Col>
                  </Row>
                </div>
                {listTableModel && (
                  <>
                    {activeItem.toLowerCase() !== 'obsolescencia' && (
                      <div className='mt-1 '>
                        <Table
                          style={{
                            cursor: 'pointer',
                          }}
                          bordered
                          responsive>
                          <thead>
                          <tr>
                            <th className='p-0 m-0 mx-5 px-5'></th>
                            {periodos.map((periodo, index) => {
                              return (
                                <th>
                                  {activeItem.toLowerCase() === 'cobertura' ? (
                                    <>
                                      <div className='d-flex justify-content-between'>
                                        <div
                                          style={{
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            width: '100%',
                                          }}>
                                          <b>{parseDateIndicator(periodo)}</b>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className='d-flex justify-content-between'>
                                        <div
                                          style={{
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            width: '200px',
                                          }}>
                                          <b>{parseDateIndicator(periodo)}</b>
                                        </div>
                                        <div
                                          style={{
                                            border: '1px solid #9A9D9E',
                                          }}></div>
                                        <div
                                          style={{
                                            textAlign: 'center',
                                            width: '100px',
                                          }}>
                                          <b>{`%`}</b>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </th>
                              );
                            })}
                            {activeItem.toLowerCase() === 'cobertura' ? (
                              <>
                                <th
                                  style={{
                                    textAlign: 'center',
                                    minWidth: '100%',
                                  }}>
                                  Variação %
                                </th>
                              </>
                            ) : (
                              <>
                                <th
                                  style={{
                                    textAlign: 'center',
                                    minWidth: '200px',
                                  }}>
                                  Variação
                                </th>
                                <th
                                  style={{
                                    textAlign: 'center',
                                    minWidth: '200px',
                                  }}>
                                  Variação %
                                </th>
                              </>
                            )}
                          </tr>
                          </thead>
                          <tbody>
                          {letters.map(
                            (
                              ltr: {
                                letter: string;
                                color: string;
                              },
                              index,
                            ) => {
                              return <TableRowWithCollapse letra={ltr} index={index} />;
                            },
                          )}
                          {activeItem.toLowerCase() === 'cobertura' ? (
                            <></>
                          ) : (
                            <tr>
                              <td
                                style={{
                                  fontSize: '20px',
                                  textAlign: 'center',
                                }}>
                                <b>Total:</b>
                              </td>
                              {periodos.map((periodo: string, index: number) => {
                                let listTableModelFiltered: Periodo[] = [];

                                switch (activeItem.toLowerCase()) {
                                  case 'estoque':
                                    listTableModelFiltered = listTableModel!.estoque.periodos.filter((filter) => {
                                      if (filter.periodo === periodo) {
                                        return filter;
                                      }
                                    });
                                    break;
                                  case 'excesso':
                                    listTableModelFiltered = listTableModel!.excesso.periodos.filter((filter) => {
                                      if (filter.periodo === periodo) {
                                        return filter;
                                      }
                                    });
                                    break;
                                  case 'venda':
                                    listTableModelFiltered = listTableModel!.venda.periodos.filter((filter) => {
                                      if (filter.periodo === periodo) {
                                        return filter;
                                      }
                                    });
                                    break;
                                  case 'cobertura':
                                    listTableModelFiltered = listTableModel!.cobertura.periodos.filter((filter) => {
                                      if (filter.periodo === periodo) {
                                        return filter;
                                      }
                                    });
                                    break;
                                }

                                let total = 0;

                                if (listTableModelFiltered.length > 0) {
                                  total = listTableModelFiltered[0].valor;
                                }

                                return (
                                  <>
                                    <td
                                      key={`${index}-${periodo}`}
                                      style={{
                                        textAlign: 'center',
                                      }}>
                                      <div className='d-flex justify-content-between'>
                                        <div
                                          style={{
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            width: '200px',
                                          }}>
                                          {getValues().typeOfInformation! === 'QUANTIDADE_ITENS' ?
                                            <b>
                                              {total}
                                            </b>
                                            :
                                            <b>
                                              {total.toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL',
                                              })}
                                            </b>
                                          }
                                        </div>
                                        <div
                                          style={{
                                            border: '1px solid #9A9D9E',
                                          }}></div>
                                        <div
                                          style={{
                                            textAlign: 'center',
                                            width: '100px',
                                          }}>
                                          <b>{`100%`}</b>
                                        </div>
                                      </div>
                                    </td>
                                  </>
                                );
                              })}

                              <RenderVariacaoTotal type={'valor'} />
                              <RenderVariacaoTotal type={'porcentagem'} />
                            </tr>
                          )}
                          </tbody>
                        </Table>
                      </div>
                    )}

                    {activeItem.toLowerCase() === 'obsolescencia' && (
                      <>
                        <div className='mt-1 '>
                          <TableHeaderFixedStyled>
                            <Table>
                              <thead>
                              <tr className='p-0 m-0'>
                                <th>Período</th>
                                {period.map((value, index) => {
                                  return (
                                    <>
                                      <th
                                        key={index}
                                        style={{
                                          whiteSpace: 'nowrap',
                                        }}>
                                        {parseDateIndicator(value)}
                                      </th>
                                      <th key={index + 4234}>%</th>
                                    </>
                                  );
                                })}
                              </tr>
                              </thead>
                              <tbody>
                              {listTableModelObsolescencia.map((value, index) => {
                                count += rangeOfMonthsForGrouping;

                                return (
                                  <tr key={index} className='p-0 m-0'>
                                    <td
                                      style={{
                                        whiteSpace: 'nowrap',
                                      }}>
                                      {count - rangeOfMonthsForGrouping} a {count} MESES
                                    </td>
                                    {period.map((valuePeriod: any, index) => {
                                      let obsolecenciaFilter = value.obsolescencias.filter((vl_f: {
                                        anoMes: string
                                      }) => vl_f.anoMes === valuePeriod);
                                      let renderObsolecenciaValor = 0;
                                      if (obsolecenciaFilter.length === 0) {
                                        renderObsolecenciaValor = 0;
                                      } else if (getValues().typeOfInformation! === 'QUANTIDADE_ITENS') {
                                        renderObsolecenciaValor = obsolecenciaFilter[0].quantidadeObsolescencia;
                                      } else if (getValues().typeOfInformation! === 'VALOR_REPOSICAO') {
                                        renderObsolecenciaValor = obsolecenciaFilter[0].valorReposicao;
                                      } else if (getValues().typeOfInformation! === 'VALOR_VENDA') {
                                        renderObsolecenciaValor = obsolecenciaFilter[0].valorVenda;
                                      } else if (getValues().typeOfInformation! === 'CUSTO_MEDIO') {
                                        renderObsolecenciaValor = obsolecenciaFilter[0].custoMedio;
                                      }
                                      return (
                                        <>
                                          {getValues().typeOfInformation! === 'QUANTIDADE_ITENS' ?
                                            <td>
                                              {renderObsolecenciaValor}
                                            </td>
                                            :
                                            <td>
                                              {renderObsolecenciaValor.toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL',
                                              })}
                                            </td>
                                          }
                                          <td>
                                            {renderObsolecenciaValor === 0 ? (
                                              <>0</>
                                            ) : (
                                              <>{((renderObsolecenciaValor * 100) / totalTotalByDate[valuePeriod]).toFixed(2)}%</>
                                            )}
                                          </td>
                                        </>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                              <tr className='p-0 m-0'>
                                <td>
                                  <strong>Total</strong>
                                </td>
                                {periodos.map((value: any, index) => {
                                  return (
                                    <>
                                      <td>
                                        {getValues().typeOfInformation! === 'QUANTIDADE_ITENS' ?
                                          <strong>
                                            {totalTotalByDate[value]}
                                          </strong>
                                          :
                                          <strong>
                                            {totalTotalByDate[value].toLocaleString('pt-br', {
                                              style: 'currency',
                                              currency: 'BRL',
                                            })}
                                          </strong>
                                        }
                                      </td>
                                      <td></td>
                                    </>
                                  );
                                })}
                              </tr>
                              </tbody>
                            </Table>
                          </TableHeaderFixedStyled>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div>
                  <Collapse in={openTableCollapse}>
                    <div>
                      <CollapseGraphic />
                    </div>
                  </Collapse>
                </div>
              </Card.Body>
            </Card>
            <ModalPlanning />
            <ModalVisualization />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default IndicatorPageV2;
