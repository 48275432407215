/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { useEffect, useState } from "react";
import { UseAuth } from "presentation/hook/auth-hook";
import { RestUseCase } from "data/useCase/rest-usecase";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReportPage from "presentation/pages/scrap/report-page";
import { ConfigTable } from "domain/interfaces/table-interface";
import { EntityFieldReport } from "domain/entity/field/report/field-report";
import CreateModalReport from "presentation/components/table/table-modal-component";
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page";
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface";
import { ModalListItemGenerateScrap } from "presentation/components/modal/modal-list-item-generate-scrap-component";
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter";
import { UseAlert } from "presentation/hook/alert-hook";
import {
    getParameterUrlByName,
    parseLinkHeader,
} from "main/helper/request-helper";
import { getEnv } from 'main/helper/window-helper'

interface LinkHeader {
    first: string;
    last: string;
    next: string;
    prev: string;
}
export const ApprovalScrapFactory: React.FC = () => {
    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** CAMPOS DA TELA */
    const entityFieldsHistoric: EntityFieldReport[] = [
        { name: "id", labelTable: "ID", typeField: "number", align: "left" },
        {
            name: "dataCadastro",
            labelTable: "Data Cadastro",
            typeField: "date",
            align: "left",
        },
        {
            name: "dataAgendamento",
            labelTable: "Data Agendamento Scrap (Sugestão)",
            typeField: "date",
            align: "left",
        },
        {
            name: "status",
            labelTable: "Status",
            typeField: "text",
            align: "left",
        },
        {
            name: "nomeAuditor",
            labelTable: "Auditor",
            typeField: "text",
            align: "left",
        },
        {
            name: "nomeFilialFornecedor",
            labelTable: "Filial",
            typeField: "text",
            align: "left",
        },
    ];
    const entityFieldsItems: EntityFieldReport[] = [
        { name: "id", labelTable: "ID", typeField: "number", align: "left" },
        {
            name: "item",
            labelTable: "Item",
            typeField: "number",
            align: "left",
        },
        {
            name: "precoItemOriginal",
            labelTable: "Preço Item Original",
            typeField: "monetary",
            align: "left",
        },
        {
            name: "preco",
            labelTable: "Preço",
            typeField: "monetary",
            align: "left",
        },
        {
            name: "quantidade",
            labelTable: "Quantidade",
            typeField: "number",
            align: "left",
        },
        {
            name: "quantidadeAuditor",
            labelTable: "Quantidade Auditor",
            typeField: "input",
            align: "left",
            disabled: true,
        },
        {
            name: "codigoItem",
            labelTable: "Código Item",
            typeField: "text",
            align: "left",
        },
        {
            name: "descItem",
            labelTable: "Descrição Item",
            typeField: "text",
            align: "left",
        },
    ];
    const [entityFields] = useState<EntityFieldReport[]>(entityFieldsHistoric);
    const [entityItemFields, updateEntityItemFieldsScrap] =
        useState<EntityFieldReport[]>(entityFieldsItems);

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([]);
    const [tableItemsHeader, updateTableItemsHeader] = useState<any[]>([
        "id",
        "item",
        "precoItemOriginal",
        "preco",
        "quantidade",
        "quantidadeAuditor",
        "codigoItem",
        "descItem",
    ]);
    const [tableBody, updateTableBody] = useState<any[]>([]);
    const [tableItemsBody, updateTableItemsBody] = useState<any[]>([]);
    const clearTableBody = () => {
        updateTableBody([]);
    };
    const clearTableItemBody = () => {
        updateTableItemsBody([]);
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** CONFIGURACAO */
    const configTable: ConfigTable = {
        checkboxes: true,
        icons: false,
        buttonHeader: false,
        selectsTable: true,
        navegation: false,
        buttonFooter: false,
        pagination: true,
        planning: false,
        openModal: true,
    };
    const configTableItems: ConfigTable = {
        checkboxes: false,
        icons: false,
        buttonHeader: false,
        selectsTable: false,
        navegation: false,
        buttonFooter: false,
        pagination: true,
        planning: false,
        openModal: false,
    };
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_SCRAP")!
    const ENDPOINT_PROVIDER = getEnv("REACT_APP_END_POINT_PROVIDER")! + ``
    const ENDPOINT_USERS = getEnv("REACT_APP_END_POINT_USER_PROVIDER")!
    const titlePage = "Aprovar Scrap"

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** CHAMADAS BACKEND */
    const { showAlert } = UseAlert();
    const [withError, updateWithError] = useState<boolean>(false);
    const [waiting, updateWaiting] = useState<boolean>(false);
    const getAll = async (params?: {
        pagination: string;
        page: number;
        size: number;
    }) => {
        clearTableBody();
        let pagePagination: number;
        let pageSize: number;
        /** configura paginacao manual ou vindo de outros metodos */
        if (params) {
            pagePagination = params.page;
            pageSize = params.size;
        } else {
            pagePagination = 0;
            pageSize = totalPageSize;
        }
        if (provider !== undefined) {
            updateWaiting(true);
            await new RestUseCase(
                `${ENDPOINT_CRUD}?idFornecedor=${provider?.value}&status=${scrapStatus?.value}&ativos=true&page=${pagePagination}&size=${pageSize}`
            )
                .Get()
                .then((response) => {
                    if (response.headers["link"]) {
                        let links: LinkHeader = parseLinkHeader(
                            response.headers["link"]
                        ) as any;

                        updateFirstPagination(links.first);
                        updateLastPagination(links.last);
                        updateNextPagination(links.next);
                        updatePrevPagination(links.prev);

                        setValuePaginations(
                            pagePagination,
                            parseInt(
                                getParameterUrlByName("page", links.first)!
                            ),
                            parseInt(getParameterUrlByName("page", links.last)!)
                        );
                    }

                    if (response.statusCode === 200) {
                        updateTableHeader(
                            entityFieldsHistoric.map((value) => {
                                return {
                                    name: value.name,
                                    labelTable: value.labelTable,
                                    align: value.align,
                                };
                            })
                        );
                        updateTableBody(
                            response.body.map((item: any) => {
                                item["checked"] = false;
                                return item;
                            })
                        );
                    } else {
                        updateWithError(true);
                    }
                })
                .catch(() => updateWithError(true));
            updateWaiting(false);
        }
    };
    const handleSelectProvider = async () => {
        await new RestUseCase(ENDPOINT_PROVIDER)
            .Get()
            .then((response) => {
                updateProviderSelect(
                    response.body.map((value: any) => {
                        return { value: value.id, label: value.nome };
                    })
                );
            })
            .catch(() => updateWithError(true));
    };
    const handleUserProvider = async () => {
        await new RestUseCase(ENDPOINT_USERS! + "/" + provider?.value)
            .Get()
            .then((response) => {
                updateUsersSelect(
                    response.body.map((value: any) => {
                        return { value: value.id, label: value.nome };
                    })
                );
            })
            .catch(() => updateWithError(true));
    };
    const getItems = async (
        pagination: { page: string; size: string },
        idScrap: number
    ) => {
        clearTableItemBody();
        updateWaiting(true);
        let pagePagination: number;
        let pageSize: number;
        /** configura paginacao manual ou vindo de outros metodos */
        if (pagination) {
            pagePagination = Number(pagination.page);
            pageSize = Number(pagination.size);
        } else {
            pagePagination = 0;
            pageSize = totalPageSize;
        }

        await new RestUseCase(
            `${ENDPOINT_CRUD}/${idScrap}?idScrap=${idScrap}&page=${pagination.page}&size=${pagination.size}`
        )
            .Post()
            .then((response) => {

                if (response.headers["link"]) {
                    let links: LinkHeader = parseLinkHeader(
                        response.headers["link"]
                    ) as any;

                    updateFirstPaginationTableItem(links.first);
                    updateLastPaginationTableItem(links.last);
                    updateNextPaginationTableItem(links.next);
                    updatePrevPaginationTableItem(links.prev);

                    setValuePaginationsTableItem(
                        pagePagination,
                        parseInt(
                            getParameterUrlByName("page", links.first)!
                        ),
                        parseInt(getParameterUrlByName("page", links.last)!)
                    );
                }

                if (response.statusCode === 200) {
                    updateEntityItemFieldsScrap(
                        entityItemFields.map((value) => {
                            if (
                                value.name === "quantidadeAuditor" &&
                                scrapStatus?.value === "Aguardando Auditor"
                            ) {
                                value.disabled = false;
                            } else {
                                value.disabled = true;
                            }
                            return value;
                        })
                    );
                    updateTableItemsHeader(
                        entityFieldsItems.map((value) => {
                            return {
                                name: value.name,
                                labelTable: value.labelTable,
                                align: value.align,
                            };
                        })
                    );
                    updateTableItemsBody(
                        response.body.map((value: any) => {
                            return value;
                        })
                    );
                } else {
                    updateWithError(true);
                }
            })
            .catch(() => updateWithError(true));
        updateWaiting(false);
    };
    const saveAll = async () => {
        if (provider !== undefined && action !== undefined) {
            const tablefilterBySelected = tableBody
                .filter((item) => item["checked"] === true)
                .map((item) => item["id"]);

            if (tablefilterBySelected.length > 0) {
                updateWaiting(true);
                if (scrapStatus?.value === "Aguardando escolha de Auditor") {
                    await new RestUseCase(`${ENDPOINT_CRUD}/auditor`)
                        .Post({
                            data: {
                                scraps: tablefilterBySelected,
                                idUsuario: user?.value,
                            },
                        })
                        .then(() => {})
                        .catch(() => updateWithError(true));
                }

                await new RestUseCase(`${ENDPOINT_CRUD}/status`)
                    .Put({
                        data: {
                            ids: tablefilterBySelected,
                            action: {
                                action: action.value,
                                desc: descriptionScrap,
                            },
                        },
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            showAlert({
                                show: true,
                                content: "Ação efetuada com sucesso",
                                color: "success",
                                time: 2000,
                            });
                        } else {
                            updateWithError(true);
                            showAlert({
                                show: true,
                                content:
                                    "Ops!!! Algo deu errado tente novamente mais tarde...",
                                color: "danger",
                                time: 2000,
                            });
                        }
                    })
                    .catch(() => updateWithError(true));

                await getAll({
                    pagination: `?page=${0}&size=${totalPageSize}`,
                    page: 0,
                    size: totalPageSize,
                });

                updateWaiting(false);
            } else {
                showAlert({
                    show: true,
                    content: "Selecione um item do Scrap",
                    color: "warning",
                    time: 2000,
                });
            }
        } else {
            showAlert({
                show: true,
                content: "Selecione uma ação",
                color: "warning",
                time: 2000,
            });
        }
    };
    const save = async () => {
        if (provider !== undefined && action !== undefined) {
            updateWaiting(true);
            await new RestUseCase(`${ENDPOINT_CRUD}/${rowScrap.id}/status`)
                .Put({
                    data: { action: action.value, desc: descriptionScrap },
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        showAlert({
                            show: true,
                            content: "Ação efetuada com sucesso",
                            color: "success",
                            time: 2000,
                        });
                    } else {
                        updateWithError(true);
                        showAlert({
                            show: true,
                            content:
                                "Ops!!! Algo deu errado tente novamente mais tarde...",
                            color: "danger",
                            time: 2000,
                        });
                    }
                })
                .catch(() => updateWithError(true));
            updateWaiting(false);
        } else {
            showAlert({
                show: true,
                content: "Selecione uma ação",
                color: "warning",
                time: 2000,
            });
        }
    };
    const saveRow = async (params: any) => {
        if (rowScrap.status === "Aguardando Auditor") {
            updateWaiting(true);
            await new RestUseCase(`${ENDPOINT_CRUD}/${rowScrap.id}/audit`)
                .Post({
                    data: [
                        {
                            id: params.id,
                            quantidade: params.quantidade,
                        },
                    ],
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        showAlert({
                            show: true,
                            content: "Quantidade do Auditor salva com sucesso",
                            color: "success",
                            time: 2000,
                        });
                    } else {
                        showAlert({
                            show: true,
                            content:
                                "Ops!!! Algo deu errado tente novamente mais tarde...",
                            color: "danger",
                            time: 2000,
                        });
                    }
                })
                .catch(() => updateWithError(true));
            updateWaiting(false);
        }
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** HEADER */
    const [scrapStatus, updateScrapStatus] = useState<SelectOptionInterface>();
    const onChangeSelectScrapStatus = (
        providerOptionValue: SelectOptionInterface
    ) => {
        updateScrapStatus(providerOptionValue);
    };
    const [scrapStatusSelect, updateScrapStatusSelect] = useState<
        SelectOptionInterface[]
    >([
        {
            label: "Criado",
            value: "Criado",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_VENDAS"],
            actions: ["APROVAR", "CANCELAR"],
        },
        {
            label: "Aguardando escolha de Auditor",
            value: "Aguardando escolha de Auditor",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_VENDAS"],
            actions: ["SEM_AUDITOR", "COM_AUDITOR", "APROVAR", "CANCELAR"],
        },
        {
            label: "Aguardando Auditor",
            value: "Aguardando Auditor",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_AUDITOR"],
            actions: ["APROVAR", "CANCELAR"],
        },
        {
            label: "Aguardando CPV",
            value: "Aguardando CPV",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_CONSULTOR"],
            actions: ["APROVAR", "CANCELAR"],
        },
        {
            label: "Aguardando CPV (Sem Auditor)",
            value: "Aguardando CPV (Sem Auditor)",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_CONSULTOR"],
            actions: ["APROVAR", "CANCELAR"],
        },
        {
            label: "Aguardando Concessionária inserir NF (XML)",
            value: "Aguardando Concessionária inserir NF (XML)",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_VENDAS", "SCRAP_COMPRADOR"],
            actions: ["APROVAR", "CANCELAR"],
        },
        {
            label: "Processo Entregue (Em análise pela Fábrica)",
            value: "Processo Entregue (Em análise pela Fábrica)",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_VENDAS"],
            actions: ["APROVAR", "REPROVAR"],
        },
        {
            label: "Processo em análise do Financeiro (Fábrica)",
            value: "Processo em análise do Financeiro (Fábrica)",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_FINANCEIRO"],
            actions: ["APROVAR", "REPROVAR"],
        },
        {
            label: "Programado para pagamento à Concessionária",
            value: "Programado para pagamento à Concessionária",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_FINANCEIRO"],
            actions: ["APROVAR", "CANCELAR"],
        },
        {
            label: "Pago (Creditado em Conta movimento do Dealer)",
            value: "Pago (Creditado em Conta movimento do Dealer)",
            role: ["SCRAP_ADMINISTRADOR", "SCRAP_FINANCEIRO"],
            actions: [],
        },
        {
            label: "Cancelado",
            value: "Cancelado",
            role: ["SCRAP_ADMINISTRADOR"],
            actions: [],
        },
    ]);

    const [providerSelect, updateProviderSelect] =
        useState<SelectOptionInterface[]>();
    const [provider, updateProvider] = useState<SelectOptionInterface>();
    const actions = [
        { label: "Aprovar", value: "APROVAR" },
        { label: "Cancelar", value: "CANCELAR" },
        { label: "Reprovar", value: "REPROVAR" },
        { label: "Com Auditor", value: "COM_AUDITOR" },
        { label: "Sem Auditor", value: "SEM_AUDITOR" },
    ]
    const [actionsToApprove, updateActionsToApprove] = useState<
        SelectOptionInterface[]
    >();
    
    const [action, updateAction] = useState<SelectOptionInterface>();
    const [usersSelect, updateUsersSelect] =
        useState<SelectOptionInterface[]>();
    const [user, updateUser] = useState<SelectOptionInterface>();
    const onChangeSelectProvider = (
        providerOptionValue: SelectOptionInterface
    ) => {
        updateProvider(providerOptionValue);
    };
    const onChangeSelectActionsToApprove = (
        actionsToApproveOptionValue: SelectOptionInterface
    ) => {
        updateAction(actionsToApproveOptionValue);
    };
    const onChangeSelectUser = (userOptionValue: SelectOptionInterface) => {
        updateUser(userOptionValue);
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** MODAL ITEMS */
    const [showModalItem, updateShowModalItem] = useState<boolean>(false);
    const [rowScrap, updateRowScrap] = useState<any>();
    const [descriptionScrap, updateDescriptionScrap] = useState<string>("");
    const [colunsChecked, updateColunsChecked] = useState<boolean>(false);
    const openModalItem = (params: any) => {
        updateRowScrap(params);
        updateShowModalItem(true);
        getItems({ page: "0", size: "50" }, params.id);
    };
    const closeModalItem = () => {
        updateShowModalItem(false);
    };

    const checkboxRow = (params: any) => {
        updateTableBody(
            tableBody.map((item: any) => {
                if (item.id === params.id) {
                    item["checked"] = !params["checked"];
                }
                return item;
            })
        );
    };

    const checkboxColumn = () => {
        updateColunsChecked(!colunsChecked);
        updateTableBody(
            tableBody.map((item: any) => {
                item["checked"] = !colunsChecked;
                return item;
            })
        );
    };

    /**
     * paginacao
     */
    /**
     *
     *
     *
     *
     *  paginacao */
    var totalPageSize: number = 50;
    const [firstPagination, updateFirstPagination] = useState<string>("");
    const [lastPagination, updateLastPagination] = useState<string>("");
    const [nextPagination, updateNextPagination] = useState<string>("");
    const [prevPagination, updatePrevPagination] = useState<string>("");
    const [paginationItemList, updatePaginationItemList] =
        useState<{ item: number; active: boolean }[]>();
    const setValuePaginations = (
        paginationsActive: number,
        prev: number,
        next: number
    ) => {
        let modelPaginationList: any[] = [];
        for (
            let index = paginationsActive;
            index < 6 + paginationsActive;
            index++
        ) {
            if (!(index > next))
                modelPaginationList.push({
                    item: index,
                    active: index === paginationsActive ? true : false,
                });
        }
        updatePaginationItemList(modelPaginationList);
    };
    const onClickFirstPagination = async () => {
        await getAll({
            pagination: `?page=${0}&size=${totalPageSize}`,
            page: 0,
            size: totalPageSize,
        });
    };
    const onClickLastPagination = async () => {
        const page: string = getParameterUrlByName("page", lastPagination)!;
        await getAll({
            pagination: `?page=${page}&size=${totalPageSize}`,
            page: parseInt(page),
            size: totalPageSize,
        });
    };
    const onClickNextPagination = async () => {
        const page: string = getParameterUrlByName("page", nextPagination)!;
        await getAll({
            pagination: `?page=${page}&size=${totalPageSize}`,
            page: parseInt(page),
            size: totalPageSize,
        });
    };
    const onClickPrevPagination = async () => {
        const page: string = getParameterUrlByName("page", prevPagination)!;
        await getAll({
            pagination: `?page=${page}&size=${totalPageSize}`,
            page: parseInt(page),
            size: totalPageSize,
        });
    };


    /**
     *
     *
     *
     *
     *  paginacao table items */
    var totalPageSize: number = 50;
    const [firstPaginationTableItem, updateFirstPaginationTableItem] = useState<string>("");
    const [lastPaginationTableItem, updateLastPaginationTableItem] = useState<string>("");
    const [nextPaginationTableItem, updateNextPaginationTableItem] = useState<string>("");
    const [prevPaginationTableItem, updatePrevPaginationTableItem] = useState<string>("");
    const [paginationItemListTableItem, updatePaginationItemListTableItem] =
        useState<{ item: number; active: boolean }[]>();
    const setValuePaginationsTableItem = (
        paginationsActive: number,
        prev: number,
        next: number
    ) => {
        let modelPaginationList: any[] = [];
        for (
            let index = paginationsActive;
            index < 6 + paginationsActive;
            index++
        ) {
            if (!(index > next))
                modelPaginationList.push({
                    item: index,
                    active: index === paginationsActive ? true : false,
                });
        }
        updatePaginationItemListTableItem(modelPaginationList);
    };
    const onClickFirstPaginationTableItem = async () => {
        await getItems({ page: "0", size: "50" }, rowScrap.id);
    };
    const onClickLastPaginationTableItem = async () => {
        const page: string = getParameterUrlByName("page", lastPaginationTableItem)!;
        await getItems({ page: page, size: "50" }, rowScrap.id);
    };
    const onClickNextPaginationTableItem = async () => {
        const page: string = getParameterUrlByName("page", nextPaginationTableItem)!;
        await getItems({ page: page, size: "50" }, rowScrap.id);
    };
    const onClickPrevPaginationTableItem = async () => {
        const page: string = getParameterUrlByName("page", prevPaginationTableItem)!;
        await getItems({ page: page, size: "50" }, rowScrap.id);
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** INICIO */
    useEffect(() => {
        if (provider !== undefined && scrapStatus !== undefined) {
            getAll({
                pagination: `?page=${0}&size=${totalPageSize}`,
                page: 0,
                size: totalPageSize,
            });
            handleUserProvider();
        }
    }, [provider, scrapStatus]);

    useEffect(() => {
        handleSelectProvider();
    }, []);

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth();
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Scrap"
        )[0].navItem.filter((item) => item.title === "Aprovação Scrap")[0].role;

        if (loggedUserData?.role) {
            let newListScrapStatusByRole: SelectOptionInterface[] = [];

            loggedUserData.role.forEach((value) => {
                scrapStatusSelect.forEach((item) => {
                    if (item.role?.includes(value)) {
                        if (!newListScrapStatusByRole.includes(item)) {
                            newListScrapStatusByRole.push(item);
                        }
                    }
                });

                if (roleScreen.includes(value)) {
                    updateShowScreen(true);
                }
            });

            updateScrapStatusSelect(newListScrapStatusByRole);
        }
    }, [loggedUserData]);

    useEffect(() => {
        updateActionsToApprove([])

        if (scrapStatus !== undefined) {
            let newListActionsToApproveByAction: SelectOptionInterface[] = [];

            actions.forEach((item) => {
                if (scrapStatus.actions?.includes(item.value)) {
                    if (!newListActionsToApproveByAction.includes(item)) {
                        newListActionsToApproveByAction.push(item);
                    }
                }
            });

            updateActionsToApprove(newListActionsToApproveByAction);
        }
    }, [scrapStatus]);

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** RENDERIZACAO */
    return (
        <>
            {showScreen ? (
                <ReportPage
                    titlePage={titlePage}
                    entityFields={entityFields}
                    tableHeader={tableHeader}
                    tableBody={tableBody}
                    configTable={configTable}
                    withError={withError}
                    waiting={waiting}
                    isOpenModalItem={openModalItem}
                    checkboxRow={checkboxRow}
                    checkboxColumn={checkboxColumn}
                    onClickFirstPagination={onClickFirstPagination}
                    onClickLastPagination={onClickLastPagination}
                    onClickNextPagination={onClickNextPagination}
                    onClickPrevPagination={onClickPrevPagination}
                    paginationItemList={paginationItemList}
                >
                    <>{/* Planejamento */}</>
                    <>
                        <Row className="mb-1">
                            <Col md={3}>
                                <Form.Label>Fornecedor:</Form.Label>
                                <Col>
                                    <Select
                                        defaultValue={provider}
                                        options={providerSelect}
                                        onChange={(val) =>
                                            onChangeSelectProvider(val!)
                                        }
                                        placeholder="Selecione o Fornecedor"
                                    />
                                </Col>
                            </Col>
                            <Col md={3}>
                                <Form.Label>Status:</Form.Label>
                                <Col>
                                    <Select
                                        defaultValue={scrapStatus}
                                        options={scrapStatusSelect}
                                        onChange={(val) =>
                                            onChangeSelectScrapStatus(val!)
                                        }
                                        placeholder="Selecione o Status"
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={3}>
                                <Form.Label>Aprovação:</Form.Label>
                                <Col>
                                    <Select
                                        options={actionsToApprove}
                                        onChange={(val) =>
                                            onChangeSelectActionsToApprove(val!)
                                        }
                                        placeholder="Selecione a Ação desejada"
                                    />
                                </Col>
                            </Col>
                            <Col md={6}>
                                <Form.Label>Descrição:</Form.Label>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        onChange={(event) =>
                                            updateDescriptionScrap(
                                                event.target.value!
                                            )
                                        }
                                    />
                                </Col>
                            </Col>
                            {scrapStatus?.value ===
                            "Aguardando escolha de Auditor" ? (
                                <>
                                    <Col md={3}>
                                        <Form.Label>Usuário:</Form.Label>
                                        <Col>
                                            <Select
                                                options={usersSelect}
                                                onChange={(val) =>
                                                    onChangeSelectUser(val!)
                                                }
                                                placeholder="Selecione o usuário"
                                            />
                                        </Col>
                                    </Col>
                                </>
                            ) : (
                                <></>
                            )}
                        </Row>
                        <div className="d-flex align-items-center justify-content-end mt-2 mb-3">
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                    saveAll();
                                }}
                            >
                                Salvar Selecionados
                            </Button>
                        </div>
                    </>
                    <>{/* Navegacao */}</>
                    <>{/* Footer */}</>
                    <>
                        <ModalListItemGenerateScrap
                            isOpen={showModalItem}
                            title={rowScrap?.nomeFilialFornecedor}
                            open={openModalItem}
                            close={closeModalItem}
                            children={{
                                body: (
                                    <>
                                        <Row>
                                            <Col md={3}>
                                                <Form.Label>
                                                    Aprovação:
                                                </Form.Label>
                                                <Col>
                                                    <Select
                                                        options={
                                                            actionsToApprove
                                                        }
                                                        onChange={(val) =>
                                                            onChangeSelectActionsToApprove(
                                                                val!
                                                            )
                                                        }
                                                        placeholder="Selecione a Ação desejada"
                                                    />
                                                </Col>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Label>
                                                    Descrição:
                                                </Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        onChange={(event) =>
                                                            updateDescriptionScrap(
                                                                event.target
                                                                    .value!
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Col>
                                            {rowScrap?.status ===
                                            "Aguardando escolha de Auditor" ? (
                                                <>
                                                    <Col md={3}>
                                                        <Form.Label>
                                                            Usuário:
                                                        </Form.Label>
                                                        <Col>
                                                            <Select
                                                                options={
                                                                    usersSelect
                                                                }
                                                                onChange={(
                                                                    val
                                                                ) =>
                                                                    onChangeSelectUser(
                                                                        val!
                                                                    )
                                                                }
                                                                placeholder="Selecione o usuário"
                                                            />
                                                        </Col>
                                                    </Col>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Row>
                                        <div className="d-flex align-items-center justify-content-end mt-2">
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                onClick={async () => {
                                                    await save();
                                                    closeModalItem();
                                                    await getAll({
                                                        pagination: `?page=${0}&size=${totalPageSize}`,
                                                        page: 0,
                                                        size: totalPageSize,
                                                    });
                                                }}
                                            >
                                                Salvar
                                            </Button>
                                        </div>
                                        <div className="mt-3">
                                            <CreateModalReport
                                                tableHeader={tableItemsHeader}
                                                tableBody={tableItemsBody}
                                                entityFields={entityItemFields}
                                                configTable={configTableItems}
                                                onBlurSaveRow={saveRow}

                                                onClickFirstPagination={onClickFirstPaginationTableItem}
                                                onClickLastPagination={onClickLastPaginationTableItem}
                                                onClickNextPagination={onClickNextPaginationTableItem}
                                                onClickPrevPagination={onClickPrevPaginationTableItem}
                                                paginationItemList={paginationItemListTableItem}

                                            />
                                        </div>
                                    </>
                                ),
                                footer: undefined,
                            }}
                        />
                    </>
                </ReportPage>
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
