

import styled from "styled-components"

export const TableHeaderFixedStyled = styled.div`

    overflow: auto; 
    height: 500px; 

    font: Open Sans;
    font-size: 12px;

    cursor: pointer;

    thead th {
        position: sticky; 
        top: 0; z-index: 0; 
    }

    table  { 
        border-collapse: collapse; 
        width: 100%; 
    }
    th, td { 
        /* padding: 8px 16px;  */
    }
    th     { 
        background:#F0F0F0; 
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    .wrapper {
        position: relative;
        overflow: auto;
        border: 1px solid black;
        white-space: nowrap;
    }

    .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        background-color: #DCDCDC;
    }

    .first-col {
        overflow-wrap: break-word;
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        left: 0px;
        z-index: 98;
        background-color: #fff;
    }
    
    .second-col {
        overflow-wrap: break-word;
        width: 150px;
        min-width: 150px;
        max-width: 150px;
        left: 100px;
        z-index: 98;
        background-color: #fff;
    }
    
    .third-col {
        overflow-wrap: break-word;
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        left: 200px;
        z-index: 98;
        background-color: #fff;
    }

    .first-col-header {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        left: 0px;
        z-index: 99;
        position: sticky;
        top: 0;
        background-color: #f2f2f2; 
    }

    .second-col-header {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
        left: 100px;
        z-index: 99;
        position: sticky;
        top: 0;
        background-color: #f2f2f2; 
    }

    .third-col-header {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        left: 200px;
        z-index: 99;
        position: sticky;
        top: 0;
        background-color: #f2f2f2; 
    }

`