/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select'
import { useEffect, useState } from "react"
import CrudPage from "presentation/pages/crud-page"
import { UseAuth } from 'presentation/hook/auth-hook'
import { RestUseCase } from "data/useCase/rest-usecase"
import { Button, Col, Form, Pagination, Row } from "react-bootstrap"
import { ParseDateToEn } from "main/helper/format-date-helper"
import { EntityField } from "domain/entity/field/crud/field-crud-entity"
import { getParameterUrlByName, parseLinkHeader } from 'main/helper/request-helper'
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface"
import ErrorNotAuthorizedPage from 'presentation/pages/error/error-not-authorized-page'
import { HeaderNavegationList } from 'main/adapter/headerNavigation/header-navegation-adapter'
import { getEnv } from 'main/helper/window-helper'

interface LinkHeader { first: string, last: string, next: string, prev: string }

export const HolidayFactory: React.FC = () => {

    /** ENDPOINT */
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_HOLIDAY")!
    const ENDPOINT_FILIAL = getEnv("REACT_APP_END_POINT_FILIAL")!

    /** TITULO DA PAGINA */
    const titlePage = "Feriado"

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([])
    const [tableBody, updateTableBody] = useState([])

    /** FILTRO DA TABELA */
    const [listFilial, updateListFilial] = useState<SelectOptionInterface[]>([])
    const [filial, updateFilial] = useState<SelectOptionInterface>()
    const [dateInitial, updateDateInitial] = useState<string>()
    const [dateEnd, updateDateEnd] = useState<string>()
    function changeFilialSelected(filialOptions: SelectOptionInterface) {
        updateFilial(filialOptions)
    }
    function changeDateInitial(date: string) {
        updateDateInitial(date)
    }
    function changeDateEnd(date: string) {
        updateDateEnd(date)
    }

    /** PAGINACAO */
    const [firstPagination, updateFirstPagination] = useState<string>("")
    const [lastPagination, updateLastPagination] = useState<string>("")
    const [nextPagination, updateNextPagination] = useState<string>("")
    const [prevPagination, updatePrevPagination] = useState<string>("")
    const [pageDescription, updatePageDescription] = useState<string>("Primeira página")
    const onClickFirstPagination = async () => {
        const page: string = getParameterUrlByName("page", firstPagination)!
        const size: string = getParameterUrlByName("size", firstPagination)!
        await getAll({ page: page, size: size })
        updatePageDescription(page)

    }
    const onClickLastPagination = async () => {
        const page: string = getParameterUrlByName("page", lastPagination)!
        const size: string = getParameterUrlByName("size", lastPagination)!
        await getAll({ page: page, size: size })
        updatePageDescription("Última página")

    }
    const onClickNextPagination = async () => {
        const page: string = getParameterUrlByName("page", nextPagination)!
        const size: string = getParameterUrlByName("size", nextPagination)!
        await getAll({ page: page, size: size })
        updatePageDescription(page)

    }
    const onClickPrevPagination = async () => {
        const page: string = getParameterUrlByName("page", prevPagination)!
        const size: string = getParameterUrlByName("size", prevPagination)!
        await getAll({ page: page, size: size })
        updatePageDescription(page)

    }

    /** CHAMADA ENDPOINT */
    const getAll = async (pagination: { page: string, size: string }, filial?: number, dateIni?: string, dateEnd?: string) => {
        let querie = `?page=${pagination.page}&size=${pagination.size}&`

        if (filial)
            querie += `filial=${filial}&`

        if (dateIni)
            querie += `inicio=${dateIni}&`

        if (dateEnd)
            querie += `fim=${dateEnd}&`

        await new RestUseCase(`${ENDPOINT_CRUD}${querie}`)
            .Get()
            .then(response => {
                if (response.headers["link"]) {
                    let links: LinkHeader = parseLinkHeader(response.headers["link"]) as any

                    updateFirstPagination(links.first)
                    updateLastPagination(links.last)
                    updateNextPagination(links.next)
                    updatePrevPagination(links.prev)
                }

                updateTableHeader(entityFields.map((value) => { return { name: value.name, labelTable: value.labelTable, align: value.align, columnVisible: value.tableField.columnVisible } }))
                updateTableBody(response.body.map((value: any) => {
                    return value
                }))
            })
    }
    const handleSelectFilial = async () => {
        await new RestUseCase(ENDPOINT_FILIAL)
            .Get()
            .then(response => {
                updateListFilial(response.body.map((value: any) => {
                    return { value: value.id, label: value.nome }
                }))
            })
    }

    /** CAMPOS PARA MODAL E TABELA */
    const entityFields: EntityField[] = [
        { name: "id", labelField: "", labelTable: "ID", typeField: "hidden", valueField: null, sizeField: 0, required: false, align: "center", tableField: { column: "ID", columnVisible: true } },
        { name: "idFilial", labelField: "Filial:", labelTable: "Filial", typeField: "select", valueField: null, listValueField: listFilial, sizeField: 3, required: false, align: "center", tableField: { column: "Filial", columnVisible: true } },
        { name: "dia", labelField: "Data:", labelTable: "Data", typeField: "date", valueField: "", sizeField: 3, required: false, align: "center", tableField: { column: "Data", columnVisible: true } },
        { name: "nome", labelField: "Nome:", labelTable: "Nome", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Nome", columnVisible: true } }
    ]
    const [entity, updateEntity] = useState(entityFields)

    useEffect(() => {
        handleSelectFilial()
    }, [])

    useEffect(() => {
        updateEntity(entity.map((value) => {
            if (value.name === "idFilial") {
                value.listValueField = listFilial
            }
            return value
        }))
    }, [listFilial])


    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Configurações")[0].navItem.filter(item => item.title === "Cadastrar Feriado")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }

    }, [loggedUserData])

    return (
        <>
            {showScreen ?
                <CrudPage
                    entityFields={entity}
                    updateEntity={updateEntity}

                    titlePage={titlePage}

                    getAll={() => getAll({ page: "0", size: "50" })}

                    getById={async function (id: number) {
                        const response = await new RestUseCase(`${ENDPOINT_CRUD}/${id}`)
                            .GetById()
                            .finally()

                        updateEntity(entityFields.map((value) => {
                            if (value.typeField === "date")
                                value.valueField = ParseDateToEn(response.body[value.name])
                            else if (value.typeField === "radio")
                                value.valueField = response.body[value.name].toString()
                            else
                                value.valueField = response.body[value.name]

                            return value
                        }))
                    }}

                    post={async function (model: any) {
                        delete model["id"]
                        if(model["dia"] !== "") {
                            model["dia"] = new Date(model["dia"]).toISOString()
                        }

                        await new RestUseCase(`${ENDPOINT_CRUD}`).Post({ data: model }).finally()
                    }}

                    put={async function (id: number, model: any) {
                        delete model["id"]
                        if(model["dia"] !== "") {
                            model["dia"] = new Date(model["dia"]).toISOString()
                        }

                        const response = await new RestUseCase(`${ENDPOINT_CRUD}/${id}`).Put({ data: model }).finally()

                        updateEntity(entityFields.map((value) => {
                            value.valueField = response.body[value.name]
                            return value
                        }))
                    }}

                    del={async function (id: number) {
                        await new RestUseCase(`${ENDPOINT_CRUD}/${id}`).Delete().finally()
                    }}

                    tableHeader={tableHeader}
                    tableBody={tableBody}

                    children={
                        {
                            filterTable:
                                <>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Label>Filial:</Form.Label>
                                            <Select options={listFilial} onChange={val => { changeFilialSelected(val!) }} />
                                        </Col>

                                        <Col md={3}>
                                            <Form.Label>Início:</Form.Label>
                                            <Col>
                                                <Form.Control type="date" onChange={(event) => changeDateInitial(event.target.value)} />
                                            </Col>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Fim:</Form.Label>
                                            <Col>
                                                <Form.Control type="date" onChange={(event) => changeDateEnd(event.target.value)} />
                                            </Col>
                                        </Col>
                                    </Row>
                                    <div className='d-flex align-items-center justify-content-end mt-4'>
                                        <Button variant="primary" size="sm" className='mb-4' onClick={() => getAll({ page: "0", size: "50" }, filial?.value, dateInitial, dateEnd)}>Pesquisar</Button>
                                    </div>
                                </>,
                            pagination:
                                <>
                                    <Row className="d-flex align-items-center justify-content-between">
                                        <Col className="d-flex justify-content-start">
                                            <p><strong>{pageDescription}</strong></p>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <Pagination size="sm">
                                                <Pagination.First onClick={onClickFirstPagination} />
                                                <Pagination.Prev onClick={onClickPrevPagination} />
                                                <Pagination.Next onClick={onClickNextPagination} />
                                                <Pagination.Last onClick={onClickLastPagination} />
                                            </Pagination>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <></>
                                        </Col>
                                    </Row>
                                </>

                        }
                    }
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )

}
