import { RestUseCase } from "data/useCase/rest-usecase"
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface"
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter"
import { getEnv } from "main/helper/window-helper"
import { UseAlert } from "presentation/hook/alert-hook"
import { UseAuth } from "presentation/hook/auth-hook"
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page"
import CompanyGroupPage from "presentation/pages/safety/company-group-page"
import { useEffect, useState } from "react"

export const CompanyGroupFactory: React.FC = () => {

  const ENDPOINT_COMPANIES = getEnv("REACT_APP_END_POINT_COMPANIES")!
  const ENDPOINT_GROUP_COMPANY = getEnv("REACT_APP_END_POINT_GROUP_COMPANY")!

  /** USUARIO LOGADO */
  const { loggedUserData } = UseAuth()

  /**  LOADING **/
  const [loading, updateLoading] = useState<boolean>(false)

  /** ALERTA **/
  const { showAlert } = UseAlert()

  /** COMPANY */
  const [idCompany, updateIdCompany] = useState<number>();
  const [modelCompany, updateModelCompany] = useState<SelectOptionInterface>();

  const [controlledCompanies, updateControlledCompanies] = useState<number[]>([]);

  /** SELECT COMPANY */
  function handleSelectProvider(company?: { value: number; label: string }) {
    if (company) {
      updateModelCompany(company);
      updateIdCompany(company.value);
      listControlledCompanies(company.value)  
    }
  }

  /** SELECT */
  const [companiesLis, updateCompaniesList] = useState<SelectOptionInterface[]>([])

  async function listAllCompanies(): Promise<any> {
    updateLoading(true)
    await new RestUseCase(ENDPOINT_COMPANIES).Get()
      .then((response) => {
        if (response.statusCode !== 500 && response.statusCode !== undefined) {
          updateCompaniesList(response.body.map((value: any) => { return { value: value.id, label: value.nome, } })
          .sort((a: any, b: any) => a.label.localeCompare(b.label)))
        }
        updateLoading(false)
      })
  }

  async function listControlledCompanies(companyId: number) {
    updateLoading(true)
    await new RestUseCase(ENDPOINT_GROUP_COMPANY + "/" + companyId).Get()
      .then((response) => {
        if (response.statusCode !== 500 && response.statusCode !== undefined) {
          updateControlledCompanies(response.body.controladas)
        }
        updateLoading(false)
      })
  }

  async function saveControlledCompanies(companiesList: SelectOptionInterface[] ) {
    updateLoading(true)
    let controladas = companiesList.map(company => company.value)
    await new RestUseCase(ENDPOINT_GROUP_COMPANY + "/" + idCompany).Put({ data: { controladas } })
      .then((response) => {
        if (response.statusCode >= 200 && response.statusCode <= 300 && response.statusCode !== undefined) {
          if(idCompany !== null && idCompany !== undefined){
            listControlledCompanies(idCompany)
          }
          showAlert({ show: true, content: "Empresas adicionas com sucesso", color: "success", time: 5000 })
        } else {
          showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
        }
        updateLoading(false)
      })
  }

  /** VERIFICA PERMISSAO DE ACESSO */
  const [showScreen, updateShowScreen] = useState<boolean>(false)
  useEffect(() => {
    const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Configurações")[0].navItem.filter(item => item.title === "Cadastrar Usuário")[0].role

    if (loggedUserData?.role) {
      loggedUserData.role.forEach(value => {
        if (roleScreen.includes(value)) {
          updateShowScreen(true)
          listAllCompanies()
        }
      })
    }
  }, [loggedUserData])

  return (
    <>
      {showScreen ?
        <CompanyGroupPage titlePage="Grupo da Empresa"
          modelCompany={modelCompany}
          handleSelectProvider={handleSelectProvider}
          loading={loading}
          companiesList={companiesLis}
          controlledCompanies={controlledCompanies} 
          saveControlledCompanies={saveControlledCompanies}/>
        :
        <ErrorNotAuthorizedPage />
      }
    </>
  )

}

