import { useEffect, useState } from "react"
import { UseAuth } from "presentation/hook/auth-hook"
import { RestCrud } from "data/useCase/rest-crud-usecase"
import { RegionalEntity } from "domain/entity/regional-entity"
import RegionalPage from "presentation/pages/provider/regional-page"
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page"
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter"
import { getEnv } from "main/helper/window-helper"

export const RegionalFactory: React.FC = () => {

    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_REGIONAL")

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Fornecedor")[0].navItem.filter(item => item.title === "Cadastrar Regional")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }
    }, [loggedUserData])

    return (
        <>
            {showScreen ?
                <RegionalPage
                    modelMethods={{
                        create: async function (model: RegionalEntity): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Post(model)
                            return response.body
                        },
                        edit: async function (model: RegionalEntity, id: number): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Patch(model, id)
                            return response.body
                        },
                        remove: async function (id: number): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Delete(id)
                            return response.body
                        },
                        getAll: async function (params?: { pagination: string, idProvider: number }): Promise<RegionalEntity[]> {
                            let query = `?idFornecedor=${params?.idProvider}`

                            if (params?.pagination)
                                query += `${params?.pagination}`

                            const response = await new RestCrud(ENDPOINT_CRUD! + `${query}`).Get()
                            return response as any
                        },
                        getById: async function (id: number): Promise<RegionalEntity> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).GetById(id)
                            return response.body
                        },
                        get: async function (): Promise<RegionalEntity> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Get()
                            return response.body
                        }
                    }}
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )
}
