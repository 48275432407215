export function ParseDateToBr(date: string) {
    return new Intl.DateTimeFormat("pt-BR").format(new Date(date));
}

export function ParseDateToBrV2(date: string) {
    var dataArray = date.split("-");
    let dia = dataArray[2];
    if (dataArray[2].length > 2) {
        dia = dataArray[2].substr(0, 2);
    }
    return dia + "/" + dataArray[1] + "/" + dataArray[0];
}

export function ParseDateToBrWithHours(date: string) {
    let options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
        timeZone: "America/Sao_Paulo",
    };

    return new Intl.DateTimeFormat("pt-BR", options).format(new Date(date));
}

export function ParseDateToEn(date: string) {
    return new Date(date).toISOString().slice(0, 10);
}
