/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Modal,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap';

import Select from 'react-select';
import { UseAlert } from 'presentation/hook/alert-hook';

import { SpinnerSC } from 'presentation/style/spinner/spinner-styled';
import { SCDesctiption } from 'presentation/style/typography/titlePage';
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled';

import { Footer } from 'presentation/components/footer/footer-component';
import { Headers } from 'presentation/components/header/headers-component';
import ContainerFilter from 'presentation/components/container/container-filter-component';
import {
  DragInDropHideOrderColumsTableComponent,
} from 'presentation/components/dragDrop/drag-in-drop-hide-order-colums-table-component';

import { BsArrowDownRight, BsArrowRight, BsSliders } from 'react-icons/bs';

import { FiltersDefault } from 'domain/interfaces/filter-interface';

import { BiColumns } from 'react-icons/bi';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { SortByKey } from 'main/helper/arrays-helper';
import { Filial } from 'data/useCase/filial-usecase';
import { ParseDateToBr, ParseDateToBrWithHours, ParseDateToEn } from 'main/helper/format-date-helper';
import { ColumsTableEqualizationAdapter } from 'main/adapter/columsTable/colums-table-equalization-adapter';
import { getParameterUrlByName, parseLinkHeader } from 'main/helper/request-helper';
import { getLocalStorage, updateLocalStorege } from 'data/cache/localstorage-cache';
import { generateRandomId, generateRandomIdV2 } from 'main/helper/randon-helper';
import { TableDescriptions } from 'domain/interfaces/table-interface';
import { Painel } from 'presentation/components/painel/peinel-component';
import { getEnv } from 'main/helper/window-helper';
import { TitlePageComponent } from '../../components/title/title-page-component';
import { TitleGroupComponent } from '../../components/title/title-group-component';

interface LinkHeader {
  first: string;
  last: string;
  next: string;
  prev: string;
}

interface ModelSave {
  tipo?: string;
  filialsRecebimento?: number[];
  filiaisTransferencia?: number[];
  itens?: any[];
  filtros?: any[];
}

type Props = {
  optionsFiltersDefault: FiltersDefault[];

  loggedUserDataRole: string[];
  processAndList: (params?: any) => Promise<any>;
  list: (params?: any) => Promise<any>;
  save: (params?: any) => Promise<any>;
  saveAll: (params?: any) => Promise<any>;
  removeAll: (params?: any) => Promise<any>;
  remove: (params?: any) => Promise<any>;
  exportFileCSV: (params?: any) => Promise<any>;
  exportFileExcel: (params?: any) => Promise<any>;
  generateEqualization: (params?: any) => Promise<any>;

  listByIdPlanning: (params?: any) => Promise<any>;
  listAllPlanning: (params?: any) => Promise<any>;
  createPlanning: (params?: any) => Promise<any>;
  editPlanning: (params?: any) => Promise<any>;
  removePlanning: (params?: any) => Promise<any>;
  panelList: any[];
  lastCharge: string;
  typeRemanejamento: {
    dataPesquisa: string;
    tipo: string;
  };
};

const titlePage = 'Equalização';
const LOCALSTORAGE_COLUMS = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_EQUALIZATION')!;
const LOCALSTORAGE_COLUMS_ORDER = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_EQUALIZATION')! + '_order';
const LOCALSTORAGE_PLANNING = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_EQUALIZATION')!;

const EqualizationPage: React.FC<Props> = ({
                                             optionsFiltersDefault,
                                             processAndList,
                                             list,
                                             save,
                                             saveAll,
                                             removeAll,
                                             remove,
                                             listAllPlanning,
                                             createPlanning,
                                             editPlanning,
                                             removePlanning,
                                             exportFileCSV,
                                             exportFileExcel,
                                             generateEqualization,
                                             panelList,
                                             lastCharge,
                                             typeRemanejamento,
                                           }: Props) => {
  /**
   *
   *
   *
   *
   *  ultima carga / pesquisa */
  const [lastSearch, updateLastSearch] = useState<string>();

  /**
   *
   *
   *
   *
   *  loading */
  const [loading, updateLoading] = useState<boolean>(false);

  function Loading() {
    return (
      <SpinnerSC>
        <div
          className='spinner-border text-secondary'
          role='status'
        ></div>
      </SpinnerSC>
    );
  }

  /**
   *
   *
   *
   *
   *  alerta */
  const { showAlert } = UseAlert();

  /**
   *
   *
   *
   *
   *  paginacao */
  var totalPageSize: number = 50;
  const [firstPagination, updateFirstPagination] = useState<string>('');
  const [lastPagination, updateLastPagination] = useState<string>('');
  const [nextPagination, updateNextPagination] = useState<string>('');
  const [prevPagination, updatePrevPagination] = useState<string>('');
  const [paginationItemList, updatePaginationItemList] =
    useState<{
      item: number;
      active: boolean
    }[]>();
  const setValuePaginations = (
    paginationsActive: number,
    prev: number,
    next: number,
  ) => {
    let modelPaginationList: any[] = [];
    for (
      let index = paginationsActive;
      index < 6 + paginationsActive;
      index++
    ) {
      if (!(index > next))
        modelPaginationList.push({
          item: index,
          active: index === paginationsActive ? true : false,
        });
    }
    updatePaginationItemList(modelPaginationList);
  };
  const onClickItemPagination = async (pagination: any) => {
    totalPageSize = Number(getParameterUrlByName('size', nextPagination))!;
    await handleTable({
      pagination: `?page=${pagination.item}&size=${totalPageSize}`,
      page: parseInt(pagination.item),
      size: totalPageSize,
    });
  };
  const onClickFirstPagination = async () => {
    const size: string = getParameterUrlByName('size', firstPagination)!;
    await handleTable({
      pagination: `?page=${0}&size=${size}`,
      page: 0,
      size: parseInt(size),
    });
  };
  const onClickLastPagination = async () => {
    const page: string = getParameterUrlByName('page', lastPagination)!;
    const size: string = getParameterUrlByName('size', lastPagination)!;
    await handleTable({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      size: parseInt(size),
    });
  };
  const onClickNextPagination = async () => {
    const page: string = getParameterUrlByName('page', nextPagination)!;
    const size: string = getParameterUrlByName('size', nextPagination)!;
    await handleTable({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      size: parseInt(size),
    });
  };
  const onClickPrevPagination = async () => {
    const page: string = getParameterUrlByName('page', prevPagination)!;
    const size: string = getParameterUrlByName('size', prevPagination)!;
    await handleTable({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      size: parseInt(size),
    });
  };

  /**
   *
   *
   *
   *
   *  model table */
  const [listTableModel, updateListTableModel] = useState<any[]>([]);

  /**
   *
   *
   *
   *
   *  chamadas so banckend */
  async function handleTable(params?: {
    pagination: string;
    page: number;
    size: number;
    ordination?: string;
  }) {
    let pagePagination;
    /** limpa os checkbox */
    updateCheckAll(false);
    /** inicia o loading */
    updateLoading(true);
    let response;
    /** configura paginacao manual ou vindo de outros metodos */
    if (params) {
      response = await list({ pagination: params.pagination });
      pagePagination = params.page;
    } else {
      pagePagination = 0;
      response = await list({
        pagination: `?page=${pagePagination}&size=${totalPageSize}`,
      });
    }
    /** links da paginacao */
    if (response.headers['link']) {
      let links: LinkHeader = parseLinkHeader(
        response.headers['link'],
      ) as any;

      updateFirstPagination(links.first);
      updateLastPagination(links.last);
      updateNextPagination(links.next);
      updatePrevPagination(links.prev);

      setValuePaginations(
        pagePagination,
        parseInt(getParameterUrlByName('page', links.first)!),
        parseInt(getParameterUrlByName('page', links.last)!),
      );
    }
    /** data da ultima carga */
    if (response.headers['is-ultima-pesquisa'])
      updateLastSearch(
        response.headers['is-ultima-pesquisa'] === 'null'
          ? 'NULL'
          : ParseDateToBrWithHours(
            response.headers['is-ultima-pesquisa'],
          ),
      );
    if (response.statusCode === 200) {
      if (response.body.length > 0) {
        updateListTableModel(
          response.body.map((value: any) => {
            value['dataUltimaVenda'] = ParseDateToBr(
              value['dataUltimaVenda'],
            );
            value['dataUltimaCompra'] = ParseDateToBr(
              value['dataUltimaCompra'],
            );
            value['dataUltimaVendaTransferenciaFilial'] = ParseDateToBr(
              value['dataUltimaVendaTransferenciaFilial'],
            );
            return value;
          }),
        );
        changeValueListByColums(response.body);
      }
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  async function handleProcessTable(model?: ModelSave) {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    /** inicia o loading */
    updateLoading(true);

    let modelHandle = modelSave;

    if (model) {
      modelHandle = model;
    }
    let response = await processAndList({
      pagination: `?page=${0}&size=${totalPageSize}`,
      model: modelHandle,
    });
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      updateListTableModel(
        response.body.map((value: any) => {
          value['dataUltimaVenda'] = ParseDateToBr(
            value['dataUltimaVenda'],
          );
          value['dataUltimaCompra'] = ParseDateToBr(
            value['dataUltimaCompra'],
          );
          value['dataUltimaVendaTransferenciaFilial'] = ParseDateToBr(
            value['dataUltimaVendaTransferenciaFilial'],
          );
          return value;
        }),
      );

      showAlert({
        show: true,
        content: 'Processado com sucesso',
        color: 'success',
        time: 2000,
      });
      /** data da ultima carga */
      if (response.headers['is-ultima-pesquisa'])
        updateLastSearch(
          response.headers['is-ultima-pesquisa'] === 'null'
            ? 'NULL'
            : ParseDateToBrWithHours(
              response.headers['is-ultima-pesquisa'],
            ),
        );
      /** links da paginacao */
      if (response.headers['link']) {
        let links: LinkHeader = parseLinkHeader(
          response.headers['link'],
        ) as any;

        updateFirstPagination(links.first);
        updateLastPagination(links.last);
        updateNextPagination(links.next);
        updatePrevPagination(links.prev);

        setValuePaginations(
          0,
          parseInt(getParameterUrlByName('page', links.first)!),
          parseInt(getParameterUrlByName('page', links.last)!),
        );
      }
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  async function handleGenerateEqualization() {
    /** inicia o loading */
    updateLoading(true);
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    let response = await generateEqualization();
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      const url = window.URL.createObjectURL(response.body);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `equalizacao.csv`);
      document.body.appendChild(link);
      link.click();
      link!.parentNode!.removeChild(link);
      showAlert({
        show: true,
        content: 'Equalização gerada com sucesso',
        color: 'success',
        time: 2000,
      });
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  async function handleDeleteAll() {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    /** inicia o loading */
    updateLoading(true);
    let response = await removeAll(modelSave);
    /** dados da requisicao */
    if (response.statusCode !== 500)
      showAlert({
        show: true,
        content: 'Tudo Salvo com sucesso',
        color: 'success',
        time: 2000,
      });
    else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** chama a listagem da tela */
    await handleTable();
    /** finaliza o loading */
    updateLoading(false);
  }

  async function handleDeletePage() {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    modelSave.itens = listTableModel.map((value) => {
      return { filial: value['filial'], id: value['id'] };
    });
    let response = await remove(modelSave);
    /** dados da requisicao */
    if (response.statusCode !== 500)
      showAlert({
        show: true,
        content: 'Items Bloqueados com sucesso',
        color: 'success',
        time: 2000,
      });
    else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** chama a listagem da tela */
    await handleTable();
  }

  async function handleSavePage() {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    modelSave.itens = listTableModel.map((value) => {
      return {
        filial: value['filial'],
        id: value['id'],
        bloqueado: value['bloqueado'],
      };
    });
    let response = await save(modelSave);
    /** dados da requisicao */
    if (response.statusCode !== 500)
      showAlert({
        show: true,
        content: 'Página Salva com sucesso',
        color: 'success',
        time: 2000,
      });
    else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** chama a listagem da tela */
    await handleTable();
  }

  async function handleSaveAll() {
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    /** inicia o loading */
    updateLoading(true);
    let response = await saveAll(modelSave);
    /** dados da requisicao */
    if (response.statusCode !== 500)
      showAlert({
        show: true,
        content: 'Tudo Salvo com sucesso',
        color: 'success',
        time: 2000,
      });
    else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** chama a listagem da tela */
    await handleTable();
    /** finaliza o loading */
    updateLoading(false);
  }

  /**
   *
   *
   *
   *
   *  exporta arquivos CSV/EXCEL */
  const [showModalExport, updateShowModalExport] = useState(false);
  const closeModalExport = () => updateShowModalExport(false);
  const showExport = () => updateShowModalExport(true);

  function ModalExport() {
    const [selectedOption, setSelectedOption] = useState<string>('');

    const handleOptionChange = (value: string) => {
      setSelectedOption(value);
    };
    return (
      <Modal
        show={showModalExport}
        onHide={closeModalExport}
        fullscreen={'md-down'}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className='ms-3 lead'
            style={{ fontSize: '30px' }}
          >
            Exportar arquivo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Escolha o tipo de arquivo que deseja:</p>
          <Form.Check
            inline
            label='Excel'
            name='Excel'
            type={'radio'}
            checked={selectedOption === 'Excel'}
            value='Excel'
            onChange={() => handleOptionChange('Excel')}
          />
          <Form.Check
            inline
            label='CSV'
            name='CSV'
            type={'radio'}
            checked={selectedOption === 'CSV'}
            value='CSV'
            onChange={() => handleOptionChange('CSV')}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              if (selectedOption === 'Excel') {
                handleExportExcel();
              } else {
                handleExportCSV();
              }
              closeModalExport();
            }}
            disabled={selectedOption === ''}
          >
            Exportar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleExportExcel = async () => {
    showAlert({show: true,content: 'Iniciando exportação de excel, pode demorar um pouco, aguarde um momento...',color: 'default',time: 5000,});
    updateLoading(true);
    try {
      const response = await exportFileExcel();

      const url = window.URL.createObjectURL(new Blob([response.body]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Equalizacao.xlsx`);
      document.body.appendChild(link);
      link.click();
      link!.parentNode!.removeChild(link);
      showAlert({show: true,content: 'Exportado com sucesso',color: 'success',time: 5000,});
    } catch (error) {
      showAlert({show: true,content: 'Houve um erro na exportação, tente novamente mais tarde.',color: 'danger',time: 5000,});
      console.error('Error downloading Excel file:', error);
    }
    updateLoading(false);
  };

  async function handleExportCSV() {
    /** inicia o loading */
    updateLoading(true);
    showAlert({show: true,content: 'Aguarde um momento',color: 'default',time: 5000,});
    let response = await exportFileCSV();
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      const url = window.URL.createObjectURL(
        new Blob([response.body], { type: 'text/csv' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Relatorio_Equalizacao.csv`);
      document.body.appendChild(link);
      link.click();
      link!.parentNode!.removeChild(link);
      showAlert({show: true,content: 'Exportado com sucesso',color: 'success',time: 5000,});
    } else
      showAlert({show: true,content: 'Ops!!! Algo deu errado tente novamente mais tarde...',color: 'danger',time: 5000,});
    /** finaliza o loading */
    updateLoading(false);
  }

  /**
   *
   *
   *
   *
   *  colunas das tabelas */
  const [columns, updateColumns] = useState<TableDescriptions[]>(
    ColumsTableEqualizationAdapter,
  );
  const [listTable, updateListTable] = useState<any[]>([]);

  /**
   *
   *
   *
   *
   *  tipo de relatório [VIRTUAL / FISICA] */
  const [modelSave, updateModelSave] = useState<ModelSave>({
    tipo: 'VIRTUAL',
    filialsRecebimento: [],
    filiaisTransferencia: [],
    filtros: [],
  });

  /**
   *
   *
   *
   *
   *  reordena / disabilita colunas */
  const [showModalColumns, updateShowModalColumns] = useState(false);
  const closeColumn = () => updateShowModalColumns(false);
  const showColumn = () => updateShowModalColumns(true);

  function handleOrderHideColumns(columns: TableDescriptions[]) {
    updateLocalStorege(
      LOCALSTORAGE_COLUMS,
      columns.sort((a, b) => (a.order > b.order ? 1 : -1)),
    );
    updateColumns(columns.sort((a, b) => (a.order > b.order ? 1 : -1))); //tiago
  }

  function ModalOrderHideColumns() {
    return (
      <Modal show={showModalColumns} onHide={closeColumn} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Reordenar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className='mb-2'>
              <Form.Group as={Row}>
                <Form.Label column>
                  Quantidade de Item na Tabela:
                </Form.Label>
                <Col sm='12' md={4}>
                  <Form.Control
                    type='number'
                    defaultValue={50}
                    onBlur={(e) => {
                      e.preventDefault();
                      totalPageSize = parseInt(
                        e.target.value,
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Card>
            <Card.Body>
              <p className='mb-0 text-center'>
                <small>
                  Clique no item deixando-o em azul para que
                  apareça na tabela,
                </small>
              </p>
              <p className='mt-0 text-center'>
                <small>
                  clique e segure no item para arrastá-lo e
                  reordenar as colunas da tabela trocando os
                  itens de lugar
                </small>
              </p>
              <DragInDropHideOrderColumsTableComponent
                columns={columns}
              />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size='sm'
            variant='primary'
            onClick={async () => {
              closeColumn();
              handleOrderHideColumns(columns);
              changeValueListByColums(listTableModel);
              await handleTable();
            }}
          >
            Aplicar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *  ordenacao de valores da tabela e checkbox */
  const [checkAll, updateCheckAll] = useState<boolean>(false);

  function changeValueListByColums(model: any[]) {
    if (model !== undefined) {
      var ListTableChanged: any[] = [];
      var columsName = columns
        .filter((value) => value.hidden === false)
        .map((vl) => {
          return vl.dataField;
        });

      model.forEach((value) => {
        const filtered = Object.keys(value)
          .filter((key) => columsName.includes(key))
          .reduce((obj: any, key: any) => {
            obj[key] = value[key];
            return obj;
          }, {});

        ListTableChanged.push(filtered);
      });
      updateListTable(ListTableChanged);
    }
  }

  async function orderValueColums(nameColumn: TableDescriptions) {
    /** busca no localstorage */
    let localStorageColumsOrder: any[] =
      getLocalStorage(LOCALSTORAGE_COLUMS_ORDER) ?? [];

    columns.forEach((value) => {
      if (value.id === nameColumn.id) {
        if (localStorageColumsOrder.length === 0) {
          value.ordination!.ordinationValue = 'asc';
          localStorageColumsOrder[0] = value.ordination;
          updateLocalStorege(
            LOCALSTORAGE_COLUMS_ORDER,
            localStorageColumsOrder,
          );
        } else {
          if (value.ordination?.ordinationValue === 'asc') {
            value.ordination!.ordinationValue = 'desc';
            localStorageColumsOrder[0] = value.ordination;
            updateLocalStorege(
              LOCALSTORAGE_COLUMS_ORDER,
              localStorageColumsOrder,
            );
          } else if (value.ordination?.ordinationValue === 'desc') {
            value.ordination!.ordinationValue = '';
            localStorageColumsOrder[0] = value.ordination;
            updateLocalStorege(
              LOCALSTORAGE_COLUMS_ORDER,
              localStorageColumsOrder,
            );
          } else if (value.ordination?.ordinationValue === '') {
            value.ordination!.ordinationValue = 'asc';
            localStorageColumsOrder[0] = value.ordination;
            updateLocalStorege(
              LOCALSTORAGE_COLUMS_ORDER,
              localStorageColumsOrder,
            );
          }
        }
      } else {
        if (
          value.ordination !== undefined &&
          value.ordination !== null
        ) {
          value.ordination!.ordinationValue = '';
        }
      }
    });
    /** chama o endpoint */
    totalPageSize = Number(getParameterUrlByName('size', nextPagination))!;
    await handleTable({
      pagination: `?page=${0}&size=${totalPageSize}`,
      page: 0,
      size: totalPageSize,
    });
  }

  function CreateIconOrder(params?: any) {
    if (
      params.nameColumn !== undefined &&
      params.nameColumn.ordinationValue === 'asc'
    )
      return (
        <FaSortUp
          color='#FFC007'
          size={11}
          key={generateRandomId(100) + generateRandomId(300)}
        />
      );
    else if (
      params.nameColumn !== undefined &&
      params.nameColumn.ordinationValue === 'desc'
    )
      return (
        <FaSortDown
          color='#FFC007'
          size={11}
          key={generateRandomId(100) + generateRandomId(300)}
        />
      );
    else
      return (
        <FaSort
          size={11}
          key={generateRandomId(100) + generateRandomId(300)}
        />
      );
  }

  function handleCheckAll() {
    let valueChecked = checkAll === false ? true : false;
    updateCheckAll(valueChecked);
    updateListTableModel(
      listTableModel.map((value) => {
        value['bloqueado'] = valueChecked
        return value;
      }),
    );
  }

  function handleCheckItem(params: any) {
    updateListTableModel(
      listTableModel.map((value) => {
        if (value['id'] === params.id)
          value['bloqueado'] =
            params['bloqueado'] === false ? true : false;
        return value;
      }),
    );
  }

  /**
   *
   *
   *
   *
   *  planejamento */
  const [showModalPlanning, updateShowModalPlanning] = useState(false);
  const [itemPlanning, updateItemPlanning] = useState<number>(0);
  const closePlanning = () => updateShowModalPlanning(false);
  const showPlanning = () => updateShowModalPlanning(true);

  async function handleApplyPlanning(model: any) {
    /** inicia o loading */
    updateLoading(true);
    /** fecha o modal */
    closePlanning();
    let countPlanning = model.length;
    /** salva no localstorage */
    updateLocalStorege(LOCALSTORAGE_PLANNING, model);
    updateItemPlanning(countPlanning);

    let response = await list({
      pagination: `?page=${0}&size=${totalPageSize}`,
    });
    /** dados da requisicao */
    if (response.statusCode === 200) {
      updateListTableModel(
        response.body.map((value: any) => {
          if (value['dataPesquisa'])
            value['dataPesquisa'] = ParseDateToEn(
              value['dataPesquisa'],
            );
          return value;
        }),
      );
      changeValueListByColums(response.body);
      if (countPlanning >= 0)
        showAlert({
          show: true,
          content: 'Planejamento Aplicado com sucesso',
          color: 'success',
          time: 2000,
        });
      else
        showAlert({
          show: true,
          content: 'Nenhum item aplicado',
          color: 'warning',
          time: 2000,
        });
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** links da paginacao */
    if (response.headers['link']) {
      let links: LinkHeader = parseLinkHeader(
        response.headers['link'],
      ) as any;

      updateFirstPagination(links.first);
      updateLastPagination(links.last);
      updateNextPagination(links.next);
      updatePrevPagination(links.prev);

      setValuePaginations(
        0,
        parseInt(getParameterUrlByName('page', links.first)!),
        parseInt(getParameterUrlByName('page', links.last)!),
      );
    }
    /** finaliza o loading */
    updateLoading(false);
  }

  function ModalPlanning() {
    return (
      <Modal
        show={showModalPlanning}
        onHide={closePlanning}
        size={'xl'}
        fullscreen={'md-down'}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className='ms-3 lead'
            style={{ fontSize: '30px' }}
          >
            Planejamento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <ContainerFilter
                keyLocalStorage={LOCALSTORAGE_PLANNING}
                filterLocalStorage={getLocalStorage(
                  LOCALSTORAGE_PLANNING,
                )}
                apply={handleApplyPlanning}
                filtersDefault={optionsFiltersDefault}
                listAllPlanning={listAllPlanning}
                listByIdPlanning={function(
                  params?: any,
                ): Promise<any> {
                  throw new Error(
                    'listByIdPlanning ==> não foi implementada',
                  );
                }}
                createPlanning={createPlanning}
                editPlanning={editPlanning}
                removePlanning={removePlanning}
                isOnlineConsultant={false}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *  configurações da tabela */
  const [modelSaveOutsideModal, updateModelSaveOutsideModal] = useState<ModelSave>({
    tipo: 'VIRTUAL',
    filialsRecebimento: [],
    filiaisTransferencia: [],
    filtros: [],
  });
  const [action, updateAction] = useState<string>('VIRTUAL');
  const [modelFilialReceivingSelect, updateModelFilialReceivingSelect] =
    useState<SelectOptionInterface[]>();
  const [modelFilialTransferSelect, updateModelFilialTransferSelect] =
    useState<SelectOptionInterface[]>();

  /**
   *
   *
   *
   *
   *  demandas */
  const today = new Date();
  const periodsMonths = datePeriodsMonths(today, 7);

  function lastMonth(date: string | number | Date, diff: number) {
    const listMonths = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];
    const d = new Date(date);
    let ano = d.getFullYear();
    let month = d.getMonth() - diff;
    if (month < 0) {
      month = month + 12;
      ano = ano - 1;
    }
    return `${listMonths[month]}/${ano}`;
  }

  function datePeriodsMonths(
    data: string | number | Date,
    numberOfMonths: number,
  ) {
    const datas = [];
    for (let i = 0; i < numberOfMonths; i++) {
      datas.push(lastMonth(data, i));
    }
    return datas;
  }

  /**
   *
   *
   *
   *
   *  Processar */
  const [showModalProcess, updateShowModalProcess] = useState(false);
  const closeProcess = () => updateShowModalProcess(false);
  const showProcess = () => updateShowModalProcess(true);

  function ModalProcess() {
    const [modelSaveModal, updateModelSaveModal] = useState<ModelSave>(modelSaveOutsideModal ?? {
      tipo: 'VIRTUAL',
      filialsRecebimento: [],
      filiaisTransferencia: [],
      filtros: [],
    });
    const [actionModal, updateActionModal] = useState<string>(action);
    const [modelFilialReceivingSelectModal, updateModelFilialReceivingSelectModal] = useState<SelectOptionInterface[]>(modelFilialReceivingSelect ?? []);
    const [modelFilialTransferSelectModal, updateModelFilialTransferSelectModal,] = useState<SelectOptionInterface[]>(modelFilialTransferSelect ?? []);

    function handleValueFilialReceivingBranchModal(filial: any) {
      updateModelFilialReceivingSelectModal(filial);
      updateModelSaveModal((prevState) => ({
        ...prevState,
        filialsRecebimento: filial.map((value: any) => {
          return value.value;
        }),
      }));

      updateOptions(
        options.map((item: any) => {
          if (filial.includes(item)) {
            item.isDisabled = true;
          } else {
            item.isDisabled = false;
          }
          return item;
        }),
      );
    }

    function handleValueFilialTransferBranchModal(filial: any) {
      updateModelFilialTransferSelectModal(filial);
      updateModelSaveModal((prevState) => ({
        ...prevState,
        filiaisTransferencia: filial.map((value: any) => {
          return value.value;
        }),
      }));

      updateOptions(
        options.map((item: any) => {
          if (filial.includes(item)) {
            item.isDisabled = true;
          } else {
            item.isDisabled = false;
          }
          return item;
        }),
      );
    }

    const [options, updateOptions] = useState<SelectOptionInterface[]>([]);
    const ENDPOINT_FILIAL = getEnv('REACT_APP_END_POINT_FILIAL');

    async function handleOptions() {
      await new Filial(`${ENDPOINT_FILIAL}?modulos=COMPRAS`)
        .Get()
        .then((response) => {
          if (
            response.statusCode !== 500 &&
            response.statusCode !== undefined
          ) {
            let resp = response.body;

            SortByKey(resp, 'id');
            updateOptions(
              resp.map((value: any) => {
                return {
                  value: value.id,
                  label: value.nome,
                  isDisabled: false,
                };
              }),
            );
          }
        })
        .catch((error) => console.log('error', error));
    }

    useEffect(() => {
      const namePage = "equalization";
      localStorage.setItem("actual_page", namePage);
      handleOptions();
      handleOrderHideColumns(columns);
    }, []);

    return (
      <Modal
        show={showModalProcess}
        onHide={closeProcess}
        fullscreen={'md-down'}
        size={'xl'}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className='ms-3 lead'
            style={{ fontSize: '30px' }}
          >
            Processar Equalização
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ zIndex: 4 }}>
            <Col md={4}>
              <Form.Check
                inline
                label='VIRTUAL'
                type='radio'
                value='VIRTUAL'
                checked={actionModal === 'VIRTUAL'}
                onChange={() => {
                  updateActionModal('VIRTUAL');
                  updateModelSaveModal((prevState) => ({
                    ...prevState,
                    tipo: 'VIRTUAL',
                  }));
                }}
              />
              <Form.Check
                inline
                label='FISICA'
                type='radio'
                value='FISICA'
                checked={actionModal === 'FISICA'}
                onChange={() => {
                  updateActionModal('FISICA');
                  updateModelSaveModal((prevState) => ({
                    ...prevState,
                    tipo: 'FISICA',
                  }));
                }}
              />
            </Col>
            <Col md={4}>
              <Form.Label className='me-3'>
                Filiais de Recebimento:
              </Form.Label>
              <Col>
                <Select
                  options={options}
                  defaultValue={
                    modelFilialReceivingSelectModal
                  }
                  onChange={(val) =>
                    handleValueFilialReceivingBranchModal(
                      val,
                    )
                  }
                  placeholder='Selecione a Filial'
                  isMulti={true}
                />
              </Col>
            </Col>
            <Col md={4}>
              <Form.Label className='me-3'>
                Filiais de Tranferência:
              </Form.Label>
              <Col>
                <Select
                  options={options}
                  defaultValue={
                    modelFilialTransferSelectModal
                  }
                  onChange={(val) =>
                    handleValueFilialTransferBranchModal(
                      val,
                    )
                  }
                  placeholder='Selecione a Filial'
                  isMulti={true}
                />
              </Col>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              closeProcess();
              updateAction(modelSaveModal.tipo ?? 'VIRTUAL');
              updateModelSave(modelSaveModal);
              updateModelSaveOutsideModal(modelSaveModal)
              updateModelFilialReceivingSelect(
                modelFilialReceivingSelectModal,
              );
              updateModelFilialTransferSelect(
                modelFilialTransferSelectModal,
              );
              handleProcessTable(modelSaveModal);
            }}
          >
            Processar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *
   * componentes*/
  const refConteiner = useRef<HTMLDivElement>(null);

  function ContentTable(params: {
    value: any;
    index: number
  }) {
    const [openTableCollapse, updateOpenCollapse] = useState(false);

    let backgroundColor;

    if (
      params.value['alterado'] === true &&
      params.value['bloqueado'] === false
    ) {
      backgroundColor = '#AEFFD1';
    } else if (
      params.value['alterado'] === true &&
      params.value['bloqueado'] === true
    ) {
      backgroundColor = '#FF8C76';
    } else if (params.value['alterado'] === false) {
      backgroundColor = '';
    }

    return (
      <>
        <tr
          key={generateRandomIdV2()}
          className='text-center'
          style={{ background: backgroundColor }}
        >
          <td key={generateRandomIdV2()} className='m-0 p-0'>
            {/* <div className='d-flex align-items-center justify-content-between'>
              <Button
                variant='outline-primary'
                className='d-flex align-items-center'
                aria-controls='collapse-td'
                onClick={() => {
                  updateOpenCollapse(!openTableCollapse);
                }}
                size={'sm'}
              >
                {!openTableCollapse ? (
                  <BsArrowDownRight className='ms-1' />
                ) : (
                  <BsArrowRight className='ms-1' />
                )}
              </Button>
            </div> */}
          </td>
          {columns.map((vc: any, i: number) => {
            if (vc.hidden === false) {
              if (
                vc.dataField === 'alterado' ||
                vc.dataField === 'remanejamentos' ||
                vc.dataField === 'id'
              ) {
                return undefined;
              }

              if (
                params.value[vc.dataField] === null ||
                params.value[vc.dataField] === undefined
              ) {
                return (
                  <td
                    key={i}
                    className='m-0 p-0 mx-2'
                    style={{
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      fontWeight: 250,
                    }}
                  >
                    <div className='m-0 p-0 mx-3 my-1'>
                      {''}
                    </div>
                  </td>
                );
              }

              if (
                typeof params.value[vc.dataField] === 'number'
              ) {
                let formatted = params.value[vc.dataField];
                if (vc.numberFormat === 'currency') {
                  formatted = Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formatted);
                }
                return (
                  <td
                    key={i}
                    className='m-0 p-0 mx-2'
                    style={{
                      textAlign: vc.align,
                      whiteSpace: 'nowrap',
                      fontWeight: 250,
                    }}
                  >
                    <div className='m-0 p-0 mx-3 my-1'>
                      {formatted}
                    </div>
                  </td>
                );
              }

              if (
                typeof params.value[vc.dataField] === 'string'
              ) {
                return (
                  <td
                    key={i}
                    className='m-0 p-0 mx-2'
                    style={{
                      textAlign: vc.align,
                      whiteSpace: 'nowrap',
                      fontWeight: 250,
                    }}
                  >
                    <div className='m-0 p-0 mx-3 my-1'>
                      {params.value[vc.dataField]}
                    </div>
                  </td>
                );
              }

              if (vc.dataField === 'demandas') {
                return params.value['demandas'].map(
                  (month: any, indexMonth: number) => {
                    return (
                      <td key={indexMonth} className='m-0 p-0'
                          style={{ textAlign: vc.align, whiteSpace: 'nowrap', fontWeight: 250 }}>
                        {month}
                      </td>
                    );
                  },
                );
              }

              if (vc.dataField === 'bloqueado') {
                return (
                  <td
                    key={i}
                    className='m-0 p-0 mx-2'
                    style={{
                      textAlign: vc.align,
                      whiteSpace: 'nowrap',
                      fontWeight: 250,
                    }}
                  >
                    <div className='m-0 p-0 mx-3 my-1'>
                      <Form.Check
                        className='ms-3'
                        inline
                        type='checkbox'
                        checked={params.value.bloqueado}
                        onChange={() =>
                          handleCheckItem(
                            params.value,
                          )
                        }
                      />
                    </div>
                  </td>
                );
              }

              return (
                <td key={generateRandomIdV2()}>
                  {params.value[vc.dataField]}
                </td>
              );
            }
          })}
        </tr>
        {/* <tr
          key={generateRandomIdV2()}
          style={{ position: 'sticky', left: 0, zIndex: 2 }}
        >
          <Collapse in={openTableCollapse}>
            <td colSpan={100} style={{ width: '100%', padding: 0 }}>
              <Table
                style={{
                  width: refConteiner.current?.clientWidth,
                  position: 'sticky',
                  left: 0,
                  cursor: 'pointer',
                }}
              >
                <thead>
                <tr>
                  <th style={{ zIndex: 0 }}>
                    Quantidade
                  </th>
                  <th style={{ zIndex: 0 }}>De Filial</th>
                  <th style={{ zIndex: 0 }}>
                    Para Filial
                  </th>
                  <th style={{ zIndex: 0 }}>
                    Cst Médio Unit
                  </th>
                  <th style={{ zIndex: 0 }}>
                    valor da Transferencia
                  </th>
                </tr>
                </thead>
                <tbody>
                {params.value['remanejamentos'].map(
                  (value: any, index: any) => {
                    return (
                      <>
                        <tr
                          key={generateRandomIdV2()}
                        >
                          <td>
                            {value.quantidade}
                          </td>
                          <td>{value.filial}</td>
                          <td>
                            {value.siglaFilial}
                          </td>
                          <td>
                            {value.custoMedio !== undefined ? value.custoMedio.toLocaleString(
                              'pt-br',
                              {
                                style: 'currency',
                                currency:
                                  'BRL',
                              },
                            ) : value.custoMedio}
                          </td>
                          <td>
                            {value.valorTransferencia !== undefined ? value.valorTransferencia.toLocaleString(
                              'pt-br',
                              {
                                style: 'currency',
                                currency:
                                  'BRL',
                              },
                            ) : value.valorTransferencia}
                          </td>
                        </tr>
                      </>
                    );
                  },
                )}
                </tbody>
              </Table>
            </td>
          </Collapse>
        </tr> */}
      </>
    );
  }

  /**
   *
   *
   *
   *
   *  controle de estado*/
  useEffect(() => {
    handleTable();

    const columsLocalStorage = getLocalStorage(LOCALSTORAGE_COLUMS);
    const planningLocalStorage = getLocalStorage(LOCALSTORAGE_PLANNING);

    if (planningLocalStorage)
      updateItemPlanning(planningLocalStorage.length - 1);

    if (columsLocalStorage)
      updateColumns(
        columsLocalStorage.sort(
          (a: {
            order: number
          }, b: {
            order: number
          }) =>
            a.order > b.order ? 1 : -1,
        ),
      );

    updateLocalStorege(LOCALSTORAGE_COLUMS_ORDER, []);
  }, []);
  useEffect(() => {
    changeValueListByColums(listTableModel);
  }, [listTableModel, updateListTableModel]);

  /**
   *
   *
   *
   *
   *
   */
  return (
    <>
      <Headers />
      {loading && <Loading />}
      {!loading && (
        <div>
          <div className='mx-5'>
            <TitleGroupComponent titlePage={titlePage}>
              <Row>
                <Col className='d-flex align-items-center justify-content-end'>
                  <Button
                    variant='outline-primary'
                    className='d-flex align-items-center'
                    onClick={showPlanning}
                    size='sm'
                  >
                    {itemPlanning > 0 ? (
                      <Badge bg='danger' className='me-2'>
                        {itemPlanning}
                      </Badge>
                    ) : (
                      <></>
                    )}
                    <BsSliders className='me-1' />
                    Planejamento
                  </Button>
                  <Button
                    className='ms-1'
                    variant='outline-primary'
                    // onClick={() => handleProcessTable()}
                    onClick={() => showProcess()}
                    size='sm'
                  >
                    Processar
                  </Button>
                  <Button
                    className='ms-1'
                    variant='outline-primary'
                    onClick={showExport}
                    size='sm'
                    disabled={listTable.length === 0}
                  >
                    Exportar
                  </Button>
                  <Button
                    className='ms-1'
                    variant='primary'
                    onClick={handleGenerateEqualization}
                    size='sm'
                    disabled={listTable.length === 0}
                  >
                    Gerar Equalização
                  </Button>
                </Col>
              </Row>
            </TitleGroupComponent>

          </div>
          <div className='mx-3'>
            <>
              <Card>
                <Card.Body>
                  {listTable.length > 0 ? (
                    <>
                      <div className='d-flex align-items-center justify-content-center'>
                        <Painel panel={panelList} />
                      </div>
                      <div>
                        <Row>
                          <Col className='d-flex align-items-center justify-content-between'>
                            <Button
                              variant='outline-primary'
                              onClick={showColumn}
                              size={'sm'}
                              className='d-flex align-items-center'
                            >
                              <BiColumns />
                            </Button>
                            <div>
                              <Badge bg='secondary'>
                                {`
                                                                    ${
                                  typeRemanejamento.tipo !==
                                  ''
                                    ? `Tipo: ${typeRemanejamento.tipo}`
                                    : ``
                                }
                                                                `}
                              </Badge>
                              <Badge
                                bg='secondary'
                                className='ms-2'
                              >
                                {typeRemanejamento.dataPesquisa
                                  ? `Última Pesquisa: ${typeRemanejamento.dataPesquisa}`
                                  : `Sem dados da última pesquisa`}
                              </Badge>
                              <Badge
                                bg='secondary'
                                className='ms-2'
                              >
                                {lastCharge
                                  ? `Última Carga: ${lastCharge}`
                                  : `Sem dados da última carga`}
                              </Badge>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className='mt-3'>
                        <TableHeaderFixedStyled>
                          <Table>
                            <thead>
                            <tr className='m-0 p-0'>
                              <th></th>
                              {columns.map(
                                (
                                  value,
                                  index,
                                ) => {
                                  let align =
                                    value.align;

                                  let classNameDiv = `d-flex align-items-center justify-content-${align} mx-2`;
                                  if (
                                    align ===
                                    'left'
                                  )
                                    classNameDiv = `d-flex align-items-center justify-content-start mx-2`;
                                  if (
                                    align ===
                                    'right'
                                  )
                                    classNameDiv = `d-flex align-items-center justify-content-end mx-2`;

                                  if (
                                    value.hidden ===
                                    false
                                  ) {
                                    if (
                                      value.dataField ===
                                      'remanejamentos' ||
                                      value.dataField ===
                                      'id' ||
                                      value.dataField ===
                                      'alterado'
                                    ) {
                                      return undefined;
                                    }

                                    if (value.dataField === 'demandas') {
                                      return periodsMonths.map(
                                        (month, indexMonth) => {
                                          return (
                                            <th key={indexMonth}>
                                              <div className='d-flex align-items-center'>
                                                <p style={{ whiteSpace: 'nowrap' }} className='m-2'>
                                                  {month}
                                                </p>
                                              </div>
                                            </th>
                                          );
                                        },
                                      );
                                    }

                                    return (
                                      <>
                                        {value.ordination ? (
                                          <th
                                            align={
                                              value.align
                                            }
                                            className='px-1'
                                            key={
                                              index
                                            }
                                            onClick={() => {
                                              orderValueColums(
                                                value,
                                              );
                                            }}
                                          >
                                            <div
                                              className={
                                                classNameDiv
                                              }
                                            >
                                              <div className='d-flex align-items-center justify-content-start'>
                                                <p
                                                  style={{
                                                    whiteSpace:
                                                      'nowrap',
                                                  }}
                                                  className='m-1'
                                                >
                                                  {
                                                    value.text
                                                  }
                                                </p>
                                                <CreateIconOrder
                                                  nameColumn={
                                                    value.ordination
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </th>
                                        ) : (
                                          <th
                                            align={
                                              value.align
                                            }
                                            className='px-1'
                                            key={
                                              index
                                            }
                                          >
                                            <div
                                              className={
                                                classNameDiv
                                              }
                                            >
                                              <>
                                                <p
                                                  style={{
                                                    whiteSpace:
                                                      'nowrap',
                                                  }}
                                                  className='m-1'
                                                >
                                                  {
                                                    value.text
                                                  }
                                                </p>
                                                {value.dataField ===
                                                  'bloqueado' && (
                                                    <Form.Check
                                                      type='checkbox'
                                                      onChange={() =>
                                                        handleCheckAll()
                                                      }
                                                      checked={
                                                        checkAll
                                                      }
                                                      className='ms-1'
                                                    />
                                                  )}
                                              </>
                                            </div>
                                          </th>
                                        )}
                                      </>
                                    );
                                  }
                                },
                              )}
                            </tr>
                            </thead>
                            <tbody>
                            {listTable.map(
                              (
                                value,
                                index,
                              ) => {
                                return (
                                  <>
                                    <ContentTable
                                      value={
                                        value
                                      }
                                      index={
                                        index
                                      }
                                    />
                                  </>
                                );
                              },
                            )}
                            <tr></tr>
                            </tbody>
                          </Table>
                        </TableHeaderFixedStyled>
                      </div>
                      <div className='mt-4'>
                        <Row className='d-flex align-items-start justify-content-between'>
                          <Col className='d-flex justify-content-start'></Col>
                          <Col>
                            <div className='d-flex justify-content-center'>
                              <Pagination size='sm'>
                                <Pagination.First
                                  onClick={
                                    onClickFirstPagination
                                  }
                                >
                                  Primeira
                                </Pagination.First>
                                <Pagination.Prev
                                  onClick={
                                    onClickPrevPagination
                                  }
                                >
                                  Anterior
                                </Pagination.Prev>
                                {paginationItemList?.map(
                                  (
                                    value,
                                    key,
                                  ) => {
                                    return (
                                      <Pagination.Item
                                        key={
                                          key
                                        }
                                        active={
                                          value.active
                                        }
                                        onClick={() =>
                                          onClickItemPagination(
                                            value,
                                          )
                                        }
                                      >
                                        {value.item +
                                          1}
                                      </Pagination.Item>
                                    );
                                  },
                                )}
                                <Pagination.Next
                                  onClick={
                                    onClickNextPagination
                                  }
                                >
                                  Próxima
                                </Pagination.Next>
                                <Pagination.Last
                                  onClick={
                                    onClickLastPagination
                                  }
                                >
                                  Última
                                </Pagination.Last>
                              </Pagination>
                            </div>
                          </Col>
                          <Col className='d-flex justify-content-end'>
                            <Button
                              variant='outline-danger'
                              onClick={() => {
                                handleDeleteAll();
                                updateLoading(
                                  true,
                                );
                              }}
                              size={'sm'}
                            >
                              Remover Tudo
                            </Button>
                            <Button
                              className='ms-1'
                              variant='outline-danger'
                              onClick={() => {
                                handleDeletePage();
                                updateLoading(
                                  true,
                                );
                              }}
                              size={'sm'}
                            >
                              Remover Página
                            </Button>
                            <Button
                              className='ms-1'
                              variant='outline-primary'
                              onClick={() => {
                                handleSavePage();
                                updateLoading(
                                  true,
                                );
                              }}
                              size={'sm'}
                            >
                              Salvar Página
                            </Button>
                            <Button
                              className='d-flex align-items-center ms-1'
                              variant='outline-primary'
                              onClick={
                                handleSaveAll
                              }
                              size={'sm'}
                            >
                              Salvar Tudo
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className='d-flex align-items-center justify-content-center'
                        style={{ height: '60vh' }}
                      >
                        <p className='m-5'>
                          A busca não retorna nada,
                          verifique o Planejamento ou
                          clique em Processar
                        </p>
                      </div>
                      <div className='mt-4'>
                        <Row className='d-flex align-items-center justify-content-between'>
                          <Col className='d-flex align-items-center justify-content-start'></Col>
                          <Col className='d-flex align-items-center justify-content-center'>
                            <Pagination size='sm'>
                              <Pagination.First
                                onClick={
                                  onClickFirstPagination
                                }
                              >
                                Primeira
                              </Pagination.First>
                              <Pagination.Prev
                                onClick={
                                  onClickPrevPagination
                                }
                              >
                                Anterior
                              </Pagination.Prev>
                              {paginationItemList?.map(
                                (
                                  value,
                                  key,
                                ) => {
                                  return (
                                    <Pagination.Item
                                      key={
                                        key
                                      }
                                      active={
                                        value.active
                                      }
                                      onClick={() =>
                                        onClickItemPagination(
                                          value,
                                        )
                                      }
                                    >
                                      {value.item +
                                        1}
                                    </Pagination.Item>
                                  );
                                },
                              )}
                              <Pagination.Next
                                onClick={
                                  onClickNextPagination
                                }
                              >
                                Próxima
                              </Pagination.Next>
                              <Pagination.Last
                                onClick={
                                  onClickLastPagination
                                }
                              >
                                Última
                              </Pagination.Last>
                            </Pagination>
                          </Col>
                          <Col className='d-flex justify-content-end'>
                            <Button
                              variant='outline-danger'
                              size={'sm'}
                              disabled
                            >
                              Remover Tudo
                            </Button>
                            <Button
                              className='ms-1'
                              variant='outline-danger'
                              size={'sm'}
                              disabled
                            >
                              Remover Página
                            </Button>
                            <Button
                              className='ms-1'
                              variant='outline-primary'
                              size={'sm'}
                              disabled
                            >
                              Salvar Página
                            </Button>
                            <Button
                              className='d-flex align-items-center ms-1'
                              variant='outline-primary'
                              size={'sm'}
                              disabled
                            >
                              Salvar Tudo
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </>
          </div>
          <Footer />
        </div>
      )}

      <ModalOrderHideColumns />
      <ModalPlanning />
      <ModalProcess />
      <ModalExport />
    </>
  );
};

export default EqualizationPage;
