type examinedFile = { error: string | null, readingProgress: number, numberOfLines: number }

var errors: any[] = []
var blob: Blob

const LoadFile = async (file: File, lineSize: number, numberOfColumns: number, fileType: number, companyCode: number): Promise<examinedFile> => {

    const layout10 = "N5N5N5A50N5N5N7A33A50A20A5N4N7N7N7N7N7N7N7N7N7N7N7N7"
    const layout11 = "N5N5N7N5A10N10N10N10N10N8N8N8A20N7N7N7N7N7N7A33N5A1N7"

    var layout = fileType === 10 ? layout10 : layout11

    console.log("LoadFile", {
        file: file, lineSize: lineSize, numberOfColumns: numberOfColumns, fileType: fileType, companyCode: companyCode
    })

    return new Promise<examinedFile>((resolve) => {

        var generalLine = 1
        var readingProgress = 0
        var numberOfLines = 0
        var fullSize = file.size
        var sizeEachSlice = lineSize * 2
        var progress = 0

        const reader = new FileReader()

        if (file.name.indexOf('.zip') >= 0) {
            resolve({
                error: "Para validar o arquivo o necessário que ele esteja descompactado",
                readingProgress: 0,
                numberOfLines: 0
            })
        } else {
            var isConfigured = false
            blob = sliceFile(file, 0, sizeEachSlice)
        }

        reader.onerror = function (e) {
            switch (e.target!.error!.code) {
                case e.target!.error!.NOT_FOUND_ERR:
                    addErrorGeneral('Arquivo Não Encontrado')
                    break
                case e.target!.error!.ABORT_ERR:
                    break
                default:
                    addErrorGeneral('Erro encontrado na leitura do arquivo.')
            }
        }

        reader.onabort = function (e) {
            alert('Verificação Cancelada.')
        }

        reader.onloadend = function (e) {
            if (e.target!.readyState === FileReader.DONE) {

                var contents = e.target!.result as string
                var line = contents!.split('\n')
                var count = 0

                numberOfLines = line.length
                progress += contents.length

                if (progress > 700) {

                    for (var i = 0; i < line.length; i++, generalLine++) {

                        count++
                        readingProgress = parseInt((count * 100 / line.length).toFixed(5))

                        if (line[i].length <= 1 && i === line.length - 1)
                            continue
                        if (line[i].length > lineSize) {
                            if (i !== line.length - 1 || !(i === line.length - 1 && line[i].length === lineSize - 2)) {
                                // var msg = 'Tamanho da linha diferente. Tamanho esperado: ' + (lineSize - 1) + ', tamanho encontrado: ' + line[i].length + ', valor: ' + line[i] + '.'
                                var msg = 'Tamanho da linha diferente. Tamanho esperado: ' + (lineSize - 1) + ', tamanho encontrado: ' + line[i].length
                                if (line[i].indexOf('"') === 0) {
                                    msg = 'Linha começando com o caracter aspas duplas ("), valor: ' + line[i] + '.'
                                }
                                addErrorToLine(msg, generalLine)
                            }
                        }

                        var colunas = line[i].split(';')
                        if (colunas.length !== numberOfColumns && colunas.length > 0)
                            addErrorToLine('Quantidade de ";" encontrados maior que o esperado.', generalLine)

                        if (colunas[0] != null && parseInt(colunas[0]) !== companyCode)
                            addErrorToLine('Id da empresa no arquivo (' + parseInt(colunas[0]) + ') é diferente da empresa logada (' + companyCode + ').', generalLine)

                        for (var c = 0; c < colunas.length; c++) {
                            searchForGenericErrors(colunas[c], generalLine, c, layout)
                        }

                        if (errors.length >= 1) {
                            progress = progress - ((line.length - i) * lineSize)
                            break
                        }
                    }

                } else
                    progress = 0

                if (progress < fullSize && errors.length === 0) {
                    blob = sliceFile(file, progress, sizeEachSlice)
                    reader.readAsText(blob)
                }

                console.log("ERROR ==> ", errors)

                var objectReturn = {
                    error: errors[0],
                    readingProgress: readingProgress,
                    numberOfLines: numberOfLines
                }

                resolve(objectReturn)

            }
        }

        reader.onload = function (e) {

            var contents = e.target!.result as string
            var line = contents!.split('\n')

            if (line.length > 0 && isConfigured === false) {
                lineSize = line[0].length + 1
                numberOfColumns = line[0].split(';').length
                sizeEachSlice = lineSize * 2000
                isConfigured = true
                progress = 0
                console.log("ONLOAD ===> ", 'sizeEachSlice: ' + sizeEachSlice + ', lineSize: ' + lineSize + ', lineSize: ' + lineSize)
            }
        }

        reader.readAsText(file)

    })
}

export default LoadFile

function addErrorInColumn(msg: any, line: any, value: any, col: any) {
    errors.push(`Linha: ${line}, Coluna: ${col}, Valor: ${value}, Erro: ${msg}`)
}

function addErrorToLine(msg: any, line: any) {
    errors.push(`Linha: ${line}, Erro: ${msg}`)
}

function addErrorGeneral(error: any) {
    errors.push('Linha: ', error)
}

function searchForGenericErrors(
    value: string,
    generalLine: any,
    colCurrent: number,
    layout: string | null
) {
    if (/[^0-9]/.test(value) === false
        && /^[A-Za-z\d\s]+$/.test(value) === false && value.length > 1) {
        addErrorInColumn('Caracter especial encontrado.', generalLine, value, colCurrent + 1)
    }
    if (/\t/.test(value) === true)
        addErrorInColumn('Caracter de tabulação encontrado.', generalLine, value, colCurrent + 1)
    if (/\n/.test(value) === true)
        addErrorInColumn('Caracter de <ENTER> encontrado no conteúdo do campo.', generalLine, value, colCurrent + 1)
    if (/\v/.test(value) === true)
        addErrorInColumn('Caracter de tabulação vertical encontrado.', generalLine, value, colCurrent + 1)
    if (/^\d*(\.\d+)$/.test(value) === true && /[A-Za-z]/.test(value) === false)
        addErrorInColumn('Ponto encontrado em campo numérico inteiro.', generalLine, value, colCurrent + 1)

    if (layout != null) {
        var layouts = layout.split("")
        var pattern = layouts[colCurrent]
        if (pattern) {
            var qtd = parseInt(pattern.charAt(1) + pattern.charAt(2) + pattern.charAt(3))
            // Tirar o caracter ENTER
            value = value.replace(/(\r\n|\n|\r)/gm, "")
            if (value.length > qtd)
                addErrorInColumn('Campo com tamanho maior.', generalLine, value, colCurrent + 1)
            if (value.length < qtd)
                addErrorInColumn('Campo com tamanho menor.', generalLine, value, colCurrent + 1)
        }
    }
}

function sliceFile(
    file: File,
    initialSize: number,
    sizeEachSlice: number
): Blob {
    if (file.slice)
        blob = file.slice(initialSize, initialSize + sizeEachSlice) as Blob
    console.log("SLICE_FILE ===> ", 'initialSize: ' + initialSize + ', tamFinal: ' + (initialSize + sizeEachSlice) + ', sizeEachSlice: ' + sizeEachSlice + ', file total: ' + file.size)
    return blob
}
