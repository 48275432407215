import { OrdinationInterface } from "domain/interfaces/ordination-interface";

export const OrdinationVirtualEqValues: OrdinationInterface[] = [
  { croStrColuna: "Crítico        ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Crítico" },
  { croStrColuna: "Crítico Asc.   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Crítico Asc." },
  { croStrColuna: "Crítico Des.   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Crítico Des." },
  { croStrColuna: "Novo Crítico   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Novo Crítico" },
  { croStrColuna: "Dem.Zero Asc.  ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Dem.Zero Asc." },
  { croStrColuna: "Dem. Zero      ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Dem. Zero" },
  { croStrColuna: "Estável        ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Estável" },
  { croStrColuna: "Estável Asc.   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Estável Asc." },
  { croStrColuna: "Estável Des.   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Estável Des." },
  { croStrColuna: "Excesso        ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Excesso" },
  { croStrColuna: "Excesso Asc.   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Excesso Asc." },
  { croStrColuna: "Excesso Des.   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao:"Excesso Des." },
  { croStrColuna: "Novo           ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Novo" },
  { croStrColuna: "Pleno          ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Pleno" },
  { croStrColuna: "Pleno Asc.     ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Pleno Asc." },
  { croStrColuna: "Pleno Des.     ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Pleno Des." },
  { croStrColuna: "Novo Asc.      ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Novo Asc." },
  { croStrColuna: "Novo Des.      ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Novo Des." },
  { croStrColuna: "Novo Premiun   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Novo Premiun" },
  { croStrColuna: "Novo S/Vda     ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Novo S/Vda" },
  { croStrColuna: "Blq. Técnico   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Blq. Técnico" },
  { croStrColuna: "Blq.Definitivo ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Blq.Definitivo" },
  { croStrColuna: "Blq.Def.Desc.  ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Blq.Def.Desc." },
  { croStrColuna: "Blq.Def.Asc.   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Blq.Def.Asc." },
  { croStrColuna: "Blq.Téc.Desc.  ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Blq.Téc.Desc." },
  { croStrColuna: "Blq.Téc.Asc.   ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Blq.Téc.Asc." },
  { croStrColuna: "Ajuste         ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Ajuste" },
  { croStrColuna: "Dm.Zero S/Vda  ", croStrTipordem: "", croChaTipo: "V", croIntOrdem: 0, descricao: "Dm.Zero S/Vda" },
]