/* eslint-disable no-restricted-globals */
import { useEffect, useMemo, useState } from "react"
import { UseAlert } from "presentation/hook/alert-hook"
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { UseAuth } from "presentation/hook/auth-hook"
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter"
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { getEnv } from "main/helper/window-helper"
import { clearLocalStoragePlanning, getLocalStorage } from "data/cache/localstorage-cache"
import { useNavigate } from "react-router-dom"
import { FaArrowLeft } from "react-icons/fa"

export function HeaderNavegation() {

    const LOCALSTORAGE_TOKEN_USER_CHANGE = getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_CHANGE")!
    const changUserTokenLocalStorage: { token: string } = getLocalStorage(LOCALSTORAGE_TOKEN_USER_CHANGE)

    let navigate = useNavigate()

    const [hideToOriginUser, updateHideToOriginlUser] = useState<boolean>(true)
    const [hideSwitchInternalUser, updateHideSwitchInternalUser] = useState<boolean>(true)

    const [headerNavegation, updateHeaderNavegation] = useState<any[]>(HeaderNavegationList)

    const { loggedUserData } = UseAuth()
    useMemo(() => {

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                HeaderNavegationList.map(nav => {
                    return nav.navItem.map(item => {
                        if(item.role.includes(value)) {
                            nav.visible = true
                            item.visible = true
                        }
                    })
                })
            })
            if(loggedUserData.role.includes("IS_GRUPO_EMPRESA")){
                updateHideSwitchInternalUser(false)
            }
        }

        if(changUserTokenLocalStorage !== undefined && changUserTokenLocalStorage !== null &&
            changUserTokenLocalStorage.token !== undefined && changUserTokenLocalStorage.token !== ""){
            updateHideToOriginlUser(false)
        }

        updateHeaderNavegation(HeaderNavegationList)

    }, [loggedUserData])

    const { messageAlert, showAlert } = UseAlert()

    const AlertMemo = useMemo(() => Alert(), [messageAlert, showAlert])

    function Alert() {
        if (messageAlert?.show) {
            return (
                <>
                {
                    Store.addNotification({
                        title: "",
                        message: messageAlert.content,
                        type: messageAlert.color,
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: messageAlert.time? messageAlert.time : 5000,
                        onScreen: true,
                        showIcon: true
                        }
                    })
                }
                </>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function changeBackToOriginUser(){
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_CHANGE"))
        clearLocalStoragePlanning()
        navigate("/")
        location.reload()
    }

    useEffect(() => {
        if (messageAlert?.time) {
            setTimeout(() => {
                showAlert({
                    show: false,
                    content: "",
                    color: messageAlert.color
                })
            }, messageAlert.time)
        }
    }, [messageAlert, showAlert])

    return (
        <>
            <Navbar bg="dark" expand="lg" variant="dark">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {headerNavegation.map((nav, indexNav) => {
                                if (nav.visible) {
                                    return (
                                        <NavDropdown title={nav.navTitle} id={nav.idNav} key={indexNav}>
                                            {nav.navItem.map((item: any, indexItem: number) => {
                                                if (item.visible) {
                                                    return (
                                                        <NavDropdown.Item href={item.route} key={indexItem}>{item.title}</NavDropdown.Item>
                                                    )
                                                }
                                            })}
                                        </NavDropdown>
                                    )
                                }
                            })}
                        </Nav>
                        <div hidden={hideToOriginUser} className="me-2">
                            <Button onClick={changeBackToOriginUser} size="sm" variant="outline-primary"> 
                            <FaArrowLeft size={15}/> Usuario de Origem
                            </Button>
                        </div>
                        <div hidden={hideSwitchInternalUser}>
                            <Button href="/troca-usuario-interno-empresa" size="sm" variant="secondary" >Trocar Usuario</Button>
                        </div>
                        { AlertMemo }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )

}


