import { useEffect, useState } from "react"
import { UseAuth } from "presentation/hook/auth-hook"
import { RestCrud } from "data/useCase/rest-crud-usecase"
import { PanelInfo } from "data/useCase/panel-info-usecase"
import PageDefault from "presentation/pages/page-default"
import { DefaultEntity } from "domain/entity/default-entity"
import { PanelEntity } from "domain/entity/panel/panel-entity"
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page"
import { getEnv } from "main/helper/window-helper"

export const DefaultFactory: React.FC = () => {

    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_DEFAULT")
    const ENDPOINT_PANEL = getEnv("REACT_APP_END_POINT_GET_PANEL")

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = ["ADMIN"]

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }
    }, [loggedUserData])

    return (
        <>
            {showScreen ?

                <PageDefault
                    modelMethods={{
                        create: async function (model: DefaultEntity): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Post(model)
                            return response.body
                        },
                        edit: async function (model: DefaultEntity): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Put(model, model.id!)
                            return response.body
                        },
                        remove: async function (id: number): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Delete(id)
                            return response.body
                        },
                        getAll: async function (): Promise<DefaultEntity[]> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Get()
                            return response.body
                        },
                        getById: async function (id: number): Promise<DefaultEntity> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).GetById(id)
                            return response.body
                        },
                        get: async function (): Promise<DefaultEntity> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Get()
                            return response.body
                        }
                    }}
                    getPanel={async function (): Promise<PanelEntity[]> {
                        const response = await new PanelInfo(ENDPOINT_PANEL!).Get()
                        return response.body
                    }}
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )
}
