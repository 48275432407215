/* eslint-disable no-restricted-globals */
import { setLocalStorege } from "data/cache/localstorage-cache"
import { AuthUseCase } from "data/useCase/auth-usecase"
import { getEnv } from "main/helper/window-helper"
import LoginPage from "presentation/pages/auth/login-page"
import { useState } from "react"

export const LoginFactory: React.FC = () => {

    const ENDPOINT_LOGIN = getEnv("REACT_APP_END_POINT_LOGIN")!
    const LOCALSTORAGE_TOKEN_USER_LOGIN = getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_LOGIN")!
    const LOCALSTORAGE_VIEW_COMMUNIQUE = getEnv("REACT_APP_LOCALSTORAGE_VIEW_COMMUNIQUE")!

    const [withError, updateWithError] = useState<boolean>(false)
    const [waiting, updateWaiting] = useState<boolean>(false)

    const authentication = async (params: { login: string, password: string }) => {
        updateWaiting(true)

        const loginFormData = new FormData();
        loginFormData.append('username', params.login);
        loginFormData.append('password', params.password);

        await new AuthUseCase(`${ENDPOINT_LOGIN}/login`)
            .Post({ data: {
                username: params.login,
                password: params.password
            }})
            .then(response => {
                if (response.statusCode === 200) {
                    setLocalStorege(LOCALSTORAGE_TOKEN_USER_LOGIN, response.body)
                    setLocalStorege(LOCALSTORAGE_VIEW_COMMUNIQUE, { view: true })
                    location.reload()
                } else {
                    updateWithError(true)
                }
            })
            .catch((error) => {
                updateWithError(true)
            })
        updateWaiting(false)
    }

    const resetPassword = async (params: { email: string, login?: string }) => {
        updateWaiting(true)

        let query = `?email=${params.email}`

        if(params.login !== null && params.login !== undefined && params.login !== "") {
            query += `&login=${params.login}`
        }

        await new AuthUseCase(`${ENDPOINT_LOGIN}/request-password-reset${query}`)
            .Post()
            .then(response => {
            })
            .catch((error) => {
                updateWithError(true)
            })
        updateWaiting(false)
    }

    return (
        <>
            <LoginPage authentication={authentication} resetPassword={resetPassword} error={withError} waiting={waiting} />
        </>
    )
}