import api from "infra/api/api-infra"
import { AxiosResponse } from "axios"
import { HttpRequest } from "domain/types/http-type"

export async function CallHttpLogin(request: HttpRequest) {

    let axiosResponse: AxiosResponse

    try {
        axiosResponse = await api.request({
            url: request.url,
            method: request.method,
            data: request.body,
            headers: request.headers,
        })
    } catch (error: any) {
        axiosResponse = error
    }

    return {
        statusCode: axiosResponse.status,
        headers: axiosResponse.headers,
        body: axiosResponse.data
    }
}
