import { useEffect, useState } from "react"
import CrudPage from "presentation/pages/crud-page"
import { UseAuth } from "presentation/hook/auth-hook"
import { RestUseCase } from "data/useCase/rest-usecase"
import { EntityField } from "domain/entity/field/crud/field-crud-entity"
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page"
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter"
import { getEnv } from "main/helper/window-helper"

export const ManufacturerFactory: React.FC = () => {

    /** ENDPOINT */
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_MANUFACTURER")!

    /** TITULO DA PAGINA */
    const titlePage = "Cadastrar Fabricante"

    /** CAMPOS PARA MODAL E TABELA */
    const entityFields: EntityField[] = [
        { name: "codigo", labelField: "", labelTable: "Código", typeField: "hidden", valueField: null, sizeField: 0, required: false, align: "center", tableField: { column: "Código", columnVisible: true } },
        { name: "nome", labelField: "Nome:", labelTable: "Nome", typeField: "text", valueField: null, sizeField: 0, required: false, align: "left", tableField: { column: "Nome", columnVisible: true } },
        { name: "percentualDesconto", labelField: "Percentual de Desconto:", labelTable: "Percentual de Desconto", typeField: "number", valueField: null, sizeField: 0, required: false, align: "right", tableField: { column: "Percentual de Desconto", columnVisible: true } },
        { name: "prazo", labelField: "Prazo:", labelTable: "Prazo", typeField: "number", valueField: null, sizeField: 0, required: false, align: "right", tableField: { column: "Prazo", columnVisible: true } }
    ]
    const [entity, updateEntity] = useState(entityFields)

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([])
    const [tableBody, updateTableBody] = useState([])

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Configurações")[0].navItem.filter(item => item.title === "Cadastrar Fabricante")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }

    }, [loggedUserData])

    return (
        <>
            {showScreen ?
                <CrudPage
                    entityFields={entity}
                    updateEntity={updateEntity}

                    titlePage={titlePage}

                    getAll={async function () {
                        const response = await new RestUseCase(`${ENDPOINT_CRUD}`)
                            .Get()
                            .finally()

                        updateTableHeader(entityFields.map((value) => { return { name: value.name, labelTable: value.labelTable, align: value.align, columnVisible: value.tableField.columnVisible } }))
                        updateTableBody(response.body)

                    }}

                    getById={async function (id: number) {
                        const response = await new RestUseCase(`${ENDPOINT_CRUD}/${id}`)
                            .GetById()
                            .finally()

                        updateEntity(entityFields.map((value) => {
                            value.valueField = response.body[value.name]
                            return value
                        }))
                    }}

                    post={async function (model: any) {
                        const id = model["codigo"]
                        await new RestUseCase(`${ENDPOINT_CRUD}/${id}`).Post({ data: model }).finally()
                    }}

                    patch={async function (id: number, model: any) {
                        const response = await new RestUseCase(`${ENDPOINT_CRUD}/${id}`).Patch({ data: model }).finally()

                        updateEntity(entityFields.map((value) => {
                            value.valueField = response.body[value.name]
                            return value
                        }))
                    }}

                    del={async function (id: number) {
                        await new RestUseCase(`${ENDPOINT_CRUD}/${id}`).Delete().finally()
                    }}

                    tableHeader={tableHeader}
                    tableBody={tableBody}

                    valueId={"codigo"}
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )

}
