import { useEffect, useState } from 'react';
import CrudPage from 'presentation/pages/crud-page';
import { UseAuth } from 'presentation/hook/auth-hook';
import { RestUseCase } from 'data/useCase/rest-usecase';
import { ParseDateToBrWithHours, ParseDateToEn } from 'main/helper/format-date-helper';
import { EntityField } from 'domain/entity/field/crud/field-crud-entity';
import ErrorNotAuthorizedPage from 'presentation/pages/error/error-not-authorized-page';
import { HeaderNavegationList } from 'main/adapter/headerNavigation/header-navegation-adapter';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { getEnv } from 'main/helper/window-helper';

export const InfoFactory: React.FC = () => {
  /** ENDPOINT */
    // const ENDPOINT_CRUD = getEnv('REACT_APP_END_POINT_INFO')!;
  const ENDPOINT_CRUD = getEnv('REACT_APP_END_POINT_HELP')!;


  /** TITULO DA PAGINA */
  const titlePage = 'Informações da Tela';

  /** USUARIO LOGADO */
  const { loggedUserData } = UseAuth();

  const options = HeaderNavegationList.flatMap(category => category.navItem.map(({ title, route }) => ({
    label: title,
    value: route,
  })));

  /** CAMPOS PARA MODAL E TABELA */
  const entityFields: EntityField[] = [
    {
      name: 'id',
      labelField: '',
      labelTable: 'ID',
      typeField: 'hidden',
      valueField: '',
      sizeField: 0,
      required: false,
      align: 'center',
      tableField: { column: 'id', columnVisible: true },
    },
    {
      name: 'conteudo',
      labelField: 'Conteúdo:',
      labelTable: 'Conteúdo',
      typeField: 'html',
      valueField: '',
      sizeField: 12,
      required: false,
      align: 'center',
      tableField: { column: 'Conteúdo', columnVisible: false },
    },
    {
      name: 'tela',
      labelField: 'Tela:',
      labelTable: 'Tela',
      typeField: 'select',
      valueField: null,
      listValueField: options,
      sizeField: 3,
      required: false,
      align: 'center',
      tableField: { column: 'Tela', columnVisible: true },
    },
    {
      name: 'Upload',
      labelField: 'Upload:',
      labelTable: 'Upload',
      typeField: 'file',
      valueField: '',
      sizeField: 9,
      required: false,
      align: 'left',
      tableField: { column: 'upload', columnVisible: false },
      onCallBack: () => {
        updateEntity(entity);
      },
    },
  ];

  const [entity, updateEntity] = useState(entityFields);

  /** TABELA */
  const [tableHeader, updateTableHeader] = useState<any[]>([]);
  const [tableBody, updateTableBody] = useState([]);

  /** VERIFICA PERMISSAO DE ACESSO */
  const [showScreen, updateShowScreen] = useState<boolean>(false);
  useEffect(() => {
    const roleScreen = ['ADMIN'];

    if (loggedUserData?.role) {
      loggedUserData.role.forEach(value => {
        console.log(value);
        if (roleScreen.includes(value))
          updateShowScreen(true);
      });
    }
  }, [loggedUserData]);

  return (
    <>
      {showScreen ? (
        <CrudPage
          filterByCompany={false}
          entityFields={entity}
          updateEntity={updateEntity}
          titlePage={titlePage}
          getAll={async function() {
            const response = await new RestUseCase(
              `${ENDPOINT_CRUD}`,
            )
              .Get()
              .finally();

            updateTableHeader(
              entityFields.map((value) => {
                return {
                  name: value.name,
                  labelTable: value.labelTable,
                  align: value.align,
                  columnVisible: value.tableField.columnVisible,
                };
              }),
            );
            updateTableBody(response.body.filter((item: any) => item.label === 'IS_COMPRAS'));
          }}
          getById={async function(id: number) {
            const response = await new RestUseCase(
              `${ENDPOINT_CRUD}/${id}`,
            )
              .GetById()
              .finally();

            updateEntity(
              entityFields.map((value) => {
                value.valueField = response.body[value.name];
                return value;
              }),
            );
          }}
          post={async function(model: any) {
            delete model['id'];
            model['label'] = 'IS_COMPRAS';
            await new RestUseCase(ENDPOINT_CRUD)
              .Post({ data: model })
              .finally();
          }}
          patch={async function(id: number, model: any) {
            delete model['id'];
            model['label'] = 'IS_COMPRAS';
            const response = await new RestUseCase(
              `${ENDPOINT_CRUD}/${id}`,
            )
              .Put({ data: model })
              .finally();

            updateEntity(
              entityFields.map((value) => {
                value.valueField = response.body[value.name];
                return value;
              }),
            );
          }}
          del={async function(id: number) {
            await new RestUseCase(`${ENDPOINT_CRUD}/${id}`).Delete().finally();
          }}
          tableHeader={tableHeader}
          tableBody={tableBody}
        />
      ) : (
        <ErrorNotAuthorizedPage />
      )}
    </>
  );
};
