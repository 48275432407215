import { UseAuth } from "presentation/hook/auth-hook";
import Private from "./private-route";
import Public from "./public-route";

export function AppRoutes() {

    const { isLoggedIn } = UseAuth()

    return !isLoggedIn ? <Public /> : <Private />;

}

export default AppRoutes
