const hasWindow = typeof window !== "undefined";

export function GetWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
        width,
        height,
    };
}

export function getEnv(key: string): string {
    if (hasWindow) {
        const windowTESTE: any = window.window;
        if (windowTESTE._env_) {
            return windowTESTE._env_[key] ?? "";
        } else {
            return process.env[key] ?? "";
        }
    } else {
        return process.env[key] ?? "";
    }
}
