import Select from 'react-select'
import { useEffect, useState } from "react"
import { Filial } from "data/useCase/filial-usecase"
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface'
import { getEnv } from 'main/helper/window-helper'

type Props = {
    passOnTheValue: any,
    defaultValue?: SelectOptionInterface
}

export const SelectProvider: React.FC<Props> = ({ passOnTheValue, defaultValue }: Props) => {

    const ENDPOINT_FILIAL = getEnv("REACT_APP_END_POINT_PROVIDER")

    const [options, updateOptions] = useState<SelectOptionInterface[]>([])

    async function handleOptions() {
        await new Filial(ENDPOINT_FILIAL!)
            .Get()
            .then(response => {
                if (response.statusCode !== 500 && response.statusCode !== undefined) {
                    updateOptions(response.body.map((value: any) => {
                        return {
                            value: value.id,
                            label: value.nome,
                        }
                    }))
                }
            })
            .catch(error => console.log("error", error))
    }

    function changeValueSelectes(params: any) {
        passOnTheValue(params)
    }

    /**
     * 
     * INIT
     * 
     */
    useEffect(() => {
        handleOptions()
    }, [])

    return (

        <>
            <Select
                defaultValue={defaultValue}
                options={options}
                onChange={val => changeValueSelectes(val)}
            />
        </>
    )

}
