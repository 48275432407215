import { SCDesctiption } from 'presentation/style/typography/titlePage';
import { ReactNode } from 'react';

type PropsHeaderCrud = {
  title: string
  children: ReactNode
}

const HeaderCrud: React.FC<PropsHeaderCrud> = ({ title, children }: PropsHeaderCrud) => {

  return (
    <div className='mx-4'>
      <div className='d-flex align-items-center justify-content-between'>
        <div>
          {/*<SCDesctiption>{title}</SCDesctiption>*/}
        </div>
        {children}
      </div>
    </div>
  );
};

export default HeaderCrud;