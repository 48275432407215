import { EntityFieldReport } from "domain/entity/field/report/field-report";
import { ConfigTable } from "domain/interfaces/table-interface";
import { ParseDateToBrV2 } from "main/helper/format-date-helper";
import { TableHeaderStyled } from "presentation/style/table/table-header";
import { TableHeaderFixedStyled } from "presentation/style/table/table-header-fixed-styled";
import { Form, Table } from "react-bootstrap";
import { BsCheckLg } from "react-icons/bs";
import {
    MdNotificationImportant,
    MdOutlineNotificationImportant,
} from "react-icons/md";

type PropsTable = {
    tableHeader: any[];
    tableBody: any[];
    entityFields: EntityFieldReport[];
    configTable: ConfigTable;
    onClickCheckboxRow?: any;
    onClickCheckboxColumn?: any;
    onClickOpenModal?: any;
    changeItem?: any;
    saveRow?: any;
};

const CreateTableReport: React.FC<PropsTable> = ({
    tableHeader,
    tableBody,
    entityFields,
    configTable,
    onClickCheckboxRow,
    onClickCheckboxColumn,
    onClickOpenModal,
    changeItem,
    saveRow,
}: PropsTable) => {
    return (
        <TableHeaderFixedStyled>
            <Table>
                <thead>
                    <tr>
                        {configTable.checkboxes ? (
                            tableBody.length > 0 ? (
                                <>
                                    <th
                                        style={{ whiteSpace: "nowrap" }}
                                        className="m-0 p-0"
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            className="ms-1 mb-1"
                                            onClick={onClickCheckboxColumn}
                                        />
                                    </th>
                                </>
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        )}
                        {tableHeader.map((value, index) => {
                            return (
                                <th
                                    key={index}
                                    style={{
                                        whiteSpace: "nowrap",
                                        textAlign: value.align,
                                    }}
                                >
                                    {value.labelTable}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {tableBody.map((value, index) => {
                        return (
                            <tr key={index}>
                                {configTable.checkboxes ? (
                                    <td key={index} className="m-0 p-0">
                                        <Form.Check
                                            type="checkbox"
                                            className="ms-1 me-2"
                                            onChange={() => {
                                                onClickCheckboxRow(value);
                                            }}
                                            checked={value["checked"]}
                                        />
                                    </td>
                                ) : (
                                    <></>
                                )}

                                {tableHeader.map((v, i) => {
                                    if (entityFields[i] !== undefined) {
                                        let typeField =
                                            entityFields[i].typeField;
                                        let align = entityFields[i].align;
                                        let fieldValue = value[v.name];

                                        let className = `d-flex align-items-center justify-content-${align} m-0 p-0 mx-2`;
                                        if (align === "left")
                                            className = `d-flex align-items-center justify-content-start m-0 p-0 mx-2`;
                                        if (align === "right")
                                            className = `d-flex align-items-center justify-content-end m-0 p-0 mx-2`;

                                        if (v.name === "status") {
                                            return (
                                                <td
                                                    key={i}
                                                    className="m-0 p-0"
                                                    onClick={() => {
                                                        if (
                                                            configTable.openModal
                                                        ) {
                                                            onClickOpenModal(
                                                                value
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className={className}
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                            fontWeight: 650,
                                                        }}
                                                    >
                                                        {fieldValue}
                                                        {fieldValue ===
                                                        "Aguardando Concessionária inserir NF (XML)" ? (
                                                            <MdOutlineNotificationImportant
                                                                color="red"
                                                                size="1.4em"
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </td>
                                            );
                                        } else if (
                                            fieldValue !== null ||
                                            fieldValue !== undefined
                                        ) {
                                            if (typeField === "date") {
                                                if(fieldValue !== null){
                                                    fieldValue = ParseDateToBrV2(fieldValue);
                                                    return (
                                                        <td
                                                            key={i}
                                                            className="m-0 p-0"
                                                            onClick={() => {
                                                                if (
                                                                    configTable.openModal
                                                                ) {
                                                                    onClickOpenModal(
                                                                        value
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    className
                                                                }
                                                                style={{
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    fontWeight: 250,
                                                                }}
                                                            >
                                                                {fieldValue}
                                                            </div>
                                                        </td>
                                                    );
                                                } else {
                                                    return (
                                                        <td
                                                            key={i}
                                                            className="m-0 p-0"
                                                            onClick={() => {
                                                                if (
                                                                    configTable.openModal
                                                                ) {
                                                                    onClickOpenModal(
                                                                        value
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    className
                                                                }
                                                                style={{
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    fontWeight: 250,
                                                                }}
                                                            >
                                                            </div>
                                                        </td>
                                                    );
                                                }
                                            } else if (typeField === "text") {
                                                return (
                                                    <td
                                                        key={i}
                                                        className="m-0 p-0"
                                                        onClick={() => {
                                                            if (
                                                                configTable.openModal
                                                            ) {
                                                                onClickOpenModal(
                                                                    value
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                className
                                                            }
                                                            style={{
                                                                whiteSpace:
                                                                    "nowrap",
                                                                fontWeight: 250,
                                                            }}
                                                        >
                                                            {fieldValue}
                                                        </div>
                                                    </td>
                                                );
                                            } else if (
                                                typeField === "monetary"
                                            ) {
                                                if (fieldValue !== null) {
                                                    return (
                                                        <td
                                                            key={i}
                                                            className="m-0 p-0"
                                                            onClick={() => {
                                                                if (
                                                                    configTable.openModal
                                                                ) {
                                                                    onClickOpenModal(
                                                                        value
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    className
                                                                }
                                                                style={{
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    fontWeight: 250,
                                                                }}
                                                            >
                                                                {fieldValue.toLocaleString(
                                                                    "pt-br",
                                                                    {
                                                                        style: "currency",
                                                                        currency:
                                                                            "BRL",
                                                                    }
                                                                )}
                                                            </div>
                                                        </td>
                                                    );
                                                }
                                            } else if (typeField === "radio") {
                                                return (
                                                    <td
                                                        key={i}
                                                        className="m-0 p-0"
                                                        onClick={() => {
                                                            if (
                                                                configTable.openModal
                                                            ) {
                                                                onClickOpenModal(
                                                                    value
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                className
                                                            }
                                                            style={{
                                                                whiteSpace:
                                                                    "nowrap",
                                                                fontWeight: 250,
                                                            }}
                                                        >
                                                            {fieldValue}
                                                        </div>
                                                    </td>
                                                );
                                            } else if (typeField === "input") {
                                                return (
                                                    <td
                                                        key={i}
                                                        className="m-0 p-0"
                                                        onClick={() => {
                                                            if (
                                                                configTable.openModal
                                                            ) {
                                                                onClickOpenModal(
                                                                    value
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center justify-content-center m-0 p-0">
                                                            <div>
                                                                <Form.Control
                                                                    className="m-0 p-0"
                                                                    type="text"
                                                                    value={
                                                                        fieldValue
                                                                    }
                                                                    size="sm"
                                                                    style={{
                                                                        width: 50,
                                                                    }}
                                                                    onBlur={(
                                                                        event
                                                                    ) =>
                                                                        saveRow(
                                                                            {
                                                                                item: value,
                                                                                value: event
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        changeItem(
                                                                            {
                                                                                item: value,
                                                                                value: event
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            } else if (
                                                typeField === "inputNumber"
                                            ) {
                                                return (
                                                    <td
                                                        key={i}
                                                        className="m-0 p-0"
                                                        onClick={() => {
                                                            if (
                                                                configTable.openModal
                                                            ) {
                                                                onClickOpenModal(
                                                                    value
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center justify-content-center m-0 p-0">
                                                            <div>
                                                                <Form.Control
                                                                    className="m-0 p-0"
                                                                    type="number"
                                                                    min="0"
                                                                    value={
                                                                        fieldValue
                                                                    }
                                                                    size="sm"
                                                                    style={{
                                                                        width: 50,
                                                                    }}
                                                                    onBlur={(
                                                                        event
                                                                    ) =>
                                                                        saveRow(
                                                                            {
                                                                                item: value,
                                                                                value: event
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        changeItem(
                                                                            {
                                                                                item: value,
                                                                                value: event
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        )
                                                                    }
                                                                    onKeyDown={(
                                                                        evt
                                                                    ) =>
                                                                        (evt.key ===
                                                                            "e" ||
                                                                            evt.key ===
                                                                                "-" ||
                                                                            evt.key ===
                                                                                "." ||
                                                                            evt.key ===
                                                                                "," ||
                                                                            evt.key ===
                                                                                "'") &&
                                                                        evt.preventDefault()
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            } else if (typeField === "number") {
                                                return (
                                                    <td
                                                        key={i}
                                                        className="m-0 p-0"
                                                        onClick={() => {
                                                            if (
                                                                configTable.openModal
                                                            ) {
                                                                onClickOpenModal(
                                                                    value
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                className
                                                            }
                                                            style={{
                                                                whiteSpace:
                                                                    "nowrap",
                                                                fontWeight: 250,
                                                            }}
                                                        >
                                                            {fieldValue}
                                                        </div>
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td
                                                        key={i}
                                                        className="m-0 p-0"
                                                        onClick={() => {
                                                            if (
                                                                configTable.openModal
                                                            ) {
                                                                onClickOpenModal(
                                                                    value
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center justify-content-center m-0 p-0"
                                                            style={{
                                                                whiteSpace:
                                                                    "nowrap",
                                                                fontWeight: 250,
                                                            }}
                                                        >
                                                            {fieldValue}
                                                        </div>
                                                    </td>
                                                );
                                            }
                                        }
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </TableHeaderFixedStyled>
    );
};

export default CreateTableReport;
