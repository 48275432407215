// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { EntityField } from 'domain/entity/field/crud/field-crud-entity';
import JoditEditor from 'jodit-react';
import { ParseDateToEn } from 'main/helper/format-date-helper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { BsAsterisk } from 'react-icons/bs';
import { RestUseCase } from '../../../data/useCase/rest-usecase';
import { UseAlert } from '../../hook/alert-hook';
import { getLocalStorage } from 'data/cache/localstorage-cache';
import { getEnv } from 'main/helper/window-helper';

type PropsField = {
  entityFields: EntityField[];
  changeField: any;
  inEditing?: boolean;
};

const CreateFieldModalCrud: React.FC<PropsField> = ({
                                                      entityFields,
                                                      changeField,
                                                      inEditing,
                                                    }: PropsField) => {

  /**
   *
   *
   *
   *
   *  alerta */
  const { showAlert } = UseAlert();

  /**
   *
   * EDITOR TEXT
   *
   */
  const editor = useRef(null);

  function obterExtensaoDoArquivo(url: string) {
    // Use a função split para dividir a URL pelo ponto e pegar a última parte
    const partes = url.split('.');
    // A última parte será a extensão do arquivo
    const extensao = partes[partes.length - 1];
    return extensao.toLowerCase(); // Retorna a extensão em minúsculas para consistência
  }

  function gerarHTMLPorExtensao(url: string) {
    const extensao = obterExtensaoDoArquivo(url);

    switch (extensao) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return `<!-- IMAGEM COLOCADA VIA IS --><img src='${url}' alt='Imagem'>`;

      case 'pdf':
        return `<!-- PDF COLOCADA VIA IS --><a href='${url}' target='_blank'>Abrir PDF</a>`;

      case 'xlsx':
      case 'xls':
        return `<!-- PLANILHA COLOCADA VIA IS --><a href='${url}' target='_blank'>Abrir Planilha</a>`;

      case 'mp4':
      case 'avi':
      case 'mov':
        return `<!-- VIDEO COLOCADA VIA IS --><video width='320' height='240' controls><source src='${url}' type='video/mp4'></video>`;

      default:
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado',
          color: 'danger',
          time: 2000,
        });
        return '';
    }
  }

  function handleOnChangeEditorText(event: any, name: string) {

    console.log('handleOnChangeEditorText > name -> ', name);
    console.log('handleOnChangeEditorText > element -> ', event);

    updateEntity(
      entityFields.map((element) => {
        if (element.name === name) {
          element.valueField = event;
        }

        return element;
      }),
    );

    if (name === 'Upload') {
      console.log('handleOnChangeEditorText > IF');

      updateEntity(
        entityFields.map((element) => {
          if (element.name === 'conteudo') {
            console.log('handleOnChangeEditorText > IF > name -> ', name);
            console.log('handleOnChangeEditorText > IF > element -> ', element);
            console.log('handleOnChangeEditorText > IF > event -> ', event);
            console.log('handleOnChangeEditorText > IF > nameContentFile -> ', nameContentFile);
            console.log('handleOnChangeEditorText > IF > gerarHTMLPorExtensao(nameContentFile) -> ', gerarHTMLPorExtensao(event));

            const conteudo = gerarHTMLPorExtensao(event);

            if (conteudo !== undefined) {
              element.valueField += gerarHTMLPorExtensao(event);
            }
          }
          return element;
        }),
      );
    }

    console.log('handleOnChangeEditorText > event -> ', event);
    console.log('handleOnChangeEditorText > name -> ', name);
    console.log('handleOnChangeEditorText > entityFields -> ', entityFields);
  }

  const [entity, updateEntity] = useState(entityFields);

  const handleOnChange = useCallback((event: any) => {
    event.preventDefault();
    const { name, value } = event.target;

    updateEntity(
      entityFields.map((element) => {
        if (element.name === name) element.valueField = value;
        return element;
      }),
    );
  }, []);

  const handleOnClick = useCallback((event: any) => {
    event.preventDefault();
    const { name, value } = event.target;

    console.log('handleOnClick > event.target ->', event.target);
    console.log('handleOnClick > name ->', name);
    console.log('handleOnClick > value ->', value);

    updateEntity(
      entityFields.map((element) => {
        if (element.name === name) {
          element.valueField = value;
        }
        return element;
      }),
    );

  }, []);

  const [contentFile, updateContentFile] = useState<File | undefined>();
  const [nameContentFile, updateNameContentFile] = useState<any>(null);
  const uploadFile = async (file: File) => {

    const timestampAtual: number = new Date().getTime();
    const TOKEN_USER_LOGIN = getEnv('REACT_APP_LOCALSTORAGE_TOKEN_USER_LOGIN')!;
    const ENDPOINT_CRUD = getEnv('REACT_APP_END_POINT_HELP')!;
    const REACT_APP_API_BACKEND = getEnv("REACT_APP_API_BACKEND")

    const tokenLocalStorageLogin: {
      token: string
    } =
      getLocalStorage(TOKEN_USER_LOGIN);

    var myHeaders = new Headers();
    myHeaders.append('accept', '*/*');
    myHeaders.append('Authorization', `Bearer ${tokenLocalStorageLogin.token}`)

    var formdata = new FormData();
    formdata.append('file', file, `${timestampAtual}_${file.name}`);

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    return await fetch(`${REACT_APP_API_BACKEND}${ENDPOINT_CRUD}/upload_file`, requestOptions)
      .then(response => response.text())
      .then(result => {
        updateNameContentFile(result);
        return result;
      })
      .catch(error => {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado',
          color: 'danger',
          time: 2000,
        });
        console.log('showAlert');
        console.log('error', error);
      });
  };

  const handleOnChangeFile = async (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (event.target.files !== null) {
      console.log('handleOnChangeFile > event.target ->', event.target);
      console.log('handleOnChangeFile > name ->', name);
      console.log('handleOnChangeFile > value ->', value);
      console.log('handleOnChangeFile > event.target.files![0] ->', event.target.files![0]);

      const result = await uploadFile(event.target.files![0]);
      console.log('handleOnChangeFile > nameContentFile ->', nameContentFile);
      console.log('handleOnChangeFile > result ->', result);
      console.log('handleOnChangeFile > typeof result ->', typeof result);

      if (result !== undefined) {
        updateContentFile(event.target.files![0]);

        if (name === 'Upload') {
          console.log('handleOnChangeFile > IF');
          handleOnChangeEditorText(result, name);
        }

        updateEntity(
          entityFields.map((element) => {
            if (element.name === name) {
              element.valueField = contentFile;
            }
            return element;
          }),
        );

      } else {
        showAlert({
          show: true,
          content: 'Ops!!! Algo deu errado',
          color: 'danger',
          time: 2000,
        });
      }
    }
  };
  // useEffect(() => {
  //   if (nameContentFile !== null) {
  //
  //     if (nameContentFile !== undefined) {
  //       updateContentFile(event.target.files![0]);
  //
  //
  //       if (name === 'Upload') {
  //         console.log('handleOnChangeFile > IF');
  //         handleOnChangeEditorText(event.target.files![0], name);
  //       }
  //
  //       updateEntity(
  //         entityFields.map((element) => {
  //           if (element.name === name) {
  //             element.valueField = contentFile;
  //           }
  //           return element;
  //         }),
  //       );
  //     }
  //   } else {
  //     showAlert({
  //       show: true,
  //       content: 'Ops!!! Algo deu errado',
  //       color: 'danger',
  //       time: 2000,
  //     });
  //   }
  //
  // }, [nameContentFile]);

  const handleOnChangeSelect = useCallback((params: any, name: string) => {
    updateEntity(
      entityFields.map((element) => {
        if (element.name === name) element.valueField = params.value;
        return element;
      }),
    );
  }, []);

  useEffect(() => {
    changeField(entity);
    console.log('useEffect > entity', entity);
  }, [entity]);

  const CreateField = () => {
    const listFields: React.ReactElement[] = [];

    entityFields.map((element, index) => {
      let disabled = false;

      if (inEditing) {
        disabled = element.labelFieldDisable === true ? true : false;
      }

      if (element.hideFieldOnCreate && !inEditing) {
        listFields.push(
          <Form.Control
            type='hidden'
            key={index}
            value={element.valueField}
          />,
        );
      } else {
        if (element.hideFieldOnEdit && inEditing) {
          listFields.push(
            <Form.Control
              type='hidden'
              key={index}
              value={element.valueField}
            />,
          );
        } else {
          if (element.typeField === 'hidden')
            listFields.push(
              <Form.Control
                type='hidden'
                key={index}
                value={element.valueField}
              />,
            );

          if (
            element.typeField === 'text' ||
            element.typeField === 'date'
          ) {
            let value = element.valueField;

            listFields.push(
              <Col md={element.sizeField} key={index}>
                <Form.Label className='m-1'>
                  {element.labelField}
                  {element.required ? <BsAsterisk /> : ``}
                </Form.Label>
                <Col>
                  <Form.Control
                    name={element.name}
                    type={element.typeField}
                    onBlur={(event) => {
                      handleOnChange(event);
                    }}
                    defaultValue={value}
                    disabled={disabled}
                  />
                </Col>
              </Col>,
            );
          }

          if (element.typeField === 'number') {
            let value = element.valueField;

            listFields.push(
              <Col md={element.sizeField} key={index}>
                <Form.Label className='m-1'>
                  {element.labelField}
                  {element.required ? <BsAsterisk /> : ``}
                </Form.Label>
                <Col>
                  <Form.Control
                    name={element.name}
                    type={element.typeField}
                    onBlur={(event) => {
                      if (event.target.value) {
                        if (
                          Number(event.target.value) >
                          9999999.99
                        ) {
                          event.target.value =
                            '9999999.99';
                          handleOnChange(event);
                        } else {
                          handleOnChange(event);
                        }
                      }
                    }}
                    defaultValue={value}
                    disabled={disabled}
                    maxLength={7}
                  />
                </Col>
              </Col>,
            );
          }

          if (element.typeField === 'text-area') {
            let value = element.valueField;

            listFields.push(
              <Col md={element.sizeField} key={index}>
                <Form.Label className='m-1'>
                  {element.labelField}
                </Form.Label>
                <Col>
                  <Form.Control
                    name={element.name}
                    as='textarea'
                    type={element.typeField}
                    onBlur={handleOnChange}
                    defaultValue={value}
                  />
                </Col>
              </Col>,
            );
          }

          if (element.typeField === 'html') {
            let value = element.valueField;

            listFields.push(
              <Col md={element.sizeField} key={index}>
                <Form.Label className='m-1'>
                  {element.labelField}
                </Form.Label>
                <Col>
                  <JoditEditor
                    ref={editor}
                    value={value}
                    onBlur={(event) =>
                      handleOnChangeEditorText(
                        event,
                        element.name,
                      )
                    }
                    config={{
                      processPasteFromWord: true,
                      processPasteHTML: false,
                      memorizeChoiceWhenPasteFragment: true,

                      // readonly: false,
                      // autofocus: true,
                      // tabIndex: 1,
                      //
                      // askBeforePasteHTML: false,
                      // askBeforePasteFromWord: false,
                      // defaultActionOnPaste: 'insert_clear_html',

                      // placeholder: 'Write something awesome ...',
                      // beautyHTML: true,
                      // toolbarButtonSize: 'large',
                      buttons: [
                        'source',
                        '|', 'bold', 'italic',
                        '|', 'ul', 'ol',
                        '|', 'font', 'fontsize', 'brush', 'paragraph',
                        '|', 'video', 'table', 'link',
                        '|', 'left', 'center', 'right', 'justify',
                        '|', 'undo', 'redo',
                        '|', 'hr', 'eraser', 'fullsize',
                      ],
                      // extraButtons: ['uploadImage', 'codeBlock'],

                    }}
                  />
                </Col>
              </Col>,
            );
          }

          if (element.typeField === 'radio')
            listFields.push(
              <Col md={element.sizeField} key={index}>
                <Form.Label className='m-1'>
                  {element.labelField}
                </Form.Label>
                {element.listValueField!.map(
                  (el: any, i: number) => {
                    return (
                      <Form.Check
                        key={i}
                        inline
                        label={el.label}
                        name={element.name}
                        type='radio'
                        onChange={handleOnChange}
                        checked={
                          element.valueField ===
                          el.value
                            ? true
                            : false
                        }
                        value={el.value}
                      />
                    );
                  },
                )}
              </Col>,
            );

          if (element.typeField === 'select') {
            const options = element.listValueField;
            let valueField = element.valueField;

            let optionSelected = options?.filter((value) => {
              if (value.value === valueField) return value;
            })[0];

            listFields.push(
              <Col md={element.sizeField} key={index}>
                <Form.Label className='m-1'>
                  {element.labelField}
                </Form.Label>
                <Select
                  options={options}
                  defaultValue={optionSelected}
                  onChange={(val) =>
                    handleOnChangeSelect(val!, element.name)
                  }
                  isDisabled={disabled}
                />
              </Col>,
            );
          }

          if (element.typeField === 'button') {
            // console.log(element);
            listFields.push(
              <Col md={element.sizeField} key={index}>
                <Form.Label className='m-1'>
                  &ensp;
                </Form.Label>
                <Col>
                  <Button name={element.name} onClick={handleOnClick}>{element.name}</Button>
                </Col>
              </Col>,
            );
          }

          if (element.typeField === 'file') {
            let value = element.valueField;

            // console.log(element);
            listFields.push(
              <Col md={element.sizeField} key={index}>
                <Form.Label className='m-1'>
                  &ensp;
                </Form.Label>
                <Col>
                  <Form.Control
                    name={element.name}
                    type={element.typeField}
                    onChange={handleOnChangeFile}
                    // defaultValue={value}
                  />
                </Col>
              </Col>,
            );
          }

        }
      }

      return <></>;
    });

    return <Row>{listFields}</Row>;
  };

  return <CreateField />;
};

export default CreateFieldModalCrud;
