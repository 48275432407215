import { useEffect, useState } from "react";
import { UseAuth } from "presentation/hook/auth-hook";
import { RestUseCase } from "data/useCase/rest-usecase";
import { handleRequest } from "main/common/request-common";
import FilterScrapPage from "presentation/pages/scrap/filter-scrap-page";
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page";
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface";
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter";
import { ScrapFilterPlanningAdapter } from "main/adapter/planning/scrap-filter-planning-adapter";
import { FiltersDefault } from "domain/interfaces/filter-interface";
import { getEnv } from "main/helper/window-helper";
import { FilterScrapEntity } from "domain/entity/filter-scrap-entity";

export const FilterScrapFactory: React.FC = () => {
    const ENDPOINT_PLANNING = getEnv("REACT_APP_END_POINT_ANALYTICAL_PROVIDER");
    const ENDPOINT_LOCATIONS = getEnv('REACT_APP_END_POINT_LOCATIONS');

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth();
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Scrap"
        )[0].navItem.filter((item) => item.title === "Cadastro Filtro Scrap")[0]
            .role;

        if (loggedUserData?.role) {
            loggedUserData.role.forEach((value) => {
                if (roleScreen.includes(value)) updateShowScreen(true);
            });
        }
    }, [loggedUserData]);

    const [locationList, updateLocationList] = useState<SelectOptionInterface[]>([]);
    const [filial, updateFilial] = useState<SelectOptionInterface[]>();
    const [discountGroup, updateDiscountGroup] = useState<SelectOptionInterface[]>();
    const [base, updateBase] = useState<SelectOptionInterface[]>();
    const [rangeProfitability, updateRangeProfitability] = useState<SelectOptionInterface[]>();
    const ENDPOINT_FILIAL = getEnv("REACT_APP_END_POINT_FILIAL")!;
    const END_POINT_ORIGINAL_ITEMS_TRACKS = getEnv("REACT_APP_END_POINT_ORIGINAL_ITEMS_TRACKS")!;
    const END_POINT_ITEM_TRACKS = getEnv("REACT_APP_END_POINT_ITEM_TRACKS")!;
    const END_POINT_ITEM_BASE = getEnv("REACT_APP_END_POINT_ITEM_BASE")!;

    const [providerPlanning, updateProviderPlanning] = useState<SelectOptionInterface[]>();
    const END_POINT_ITEM_PROVIDER = `${process.env.REACT_APP_END_POINT_ORIGINAL_ITEMS!}/status/active`;
    const handleProvider = async (provider: number) => {
        let idProvider = provider === 1 ? 81 : provider
        await new RestUseCase(`${END_POINT_ITEM_PROVIDER}?idFornecedor=${idProvider}`)
            .Get()
            .then((response) => {
                updateProviderPlanning(
                    response.body.map((value: any) => {
                        return { value: value.id, label: value.label };
                    })
                );
            });
    };

    const handleFilial = async () => {
        await new RestUseCase(ENDPOINT_FILIAL).Get().then((response) => {
            updateFilial(
                response.body.map((value: any) => {
                    return { value: value.id, label: value.nome };
                })
            );
        });
    };

    const handleDiscountGroup = async () => {
        await new RestUseCase(END_POINT_ORIGINAL_ITEMS_TRACKS)
            .Get()
            .then((response) => {
                updateDiscountGroup(
                    response.body.map((value: any) => {
                        return { value: value, label: value };
                    })
                );
            });
    };
    const handleRangeProfitability = async () => {
        await new RestUseCase(END_POINT_ITEM_TRACKS).Get().then((response) => {
            updateRangeProfitability(
                response.body.map((value: any) => {
                    return { value: value, label: value };
                })
            );
        });
    };
    const handleBase = async () => {
        await new RestUseCase(END_POINT_ITEM_BASE).Get().then((response) => {
            updateBase(
                response.body.bases.map((value: any) => {
                    return { value: value.codigo, label: `${value.codigo} - ${value.desc}` };
                })
            );
        });
    };

    async function listLocations(): Promise<any> {
        await new RestUseCase(ENDPOINT_LOCATIONS).Get().then((response) => {
            if (response.statusCode !== 500 && response.statusCode !== undefined) {
                updateLocationList(
                    response.body.locacoes.map((value: any) => {
                        return { value: value.codigo, label: value.codigo };
                    }),
                );
            }
        });
    }

    useEffect(() => {
        handleFilial();
        handleDiscountGroup();
        handleRangeProfitability();
        handleBase();
        listLocations();
    }, []);

    const [scrapFilterPlanningAdapter, updateScrapFilterPlanningAdapter] =
        useState<FiltersDefault[]>(ScrapFilterPlanningAdapter);

    useEffect(() => {
        let ScrapFilter: FiltersDefault[] = scrapFilterPlanningAdapter.map(
            (value) => {
                if (value.label === "Filial") {
                    value.field[1].optionsInput = filial;
                }

                if (value.label === "Grupo de desconto") {
                    value.field[1].optionsInput = discountGroup;
                }

                if (value.label === "Base") {
                    value.field[1].optionsInput = base;
                }

                if (value.label === "Lucratividade do fornecedor") {
                    value.field[1].optionsInput = rangeProfitability;
                }

                if (value.label === "Status do item do Fornecedor") {
                    value.field[1].optionsInput = providerPlanning;
                }
                if (value.label === 'Locações') value.field[1].optionsInput = locationList;
                return value;
            }
        );

        ScrapFilter.sort(function (a, b) {
            return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
        });

        updateScrapFilterPlanningAdapter(ScrapFilter);
    }, [filial, discountGroup, base, rangeProfitability, providerPlanning, locationList]);

    async function listAll(params: { idProvider: number }): Promise<any> {
        let query = `?fornecedor=${params.idProvider}`;

        const response = await new RestUseCase(
            ENDPOINT_PLANNING! + `/all${query}`
        ).Get();

        await handleProvider(params.idProvider);

        return await handleRequest(response);
    }

    return (
        <>
            {showScreen ? (
                <FilterScrapPage
                    listAllPlanning={(content) => listAll(content)}
                    optionsFiltersDefault={scrapFilterPlanningAdapter}
                    createPlanning={async function (params: { idProvider: number; filterScrapEntity: FilterScrapEntity; }): Promise<any> {
                        let query = `?fornecedor=${params.idProvider}`;
                        const response = await new RestUseCase(
                            ENDPOINT_PLANNING! + `${query}`
                        ).Put({ data: params.filterScrapEntity });
                        return handleRequest(response);
                    }}
                    deletePlanning={async function (params: { idPlanning: number }): Promise<any> {
                        const response = await new RestUseCase(
                            ENDPOINT_PLANNING! + `/${params.idPlanning}`
                        ).Delete();
                        return handleRequest(response);
                    }}
                />
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
