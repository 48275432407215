import { createContext, ReactNode, useState } from "react"

export type ProviderPropsType = { children: ReactNode }
export const AlertModalContext = createContext({} as AlertModalContextType)
export type AlertModalMessageType = { show: boolean, content: string, color: string, time?: number }
export type AlertModalContextType = { messageAlertModal: AlertModalMessageType | undefined, showAlertModal: (data: AlertModalMessageType) => void }

export function AlertModalProvider(props: ProviderPropsType) {

    const [messageAlertModal, updateMessageAlertModal] = useState<AlertModalMessageType>()

    function showAlertModal(data: AlertModalMessageType) {
        updateMessageAlertModal(data)
    }

    return (
        <AlertModalContext.Provider value={{ messageAlertModal, showAlertModal }}>
            {props.children}
        </AlertModalContext.Provider>
    )

}