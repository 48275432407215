/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { UseAuth } from "presentation/hook/auth-hook"
import { RestUseCase } from "data/useCase/rest-usecase"
import { handleRequest } from "main/common/request-common"
import { getLocalStorage } from "data/cache/localstorage-cache"
import ManagerialPage from "presentation/pages/analysis/managerial-page"
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page"
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface"
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter"
import { ManagerialFilterPlanningAdapter } from "main/adapter/planning/manegerial-filter-planning-adapter"
import { ParseDateToBrWithHours } from "main/helper/format-date-helper"
import { getEnv } from "main/helper/window-helper"
import { ColumsTableManagerialAdapter } from "main/adapter/columsTable/colums-table-managerial-adapter"
import { checkColumns } from "main/helper/table-columns-helper"

export const ManagerialFactory: React.FC = () => {

    /**
     * 
     * 
     * 
     * 
     *  endpoint */
    const ENDPOINT_TABLE = getEnv("REACT_APP_END_POINT_MENAGERIAL")
    const ENDPOINT_PLANNING = getEnv("REACT_APP_END_POINT_MENAGERIAL") + "/filtros"
    const ENDPOINT_FILIAL = getEnv("REACT_APP_END_POINT_FILIAL")!
    const END_POINT_ORIGINAL_ITEMS_TRACKS = getEnv("REACT_APP_END_POINT_ORIGINAL_ITEMS_TRACKS")!
    const END_POINT_ITEM_TRACKS = getEnv("REACT_APP_END_POINT_ITEM_TRACKS")!
    const END_POINT_ITEM_BASE = getEnv("REACT_APP_END_POINT_ITEM_BASE")!
    const LOCALSTORAGE_PLANNING = getEnv("REACT_APP_LOCALSTORAGE_PLANNING_MENAGERIAL")!
    const ENDPOINT_MANUFACTURER = getEnv("REACT_APP_END_POINT_MANUFACTURER")
    const LOCALSTORAGE_COLUMS = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_MANAGERIAL')!;
    
    /**
     * 
     * 
     * 
     * 
     *  verificar as permissões de acesso */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Análise")[0].navItem.filter(item => item.title === "Gerencial")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }
    }, [loggedUserData])

    /**
     * 
     * 
     * 
     * 
     *  selects */
    const [filial, updateFilial] = useState<SelectOptionInterface[]>()
    const [discountGroup, updateDiscountGroup] = useState<SelectOptionInterface[]>()
    const [base, updateBase] = useState<SelectOptionInterface[]>()
    const [rangeProfitability, updateRangeProfitability] = useState<SelectOptionInterface[]>()
    const [manufacturer, updateManufacturer] = useState<SelectOptionInterface[]>();

    /**
     * 
     * 
     * 
     * 
     *  chamada ao backend */
    const END_POINT_LAST_CHARGE = getEnv("REACT_APP_END_POINT_LAST_CHARGE")!    
    const [lastCharge, updateLastCharge] = useState<string>()
    const handleLastCharge = async () => {
        await new RestUseCase(END_POINT_LAST_CHARGE)
            .Get()
            .then(response => {
                updateLastCharge(ParseDateToBrWithHours(response.body))
            })
    }
    const handleFilial = async () => {
        await new RestUseCase(`${ENDPOINT_FILIAL}?modulos=COMPRAS`)
            .Get()
            .then(response => {
                updateFilial(response.body.map((value: any) => { return { value: value.id, label: value.nome } }))
            })
    }
    const handleDiscountGroup = async () => {
        await new RestUseCase(END_POINT_ORIGINAL_ITEMS_TRACKS)
            .Get()
            .then(response => {
                updateDiscountGroup(response.body.map((value: any) => { return { value: value, label: value } }))
            })
    }
    const handleRangeProfitability = async () => {
        await new RestUseCase(END_POINT_ITEM_TRACKS)
            .Get()
            .then(response => {
                updateRangeProfitability(response.body.map((value: any) => { return { value: value, label: value } }))
            })
    }
    const handleBase = async () => {
        await new RestUseCase(END_POINT_ITEM_BASE)
            .Get()
            .then(response => {
                updateBase(response.body.bases.map((value: any) => {
                    return { value: value.codigo, label: `${value.codigo} - ${ value.desc}` };
                 }))
            })
    }
    const [baseDesc, updateBaseDesc] = useState<SelectOptionInterface[]>();
    const END_POINT_ITEM_BASE_DESC = getEnv('REACT_APP_END_POINT_ITEM_BASE_DESC')!;
    const handleBaseDesc = async () => {
      await new RestUseCase(END_POINT_ITEM_BASE_DESC).Get().then((response) => {
        updateBaseDesc(
          response.body.descricoes.map((value: any) => {
            console.log(value);
            return {
              value: value,
              label: value,
            };
          }),
        );
      });
    };
    const handleManufacturer = async () => {
        await new RestUseCase(ENDPOINT_MANUFACTURER).Get().then((response) => {
            updateManufacturer(
                response.body.map((value: any) => {
                    return {
                        value: value.codigo,
                        label: `${value.codigo} - ${value.nome}`,
                    };
                })
            );
        });
    };

    /**
     * 
     * 
     * 
     * 
     *  gerenciador de estado */
    useEffect(() => {
        checkColumns(LOCALSTORAGE_COLUMS, ColumsTableManagerialAdapter);
        handleFilial()
        handleDiscountGroup()
        handleRangeProfitability()
        handleBase()
        handleBaseDesc()
        handleManufacturer()
        handleLastCharge()
    }, [])
    useEffect(() => {
        ManagerialFilterPlanningAdapter.map(value => {
            if (value.label === "Filial")
                value.field[1].optionsInput = filial
            if (value.label === "Grupo de desconto")
                value.field[1].optionsInput = discountGroup
            if (value.label === "Base")
                value.field[1].optionsInput = base
            if (value.label === "Lucratividade do fornecedor")
                value.field[1].optionsInput = rangeProfitability
            if (value.label === "Código do Fabricante") value.field[1].optionsInput = manufacturer;
            if (value.label === 'Base Descrição') {
                value.field[1].optionsInput = baseDesc;
            }
        })

        ManagerialFilterPlanningAdapter.sort(function (a, b) {
            return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
        });
    }, [filial, discountGroup, base, rangeProfitability])

    return (
        <>
            {showScreen ?
                <ManagerialPage
                    /** dados / confi para o planejamento */
                    optionsFiltersDefault={ManagerialFilterPlanningAdapter}
                    /** processa e lista */
                    processAndList={async function (params: { grouping: string, modelFilials: any[] }): Promise<any> {
                        var filiaisSelectedParameters = ""
                        params.modelFilials.forEach((el: any) => { filiaisSelectedParameters += `&filiaisSelecionadas=${el.value}` })

                        var localStoragePlanning = getLocalStorage(LOCALSTORAGE_PLANNING)

                        const response = await new RestUseCase(ENDPOINT_TABLE! + `?agrupamento=${params.grouping}${filiaisSelectedParameters}`).Put({
                            headers: { 'Action-Name': "Gerencial/Processar" },
                            data: localStoragePlanning ? localStoragePlanning : undefined
                        })
                        return handleRequest(response)
                    }}
                    /** lista */
                    list={async function (params: { grouping: string }): Promise<any> {
                        var localStoragePlanning = getLocalStorage(LOCALSTORAGE_PLANNING)

                        const response = await new RestUseCase(ENDPOINT_TABLE! + `?agrupamento=${params.grouping}`).Post({
                            data: localStoragePlanning ? localStoragePlanning : undefined
                        })
                        return handleRequest(response)
                    }}
                    /** exporta arquivo */
                    exportFileCSV={async function (params: { grouping: string }): Promise<any> {
                        var localStoragePlanning = getLocalStorage(
                            LOCALSTORAGE_PLANNING
                        );
                        const response = await new RestUseCase(ENDPOINT_TABLE! + `?agrupamento=${params.grouping}`).Post({ headers: { accept: "text/csv" }, responseType: "blob", data: localStoragePlanning ? localStoragePlanning : undefined })
                        return handleRequest(response)
                    }}
                    exportFileExcel={async function (params: { grouping: string }): Promise<any> {
                        var localStoragePlanning = getLocalStorage(
                            LOCALSTORAGE_PLANNING
                        );

                        const response = await new RestUseCase(
                            `${ENDPOINT_TABLE!}/gerar-excel?agrupamento=${params.grouping}`
                        ).Post({
                            responseType: "blob",
                            data: localStoragePlanning
                                ? localStoragePlanning
                                : undefined,
                        });
                        return handleRequest(response);
                    }}
                    /** planejamento */
                    listByIdPlanning={async function (): Promise<any> { }}
                    /** planejamento lista*/
                    listAllPlanning={async function (): Promise<any> {
                        const response = await new RestUseCase(ENDPOINT_PLANNING!).Get()
                        return handleRequest(response)
                    }}
                    /** planejamento cria*/
                    createPlanning={async function (params?: any): Promise<any> {
                        const response = await new RestUseCase(ENDPOINT_PLANNING!).Post({ data: params })
                        return handleRequest(response)
                    }}
                    /** planejamento edita*/
                    editPlanning={async function (params: { model: any, id: number }): Promise<any> {
                        const response = await new RestUseCase(ENDPOINT_PLANNING! + "/" + params.id).Put({ data: params.model })
                        return handleRequest(response)
                    }}
                    /** planejamento remove*/
                    removePlanning={async function (params?: number): Promise<any> {
                        const response = await new RestUseCase(ENDPOINT_PLANNING! + "/" + params).Delete()
                        return handleRequest(response)
                    }}
                    lastCharge={lastCharge!}
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )
}

