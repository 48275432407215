export function parseLinkHeader(header: string): KeyValueMap<string> {
    let parts: string[]
    let links: KeyValueMap<string> = {}

    if (header.length === 0) {
        throw new Error('input must not be of zero length')
    }

    parts = header.split(',')
    parts.forEach(part => {
        let section: string[] = part.split(';')
        let url: string
        let name: string

        if (section.length !== 2) {
            throw new Error('section could not be split on \';\'')
        }
        url = section[0].replace(/<(.*)>/, '$1').trim()
        name = section[1].replace(/rel="(.*)"/, '$1').trim()
        links[name] = url
    })

    return links
}

interface KeyValueMap<T> {
    [key: string]: T
}


export function getParameterUrlByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}