import styled from "styled-components"

export const SCDesctiption = styled.p`
    margin-top: 5px;
    text-align: center;
    font-size: 32px;
    text-align: start;

    font: Open Sans;

    @media (max-width: 800px) {
        font-size: 32;
    }

    @media (max-width: 600px) {
        font-size: 25px;
    }

    @media (max-width: 400px) {
        font-size: 20px;
    }

    @media (max-width: 200px) {
        font-size: 15px;
    }
`