import { CallHttp } from "infra/call/call-http-infra"

export class RestCrud {

    constructor(private readonly endPoint: string) {
        this.endPoint = endPoint
    }

    async Get() {
        return await CallHttp({
            url: this.endPoint,
            method: "GET"
        }).then(response => response)
    }

    async GetById(id: number) {
        return await CallHttp({
            url: this.endPoint + "/" + id,
            method: "GET"
        }).then(response => response)
    }

    async Post(model: any) {
        return await CallHttp({
            url: this.endPoint,
            method: "POST",
            body: model
        }).then(response => response)
    }

    async Put(model: any, id?: any) {

        const url = id ? this.endPoint + "/" + id : this.endPoint

        return await CallHttp({
            url: url,
            method: "PUT",
            body: model
        }).then(response => response)
    }

    async Patch(model: any, id: number) {
        return await CallHttp({
            url: this.endPoint + "/" + id,
            method: "PATCH",
            body: model
        }).then(response => response)
    }

    async Delete(id: number) {
        return await CallHttp({
            url: this.endPoint + "/" + id,
            method: "DELETE"
        }).then(response => {
            if (response.statusCode === 204 || response.statusCode === 200)
                response.body = true
            else
                response.body = false

            return response
        })
    }
}
