import { getLocalStorage, updateLocalStorege } from "data/cache/localstorage-cache";
import { TableDescriptions } from "domain/interfaces/table-interface";

export function checkColumns(localStorageColumnsName: string, ColunmsTableAdapter: TableDescriptions[]){
  let localStorageColumns: TableDescriptions[] = getLocalStorage(localStorageColumnsName);
  let columnsAdapter: TableDescriptions[] = ColunmsTableAdapter
  let newColumns: TableDescriptions[] = []

  if (localStorageColumns !== null) {
    newColumns = columnsAdapter.map(columnAdapter => {
      if(localStorageColumns.some(columnLocalstorage => {
        return (columnLocalstorage.dataField === columnAdapter.dataField || 
                columnLocalstorage.text === columnAdapter.text) && columnLocalstorage.hidden === false;
       })){
        columnAdapter.hidden = false
        return columnAdapter
      } else {
        columnAdapter.hidden = true
        return columnAdapter
      }
    });

    updateLocalStorege(
      localStorageColumnsName,
      newColumns.sort((a, b) => (a.order > b.order ? 1 : -1))
    );
  }
}