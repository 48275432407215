import { ReactNode } from "react"
import { Modal} from "react-bootstrap"

type Props = {
    isOpen: boolean,
    isEditing?: boolean,
    title: string,
    size?: "xl" | "sm" | "lg",
    children: {
        header?: ReactNode
        body: ReactNode
        footer?: ReactNode
    }
    open: any
    close: any
}

export function ModalComponent({ title, children, close, isOpen, size }: Props) {

    return (
        <>
            <Modal show={isOpen} onHide={() => close()} size={size ? size : "lg"} fullscreen={"md-down"}>
                <Modal.Header closeButton>
                    <Modal.Title className="ms-3 lead" style={{ fontSize: "30px" }}>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children.header}
                    {children.body}
                </Modal.Body>
                <Modal.Footer>
                    {children.footer}
                </Modal.Footer>
            </Modal>
        </>
    )

}
