/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select'
import { useState } from 'react'
import CrudPage from 'presentation/pages/crud-page'
import { RestUseCase } from 'data/useCase/rest-usecase'
import { Button, Col, Form, Row } from "react-bootstrap"
import { EntityField } from 'domain/entity/field/crud/field-crud-entity'
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface'
import ErrorNotAuthorizedPage from 'presentation/pages/error/error-not-authorized-page'
import { getEnv } from 'main/helper/window-helper'

export const QueryItemNetworkFactory: React.FC = () => {

    /** ENDPOINT */
    const ENDPOINT_LIST = getEnv("REACT_APP_END_POINT_NETWORK_ITEM")!

    /** TITULO DA PAGINA */
    const titlePage = "Pesquisar Item na Rede"

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([])
    const [tableBody, updateTableBody] = useState([])

    /** FILTRO DA TABELA */
    const [listUfs, ] = useState<SelectOptionInterface[]>([
        { label: "AC", value: "AC" },
        { label: "AL", value: "AL" },
        { label: "AM", value: "AM" },
        { label: "AP", value: "AP" },
        { label: "BA", value: "BA" },
        { label: "CE", value: "CE" },
        { label: "DF", value: "DF" },
        { label: "ES", value: "ES" },
        { label: "GO", value: "GO" },
        { label: "MA", value: "MA" },
        { label: "MG", value: "MG" },
        { label: "MS", value: "MS" },
        { label: "MT", value: "MT" },
        { label: "PA", value: "PA" },
        { label: "PB", value: "PB" },
        { label: "PE", value: "PE" },
        { label: "PI", value: "PI" },
        { label: "PR", value: "PR" },
        { label: "RJ", value: "RJ" },
        { label: "RN", value: "RN" },
        { label: "RO", value: "RO" },
        { label: "RR", value: "RR" },
        { label: "RS", value: "RS" },
        { label: "SC", value: "SC" },
        { label: "SE", value: "SE" },
        { label: "SP", value: "SP" },
        { label: "TO", value: "TO" }
    ])
    const [ufs, updateUfs] = useState<any[]>()
    const [city, updateCity] = useState<string>()
    const [code, updateCode] = useState<string>()
    function changeUfs(companyOptions: any[]) {
        updateUfs(companyOptions)
    }
    function changeCity(date: string) {
        updateCity(date)
    }
    function changeCode(date: string) {
        updateCode(date)
    }

    /** CHAMADA ENDPOINT */
    const getAll = async () => {
        let querie = `?`
        if (code)
            code!.split(';').forEach(item => querie += `cods=${item}&`)
        if (ufs)
            ufs.forEach(item => querie += `ufs=${item}&`)
        if (city)
            city.split(';').forEach(item => querie += `cid=${item}&`)

        await new RestUseCase(`${ENDPOINT_LIST}${querie}`)
            .Get()
            .then(response => {
                updateTableHeader(entityFields.map((value) => { return { name: value.name, labelTable: value.labelTable, align: value.align, columnVisible: value.tableField.columnVisible } }))
                updateTableBody(response.body.map((value: any) => {
                    return value
                }))
            })
    }

    /** CAMPOS PARA MODAL E TABELA */
    const entityFields: EntityField[] = [
        { name: "codigo", labelField: "Codigo:", labelTable: "Codigo", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Codigo", columnVisible: true } },
        { name: "nome", labelField: "Nome:", labelTable: "Nome", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Nome", columnVisible: true } },
        { name: "fabricanteNome", labelField: "Fabricante Nome:", labelTable: "Fabricante Nome", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Fabricante Nome", columnVisible: true } },
        { name: "empresaNome", labelField: "Empresa Nome:", labelTable: "Empresa Nome", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Empresa Nome", columnVisible: true } },
        { name: "filialNome", labelField: "Filial Nome:", labelTable: "Filial Nome", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Filial Nome", columnVisible: true } },
        { name: "cidade", labelField: "Cidade:", labelTable: "Cidade", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Cidade", columnVisible: true } },
        { name: "uf", labelField: "UF:", labelTable: "UF", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "UF", columnVisible: true } },
        { name: "responsavelEmail", labelField: "Responsável Email:", labelTable: "Responsável Email", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Responsável Email", columnVisible: true } },
        { name: "responsavelNome", labelField: "Responsável Nome:", labelTable: "Responsável Nome", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Responsável Nome", columnVisible: true } },
        { name: "responsavelFone", labelField: "Responsável Fone:", labelTable: "Responsável Fone", typeField: "text", valueField: null, sizeField: 6, required: false, align: "left", tableField: { column: "Responsável Fone", columnVisible: true } },
    ]

    const [entity, updateEntity] = useState(entityFields)

    /** VERIFICA PERMISSAO DE ACESSO */
    const [
        showScreen, 
    ] = useState<boolean>(true)

    return (
        <>
            {showScreen ?
                <CrudPage
                    entityFields={entity}
                    updateEntity={updateEntity}
                    titlePage={titlePage}
                    getAll={() => { }}
                    tableHeader={tableHeader}
                    tableBody={tableBody}
                    children={
                        {
                            filterTable:
                                <>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Label>Codigo(s):</Form.Label>
                                            <Form.Control type="text" onChange={event => { changeCode(event.target.value) }} />
                                        </Col>

                                        <Col md={3}>
                                            <Form.Label>Ufs:</Form.Label>
                                            <Col>
                                                <Select
                                                    options={listUfs}
                                                    onChange={(e: any) => {
                                                        let data = e.map((elements: any) => elements.value)
                                                        changeUfs(data)
                                                    }}
                                                    isMulti />
                                            </Col>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Cidade(s):</Form.Label>
                                            <Col>
                                                <Form.Control type="text" onChange={event => { changeCity(event.target.value) }} />
                                            </Col>
                                        </Col>
                                    </Row>
                                    <div className='d-flex align-items-center justify-content-end mt-4'>
                                        <Button variant="primary" size="sm" className='mb-4' onClick={() => getAll()}>Pesquisar</Button>
                                    </div>
                                </>
                        }
                    }
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )

}
