

import styled from "styled-components"

export const TableHeaderStyled = styled.div`

    overflow: auto; 
    /* height: 500px;  */

    font: Open Sans;
    font-size: 12px;

    cursor: pointer;

    thead th {
        position: sticky; 
        top: 0; z-index: 0; 
    }

    table  { 
        border-collapse: collapse; 
        width: 100%; 
    }
    th, td { 
        /* padding: 8px 16px;  */
    }
    th     { 
        background:#F0F0F0; 
    }

`