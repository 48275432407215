/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useState } from 'react';
import { Button, Card, Col, Modal, Pagination, Row, Table } from 'react-bootstrap';
import { Headers } from 'presentation/components/header/headers-component';
import HeaderCrud from 'presentation/components/header/header-crud-component';
import { Footer } from 'presentation/components/footer/footer-component';
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled';
import { MdListAlt } from 'react-icons/md';
import { BsLightningChargeFill } from 'react-icons/bs';
import { TableHeaderStyled } from 'presentation/style/table/table-header';
import { RestUseCase } from 'data/useCase/rest-usecase';
import { getEnv } from 'main/helper/window-helper';
import { ParseDateToBr } from '../../../main/helper/format-date-helper';
import { TitleGroupComponent } from 'presentation/components/title/title-group-component';
import { Loading } from 'presentation/components/loading/loading-component';


type PropsCrud = {
  loading: boolean;
  titlePage: string
  tableHeader: any[]
  tableBody: any[]
  children?: {
    filterTable?: ReactNode
  }
  onClickFirstPagination?: any
  onClickLastPagination?: any
  onClickNextPagination?: any
  onClickPrevPagination?: any
  itemList: any
  onClickItem: any
}

const QueryScrapPage: React.FC<PropsCrud> = ({
                                               loading,
                                               titlePage,
                                               children,
                                               tableHeader,
                                               tableBody,
                                               onClickFirstPagination,
                                               onClickLastPagination,
                                               onClickNextPagination,
                                               onClickPrevPagination,
                                               itemList,
                                               onClickItem,
                                             }: PropsCrud) => {

  const ENDPOINT_LIST_SCRAP = getEnv('REACT_APP_END_POINT_SCRAP_SEARCH')!;
  const ENDPOINT_LIST_ACTION_SCRAP = getEnv('REACT_APP_END_POINT_SCRAP')!;


  const titleModalAction = 'acao';
  const titleModalList = 'lista';

  /** LOADING MODAL*/
  const [loadingModal, updateLoadingModal] = useState<boolean>(false);

  const [showModalContent, updateShowModalContent] = useState(false);
  const [contentModal, updateContentModal] = useState<ReactNode>();

  const closeContent = () => {
    updateShowModalContent(false);
    updateContentModal(undefined);
  };
  const showContent = () => updateShowModalContent(true);

  const getContentModal = async (type: string, idScrap: number) => {
    updateLoadingModal(true)
    if (type === titleModalList) {
      await new RestUseCase(`${ENDPOINT_LIST_SCRAP}/${idScrap}`)
        .Post()
        .then(response => {
          if (response.statusCode >= 400) {
            updateContentModal(<>Não foram encotrados itens para esse scrap</>)
          } else {
            updateContentModal(
              <>
                <div className='my-2'>
                  <TableHeaderStyled>
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center' }}>ID</th>
                          <th style={{ textAlign: 'left' }}>Item</th>
                          <th style={{ textAlign: 'left' }}>Preço Item Original</th>
                          <th style={{ textAlign: 'left' }}>Preço</th>
                          <th style={{ textAlign: 'left' }}>Quantidade</th>
                          <th style={{ textAlign: 'left' }}>Quantidade Auditor</th>
                          <th style={{ textAlign: 'left' }}>Código do Item</th>
                          <th style={{ textAlign: 'right' }}>Descrição do Item</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.body.map((value: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td align='center'>{value.id}</td>
                              <td align='left'>{value.item}</td>
                              <td align='left'>{value.precoItemOriginal.toLocaleString(
                                'pt-br',
                                {
                                  style: 'currency',
                                  currency:
                                    'BRL',
                                },
                              )}</td>
                              <td align='left'>{value.preco.toLocaleString(
                                'pt-br',
                                {
                                  style: 'currency',
                                  currency:
                                    'BRL',
                                },
                              )}</td>
                              <td align='left'>{value.quantidade}</td>
                              <td align='left'>{value.quantidadeAuditor}</td>
                              <td align='left'>{value.codigoItem}</td>
                              <td align='right'>{value.descItem}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </TableHeaderStyled>
                </div>
              </>,
            );
          }
        });
    }

    if (type === titleModalAction) {
      await new RestUseCase(`${ENDPOINT_LIST_ACTION_SCRAP}/${idScrap}/actions`)
        .Post()
        .then(response => {
          if (response.statusCode >= 400) {
            updateContentModal(<>Não foram encotradas açoes feitas para esse scrap</>)
          } else {
            updateContentModal(
              <>
                <div className='my-2'>
                  <TableHeaderStyled>
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'left' }}>ID</th>
                          <th style={{ textAlign: 'right' }}>Nome</th>
                          <th style={{ textAlign: 'right' }}>Status</th>
                          <th style={{ textAlign: 'right' }}>Descrição</th>
                          <th style={{ textAlign: 'right' }}>Ação</th>
                          <th style={{ textAlign: 'center' }}>Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.body.map((value: any, index: number) => {

                          let dataFormatada = '';
                          if (value.data !== null || value.data !== undefined) {
                            dataFormatada = ParseDateToBr(value.data);
                          }

                          return (
                            <tr key={index}>
                              <td align='left'>{value.idUsuario}</td>
                              <td align='right'>{value.nomeUsuario}</td>
                              <td align='right'>{value.status}</td>
                              <td align='right'>{value.desc}</td>
                              <td align='right'>{value.action}</td>
                              <td align='center'>{dataFormatada}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </TableHeaderStyled>
                </div>
              </>,
            )
          }
        });
    }
    updateLoadingModal(false)
  };

  function ModalContent() {
    return (
      <Modal show={showModalContent} onHide={closeContent} size={'xl'} fullscreen={'md-down'}>
        <Modal.Header closeButton>
          <Modal.Title className='ms-3 lead' style={{ fontSize: '30px' }}>Scrap</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingModal && <Loading />}
          {!loadingModal && (
            <>{contentModal}</>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <Headers />
      <TitleGroupComponent titlePage={titlePage} children={undefined} />
      <div className='mx-2'>
        <Card>
          {loading && <Loading />}
          {!loading && (
          <Card.Body>
            {children?.filterTable ?
              <>{children?.filterTable}</>
              :
              <></>
            }

            <div className='mt-1 '>
              <TableHeaderFixedStyled>
                <Table>
                  <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    {tableHeader.map((value, index) => {
                      if (value.columnVisible) {

                        return (
                          <th className='px-1' key={index}>
                            <div>
                              <p style={{ whiteSpace: 'nowrap', textAlign: value.align }}
                                 className='m-1'>{value.labelTable}</p>
                            </div>
                          </th>
                        );
                      }
                    })}
                  </tr>
                  </thead>
                  <tbody>
                  {tableBody.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td><Button className='ms-1' variant='primary' size='sm' onClick={async () => {
                          showContent();
                          await getContentModal(titleModalAction, value.id);
                        }}><BsLightningChargeFill /></Button></td>
                        <td><Button className='ms-1' variant='primary' size='sm' onClick={async () => {
                          showContent();
                          await getContentModal(titleModalList, value.id);
                        }}><MdListAlt /></Button></td>
                        <td>{value.id}</td>
                        <td>{value.idAuditor}</td>
                        <td>{value.nomeFilialFornecedor}</td>
                        <td>{value.nomeAuditor}</td>
                        <td>{value.codigoFilialFornecedor}</td>
                        <td>{value.status}</td>
                        <td>{value.notaFiscalAtrelada}</td>
                        <td>{value.dataCadastro}</td>
                        <td>{value.dataAgendamento}</td>
                      </tr>
                    );
                  })}
                  </tbody>

                </Table>
              </TableHeaderFixedStyled>
            </div>
            {tableBody.length > 0 && <>
              <div className='mt-4'>
                <Row className='d-flex align-items-center justify-content-between'>

                  <Col className='d-flex justify-content-start'>
                  </Col>
                  <Col>
                    <div className='d-flex justify-content-center'>
                      <Pagination size='sm'>
                        <Pagination.First onClick={onClickFirstPagination}>Primeira</Pagination.First>
                        <Pagination.Prev onClick={onClickPrevPagination}>Anterior</Pagination.Prev>
                        {itemList?.map((value: any, key: any) => {
                          return (<Pagination.Item key={key} active={value.active}
                                                   onClick={() => onClickItem(value)}>{value.item + 1}</Pagination.Item>);
                        })}
                        <Pagination.Next onClick={onClickNextPagination}>Próxima</Pagination.Next>
                        <Pagination.Last onClick={onClickLastPagination}>Última</Pagination.Last>
                      </Pagination>
                    </div>
                  </Col>
                  <Col className='d-flex justify-content-end'>
                  </Col>
                </Row>
              </div>
            </>}

            </Card.Body>
          )}
        </Card>
      </div>
      <ModalContent />
      <Footer />
    </>
  );

};

export default QueryScrapPage;