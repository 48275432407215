import { Container } from "react-bootstrap"
import { SCFooter, SCText } from "./style"

export function Footer() {

    return (
        <SCFooter>
            <Container fluid className="d-flex justify-content-center align-items-center mt-2">
                <SCText>LUME TECNOLOGIA | Intelligent Supply v.6.0.0.0 | ©Copyright | Todos os direitos reservados</SCText>
            </Container>
        </SCFooter>
    )

}

