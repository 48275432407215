import styled from "styled-components"

export const SCFooter = styled.footer`
    bottom: 0;
    width: 100%;
    height: 45px;
    background-color: "#F0F0F0"
`

export const SCText = styled.p`
    text-align: center;
    font-size: 16px;

    @media (max-width: 800px) {
        font-size: 12px;
    }

    @media (max-width: 600px) {
        font-size: 10px;
    }

    @media (max-width: 400px) {
        font-size: 8px;
    }

    @media (max-width: 200px) {
        font-size: 4px;
    }
`