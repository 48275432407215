import { ReactNode } from "react"
import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"

type Props = {
    isOpen: boolean,
    isEditing: boolean,
    title: string,
    children: ReactNode[],
    open: any
    close: any
    submit: any
}

export function ModalCrud({ title, children, close, isOpen, isEditing, submit }: Props) {

    let titleModal = isEditing ? `Editando` : `Criar`
    if (title)
        titleModal = title

    const { handleSubmit } = useForm()

    return (
        <>
            <Modal show={isOpen} onHide={() => close()} size={"xl"} fullscreen={"md-down"}>
                <Modal.Header closeButton>
                    <Modal.Title className="ms-3 lead" style={{ fontSize: "30px" }}>{titleModal}</Modal.Title>
                </Modal.Header>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit(submit())
                    }}
                    className="mt-0"
                    noValidate
                >
                    <Modal.Body>
                        {children[0]}
                    </Modal.Body>
                    <Modal.Footer>
                        {children[1]}
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )

}
