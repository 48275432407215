import { CallHttpLogin } from "infra/call/call-login-http-infra"

interface Request {
    data?: any,
    headers?: any
}

export class AuthUseCase {

    constructor(private readonly endPoint: string) {
        this.endPoint = endPoint
    }

    async Post(request?: Request) {
        return await CallHttpLogin({
            url: this.endPoint,
            method: "POST",
            body: request?.data,
            headers: request?.headers
        }).then(response => response)
    }
}
