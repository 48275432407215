/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';

import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';

import { SCDesctiption } from 'presentation/style/typography/titlePage';
import { Headers } from 'presentation/components/header/headers-component';
import { SelectProvider } from 'presentation/components/select/provider-select-component';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { Loading } from 'presentation/components/loading/loading-component';
import { TitlePageComponent } from '../components/title/title-page-component';
import { TitleGroupComponent } from '../components/title/title-group-component';

type Props = {
  loading: boolean;
  requestMessage: {
    message: string;
    type: 'success' | 'danger' | '';
  };
  clearRequestMessage: () => any;
  uploadFileItemCorrelate: (params?: {
    idProvider: number;
    file: File;
  }) => Promise<any>;
  uploadFileItemOriginal: (params?: {
    idProvider: number;
    file: File;
    typeFile: string;
  }) => Promise<any>;
  uploadFileItemPromotional: (params?: {
    idProvider: number;
    file: File;
  }) => Promise<any>;
  uploadFilePurchasesProvider: (params?: {
    idProvider: number;
    file: File;
  }) => Promise<any>;
  uploadFileFilialProvider: (params?: {
    idProvider: number;
    file: File;
  }) => Promise<any>;
  download: (params: {
    typeRequest: string;
    typeFile?: string;
  }) => Promise<any>;
};

const titlePage = 'Carregar Arquivos';

const FileLoadItemPage: React.FC<Props> = ({
                                             loading,
                                             requestMessage,
                                             clearRequestMessage,
                                             uploadFileItemCorrelate,
                                             uploadFileItemOriginal,
                                             uploadFileItemPromotional,
                                             uploadFilePurchasesProvider,
                                             uploadFileFilialProvider,
                                             download,
                                           }: Props) => {
  /**
   *
   * MODAL
   *
   */
  const [modelModal, updateModelModal] = useState<{
    title: string;
    type?: string;
  }>();
  const [openModal, updateOpenModal] = useState<boolean>(false);

  const closeUploadModal = () => {
    updateOpenModal(false);
    clearRequestMessage();
  };
  const showUploadModal = () => updateOpenModal(true);

  const typeFileOption: SelectOptionInterface[] = [
    { selected: false, value: 'ITENS_PRECOS', label: 'Itens preços' },
    { selected: false, value: 'ITENS', label: 'Itens' },
    { selected: false, value: 'PRECOS', label: 'Preços' },
  ];

  function ModalUpload() {
    const [activeItemType, updateActiveItemType] = useState<number>();
    const [fileItemType, updateFileItemType] =
      useState<string>('ITENS_PRECOS');
    const [contentFile, updateContentFile] = useState<File | undefined>();

    function handleSelectProvider(filial: {
      value: number;
      label: string;
    }) {
      updateActiveItemType(filial.value);
    }

    function handleSelectTypeItem(params: string) {
      updateFileItemType(params);
    }

    function loadFile(file: File) {
      if (file !== null || file !== undefined) {
        updateContentFile(file);
      }
    }

    async function downloadFile() {
      await download({
        typeRequest: modelModal?.type!,
        typeFile: fileItemType,
      }).then((response) => {
        const href = URL.createObjectURL(response.body);
        const disposition = response.headers['content-disposition'];
        var fileName = '';
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    }

    function handleFile() {
      if (modelModal?.type === 'ITEM_ORIGINAL') {
        uploadFileItemOriginal({
          idProvider: activeItemType!,
          file: contentFile!,
          typeFile: fileItemType!,
        });
      }
      if (modelModal?.type === 'ITEM_CORRELATO') {
        uploadFileItemCorrelate({
          idProvider: activeItemType!,
          file: contentFile!,
        });
      }
      if (modelModal?.type === 'ITEM_PROMOCIONAL') {
        uploadFileItemPromotional({
          idProvider: activeItemType!,
          file: contentFile!,
        });
      }
      if (modelModal?.type === 'COMPRA_FORNECEDOR') {
        uploadFilePurchasesProvider({
          idProvider: activeItemType!,
          file: contentFile!,
        });
      }
      if (modelModal?.type === 'FILIAL_FORNECEDOR') {
        uploadFileFilialProvider({
          idProvider: activeItemType!,
          file: contentFile!,
        });
      }
    }

    return (
      <>
        <Modal
          show={openModal}
          onHide={closeUploadModal}
          size={'xl'}
          fullscreen={'md-down'}
        >
          <Container>
            <Modal.Header closeButton>
              <Modal.Title
                className='ms-3 lead'
                style={{ fontSize: '30px' }}
              >
                {modelModal?.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className='d-flex align-items-center'>
                <Col md={3}>
                  <Form.Label>Fornecedor:</Form.Label>
                  <SelectProvider
                    passOnTheValue={handleSelectProvider}
                  />
                </Col>
                {(modelModal?.type === 'ITEM_ORIGINAL' && (
                  <>
                    <Col md={3}>
                      <Form.Label>
                        Tipo de Arquivo:
                      </Form.Label>
                      <Select
                        defaultValue={typeFileOption[0]}
                        onChange={(val) => {
                          handleSelectTypeItem(
                            val?.value,
                          );
                        }}
                        options={typeFileOption}
                      />
                    </Col>

                    <Col md={4}></Col>
                    <Col md={2}>
                      <Button
                        variant='outline-primary'
                        size='sm'
                        onClick={downloadFile}
                      >
                        Baixar modelo
                      </Button>
                    </Col>
                  </>
                )) || (
                  <>
                    <Col md={7}></Col>
                    <Col md={2}>
                      {modelModal?.type !==
                        'ITEM_PROMOCIONAL' && (
                          <>
                            <Button
                              variant='outline-primary'
                              size='sm'
                              onClick={downloadFile}
                            >
                              Baixar modelo
                            </Button>
                          </>
                        )}
                    </Col>
                  </>
                )}
              </Row>
              <Row className='mt-3'>
                <Col>
                  <Card
                    className='py-5 mb-2'
                    style={{
                      backgroundColor: '#F0F0F0',
                      border: 'none',
                    }}
                  >
                    <Row className='d-flex justify-content-center mb-5'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='80'
                        height='80'
                        fill='currentColor'
                        className='bi bi-file-earmark-text'
                        viewBox='0 0 16 16'
                        color='#0433BF'
                      >
                        <path
                          d='M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z' />
                        <path
                          d='M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z' />
                      </svg>
                    </Row>
                    <Col
                      md={10}
                      sm={12}
                      lg={10}
                      className='m-auto  mb-2'
                    >
                      <input
                        type='file'
                        className='form-control'
                        onChange={(event) => {
                          event.preventDefault();
                          loadFile(
                            event.target.files![0],
                          );
                        }}
                      />
                    </Col>
                    <Col className='d-flex justify-content-center m-1'>
                      {loading === true && (
                        <div
                          className='spinner-border text-secondary'
                          role='status'
                        ></div>
                      )}
                    </Col>
                    <Col className='d-flex justify-content-center m-1'>
                      {requestMessage.type !== '' && (
                        <Alert
                          variant={
                            requestMessage.type
                          }
                        >
                          {requestMessage.message}
                        </Alert>
                      )}
                    </Col>
                  </Card>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col></Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-start'>
              <Button
                variant='primary'
                size='sm'
                onClick={handleFile}
                disabled={
                  activeItemType === undefined ? true : false
                }
              >
                Carregar Arquivo
              </Button>
            </Modal.Footer>
          </Container>
        </Modal>
      </>
    );
  }

  /**
   *
   * INIT
   *
   */
  useEffect(() => {
  }, []);

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />

      <Container>
        <TitleGroupComponent titlePage={titlePage}>
          <Row>
            <Col>
            </Col>
          </Row>
        </TitleGroupComponent>

        <Row>
          <Card style={{ width: '15rem' }} className='ms-3 mt-1'>
            <Card.Body
              onClick={() => {
                showUploadModal();
                updateModelModal({
                  title: 'Carregar Itens Originais',
                  type: 'ITEM_ORIGINAL',
                });
              }}
            >
              <Card.Title>Itens Originais</Card.Title>
              <Card.Text>
                Carrega arquivo de Itens Originais
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: '15rem' }} className='ms-3 mt-1'>
            <Card.Body
              onClick={() => {
                showUploadModal();
                updateModelModal({
                  title: 'Carregar Itens Correlatos',
                  type: 'ITEM_CORRELATO',
                });
              }}
            >
              <Card.Title>Itens Correlatos</Card.Title>
              <Card.Text>
                Carrega arquivo de Itens Correlatos
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: '15rem' }} className='ms-3 mt-1'>
            <Card.Body
              onClick={() => {
                showUploadModal();
                updateModelModal({
                  title: 'Carregar Itens Promocionais',
                  type: 'ITEM_PROMOCIONAL',
                });
              }}
            >
              <Card.Title>Itens Promocionais</Card.Title>
              <Card.Text>
                Carrega arquivo de Itens Promocionais
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: '15rem' }} className='ms-3 mt-1'>
            <Card.Body
              onClick={() => {
                showUploadModal();
                updateModelModal({
                  title: 'Carregar Compras do Fornecedor',
                  type: 'COMPRA_FORNECEDOR',
                });
              }}
            >
              <Card.Title>Compras Fornecedor</Card.Title>
              <Card.Text>
                Carrega arquivo de Compras do Fornecedor
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: '15rem' }} className='ms-3 mt-1'>
            <Card.Body
              onClick={() => {
                showUploadModal();
                updateModelModal({
                  title: 'Carregar Filial Fornecedor',
                  type: 'FILIAL_FORNECEDOR',
                });
              }}
            >
              <Card.Title>Filial Fornecedor</Card.Title>
              <Card.Text>
                Carrega arquivo de Filial Fornecedor
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>

      <ModalUpload />
    </>
  );
};

export default FileLoadItemPage;
