import { useEffect, useState } from "react"
import { UseAuth } from "presentation/hook/auth-hook"
import { RestCrud } from "data/useCase/rest-crud-usecase"
import { OriginalItemStatusEntity } from "domain/entity/original-item-status-entity"
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface"
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page"
import OriginalItemStatusPage from "presentation/pages/provider/original-item-status-page"
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter"
import { getEnv } from "main/helper/window-helper"

export const OriginalItemStatusFactory: React.FC = () => {

    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_ORIGINAL_ITEMS_STATUS")

    const providerOption: SelectOptionInterface[] = [
        { selected: false, value: 155, label: "Acura" },
        { selected: false, value: 177, label: "Agrale" },
        { selected: false, value: 75, label: "Audi" },
        { selected: false, value: 159, label: "Bentley" },
        { selected: false, value: 141, label: "BMW" },
        { selected: false, value: 183, label: "Bobcat" },
        { selected: false, value: 185, label: "Bomag" },
        { selected: false, value: 160, label: "Bugati" },
        { selected: false, value: 162, label: "Buick" },
        { selected: false, value: 164, label: "Cadilack" },
        { selected: false, value: 241, label: "Case" },
        { selected: false, value: 64, label: "Chery" },
        { selected: false, value: 180, label: "Caterpillar" },
        { selected: false, value: 63, label: "Citroen" },
        { selected: false, value: 68, label: "Chrysler" },
        { selected: false, value: 147, label: "Daihatsu" },
        { selected: false, value: 170, label: "Dafra Motos" },
        { selected: false, value: 145, label: "Dodge" },
        { selected: false, value: 154, label: "Datsun" },
        { selected: false, value: 1, label: "Fiat Automoveis SA" },
        { selected: false, value: 184, label: "Doosan" },
        { selected: false, value: 71, label: "Ford Caminhões" },
        { selected: false, value: 70, label: "Ford" },
        { selected: false, value: 149, label: "Geely" },
        { selected: false, value: 341, label: "Gauss" },
        { selected: false, value: 174, label: "GM Caminhões" },
        { selected: false, value: 76, label: "GM" },
        { selected: false, value: 22, label: "Honda" },
        { selected: false, value: 72, label: "Harley Motos" },
        { selected: false, value: 21, label: "Hyundai" },
        { selected: false, value: 69, label: "Honda Motos" },
        { selected: false, value: 153, label: "Infiniti" },
        { selected: false, value: 122, label: "Indian" },
        { selected: false, value: 176, label: "Iveco Caminhões" },
        { selected: false, value: 41, label: "Iveco" },
        { selected: false, value: 81, label: "Jeep" },
        { selected: false, value: 167, label: "Jaguar" },
        { selected: false, value: 67, label: "Kia" },
        { selected: false, value: 182, label: "John Deere" },
        { selected: false, value: 158, label: "Laborgiuini" },
        { selected: false, value: 179, label: "Komatsu" },
        { selected: false, value: 152, label: "Lexus" },
        { selected: false, value: 121, label: "Land Rover" },
        { selected: false, value: 151, label: "Maserati" },
        { selected: false, value: 65, label: "Mahindra" },
        { selected: false, value: 173, label: "MAN Caminhões" },
        { selected: false, value: 187, label: "Massey Ferguson" },
        { selected: false, value: 171, label: "Mercedes-bens Caminhões" },
        { selected: false, value: 142, label: "Mercedes-bens" },
        { selected: false, value: 102, label: "Mitsubishi" },
        { selected: false, value: 157, label: "Mini" },
        { selected: false, value: 61, label: "Nissan" },
        { selected: false, value: 178, label: "New Holland" },
        { selected: false, value: 66, label: "Peugeot" },
        { selected: false, value: 163, label: "Opel" },
        { selected: false, value: 148, label: "Porsche" },
        { selected: false, value: 441, label: "Pneus" },
        { selected: false, value: 62, label: "Renault" },
        { selected: false, value: 144, label: "RAM" },
        { selected: false, value: 166, label: "Samsung" },
        { selected: false, value: 156, label: "Rolls-Royce" },
        { selected: false, value: 77, label: "Scania" },
        { selected: false, value: 186, label: "Sany" },
        { selected: false, value: 146, label: "Seat" },
        { selected: false, value: 175, label: "Scania Caminhões" },
        { selected: false, value: 168, label: "Smart" },
        { selected: false, value: 161, label: "Skoda" },
        { selected: false, value: 101, label: "Toyota" },
        { selected: false, value: 150, label: "Tata" },
        { selected: false, value: 181, label: "Valtra" },
        { selected: false, value: 73, label: "Triumph Motos" },
        { selected: false, value: 2, label: "Volkswagen" },
        { selected: false, value: 165, label: "Vauxhall" },
        { selected: false, value: 172, label: "Volvo Caminhões" },
        { selected: false, value: 143, label: "Volvo" },
        { selected: false, value: 169, label: "Yamara Motos" },
        { selected: false, value: 74, label: "VW Caminhões" }
    ]

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Fornecedor")[0].navItem.filter(item => item.title === "Cadastrar Status de Item Original")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }
    }, [loggedUserData])

    return (
        <>
            {showScreen ?
                <OriginalItemStatusPage
                    modelMethods={{
                        create: async function (model: OriginalItemStatusEntity): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD! + `?idFornecedor=${model.fornecedor}`).Post(model)
                            return response.body
                        },
                        edit: async function (model: OriginalItemStatusEntity, id: number): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Put(model, id)
                            return response.body
                        },
                        remove: async function (id: number): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Delete(id)
                            return response.body
                        },
                        getAll: async function (params?: { pagination: string, idProvider: number }): Promise<OriginalItemStatusEntity[]> {
                            let query = `?idFornecedor=${params?.idProvider}`

                            if (params?.pagination)
                                query += `${params?.pagination}`

                            const response = await new RestCrud(ENDPOINT_CRUD! + `${query}`).Get()
                            return response as any
                        },
                        getById: async function (id: number): Promise<OriginalItemStatusEntity> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).GetById(id)
                            return response.body
                        },
                        get: async function (): Promise<OriginalItemStatusEntity> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Get()
                            return response.body
                        }
                    }}
                    providerOption={providerOption}
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )
}
