/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Nav,
  Row,
  Table,
} from 'react-bootstrap';

import { FaRegEye } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { BsSliders } from 'react-icons/bs';

import { FiltersDefault } from 'domain/interfaces/filter-interface';

import { SCDesctiption } from 'presentation/style/typography/titlePage';

import { UseAlert } from 'presentation/hook/alert-hook';

import { SpinnerSC } from 'presentation/style/spinner/spinner-styled';

import { getEnv } from 'main/helper/window-helper';

import { Footer } from 'presentation/components/footer/footer-component';
import { Headers } from 'presentation/components/header/headers-component';
import { SelectFilial } from 'presentation/components/select/filial-select-component';
import ContainerFilter from 'presentation/components/container/container-filter-component';
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled';
import { ParseDateToBrWithHours } from 'main/helper/format-date-helper';
import {
  getLocalStorage,
  updateLocalStorege,
} from 'data/cache/localstorage-cache';
import { TableDescriptions } from 'domain/interfaces/table-interface';
import { ColumsTableManagerialAdapter } from 'main/adapter/columsTable/colums-table-managerial-adapter';
import {
  DragInDropHideOrderColumsTableComponent,
} from 'presentation/components/dragDrop/drag-in-drop-hide-order-colums-table-component';
import { BiColumns } from 'react-icons/bi';
import { TitlePageComponent } from '../../components/title/title-page-component';
import { TitleGroupComponent } from '../../components/title/title-group-component';

type HandleRequest = {
  pagination?: string;
  model?: any;
  grouping: string;
  modelFilials?: any[];
};

type ModelCurve = {
  curva: string;
  valorCurva: string;
  valorCustoMedio: number;
  quantidadeItemEstoque: number;
  pontuacao?: number;
  status?: string;
  demandaAtual?: string;
  faixa?: string;
  faixaValorFinal?: number;
  faixaValorInicial?: number;
  demandaProjetada?: string;
  filial?: number;
};

type ModelPunctuation = {
  pontuacao: number;
  mesUltimaCompra: number;
  valorCustoMedio: number;
  quantidadeItens: number;
  quantidadeItensBloqueados: number;
  valorCustoMedioItensBloqueados: number;
};

type Props = {
  processAndList: (params?: any) => Promise<any>;
  list: (params?: any) => Promise<any>;
  optionsFiltersDefault: FiltersDefault[];
  listByIdPlanning: (params?: any) => Promise<any>;
  listAllPlanning: (params?: any) => Promise<any>;
  createPlanning: (params?: any) => Promise<any>;
  editPlanning: (params?: any) => Promise<any>;
  removePlanning: (params?: any) => Promise<any>;
  exportFileCSV: (params?: any) => Promise<any>;
  exportFileExcel: (params?: any) => Promise<any>;
  lastCharge: string;
};

const titlePage = 'Gerencial';
const LOCALSTORAGE_PLANNING = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_MENAGERIAL')!;

const ManagerialPage: React.FC<Props> = ({
                                           optionsFiltersDefault,
                                           list,
                                           processAndList,
                                           listByIdPlanning,
                                           listAllPlanning,
                                           createPlanning,
                                           editPlanning,
                                           removePlanning,
                                           exportFileCSV,
                                           exportFileExcel,
                                           lastCharge,
                                         }: Props) => {
  /**
   *
   *
   *
   *
   *  ultima carga / pesquisa */
  const [lastSearch, updateLastSearch] = useState<string>();

  /**
   *
   *
   *
   *
   *  loading */
  const [loading, updateLoading] = useState<boolean>(false);

  function Loading() {
    return (
      <SpinnerSC>
        <div
          className='spinner-border text-secondary'
          role='status'
        ></div>
      </SpinnerSC>
    );
  }

  /**
   *
   *
   *
   *
   *  alerta */
  const { showAlert } = UseAlert();

  /**
   *
   *
   *
   *
   *  model table */
  const [modelTableManagerial, updateModelTableManagerial] = useState<any[]>(
    [],
  );
  const [listTableModel, updateListTableModel] = useState<any[]>([]);

  const [modelTableCurve, updateModelTableCurve] = useState<ModelCurve[]>([]);
  const [modelTablePunctuation, updateModelTablePunctuation] = useState<
    ModelPunctuation[]
  >([]);

  /**
   *
   *
   *
   *
   *  chamadas so banckend */
  async function handleTable(params?: HandleRequest) {
    /** inicia o loading */
    updateLoading(true);
    setValue('groupingChanged', params?.grouping);
    let response = await list(params);
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      /** coloca valores em cada aba */
      updateModelTableCurve(response.body.curva);
      updateModelTableManagerial(response.body.gerencial);
      updateModelTablePunctuation(response.body.pontuacao);
      /** data da ultima carga */
      if (response.headers['is-ultima-pesquisa'])
        updateLastSearch(
          response.headers['is-ultima-pesquisa'] === 'null'
            ? 'NULL'
            : ParseDateToBrWithHours(
              response.headers['is-ultima-pesquisa'],
            ),
        );
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  async function handleProcessTable(params?: HandleRequest) {
    /** valida os parametros */
    if (params?.modelFilials === undefined) {
      showAlert({
        show: true,
        content: 'Escolha ao menos uma Filial',
        color: 'danger',
        time: 2000,
      });
      return;
    }
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    /** inicia o loading */
    updateLoading(true);
    let response = await processAndList(params);
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      /** coloca valores em cada aba */
      updateModelTableCurve(response.body.curva);
      updateModelTableManagerial(response.body.gerencial);
      updateModelTablePunctuation(response.body.pontuacao);
      showAlert({
        show: true,
        content: 'Processado com sucesso',
        color: 'success',
        time: 2000,
      });
      /** data da ultima carga */
      if (response.headers['is-ultima-pesquisa'])
        updateLastSearch(
          response.headers['is-ultima-pesquisa'] === 'null'
            ? 'NULL'
            : ParseDateToBrWithHours(
              response.headers['is-ultima-pesquisa'],
            ),
        );
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  /**
   *
   *
   *
   *
   *  exporta arquivos CSV/EXCEL */
  const [showModalExport, updateShowModalExport] = useState(false);
  const closeModalExport = () => updateShowModalExport(false);
  const showExport = () => updateShowModalExport(true);

  function ModalExport() {
    const [selectedOption, setSelectedOption] = useState<string>('');

    const handleOptionChange = (value: string) => {
      setSelectedOption(value);
    };
    return (
      <Modal
        show={showModalExport}
        onHide={closeModalExport}
        fullscreen={'md-down'}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className='ms-3 lead'
            style={{ fontSize: '30px' }}
          >
            Exportar arquivo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Escolha o tipo de arquivo que deseja:</p>

          <Form.Check
            inline
            label='Excel'
            name='Excel'
            type={'radio'}
            checked={selectedOption === 'Excel'}
            value='Excel'
            onChange={() => handleOptionChange('Excel')}
          />
          <Form.Check
            inline
            label='CSV'
            name='CSV'
            type={'radio'}
            checked={selectedOption === 'CSV'}
            value='CSV'
            onChange={() => handleOptionChange('CSV')}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              if (selectedOption === 'Excel') {
                handleExportExcel();
              } else {
                handleExportCSV();
              }
              closeModalExport();
            }}
            disabled={selectedOption === ''}
          >
            Exportar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleExportExcel = async () => {
    try {
      const response = await exportFileExcel({
        grouping: getValues().groupingChanged!,
      });

      const url = window.URL.createObjectURL(new Blob([response.body]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Gerencial.xlsx`);
      document.body.appendChild(link);
      link.click();
      link!.parentNode!.removeChild(link);
      showAlert({
        show: true,
        content: 'Exportado com sucesso',
        color: 'success',
        time: 2000,
      });
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };

  async function handleExportCSV() {
    /** inicia o loading */
    updateLoading(true);
    showAlert({
      show: true,
      content: 'Aguarde um momento',
      color: 'warning',
      time: 2000,
    });
    let response = await exportFileCSV({
      grouping: getValues().groupingChanged!,
    });
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      const url = window.URL.createObjectURL(response.body);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Relatorio_Gerencial.zip`);
      document.body.appendChild(link);
      link.click();
      link!.parentNode!.removeChild(link);
      showAlert({
        show: true,
        content: 'Exportado com sucesso',
        color: 'success',
        time: 2000,
      });
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  /**
   *
   *
   *
   *
   *  planejamento */
  const [showModalPlanning, updateShowModalPlanning] = useState(false);
  const [itemPlanning, updateItemPlanning] = useState<number>(0);
  const closePlanning = () => updateShowModalPlanning(false);
  const showPlanning = () => updateShowModalPlanning(true);

  async function handleApplyPlanning(model: any) {
    /** inicia o loading */
    updateLoading(true);
    /** fecha o modal */
    closePlanning();
    let countPlanning = model.length;
    /** salva no localstorage */
    updateLocalStorege(LOCALSTORAGE_PLANNING, model);
    updateItemPlanning(countPlanning);

    let response = await list({ grouping: groupingVisualization });

    /** dados da requisicao */
    if (response.statusCode === 200) {
      updateModelTableCurve(response.body.curva);
      updateModelTableManagerial(response.body.gerencial);
      updateModelTablePunctuation(response.body.pontuacao);

      if (countPlanning >= 0)
        showAlert({
          show: true,
          content: 'Planejamento Aplicado com sucesso',
          color: 'success',
          time: 2000,
        });
      else
        showAlert({
          show: true,
          content: 'Nenhum item aplicado',
          color: 'warning',
          time: 2000,
        });
    } else
      showAlert({
        show: true,
        content: 'Ops!!! Algo deu errado tente novamente mais tarde...',
        color: 'danger',
        time: 2000,
      });
    /** finaliza o loading */
    updateLoading(false);
  }

  function ModalPlanning() {
    return (
      <Modal
        show={showModalPlanning}
        onHide={closePlanning}
        size={'xl'}
        fullscreen={'md-down'}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className='ms-3 lead'
            style={{ fontSize: '30px' }}
          >
            Planejamento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <ContainerFilter
                keyLocalStorage={LOCALSTORAGE_PLANNING}
                filterLocalStorage={getLocalStorage(
                  LOCALSTORAGE_PLANNING,
                )}
                apply={handleApplyPlanning}
                filtersDefault={optionsFiltersDefault}
                listAllPlanning={listAllPlanning}
                listByIdPlanning={function(
                  params?: any,
                ): Promise<any> {
                  throw new Error(
                    'listByIdPlanning ==> não foi implementada',
                  );
                }}
                createPlanning={createPlanning}
                editPlanning={editPlanning}
                removePlanning={removePlanning}
                isOnlineConsultant={false}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *  abas */
  const [activeItem, updateActiveItem] = useState<string>('gerencial');

  async function handleActiveItem(item: string) {
    updateActiveItem(item);
  }

  var tab = 'pontuacao';
  var colName = 'Pontuacao';
  var tab2 = '';
  var colName2 = '';

  function changeColunsSelected() {
    switch (getValues().groupingChanged!) {
      case 'PONTUACAO':
        tab = 'pontuacao';
        colName = 'Pontuação';
        break;
      case 'BASE':
        tab = 'base';
        colName = 'Base';
        break;
      case 'FILIAL':
        tab = 'filial';
        colName = 'Filial';
        break;
      case 'STATUS':
        tab = 'status';
        colName = 'Status';
        break;
      case 'CURVA':
        tab = 'curva';
        colName = 'Curva';
        break;
      case 'DEMANDA_ATUAL':
        tab = 'demandaAtual';
        colName = 'Faixa';
        break;
      case 'DEMANDA_PROJETADA':
        tab = 'demandaProjetada';
        colName = 'Demanda Projetada';
        break;
      case 'FAIXA_PRECO':
        tab = 'faixaValorInicial';
        tab2 = 'faixaValorFinal';
        colName = 'Faixa Valor Inicial';
        colName2 = 'Faixa Valor Final';
        break;
    }
  }

  /**
   *
   *
   *
   *
   *  abas ==> gerencial */
  function changeValueListByColums(model: any[]) {
    if (model !== undefined) {
      var ListTableChanged: any[] = [];
      var columsName = columns
        .filter(
          (value) =>
            value.hidden === false ||
            value.dataField === tab ||
            value.dataField === tab2,
        )
        .map((vl) => {
          return vl.dataField;
        });
      model.forEach((value) => {
        const filtered = Object.keys(value)
          .filter((key) => columsName.includes(key))
          .reduce((obj: any, key: any) => {
            obj[key] = value[key];
            return obj;
          }, {});

        ListTableChanged.push(filtered);
      });
      updateListTableModel(ListTableChanged);
    }
  }

  useEffect(() => {
    const namePage = "managerial";
    localStorage.setItem("actual_page", namePage);
    changeValueListByColums(modelTableManagerial);
  }, [modelTableManagerial, updateModelTableManagerial]);

  function CreateTableManagerial() {
    changeColunsSelected();

    return (
      <>
        {listTableModel.length > 0 ? (
          <TableHeaderFixedStyled>
            <Table>
              <thead>
              <tr className='m-0 p-0'>
                <th style={{ whiteSpace: 'nowrap' }}>
                  <div className='d-flex align-items-center justify-content-center'>
                    {colName}
                  </div>
                </th>
                {colName2 !== '' && (
                  <th style={{ whiteSpace: 'nowrap' }}>
                    <div className='d-flex align-items-center justify-content-center'>
                      {colName2}
                    </div>
                  </th>
                )}
                {columns.map((value, index) => {
                  let align = value.align;

                  let classNameDiv = `d-flex align-items-center justify-content-${align} mx-2`;
                  if (align === 'left')
                    classNameDiv = `d-flex align-items-center justify-content-start mx-2`;
                  if (align === 'right')
                    classNameDiv = `d-flex align-items-center justify-content-end mx-2`;

                  if (value.hidden === false) {
                    return (
                      <>
                        <th
                          align={value.align}
                          className='px-1'
                          key={index}
                        >
                          <div
                            className={
                              classNameDiv
                            }
                          >
                            <div className='d-flex align-items-center justify-content-center'>
                              <p
                                style={{
                                  whiteSpace:
                                    'nowrap',
                                }}
                                className='m-1'
                              >
                                {value.text}
                              </p>
                            </div>
                          </div>
                        </th>
                        <th>
                          <div className='d-flex align-items-center justify-content-center'>%</div>
                        </th>
                      </>
                    );
                  }
                })}
              </tr>
              </thead>
              <tbody>
              {listTableModel.map((value, index) => {
                return (
                  <>
                    <tr
                      key={index}
                      className='text-center'
                    >
                      <td>{value[tab]}</td>
                      {tab2 !== '' && (
                        <td
                          style={{
                            whiteSpace:
                              'nowrap',
                          }}
                        >
                          {value[tab2]}
                        </td>
                      )}
                      {columns.map((vc, i) => {
                        if (vc.hidden === false) {
                          let formatted =
                            value[vc.dataField];

                          if (
                            vc.typeField ===
                            'monetary' && formatted !== undefined
                          ) {
                            formatted =
                              formatted.toLocaleString(
                                'pt-br',
                                {
                                  style: 'currency',
                                  currency:
                                    'BRL',
                                },
                              );
                          }

                          return (
                            <>
                              <td
                                key={i}
                                className='m-0 p-0 mx-2'
                                style={{
                                  textAlign:
                                  vc.align,
                                  whiteSpace:
                                    'nowrap',
                                  fontWeight: 250,
                                }}
                              >
                                <div className='m-0 p-0 mx-3 my-1'>
                                  {
                                    formatted
                                  }
                                </div>
                              </td>
                              {/* <td>%%</td> */}
                              <td>
                                {Number(
                                  value[
                                    vc
                                      .dataField
                                    ],
                                ) !== 0
                                  ? (
                                    (Number(
                                        value[
                                          vc
                                            .dataField
                                          ],
                                      ) *
                                      100) /
                                    listTableModel.reduce(
                                      function(
                                        prev,
                                        cur,
                                      ) {
                                        return (
                                          prev +
                                          cur[
                                            vc
                                              .dataField
                                            ]
                                        );
                                      },
                                      0,
                                    )
                                  ).toFixed(
                                    1,
                                  ) +
                                  '%'
                                  : '0%'}
                              </td>
                            </>
                          );
                        }
                      })}
                    </tr>
                  </>
                );
              })}

              <tr
                style={{
                  background: '#F0F0F0',
                  fontWeight: 'bold',
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 0,
                }}
              >
                <td style={{ whiteSpace: 'nowrap' }}>
                  Total
                </td>
                {columns.map((vc, i) => {
                  if (vc.hidden === false) {
                    let formatted =
                      listTableModel.reduce(function(
                          prev,
                          cur,
                        ) {
                          return (
                            prev + cur[vc.dataField]
                          );
                        },

                        0);
                    if (vc.typeField === 'monetary' && formatted !== undefined) {
                      formatted =
                        formatted.toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          },
                        );
                    }
                    return (
                      <>
                        <td
                          style={{
                            whiteSpace:
                              'nowrap',
                            textAlign: 'right',
                          }}
                        >
                          {formatted}
                        </td>
                        <td
                          style={{
                            whiteSpace:
                              'nowrap',
                          }}
                        >
                          100%
                        </td>
                      </>
                    );
                  }
                })}
              </tr>
              </tbody>
            </Table>
          </TableHeaderFixedStyled>
        ) : (
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '60vh' }}
          >
            <p className='m-5'>
              A busca não retorna nada, verifique o Planejamento
              ou clique em Processar
            </p>
          </div>
        )}
      </>
    );
  }

  /**
   *
   *
   *
   *
   *  abas ==> curva */
  function CreateTableCurve() {
    var modelTablePresentation = modelTableCurve.reduce(function(r, a) {
      r[a.curva] = r[a.curva] || [];
      r[a.curva].push(a);
      return r;
    }, Object.create(null));

    let keysPresentation = Object.keys(modelTablePresentation).sort();
    changeColunsSelected();
    return (
      <>
        {keysPresentation.length > 0 ? (
          <TableHeaderFixedStyled>
            <Table>
              <thead>
              {keysPresentation.map((el, i) => {
                var totalFaixa1 = 0;
                var totalFaixa2 = 0;
                var totalFaixa3 = 0;
                var totalFaixa4 = 0;
                return (
                  <tr key={i}>
                    <Table>
                      <thead>
                      <tr>
                        <th
                          colSpan={
                            tab2 !== ''
                              ? 7
                              : 6
                          }
                          className='text-center'
                        >
                          {el}
                        </th>
                      </tr>
                      <tr>
                        <th>{colName}</th>
                        {colName2 !== '' && (
                          <th
                            style={{
                              whiteSpace:
                                'nowrap',
                            }}
                          >
                            {colName2}
                          </th>
                        )}
                        <th>Faixa 1</th>
                        <th>Faixa 2</th>
                        <th>Faixa 3</th>
                        <th>Faixa 4</th>
                        <th>Total</th>
                      </tr>
                      </thead>
                      <tbody>
                      {modelTablePresentation[
                        el
                        ].map(
                        (
                          elPresentations: ModelCurve,
                          elPresentationsIndex: number,
                        ) => {
                          if (
                            elPresentations.valorCurva ===
                            '1'
                          ) {
                            var col1 =
                              modelTablePresentation[
                                el
                                ][
                                elPresentationsIndex
                                ][tab]
                                ? modelTablePresentation[
                                  el
                                  ][
                                  elPresentationsIndex
                                  ][tab]
                                : 0;

                            var faixa1 =
                              modelTablePresentation[
                                el
                                ][
                                elPresentationsIndex
                                ]
                                ? modelTablePresentation[
                                  el
                                  ][
                                  elPresentationsIndex
                                  ]
                                  .valorCustoMedio
                                : 0;
                            //TODO Verificar valores quando selecionado Faixa de Preço
                            if (
                              tab2 ===
                              'faixaValorFinal'
                            ) {
                              var faixa2 =
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex -
                                1
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex -
                                  1
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              var faixa3 =
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex -
                                2
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex -
                                  2
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              var faixa4 =
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex -
                                3
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex -
                                  3
                                    ]
                                    .valorCustoMedio
                                  : 0;
                            } else {
                              var faixa2 =
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex +
                                1
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex +
                                  1
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              var faixa3 =
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex +
                                2
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex +
                                  2
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              var faixa4 =
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex +
                                3
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex +
                                  3
                                    ]
                                    .valorCustoMedio
                                  : 0;
                            }

                            var total =
                              faixa1 +
                              faixa2 +
                              faixa3 +
                              faixa4;

                            return (
                              <tr>
                                <td>
                                  {
                                    col1
                                  }
                                </td>
                                {tab2 !==
                                  '' && (
                                    <td
                                      style={{
                                        whiteSpace:
                                          'nowrap',
                                      }}
                                    >
                                      {
                                        modelTablePresentation[
                                          el
                                          ][
                                          elPresentationsIndex
                                          ][
                                          tab2
                                          ]
                                      }
                                    </td>
                                  )}
                                <td>
                                  {faixa1.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency:
                                        'BRL',
                                    },
                                  )}
                                </td>
                                <td>
                                  {faixa2.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency:
                                        'BRL',
                                    },
                                  )}
                                </td>
                                <td>
                                  {faixa3.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency:
                                        'BRL',
                                    },
                                  )}
                                </td>
                                <td>
                                  {faixa4.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency:
                                        'BRL',
                                    },
                                  )}
                                </td>
                                <td>
                                  {total.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency:
                                        'BRL',
                                    },
                                  )}
                                </td>
                              </tr>
                            );
                          }

                          return <></>;
                        },
                      )}
                      </tbody>
                      <tfoot
                        style={{
                          position: 'sticky',
                          bottom: 0,
                          zIndex: 0,
                        }}
                      >
                      {modelTablePresentation[
                        el
                        ].map(
                        (
                          elPresentations: ModelCurve,
                          elPresentationsIndex: number,
                        ) => {
                          if (
                            elPresentations.valorCurva ===
                            '1'
                          ) {
                            //TODO Verificar valores quando selecionado Faixa de Preço
                            if (
                              tab2 ===
                              'faixaValorFinal'
                            ) {
                              totalFaixa1 +=
                                modelTablePresentation[
                                  el
                                  ][
                                  elPresentationsIndex
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                    elPresentationsIndex
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              totalFaixa2 +=
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex -
                                1
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex -
                                  1
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              totalFaixa3 +=
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex -
                                2
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex -
                                  2
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              totalFaixa4 +=
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex -
                                3
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex -
                                  3
                                    ]
                                    .valorCustoMedio
                                  : 0;
                            } else {
                              totalFaixa1 +=
                                modelTablePresentation[
                                  el
                                  ][
                                  elPresentationsIndex
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                    elPresentationsIndex
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              totalFaixa2 +=
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex +
                                1
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex +
                                  1
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              totalFaixa3 +=
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex +
                                2
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex +
                                  2
                                    ]
                                    .valorCustoMedio
                                  : 0;
                              totalFaixa4 +=
                                modelTablePresentation[
                                  el
                                  ][
                                elPresentationsIndex +
                                3
                                  ]
                                  ? modelTablePresentation[
                                    el
                                    ][
                                  elPresentationsIndex +
                                  3
                                    ]
                                    .valorCustoMedio
                                  : 0;
                            }
                          }
                        },
                      )}
                      <tr
                        style={{
                          background:
                            '#F0F0F0',
                          fontWeight: 'bold',
                        }}
                      >
                        <td>Total</td>
                        {tab2 !== '' && (
                          <td
                            style={{
                              whiteSpace:
                                'nowrap',
                            }}
                          ></td>
                        )}
                        <td>
                          {totalFaixa1.toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency:
                                'BRL',
                            },
                          )}
                        </td>
                        <td>
                          {totalFaixa2.toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency:
                                'BRL',
                            },
                          )}
                        </td>
                        <td>
                          {totalFaixa3.toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency:
                                'BRL',
                            },
                          )}
                        </td>
                        <td>
                          {totalFaixa4.toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency:
                                'BRL',
                            },
                          )}
                        </td>
                        <td>
                          {(
                            totalFaixa1 +
                            totalFaixa2 +
                            totalFaixa3 +
                            totalFaixa4
                          ).toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency:
                                'BRL',
                            },
                          )}
                        </td>
                      </tr>
                      </tfoot>
                    </Table>
                  </tr>
                );
              })}
              </thead>
            </Table>
          </TableHeaderFixedStyled>
        ) : (
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '60vh' }}
          >
            <p className='m-5'>
              A busca não retorna nada, verifique o Planejamento
              ou clique em Processar
            </p>
          </div>
        )}
      </>
    );
  }

  /**
   *
   *
   *
   *
   *  abas ==> pontuacao */
  function CreateTablePunctuation() {
    var modelTablePresentation = modelTablePunctuation.reduce(function(
        r,
        a,
      ) {
        r[a.pontuacao] = r[a.pontuacao] || [];
        r[a.pontuacao].push(a);
        return r;
      },
      Object.create(null));

    let keysPresentation = Object.keys(modelTablePresentation);

    return (
      <>
        {keysPresentation.length > 0 ? (
          <TableHeaderFixedStyled>
            <Table>
              <thead>
              {keysPresentation.map((el, elIndex) => {
                var totalQuantidadeItens = 0;
                var totalQuantidadeItensBloqueados = 0;
                var totalValorCustoMedioItensBloqueados = 0;
                var totalValorCustoMedio = 0;
                return (
                  <tr key={elIndex}>
                    <Table>
                      <thead>
                      <tr>
                        <th
                          colSpan={6}
                          className='text-center'
                        >{`Pontuação = ${el}`}</th>
                      </tr>
                      <tr>
                        <th>
                          Mês Ultima Compra
                        </th>
                        <th>Itens Estoque</th>
                        <th>Itens Bloq.</th>
                        <th>
                          Vlr. Itens Bloq.
                        </th>
                        <th>
                          Vlr. Itens Estoque
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {modelTablePresentation[
                        el
                        ].map(
                        (
                          elPresentations: ModelPunctuation,
                          elPresentationsIndex: number,
                        ) => {
                          totalQuantidadeItens +=
                            elPresentations.quantidadeItens;
                          totalQuantidadeItensBloqueados +=
                            elPresentations.quantidadeItensBloqueados;
                          totalValorCustoMedioItensBloqueados +=
                            elPresentations.valorCustoMedioItensBloqueados;
                          totalValorCustoMedio +=
                            elPresentations.valorCustoMedio;
                          return (
                            <tr
                              key={
                                elPresentationsIndex
                              }
                            >
                              <td>
                                {
                                  elPresentations.mesUltimaCompra
                                }
                              </td>
                              <td>
                                {
                                  elPresentations.quantidadeItens
                                }
                              </td>
                              <td>
                                {
                                  elPresentations.quantidadeItensBloqueados
                                }
                              </td>
                              <td>
                                {elPresentations.valorCustoMedioItensBloqueados
                                  ? elPresentations.valorCustoMedioItensBloqueados.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency:
                                        'BRL',
                                    },
                                  )
                                  : Number(
                                    0,
                                  ).toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency:
                                        'BRL',
                                    },
                                  )}
                              </td>
                              <td>
                                {elPresentations.valorCustoMedio
                                  ? elPresentations.valorCustoMedio.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency:
                                        'BRL',
                                    },
                                  )
                                  : Number(
                                    0,
                                  ).toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency:
                                        'BRL',
                                    },
                                  )}
                              </td>
                            </tr>
                          );
                        },
                      )}
                      </tbody>
                      <tfoot
                        style={{
                          position: 'sticky',
                          bottom: 0,
                          zIndex: 0,
                        }}
                      >
                      <tr
                        style={{
                          background:
                            '#F0F0F0',
                          fontWeight: 'bold',
                        }}
                      >
                        <td>Total</td>
                        <td>
                          {
                            totalQuantidadeItens
                          }
                        </td>
                        <td>
                          {
                            totalQuantidadeItensBloqueados
                          }
                        </td>
                        <td>
                          {totalValorCustoMedioItensBloqueados.toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency:
                                'BRL',
                            },
                          )}
                        </td>
                        <td>
                          {totalValorCustoMedio.toLocaleString(
                            'pt-br',
                            {
                              style: 'currency',
                              currency:
                                'BRL',
                            },
                          )}
                        </td>
                      </tr>
                      </tfoot>
                    </Table>
                  </tr>
                );
              })}
              </thead>
            </Table>
          </TableHeaderFixedStyled>
        ) : (
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '60vh' }}
          >
            <p className='m-5'>
              A busca não retorna nada, verifique o Planejamento
              ou clique em Processar
            </p>
          </div>
        )}
      </>
    );
  }

  /**
   *
   *
   *
   *
   * ajuste de visualizacao da tabela
   */
  const [groupingVisualization, updateGroupingVisualization] =
    useState<string>('PONTUACAO');
  const [showModalVisualization, updateShowModalVisualization] =
    useState(false);
  const closeVisualization = () => updateShowModalVisualization(false);
  const showColumnVisualization = () => updateShowModalVisualization(true);
  const { register, getValues, setValue } = useForm<{
    groupingChanged?: string;
  }>();

  function ModalVisualization() {
    return (
      <Modal
        show={showModalVisualization}
        onHide={closeVisualization}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Visualização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Card>
              <Card.Body>
                <div>
                  <Form.Label className='me-3'>
                    Agrupamento:
                  </Form.Label>
                </div>
                <div>
                  <Form.Check
                    inline
                    label='Pontuação'
                    type='radio'
                    value='PONTUACAO'
                    {...register('groupingChanged')}
                  />
                  <Form.Check
                    inline
                    label='Base'
                    type='radio'
                    value='BASE'
                    {...register('groupingChanged')}
                  />
                  <Form.Check
                    inline
                    label='Filial'
                    type='radio'
                    value='FILIAL'
                    {...register('groupingChanged')}
                  />
                  <Form.Check
                    inline
                    label='Status'
                    type='radio'
                    value='STATUS'
                    {...register('groupingChanged')}
                  />
                  <Form.Check
                    inline
                    label='Curva'
                    type='radio'
                    value='CURVA'
                    {...register('groupingChanged')}
                  />
                  <Form.Check
                    inline
                    label='Faixa de Preço'
                    type='radio'
                    value='FAIXA_PRECO'
                    {...register('groupingChanged')}
                  />
                  <Form.Check
                    inline
                    label='Curva/Faixa'
                    type='radio'
                    value='CURVA_FAIXA'
                    {...register('groupingChanged')}
                  />
                  <Form.Check
                    inline
                    label='Demanda Atual'
                    type='radio'
                    value='DEMANDA_ATUAL'
                    {...register('groupingChanged')}
                  />
                  <Form.Check
                    inline
                    label='Demanda Projetada'
                    type='radio'
                    value='DEMANDA_PROJETADA'
                    {...register('groupingChanged')}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              updateGroupingVisualization(
                getValues().groupingChanged!,
              );
              setValue(
                'groupingChanged',
                getValues().groupingChanged!,
              );
              handleTable({
                pagination: ``,
                model: {},
                grouping: getValues().groupingChanged!,
              });
              closeVisualization();
            }}
          >
            Aplicar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   *
   *
   *
   *
   *  colunas das tabelas */
  const [columns, updateColumns] = useState<TableDescriptions[]>(
    ColumsTableManagerialAdapter,
  );
  const LOCALSTORAGE_COLUMS =
    getEnv('REACT_APP_LOCALSTORAGE_COLUMS_MANAGERIAL')!;

  /**
   *
   *
   *
   *
   *  reordena / disabilita colunas */
  const [showModalColumns, updateShowModalColumns] = useState(false);
  const closeColumn = () => updateShowModalColumns(false);
  const showColumn = () => updateShowModalColumns(true);

  function handleOrderHideColumns(columns: TableDescriptions[]) {
    updateLocalStorege(
      LOCALSTORAGE_COLUMS,
      columns.sort((a, b) => (a.order > b.order ? 1 : -1)),
    );
    updateColumns(columns.sort((a, b) => (a.order > b.order ? 1 : -1))); //tiago
  }

  function ModalOrderHideColumns() {
    return (
      <Modal show={showModalColumns} onHide={closeColumn} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Reordenar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <p className='mb-0 text-center'>
                <small>
                  Clique no item deixando-o em azul para que
                  apareça na tabela,{' '}
                </small>
              </p>
              <p className='mt-0 text-center'>
                <small>
                  clique e segure no item para arrastá-lo e
                  reordenar as colunas da tabela trocando os
                  itens de lugar{' '}
                </small>
              </p>
              <DragInDropHideOrderColumsTableComponent
                columns={columns}
              />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size='sm'
            variant='primary'
            onClick={async () => {
              closeColumn();
              handleOrderHideColumns(columns);
              //  await handleTable();
            }}
          >
            Aplicar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   *
   *
   *
   *
   * processar
   */
  let modelFilial: any;
  const [showModalProcess, updateShowModalProcess] = useState(false);
  const closeProcess = () => updateShowModalProcess(false);
  const showProcess = () => updateShowModalProcess(true);

  function handleValueFilial(filial: {
    value: number;
    label: string
  }) {
    modelFilial = filial;
  }

  function ModalProcess() {
    return (
      <Modal
        show={showModalProcess}
        onHide={closeProcess}
        fullscreen={'md-down'}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className='ms-3 lead'
            style={{ fontSize: '30px' }}
          >
            Processar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className='mb-1'>
            <Form.Label column>
              Selecione a(s) Filial(s):
            </Form.Label>
            <SelectFilial
              passOnTheValue={handleValueFilial}
              isMulti
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              handleProcessTable({
                pagination: ``,
                model: {},
                grouping: getValues().groupingChanged!,
                modelFilials: modelFilial,
              });
              closeProcess();
            }}
          >
            Gerar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   *
   *
   *
   *
   * controle de estado
   */
  useEffect(() => {
    handleTable({ grouping: groupingVisualization });
    const planningLocalStorage = getLocalStorage(LOCALSTORAGE_PLANNING);
    if (planningLocalStorage)
      updateItemPlanning(planningLocalStorage.length - 1);
  }, []);

  /**
   *
   *
   *
   *
   *
   */
  return (
    <>
      <Headers />

      {loading && <Loading />}

      {!loading && (
        <div style={{ backgroundColor: '#F0F0F0' }}>
          <div className='mx-5'>
            <TitleGroupComponent titlePage={titlePage}>
              <Row>
                <Col className='d-flex align-items-center justify-content-end'>
                  <Button
                    variant='outline-primary'
                    className='d-flex align-items-center'
                    onClick={showPlanning}
                    size='sm'
                  >
                    {itemPlanning > 0 ? (
                      <Badge bg='danger' className='me-2'>
                        {itemPlanning}
                      </Badge>
                    ) : (
                      <></>
                    )}
                    <BsSliders className='me-1' />
                    Planejamento
                  </Button>
                  <Button
                    className='ms-1'
                    variant='outline-primary'
                    onClick={showExport}
                    size='sm'
                  >
                    Exportar
                  </Button>
                  <Button
                    className='ms-1'
                    variant='outline-primary'
                    onClick={showProcess}
                    size='sm'
                  >
                    Processar
                  </Button>
                </Col>
              </Row>
            </TitleGroupComponent>

          </div>
          <div className='mx-3'>
            <Card>
              <Card.Body>
                <div>
                  <Nav
                    className='justify-content-center mt-1'
                    variant='tabs'
                    defaultActiveKey={activeItem}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey='gerencial'
                        onClick={() =>
                          handleActiveItem(
                            'gerencial',
                          )
                        }
                      >
                        Gerencial
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey='curva'
                        onClick={() =>
                          handleActiveItem('curva')
                        }
                      >
                        Curva
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey='pontuacao'
                        onClick={() =>
                          handleActiveItem(
                            'pontuacao',
                          )
                        }
                      >
                        Pontuação
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div>
                  <Row>
                    <Col className='d-flex align-items-center justify-content-between my-2'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <Button
                          variant='outline-primary'
                          onClick={showColumn}
                          size={'sm'}
                          className='d-flex align-items-center me-1'
                        >
                          <BiColumns />
                        </Button>
                        <Button
                          variant='outline-primary'
                          onClick={
                            showColumnVisualization
                          }
                          size={'sm'}
                          className='d-flex align-items-center'
                        >
                          <FaRegEye />
                        </Button>
                      </div>
                      <div>
                        <Badge bg='secondary'>
                          {lastSearch
                            ? `Última Pesquisa: ${lastSearch}`
                            : `Sem dados da última pesquisa`}
                        </Badge>
                        <Badge
                          bg='secondary'
                          className='ms-2'
                        >
                          {lastCharge
                            ? `Última Carga: ${lastCharge}`
                            : `Sem dados da última carga`}
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className='mt-1 '>
                  {activeItem === 'gerencial' && (
                    <CreateTableManagerial />
                  )}
                  {activeItem === 'curva' && (
                    <CreateTableCurve />
                  )}
                  {activeItem === 'pontuacao' && (
                    <CreateTablePunctuation />
                  )}
                </div>
              </Card.Body>
            </Card>
            <ModalVisualization />
            <ModalPlanning />
            <ModalProcess />
            <ModalOrderHideColumns />
            <ModalExport />
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};

export default ManagerialPage;
