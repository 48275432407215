/* eslint-disable no-restricted-globals */
import { Container, Button } from "react-bootstrap"
import { GoLock } from "react-icons/go"
import { useNavigate } from "react-router-dom"

const ErrorAuthenticationTokenPage: React.FC = () => {

    let navigate = useNavigate()

    function handleLogout(): void {
        localStorage.clear()
        navigate("/")
        location.reload()
    }

    return (
        <Container className="d-flex align-items-center justify-content-center vh-100">
            <div>
            <GoLock color={"#DC3444"}  style={{ fontSize: 100 }}/>
                <h1>Ops! Suas Credenciais Expiraram</h1>
                <p>A página ou o arquivo que você está tentando acessar, não esta disponível neste momento.</p>
                Autentique-se novamente na aplicação por favor
                <Button variant="primary" className="ms-3" onClick={handleLogout}>Logar novamente</Button>
            </div>
        </Container>
    )
}

export default ErrorAuthenticationTokenPage