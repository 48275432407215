/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Headers } from 'presentation/components/header/headers-component';
import { ModalCrud } from 'presentation/components/modal/modal-crud-component';
import { EntityField } from 'domain/entity/field/crud/field-crud-entity';
import HeaderCrud from 'presentation/components/header/header-crud-component';
import CreateTableCrud from 'presentation/components/table/table-crud-component';
import CreateFieldModalCrud from 'presentation/components/field/field-modal-component';
import { Footer } from 'presentation/components/footer/footer-component';
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled';
import { SelectCompanie } from 'presentation/components/select/companie-select-component';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { TitleGroupComponent } from '../components/title/title-group-component';

type PropsCrud = {
  titlePage: string;
  entityFields: EntityField[];
  updateEntity?: any;
  valueId?: string;

  filterByCompany?: boolean;
  modelCompany?: SelectOptionInterface;
  handleSelectProvider?: (filial: {
    value: number;
    label: string
  }) => any;

  getAll: () => any;
  getById?: (id: number, model?: any) => any;
  post?: (model: any) => any;
  patch?: (id: number, model: any) => any;
  put?: (id: number, model: any) => any;
  del?: (id: number) => any;

  tableHeader: any[];
  tableBody: any[];

  children?: {
    filterTable?: ReactNode;
    pagination?: ReactNode;
    nevigation?: ReactNode;
  };

  readonly?: boolean;
};

const CrudPage: React.FC<PropsCrud> = ({
                                         titlePage,
                                         entityFields,
                                         valueId,
                                         getAll,
                                         getById,
                                         post,
                                         patch,
                                         put,
                                         del,
                                         tableHeader,
                                         tableBody,
                                         children,
                                         updateEntity,
                                         filterByCompany,
                                         handleSelectProvider,
                                         modelCompany,
                                         readonly = false,
                                       }: PropsCrud) => {
  const valueIdCrud = valueId ? valueId : 'id';

  const [inEditing, updateInEditing] = useState<boolean>(true);

  const [showModalCrud, updateShowModalCrud] = useState<boolean>(false);
  const openModalCrud = () => {
    updateShowModalCrud(true);
  };
  const closeModalCrud = () => {
    updateShowModalCrud(false);
    listAll();
  };

  const changeField = (modelFields: any) => {
    updateEntity(modelFields);
  };

  const searchEntityId = () => {
    return entityFields.filter((value) => value.name === valueIdCrud)[0]
      .valueField as number;
  };

  const onSubmitForm = async () => {
    var model: any = {};
    entityFields.forEach((value) => {
      model[value.name] = value.valueField;
    });

    if (inEditing) await update(model, searchEntityId());
    if (!inEditing) await create(model);

    closeModalCrud();
  };

  const onClickTable = async (id: any, model?: any) => {
    updateInEditing(true);
    await listById(id, model);
    openModalCrud();
  };

  const onClickNew = () => {
    updateInEditing(false);
    clearFields();
  };

  const clearFields = () => {
    updateEntity(
      entityFields.map((value) => {
        value.valueField = '';
        return value;
      }),
    );
  };

  const listAll = async () => {
    await getAll();
  };
  const listById = async (id: any, model?: any) => {
    if (getById) await getById(id, model);
  };
  const create = async (model: any) => {
    if (post) await post(model);
  };
  const remove = async () => {
    if (del) await del(searchEntityId());
    closeModalCrud();
  };
  const update = async (model: any, id: number) => {
    if (put) await put(id, model);
    if (patch) await patch(id, model);
  };

  function CreateSelectCompany() {
    return (
      <div className='m-2'>
        <Row className='d-flex align-items-center justify-content-start'>
          <Col md={1}>
            <Form.Label>Empresa:</Form.Label>
          </Col>
          <Col md={3}>
            <SelectCompanie
              passOnTheValue={handleSelectProvider}
              defaultValue={modelCompany}
            />
          </Col>
        </Row>
      </div>
    );
  }

  useEffect(() => {
    if (filterByCompany === undefined || filterByCompany === false) {
      listAll();
    }
  }, []);

  return (
    <>
      <Headers />
      <div className='mx-5'>
        <TitleGroupComponent titlePage={titlePage}>
          <Row>
            <Col className='d-flex align-items-center justify-content-end'>
              {post ? (
                <Button
                  size='sm'
                  variant='primary'
                  onClick={() => {
                    openModalCrud();
                    onClickNew();
                  }}
                >
                  Novo
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </TitleGroupComponent>
      </div>
      {filterByCompany === true && <CreateSelectCompany />}
      <div className='mx-2'>
        <Card>
          <Card.Body>
            {children?.filterTable ? (
              <>{children?.filterTable}</>
            ) : (
              <></>
            )}

            {children?.nevigation ? (
              <>{children?.nevigation}</>
            ) : (
              <></>
            )}
            {tableBody.length > 0 ? (
              <>
                <div className='mt-1 '>
                  <TableHeaderFixedStyled>
                    <CreateTableCrud
                      tableHeader={tableHeader}
                      tableBody={tableBody}
                      entityFields={entityFields}
                      openModalCrud={onClickTable}
                      valueId={valueIdCrud}
                    />
                  </TableHeaderFixedStyled>
                </div>
              </>
            ) : (
              <>
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{ height: '60vh' }}
                >
                  verifique seus paramentros de busca...
                </div>
              </>
            )}

            {children?.pagination ? (
              <>{children?.pagination}</>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </div>
      <Footer />
      {getById ? (
        <>
          <ModalCrud
            title={titlePage}
            open={openModalCrud}
            close={closeModalCrud}
            isOpen={showModalCrud}
            isEditing={inEditing}
            submit={onSubmitForm}
          >
            <div>
              <CreateFieldModalCrud
                entityFields={entityFields}
                changeField={changeField}
                inEditing={inEditing}
              />
            </div>
            {readonly === false && (
              <div>
                {inEditing ? (
                  put || patch ? (
                    <Button
                      variant='primary'
                      size='sm'
                      type='submit'
                    >
                      Salvar Edição
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <Button
                    variant='primary'
                    size='sm'
                    type='submit'
                  >
                    Salvar
                  </Button>
                )}
                <Button
                  className='ms-1'
                  variant='outline-primary'
                  size='sm'
                  onClick={clearFields}
                >
                  Limpar
                </Button>
                {inEditing ? (
                  del ? (
                    <Button
                      className='ms-1'
                      variant='danger'
                      size='sm'
                      onClick={remove}
                    >
                      Deletar
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </div>
            )}

            <div>
              <p>Validações: </p>
            </div>
          </ModalCrud>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CrudPage;
