import { Loading } from "presentation/components/loading/loading-component";
import { useMemo, useState } from "react";
import { Headers } from 'presentation/components/header/headers-component';
import { TitleGroupComponent } from "presentation/components/title/title-group-component";
import { Row, Col, Button, Card, Form, ListGroup } from "react-bootstrap";
import { SelectCompanie } from "presentation/components/select/companie-select-component";
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { OrdinationInterface } from "domain/interfaces/ordination-interface";
import { OrdinationTransferValues } from "main/adapter/ordinationScreen/ordination-transfer-adapter";
import { OrdinationReceiptValues } from "main/adapter/ordinationScreen/ordination-receipt-adapter";
import { OrdinationGroupInterface } from "domain/interfaces/ordination-groups-interface";
import { OrdinationPhysicalEqValues } from "main/adapter/ordinationScreen/ordination-physical-equalization-adapter";
import { OrdinationVirtualEqValues } from "main/adapter/ordinationScreen/ordination-virtual-equalization-adapter";

type Props = {
  ordinationGroup: OrdinationGroupInterface;
  company?: SelectOptionInterface,
  loading: boolean
  handleSelectProvider: (filial: { value: number; label: string }) => any
  saveOrdinationByCompany: (companyId: number, ordinationGroup: OrdinationGroupInterface) => Promise<any>;
}

const TRANSFER_BASE = "transfer_base"
const TRANSFER = "transfer"
const RECEIPT_BASE = "receipt_base"
const RECEIPT = "receipt"
const PHYSICAL_BASE = "physical_base"
const PHYSICAL = "physical"
const VIRTUAL_BASE = "virtual_base"
const VIRTUAL = "virtual"

const OrdinationPage: React.FC<Props> = ({
  company,
  ordinationGroup,
  loading,
  handleSelectProvider,
  saveOrdinationByCompany
}: Props) => {

  const [transferBaseList, updateTransferBaseList] = useState<OrdinationInterface[]>(OrdinationTransferValues.sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
  const [transferSelectedList, updateTransferSelectedList] = useState<OrdinationInterface[]>([])
  const [receiptBaseList, updateReceiptBaseList] = useState<OrdinationInterface[]>(OrdinationReceiptValues.sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
  const [receiptSelectedList, updateReceiptSelectedList] = useState<OrdinationInterface[]>([])
  const [physicalEqBaseList, updatePhysicalEqBaseList] = useState<OrdinationInterface[]>(OrdinationPhysicalEqValues.sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
  const [physicalEqSelectedList, updatePhysicalEqSelectedList] = useState<OrdinationInterface[]>([])
  const [virtualEqBaseList, updateVirtualEqBaseList] = useState<OrdinationInterface[]>(OrdinationVirtualEqValues.sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
  const [virtualEqSelectedList, updateVirtualEqSelectedList] = useState<OrdinationInterface[]>([])
  const [draggedItem, updateDraggedItem] = useState<string>("")

  const titlePage = 'Ordenação';

  function saveOrdination() {
    let data: OrdinationGroupInterface = {
      transferencia: transferSelectedList,
      recebimento: receiptSelectedList,
      virtual: virtualEqSelectedList,
      fisica: physicalEqSelectedList
    }
    saveOrdinationByCompany(company?.value, data)
  }

  function convertEnumOrdination(lista: OrdinationInterface[], baseList: OrdinationInterface[]) {
    let newList: OrdinationInterface[] = []
    baseList.forEach(value => {
      lista.forEach(listaValue => {
        if ((value.croStrColuna + value.croStrTipordem).trim() === (listaValue.croStrColuna + listaValue.croStrTipordem).trim()) {
          newList = [...newList, value]
        }
      })
    })
    return newList
  }

  function resetLists(filial?: { value: number; label: string }) {
    //Reset Lists
    updateTransferBaseList(OrdinationTransferValues)
    updateTransferSelectedList([])
    updateReceiptBaseList(OrdinationReceiptValues)
    updateReceiptSelectedList([])
    updatePhysicalEqBaseList(OrdinationPhysicalEqValues)
    updatePhysicalEqSelectedList([])
    updateVirtualEqBaseList(OrdinationVirtualEqValues)
    updateVirtualEqSelectedList([])
  }

  useMemo(() => {
    //Fill lists
    let transferList = convertEnumOrdination(ordinationGroup.transferencia, OrdinationTransferValues)
    let receiptList = convertEnumOrdination(ordinationGroup.recebimento, OrdinationReceiptValues)
    let physicalList = convertEnumOrdination(ordinationGroup.fisica, OrdinationPhysicalEqValues)
    let virtualList = convertEnumOrdination(ordinationGroup.virtual, OrdinationVirtualEqValues)

    //TRANSFER LIST
    if ((transferBaseList !== null && transferBaseList !== undefined) &&
      (transferList !== null && transferList !== undefined)) {
      let newSelectedList: OrdinationInterface[] = []
      transferList.forEach(selectedData => {
        let newItem = transferBaseList.find((data) => (data.croStrColuna + data.croStrTipordem) === (selectedData.croStrColuna + selectedData.croStrTipordem))
        if (newItem !== null && newItem !== undefined) {
          newSelectedList = [...newSelectedList, newItem]
        }
      })
      updateTransferSelectedList(newSelectedList)

      let newBaseList: OrdinationInterface[] = OrdinationTransferValues
      transferList.forEach(selectedData => {
        newBaseList = newBaseList.filter((data) => (data.croStrColuna + data.croStrTipordem) !== (selectedData.croStrColuna + selectedData.croStrTipordem))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem))
      })
      updateTransferBaseList(newBaseList)
    }

    //RECEIPT LIST
    if ((receiptBaseList !== null && receiptBaseList !== undefined) &&
      (receiptList !== null && receiptList !== undefined)) {
      let newSelectedList: OrdinationInterface[] = []
      receiptList.forEach(selectedData => {
        let newItem = receiptBaseList.find((data) => (data.croStrColuna + data.croStrTipordem) === (selectedData.croStrColuna + selectedData.croStrTipordem))
        if (newItem !== null && newItem !== undefined) {
          newSelectedList = [...newSelectedList, newItem]
        }
      })
      updateReceiptSelectedList(newSelectedList)

      let newBaseList: OrdinationInterface[] = OrdinationReceiptValues
      receiptList.forEach(selectedData => {
        newBaseList = newBaseList.filter((data) => (data.croStrColuna + data.croStrTipordem) !== (selectedData.croStrColuna + selectedData.croStrTipordem))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem))
      })
      updateReceiptBaseList(newBaseList)
    }

    //PHYSICAL LIST
    if ((physicalEqBaseList !== null && physicalEqBaseList !== undefined) &&
      (physicalList !== null && physicalList !== undefined)) {
      let newSelectedList: OrdinationInterface[] = []
      physicalList.forEach(selectedData => {
        let newItem = physicalEqBaseList.find((data) => (data.croStrColuna + data.croStrTipordem) === (selectedData.croStrColuna + selectedData.croStrTipordem))
        if (newItem !== null && newItem !== undefined) {
          newSelectedList = [...newSelectedList, newItem]
        }
      })
      updatePhysicalEqSelectedList(newSelectedList)

      let newBaseList: OrdinationInterface[] = OrdinationPhysicalEqValues
      physicalList.forEach(selectedData => {
        newBaseList = newBaseList.filter((data) => (data.croStrColuna + data.croStrTipordem) !== (selectedData.croStrColuna + selectedData.croStrTipordem))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem))
      })
      updatePhysicalEqBaseList(newBaseList)
    }

    //VIRTUAL LIST
    if ((virtualEqBaseList !== null && virtualEqBaseList !== undefined) &&
      (virtualList !== null && virtualList !== undefined)) {
      let newSelectedList: OrdinationInterface[] = []
      virtualList.forEach(selectedData => {
        let newItem = virtualEqBaseList.find((data) => (data.croStrColuna + data.croStrTipordem) === (selectedData.croStrColuna + selectedData.croStrTipordem))
        if (newItem !== null && newItem !== undefined) {
          newSelectedList = [...newSelectedList, newItem]
        }
      })
      updateVirtualEqSelectedList(newSelectedList)

      let newBaseList: OrdinationInterface[] = OrdinationVirtualEqValues
      virtualList.forEach(selectedData => {
        newBaseList = newBaseList.filter((data) => (data.croStrColuna + data.croStrTipordem) !== (selectedData.croStrColuna + selectedData.croStrTipordem))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem))
      })
      updateVirtualEqBaseList(newBaseList)
    }
  }, [ordinationGroup])

  const onDragEndReceipt = (result: { source: any; destination: any; draggableId: any; }) => {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      updateDraggedItem("")
      return;
    }
    // ADDING TO SELECTED
    if (source.droppableId === RECEIPT_BASE && destination.droppableId === RECEIPT) {
      let itemToAdd = receiptBaseList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let itemFound = receiptSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let itemRepeatedOrder = receiptSelectedList.find(data => data.croStrColuna === (draggableId.toString().replaceAll("ASC", "").replaceAll("DESC", "")))
      if (itemFound === undefined && itemRepeatedOrder === undefined) {
        let newSelectedList: OrdinationInterface[] = [...receiptSelectedList.slice(0, destination.index), itemToAdd!, ...receiptSelectedList.slice(destination.index)]
        updateReceiptSelectedList(newSelectedList)
        updateReceiptBaseList((list) => list.filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
      if (itemRepeatedOrder !== undefined) {
        updateReceiptSelectedList((list) => [...list, itemToAdd!].filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemRepeatedOrder?.croStrColuna}${itemRepeatedOrder?.croStrTipordem}`)))
        updateReceiptBaseList((list) => [...list, itemRepeatedOrder!].filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
    }
    // ADDING TO BASE
    if (source.droppableId === RECEIPT && destination.droppableId === RECEIPT_BASE) {
      let itemToAdd = receiptSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let newSelectedList = receiptSelectedList.filter(data => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
      updateReceiptSelectedList(newSelectedList)
      let itemFound = receiptBaseList.find(data => (`${data.croStrColuna}${data.croStrTipordem}`) === (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
      if (itemFound === undefined) {
        updateReceiptBaseList((list) => [...list, itemToAdd!].sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
    }
    // dropped in the same list
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === RECEIPT) {
        let itemFound = receiptSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
        let newSelectedList: OrdinationInterface[] = receiptSelectedList.filter((data) => (data.croStrColuna + data.croStrTipordem) !== (draggableId))
        newSelectedList = [...newSelectedList.slice(0, destination.index), itemFound!, ...newSelectedList.slice(destination.index)]
        updateReceiptSelectedList(newSelectedList)
      }
    }
    updateDraggedItem("")
  }

  const onDragEndTransfer = (result: { source: any; destination: any; draggableId: any; }) => {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      updateDraggedItem("")
      return;
    }
    // ADDING TO SELECTED
    if (source.droppableId === TRANSFER_BASE && destination.droppableId === TRANSFER) {
      let itemToAdd = transferBaseList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let itemFound = transferSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let itemRepeatedOrder = transferSelectedList.find(data => data.croStrColuna === (draggableId.toString().replaceAll("ASC", "").replaceAll("DESC", "")))
      if (itemFound === undefined && itemRepeatedOrder === undefined) {
        let newSelectedList: OrdinationInterface[] = [...transferSelectedList.slice(0, destination.index), itemToAdd!, ...transferSelectedList.slice(destination.index)]
        updateTransferSelectedList(newSelectedList)
        updateTransferBaseList((list) => list.filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
      if (itemRepeatedOrder !== undefined) {
        updateTransferSelectedList((list) => [...list, itemToAdd!].filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemRepeatedOrder?.croStrColuna}${itemRepeatedOrder?.croStrTipordem}`)))
        updateTransferBaseList((list) => [...list, itemRepeatedOrder!].filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
    }
    // ADDING TO BASE
    if (source.droppableId === TRANSFER && destination.droppableId === TRANSFER_BASE) {
      let itemToAdd = transferSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let newSelectedList = transferSelectedList.filter(data => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
      updateTransferSelectedList(newSelectedList)
      let itemFound = transferBaseList.find(data => (`${data.croStrColuna}${data.croStrTipordem}`) === (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
      if (itemFound === undefined) {
        updateTransferBaseList((list) => [...list, itemToAdd!].sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
    }
    // dropped in the same list
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === TRANSFER) {
        let itemFound = transferSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
        let newSelectedList: OrdinationInterface[] = transferSelectedList.filter((data) => (data.croStrColuna + data.croStrTipordem) !== (draggableId))
        newSelectedList = [...newSelectedList.slice(0, destination.index), itemFound!, ...newSelectedList.slice(destination.index)]
        updateTransferSelectedList(newSelectedList)
      }
    }
    updateDraggedItem("")
  }

  const onDragEndPhysical = (result: { source: any; destination: any; draggableId: any; }) => {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      updateDraggedItem("")
      return;
    }
    // ADDING TO SELECTED
    if (source.droppableId === PHYSICAL_BASE && destination.droppableId === PHYSICAL) {
      let itemToAdd = physicalEqBaseList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let itemFound = physicalEqSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let itemRepeatedOrder = physicalEqSelectedList.find(data => data.croStrColuna === (draggableId.toString().replaceAll("ASC", "").replaceAll("DESC", "")))
      if (itemFound === undefined && itemRepeatedOrder === undefined) {
        let newSelectedList: OrdinationInterface[] = [...physicalEqSelectedList.slice(0, destination.index), itemToAdd!, ...physicalEqSelectedList.slice(destination.index)]
        updatePhysicalEqSelectedList(newSelectedList)
        updatePhysicalEqBaseList((list) => list.filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
      if (itemRepeatedOrder !== undefined) {
        updatePhysicalEqSelectedList((list) => [...list, itemToAdd!].filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemRepeatedOrder?.croStrColuna}${itemRepeatedOrder?.croStrTipordem}`)))
        updatePhysicalEqBaseList((list) => [...list, itemRepeatedOrder!].filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
    }
    // ADDING TO BASE
    if (source.droppableId === PHYSICAL && destination.droppableId === PHYSICAL_BASE) {
      let itemToAdd = physicalEqSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let newSelectedList = physicalEqSelectedList.filter(data => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
      updatePhysicalEqSelectedList(newSelectedList)
      let itemFound = transferBaseList.find(data => (`${data.croStrColuna}${data.croStrTipordem}`) === (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
      if (itemFound === undefined) {
        updatePhysicalEqBaseList((list) => [...list, itemToAdd!].sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
    }
    // dropped in the same list
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === PHYSICAL) {
        let itemFound = physicalEqSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
        let newSelectedList: OrdinationInterface[] = physicalEqSelectedList.filter((data) => (data.croStrColuna + data.croStrTipordem) !== (draggableId))
        newSelectedList = [...newSelectedList.slice(0, destination.index), itemFound!, ...newSelectedList.slice(destination.index)]
        updatePhysicalEqSelectedList(newSelectedList)
      }
    }
    updateDraggedItem("")
  }

  const onDragEndVirtual = (result: { source: any; destination: any; draggableId: any; }) => {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      updateDraggedItem("")
      return;
    }
    // ADDING TO SELECTED
    if (source.droppableId === VIRTUAL_BASE && destination.droppableId === VIRTUAL) {
      let itemToAdd = virtualEqBaseList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let itemFound = virtualEqSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let itemRepeatedOrder = virtualEqSelectedList.find(data => data.croStrColuna === (draggableId.toString().replaceAll("ASC", "").replaceAll("DESC", "")))
      if (itemFound === undefined && itemRepeatedOrder === undefined) {
        let newSelectedList: OrdinationInterface[] = [...virtualEqSelectedList.slice(0, destination.index), itemToAdd!, ...virtualEqSelectedList.slice(destination.index)]
        updateVirtualEqSelectedList(newSelectedList)
        updateVirtualEqBaseList((list) => list.filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
      if (itemRepeatedOrder !== undefined) {
        updateVirtualEqSelectedList((list) => [...list, itemToAdd!].filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemRepeatedOrder?.croStrColuna}${itemRepeatedOrder?.croStrTipordem}`)))
        updateVirtualEqBaseList((list) => [...list, itemRepeatedOrder!].filter((data) => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
          .sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
    }
    // ADDING TO BASE
    if (source.droppableId === VIRTUAL && destination.droppableId === VIRTUAL_BASE) {
      let itemToAdd = virtualEqSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
      let newSelectedList = virtualEqSelectedList.filter(data => (`${data.croStrColuna}${data.croStrTipordem}`) !== (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
      updateVirtualEqSelectedList(newSelectedList)
      let itemFound = transferBaseList.find(data => (`${data.croStrColuna}${data.croStrTipordem}`) === (`${itemToAdd?.croStrColuna}${itemToAdd?.croStrTipordem}`))
      if (itemFound === undefined) {
        updateVirtualEqBaseList((list) => [...list, itemToAdd!].sort((a, b) => (a.croStrColuna + a.croStrTipordem).localeCompare(b.croStrColuna + b.croStrTipordem)))
      }
    }
    // dropped in the same list
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === VIRTUAL) {
        let itemFound = virtualEqSelectedList.find(data => (data.croStrColuna + data.croStrTipordem) === draggableId)
        let newSelectedList: OrdinationInterface[] = virtualEqSelectedList.filter((data) => (data.croStrColuna + data.croStrTipordem) !== (draggableId))
        newSelectedList = [...newSelectedList.slice(0, destination.index), itemFound!, ...newSelectedList.slice(destination.index)]
        updateVirtualEqSelectedList(newSelectedList)
      }
    }
    updateDraggedItem("")
  }

  const onDragStart = (result: { source: any; draggableId: any; }) => {
    const { source, draggableId } = result;
    updateDraggedItem(draggableId.toUpperCase().replaceAll("ASC", "").replaceAll("DESC", "") + source.droppableId)
  }

  const CreateSelectCompany = useMemo(() => {
    return (
      <div className=''>
        <Row className='d-flex align-items-center justify-content-start'>
          <Col md={1}>
            <Form.Label>Empresa:</Form.Label>
          </Col>
          <Col md={3}>
            <SelectCompanie
              passOnTheValue={(filial?: { value: number; label: string }) => { if (filial !== undefined) { handleSelectProvider(filial) }; resetLists() }}
              defaultValue={company}
            />
          </Col>
        </Row>
      </div>
    );
  }, [company, handleSelectProvider])

  return (
    <>
      <Headers />
      {loading ? <Loading />
        :
        <div className='mx-3'>
          <TitleGroupComponent titlePage={titlePage}>
            <Row>
              <Col className='d-flex align-items-center justify-content-end'>
                <Button variant='primary' type='submit' size='sm' onClick={() => { saveOrdination() }}>
                  Salvar
                </Button>
              </Col>
            </Row>
          </TitleGroupComponent>
          {CreateSelectCompany}
          <div className='mt-3'>
            <Card>
              <Card.Body>
                {company ?
                  <>
                    <Row>
                      <Col md={6}>
                        <Card>
                          <div className="d-flex align-items-center justify-content-center"
                            style={{ backgroundColor: "var(--bs-border-color-translucent)" }}>
                            Ordenação de Recebimento
                          </div>
                          <DragDropContext onDragEnd={onDragEndReceipt} onDragStart={onDragStart}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-1">Campos:</div>
                                <ListGroup style={{ background: "#F0F0F0", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={RECEIPT_BASE}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {receiptBaseList.map((item, index) => (
                                          <Draggable
                                            key={item.croStrColuna + item.croStrTipordem}
                                            draggableId={item.croStrColuna + item.croStrTipordem}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item style={{ borderRadius: "0.375rem" }}
                                                  key={item.croStrColuna + item.croStrTipordem} >
                                                  {`${item.descricao} ${item.croStrTipordem}`}
                                                </ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {receiptBaseList.length}</div>
                              </Col>
                              <Col md={1}>
                              </Col>
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-1">Ordens:</div>
                                <ListGroup style={{ background: "#F0F0F0", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={RECEIPT}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {receiptSelectedList.map((item, index) => (
                                          <Draggable
                                            key={item.croStrColuna + item.croStrTipordem}
                                            draggableId={item.croStrColuna + item.croStrTipordem}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item
                                                  style={((item.croStrColuna.toUpperCase() + RECEIPT_BASE === draggedItem)) ?
                                                    { borderRadius: "0.375rem", background: "#CADCFB" } : { borderRadius: "0.375rem" }}
                                                  key={item.croStrColuna + item.croStrTipordem} >
                                                  {`${item.descricao} ${item.croStrTipordem}`}
                                                </ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {receiptSelectedList.length}</div>
                              </Col>
                            </Card.Body>
                          </DragDropContext>
                        </Card>
                      </Col>
                      <Col md={6}>
                        <Card>
                          <div className="d-flex align-items-center justify-content-center"
                            style={{ backgroundColor: "var(--bs-border-color-translucent)" }}>
                            Ordenação de Transferência
                          </div>
                          <DragDropContext onDragEnd={onDragEndTransfer} onDragStart={onDragStart}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-1">Campos:</div>
                                <ListGroup style={{ background: "#F0F0F0", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={TRANSFER_BASE}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {transferBaseList.map((item, index) => (
                                          <Draggable
                                            key={item.croStrColuna + item.croStrTipordem}
                                            draggableId={item.croStrColuna + item.croStrTipordem}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item style={{ borderRadius: "0.375rem" }}
                                                  key={item.croStrColuna + item.croStrTipordem} >
                                                  {`${item.descricao} ${item.croStrTipordem}`}
                                                </ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {transferBaseList.length}</div>
                              </Col>
                              <Col md={1}>
                              </Col>
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-1">Ordens:</div>
                                <ListGroup style={{ background: "#F0F0F0", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={TRANSFER}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {transferSelectedList.map((item, index) => (
                                          <Draggable
                                            key={item.croStrColuna + item.croStrTipordem}
                                            draggableId={item.croStrColuna + item.croStrTipordem}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item
                                                  style={((item.croStrColuna.toUpperCase() + TRANSFER_BASE === draggedItem)) ?
                                                    { borderRadius: "0.375rem", background: "#CADCFB" } : { borderRadius: "0.375rem" }}
                                                  key={item.croStrColuna + item.croStrTipordem} >
                                                  {`${item.descricao} ${item.croStrTipordem}`}
                                                </ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {transferSelectedList.length}</div>
                              </Col>
                            </Card.Body>
                          </DragDropContext>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={6}>
                        <Card>
                          <div className="d-flex align-items-center justify-content-center"
                            style={{ backgroundColor: "var(--bs-border-color-translucent)" }}>
                            Status Fora da Equalização Física
                          </div>
                          <DragDropContext onDragEnd={onDragEndPhysical} onDragStart={onDragStart}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-1">Campos:</div>
                                <ListGroup style={{ background: "#F0F0F0", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={PHYSICAL_BASE}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {physicalEqBaseList.map((item, index) => (
                                          <Draggable
                                            key={item.croStrColuna + item.croStrTipordem}
                                            draggableId={item.croStrColuna + item.croStrTipordem}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item style={{ borderRadius: "0.375rem" }}
                                                  key={item.croStrColuna + item.croStrTipordem} >
                                                  {`${item.croStrColuna} ${item.croStrTipordem}`}
                                                </ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {physicalEqBaseList.length}</div>
                              </Col>
                              <Col md={1}>
                              </Col>
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-1">Ordens:</div>
                                <ListGroup style={{ background: "#F0F0F0", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={PHYSICAL}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {physicalEqSelectedList.map((item, index) => (
                                          <Draggable
                                            key={item.croStrColuna + item.croStrTipordem}
                                            draggableId={item.croStrColuna + item.croStrTipordem}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item
                                                  style={{ borderRadius: "0.375rem" }}
                                                  key={item.croStrColuna + item.croStrTipordem} >
                                                  {`${item.croStrColuna} ${item.croStrTipordem}`}
                                                </ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {physicalEqSelectedList.length}</div>
                              </Col>
                            </Card.Body>
                          </DragDropContext>
                        </Card>
                      </Col>
                      <Col md={6}>
                        <Card>
                          <div className="d-flex align-items-center justify-content-center"
                            style={{ backgroundColor: "var(--bs-border-color-translucent)" }}>
                            Status Fora da Equalização Virtual
                          </div>
                          <DragDropContext onDragEnd={onDragEndVirtual} onDragStart={onDragStart}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-1">Campos:</div>
                                <ListGroup style={{ background: "#F0F0F0", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={VIRTUAL_BASE}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {virtualEqBaseList.map((item, index) => (
                                          <Draggable
                                            key={item.croStrColuna + item.croStrTipordem}
                                            draggableId={item.croStrColuna + item.croStrTipordem}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item style={{ borderRadius: "0.375rem" }}
                                                  key={item.croStrColuna + item.croStrTipordem} >
                                                  {`${item.descricao} ${item.croStrTipordem}`}
                                                </ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {virtualEqBaseList.length}</div>
                              </Col>
                              <Col md={1}>
                              </Col>
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-1">Ordens:</div>
                                <ListGroup style={{ background: "#F0F0F0", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={VIRTUAL}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {virtualEqSelectedList.map((item, index) => (
                                          <Draggable
                                            key={item.croStrColuna + item.croStrTipordem}
                                            draggableId={item.croStrColuna + item.croStrTipordem}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item
                                                  style={{ borderRadius: "0.375rem" }}
                                                  key={item.croStrColuna + item.croStrTipordem} >
                                                  {`${item.descricao} ${item.croStrTipordem}`}
                                                </ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {virtualEqSelectedList.length}</div>
                              </Col>
                            </Card.Body>
                          </DragDropContext>
                        </Card>
                      </Col>
                    </Row>
                  </>
                  :
                  <div className="d-flex align-items-center justify-content-center m-1">Selecione uma empresa para visualizar os dados.</div>
                }
              </Card.Body>
            </Card>
          </div>
        </div>
      }
    </>
  );
}

export default OrdinationPage;

