import { TableDescriptions } from "domain/interfaces/table-interface"

export const ColumsTableEqualizationAdapter: TableDescriptions[] = [
    { align: "left", order: 1, id: 1, dataField: "alterado", text: "Alterado", hidden: false, fixed: true },
    
    { ordination: { ordinationName: "FILIAL", ordinationValue: "" }, align: "center", order: 2, id: 2, dataField: "siglaFilial", text: "Fil Rec", hidden: false },
    { ordination: { ordinationName: "BASE", ordinationValue: "" }, align: "center", order: 3, id: 3, dataField: "base", text: "Base", hidden: false },
    { ordination: { ordinationName: "CODIGO", ordinationValue: "" }, align: "center", order: 4, id: 4, dataField: "codigo", text: "Sufixo", hidden: false },
    { align: "right", order: 5, id: 5, dataField: "qme", text: "Qme", hidden: false },
    { ordination: { ordinationName: "QUANTIDADE_COMPRA", ordinationValue: "" }, align: "right", order: 6, id: 6, dataField: "quantidadeCompra", text: "Qtd Com", hidden: false },
    { align: "center", order: 7, id: 7, dataField: "valorTransferencia", text: "Vlr Transferência", hidden: false, numberFormat: "currency" },
    { align: "center", order: 8, id: 8, dataField: "quantidadeTransferencia", text: "Qtd Total Excesso", hidden: true },
    { ordination: { ordinationName: "MEDIA", ordinationValue: "" }, align: "right", order: 9, id: 9, dataField: "media", text: "Média", hidden: false },
    { ordination: { ordinationName: "PARAMETRO_MES", ordinationValue: "" }, align: "right", order: 10, id: 10, dataField: "parametroMes", text: "PMes", hidden: false },
    { ordination: { ordinationName: "CUSTO_MEDIO", ordinationValue: "" }, align: "right", order: 11, id: 11, dataField: "custoMedio", text: "Cst Méd", hidden: false, numberFormat: "currency" },
    { ordination: { ordinationName: "ESTOQUE_ATUAL", ordinationValue: "" }, align: "right", order: 12, id: 12, dataField: "estoqueAtual", text: "Est", hidden: false },
    { ordination: { ordinationName: "CURVA", ordinationValue: "" }, align: "left", order: 13, id: 13, dataField: "curva", text: "Cvf", hidden: false },
    { ordination: { ordinationName: "NOVA_CURVA", ordinationValue: "" }, align: "left", order: 14, id: 14, dataField: "novaCurva", text: "NCvf", hidden: false },
    { ordination: { ordinationName: "STATUS", ordinationValue: "" }, align: "left", order: 15, id: 15, dataField: "status", text: "Status", hidden: false },
    { ordination: { ordinationName: "ESTOQUE_PENDENTE", ordinationValue: "" }, align: "right", order: 16, id: 16, dataField: "estoquePendente", text: "Pen", hidden: false },
    { ordination: { ordinationName: "PONTUACAO", ordinationValue: "" }, align: "right", order: 17, id:17, dataField: "pontuacao", text: "Ppt", hidden: false },
    { align: "right", order: 18, id: 18, dataField: "saldo", text: "Saldo", hidden: false },
    { align: "center", order: 19, id: 19, dataField: "filialTransferencia", text: "Fil de Transf", hidden: false},
    { align: "center", order: 20, id: 20, dataField: "quantidadeFilial", text: "Qtd Fil de Transf", hidden: false },
    { align: "right", order: 21, id: 21, dataField: "estoqueTransferenciaFilial", text: "Est Fil de Transf", hidden: false },
    { align: "right", order: 22, id: 22, dataField: "valorTransferenciaFilial", text: "Vlr Fil de Transf.", hidden: false, numberFormat: "currency" },
    { align: "right", order: 23, id: 23, dataField: "custoMedioTransferenciaFilial", text: "Cst Méd Filial de Transf.", hidden: false, numberFormat: "currency" },
    { align: "right", order: 24, id: 24, dataField: "curvaTransferenciaFilial", text: "Curva Filial de Transf.", hidden: false },
    { align: "center", order: 25, id: 25, dataField: "dataUltimaVendaTransferenciaFilial", text: "Data Ult Ven Filial de Transf.", hidden: false },
    { align: "center", order: 26, id: 26, dataField: "bloqueado", text: "Bloqueado", hidden: false },
    { ordination: { ordinationName: "VALOR_COMPRA", ordinationValue: "" }, align: "right", order: 27, id: 27, dataField: "valorCompra", text: "Vlr Comp", hidden: true, numberFormat: "currency" },
    { ordination: { ordinationName: "PRECO_VENDA", ordinationValue: "" }, align: "right", order: 28, id: 28, dataField: "precoVenda", text: "Prç Vnd", hidden: true, numberFormat: "currency" },
    { ordination: { ordinationName: "PRECO_REPOSICAO", ordinationValue: "" }, align: "right", order: 29, id: 29, dataField: "precoReposicao", text: "Prç Rep", hidden: true, numberFormat: "currency" },
    { ordination: { ordinationName: "PRECO_GARANTIA", ordinationValue: "" }, align: "right", order: 30, id: 30, dataField: "precoGarantia", text: "Prç Gar", hidden: true, numberFormat: "currency" },
    { ordination: { ordinationName: "DATA_ULTIMA_VENDA", ordinationValue: "" }, align: "center", order: 31, id: 31, dataField: "dataUltimaVenda", text: "Data Ult Ven", hidden: true },
    { ordination: { ordinationName: "DATA_ULTIMA_COMPRA", ordinationValue: "" }, align: "center", order: 32, id: 32, dataField: "dataUltimaCompra", text: "Data Ult Com", hidden: true },
    { ordination: { ordinationName: "LOCALIZACAO", ordinationValue: "" }, align: "center", order: 33, id: 33, dataField: "localizacao", text: "Locação", hidden: true },
    { ordination: { ordinationName: "ULTIMA_COMPRA", ordinationValue: "" }, align: "center", order: 34, id: 34, dataField: "ultimaCompra", text: "Ult Ven", hidden: true },
    { ordination: { ordinationName: "ULTIMA_VENDA", ordinationValue: "" }, align: "center", order: 35, id: 35, dataField: "ultimaVenda", text: "Ult Com", hidden: true },
    { ordination: { ordinationName: "CUSTO_MEDIO_PENDENCIA", ordinationValue: "" }, align: "right", order: 36, id: 36, dataField: "custoMedioPendencia", text: "Custo Médio Pendência", hidden: true, numberFormat: "currency" },
    { ordination: { ordinationName: "ID", ordinationValue: "" }, align: "right", order: 37, id: 37, dataField: "id", text: "id", hidden: false, fixed: true},
    { ordination: { ordinationName: "NOME", ordinationValue: "" }, align: "left", order: 38, id: 38, dataField: "nome", text: "Descrição", hidden: true },
    { align: "center", order: 39, id: 39, dataField: "demandas", text: "Demandas", hidden: true },
    { align: "right", order: 40, id: 40, dataField: "remanejamentos", text: "Remanejamentos", hidden: false, fixed: true },
    { align: "right", order: 41, id: 41, dataField: "valorPedido", text: "Vlr Ped", hidden: true, typeField: "monetary", numberFormat: "currency" },
    { align: "right", order: 42, id: 42, dataField: "quantidadeNovoPedido", text: "Qtd Nv Pedido", hidden: true },
]