/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';

import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap';

import { CrudInterface } from 'domain/entity/interface/crud-interface';

import { UseAlert } from 'presentation/hook/alert-hook';
import { UseAlertModal } from 'presentation/hook/alert-modal-hook';

import { SCDesctiption } from 'presentation/style/typography/titlePage';

import { Footer } from 'presentation/components/footer/footer-component';
import { Headers } from 'presentation/components/header/headers-component';
import { Loading } from 'presentation/components/loading/loading-component';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import {
  FilialProviderEntity,
  FilialProviderInputs,
} from 'domain/entity/filial-provider-entity';
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled';
import {
  getParameterUrlByName,
  parseLinkHeader,
} from 'main/helper/request-helper';
import { RestUseCase } from 'data/useCase/rest-usecase';
import { ParseDateToBrV2 } from 'main/helper/format-date-helper';
import { getEnv } from 'main/helper/window-helper';
import { TitlePageComponent } from '../../components/title/title-page-component';
import { TitleGroupComponent } from '../../components/title/title-group-component';

interface LinkHeader {
  first: string;
  last: string;
  next: string;
  prev: string;
}

type Props = {
  modelMethods: CrudInterface<FilialProviderEntity>;
  ufOption: SelectOptionInterface[];
  filialOption: SelectOptionInterface[];
  selectProvider: SelectOptionInterface[];
};

const tableDescriptions: {
  label: string;
  align: 'center' | 'left' | 'right';
}[] = [
  { label: 'ID', align: 'center' },
  { label: 'Código Setor', align: 'center' },
  { label: 'Código da Filial na Fornecedora', align: 'center' },
  { label: 'Nome Fantasia', align: 'left' },
  { label: 'Cidade', align: 'left' },
  { label: 'UF', align: 'left' },
  { label: 'Início Vigência', align: 'center' },
  { label: 'Fim Vigência', align: 'center' },
  { label: 'HoldBack', align: 'center' },
  { label: 'Percentual Deságio', align: 'right' },
  { label: 'Valor Máximo Scrap', align: 'right' },
  { label: 'Valor Scrap Utilizado', align: 'right' },
];

const titlePage = 'Cadastrar Filial Fornecedor';

const FilialProviderPage: React.FC<Props> = ({
                                               modelMethods,
                                               ufOption,
                                               filialOption,
                                               selectProvider,
                                             }: Props) => {
  /**
   *
   * ALERT / ALERT MODAL
   *
   */
  const { showAlert } = UseAlert();
  const { messageAlertModal, showAlertModal } = UseAlertModal();
  const cleanAlertModal = () =>
    showAlertModal({ show: false, content: '', color: '' });

  function AlertModal() {
    if (messageAlertModal?.show) {
      return (
        <div
          className={
            'alert alert-' +
            messageAlertModal.color +
            ' fade show mx-5 mt-2 alert-dismissible'
          }
          role='alert'
        >
          {messageAlertModal.content}
          {!messageAlertModal.time && (
            <button
              type='button'
              className='ms-1 btn-close btn-sm'
              data-bs-dismiss='alert'
              aria-label='Close'
              onClick={() => cleanAlertModal()}
            ></button>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  /**
   *
   * FORM HOOK
   *
   */
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm<FilialProviderInputs>();

  function clearField(): void {
    // LIMPA OS CAMPOS
    reset({
      fornecedor: 0,
      codigoFilialFornecedor: '',
      nome: '',
      codigoSetor: '',
      cidade: '',
      inicioVigencia: '',
      fimVigencia: '',
      percentualDesagio: 0,
      valorScrapUtilizado: 0,
      valorMaximoScrap: 0,
      holdBack: 0,
      uf: '',
    });
  }

  /**
   *
   * INIT
   *
   */
  const [providerSelect, updateProviderSelect] =
    useState<SelectOptionInterface[]>(selectProvider);
  const [provider, updateProvider] = useState<SelectOptionInterface>();
  useEffect(() => {
    updateIdProvider(provider?.value);
    handleListModel({
      pagination: `?page=${0}&size=${totalPageSize}`,
      page: 0,
      idProvider: provider?.value,
    });
  }, [provider]);

  useEffect(() => {
    if (selectProvider) {
      updateProviderSelect(selectProvider);
      updateProvider(selectProvider[0]);
    }
  }, [selectProvider]);

  const onChangeSelectProvider = (
    providerOptionValue: SelectOptionInterface,
  ) => {
    updateProvider(providerOptionValue);
  };

  /**
   *
   * ERROR
   *
   */
  const [listError, updateListError] = useState<string[]>([]);

  /**
   *
   * CRUD MODEL / MODELS
   *
   */
  const [listModel, updateListModel] = useState<FilialProviderEntity[]>([]);
  const [idProvider, updateIdProvider] = useState<number>();

  async function handleListModel(params?: {
    pagination?: string;
    page: number;
    idProvider?: number;
  }): Promise<void> {
    let pagePagination;

    updateIdProvider(params?.idProvider);

    var modelRequest = {
      pagination: params === undefined ? 0 : params?.pagination,
      idProvider: params === undefined ? idProvider : params?.idProvider,
    };

    updateLoading(true);

    if (params) {
      pagePagination = params.page;
    } else {
      pagePagination = 0;
    }

    let response;
    response = await modelMethods.getAll(modelRequest);

    /** links da paginacao */
    if (response.headers['link']) {
      let links: LinkHeader = parseLinkHeader(
        response.headers['link'],
      ) as any;

      updateFirstPagination(links.first);
      updateLastPagination(links.last);
      updateNextPagination(links.next);
      updatePrevPagination(links.prev);

      setValuePaginations(
        pagePagination,
        parseInt(getParameterUrlByName('page', links.first)!),
        parseInt(getParameterUrlByName('page', links.last)!),
      );
    }

    // BUSCA LISTAGEM DE MODELS
    if (response.statusCode === 200) {
      updateListModel(response.body);
    }

    updateLoading(false);
    handleCloseModal();
  }

  async function handleModel(id: number): Promise<void> {
    // BUSCA MODEL
    await modelMethods
      .getById(id)
      .then((response) => {
        // SETAR OUTROS CAMPOS
        setValue('id', response.id!);
        setValue('fornecedor', response.fornecedor);
        setValue(
          'codigoFilialFornecedor',
          response.codigoFilialFornecedor,
        );
        setValue('nome', response.nome);
        setValue('codigoSetor', response.codigoSetor);
        setValue('cidade', response.cidade);
        setValue('inicioVigencia', response.inicioVigencia);
        setValue('fimVigencia', response.fimVigencia);
        setValue('percentualDesagio', response.percentualDesagio);
        setValue('valorScrapUtilizado', response.valorScrapUtilizado);
        setValue('valorMaximoScrap', response.valorMaximoScrap);
        setValue('holdBack', response.holdBack);
        setValue('uf', response.uf);

        handleSelectSetores(response.fornecedor);
      })
      .catch((error) => updateListError([...listError, error]));
  }

  const [setoresOption, updateSetoresOption] =
    useState<SelectOptionInterface[]>();
  const ENDPOINT_SETORES = getEnv('REACT_APP_END_POINT_SECTOR')!;
  const handleSelectSetores = async (id: number) => {
    await new RestUseCase(`${ENDPOINT_SETORES}/?idFornecedor=${id}`)
      .Get()
      .then((response) => {
        updateSetoresOption(
          response.body.map((value: any) => {
            return {
              value: value.id,
              label: value.nome,
              selected: false,
            };
          }),
        );
      });
  };

  async function handleCreteOrEdit(
    modelFields: FilialProviderInputs,
  ): Promise<void> {
    // EDITA OU CRIA
    const model: FilialProviderInputs = {
      fornecedor: modelFields.fornecedor,
      codigoFilialFornecedor: modelFields.codigoFilialFornecedor,
      nome: modelFields.nome,
      codigoSetor: modelFields.codigoSetor,
      cidade: modelFields.cidade,
      inicioVigencia: modelFields.inicioVigencia,
      fimVigencia: modelFields.fimVigencia,
      percentualDesagio: modelFields.percentualDesagio,
      valorScrapUtilizado: modelFields.valorScrapUtilizado,
      valorMaximoScrap: modelFields.valorMaximoScrap,
      holdBack: modelFields.holdBack,
      uf: modelFields.uf,
    };

    var modelRequst = { idProvider: idProvider };

    if (inEditing === true)
      await modelMethods
        .edit(model, modelFields.id!)
        .then(() =>
          showAlert({
            show: true,
            content: 'Editado com sucesso',
            color: 'success',
            time: 5000,
          }),
        )
        .catch((error) => updateListError([...listError, error]));
    else
      await modelMethods
        .create(model, modelRequst)
        .then(() =>
          showAlert({
            show: true,
            content: 'Salvo com sucesso',
            color: 'success',
            time: 5000,
          }),
        )
        .catch((error) => updateListError([...listError, error]));

    const size: string = getParameterUrlByName('size', prevPagination)!;
    await handleListModel({
      pagination: `&page=${0}&size=${size}`,
      page: 0,
      idProvider: idProvider,
    });
  }

  /**
   *
   * MODAL
   *
   */
  const [inEditing, updateInEditing] = useState<boolean>(false);
  const [openModal, updateOpenModal] = useState<boolean>(false);

  async function handleModalEdit(id: number): Promise<void> {
    // ABRE MODAL PARA EDICAO
    await handleModel(id);
    updateInEditing(true);
    updateOpenModal(true);
  }

  function handleModalCreate() {
    // ABRE MODAL PARA CRIACAO
    updateLoading(false);
    updateOpenModal(true);
    updateInEditing(false);

    if (providerSelect.length > 0) {
      handleSelectSetores(providerSelect[0].value);
    }
  }

  function handleCloseModal() {
    // FECHA MODAL
    clearField();
    updateInEditing(false);
    updateOpenModal(false);
  }

  /**
   *
   * LOADING
   *
   */
  const [loading, updateLoading] = useState<boolean>(false);

  /**
   *
   *
   *
   *
   *  paginacao */
  var totalPageSize: number = 50;
  const [firstPagination, updateFirstPagination] = useState<string>('');
  const [lastPagination, updateLastPagination] = useState<string>('');
  const [nextPagination, updateNextPagination] = useState<string>('');
  const [prevPagination, updatePrevPagination] = useState<string>('');
  const [paginationItemList, updatePaginationItemList] =
    useState<{
      item: number;
      active: boolean
    }[]>();
  const setValuePaginations = (
    paginationsActive: number,
    prev: number,
    next: number,
  ) => {
    let modelPaginationList: any[] = [];
    for (
      let index = paginationsActive;
      index < 6 + paginationsActive;
      index++
    ) {
      if (!(index > next))
        modelPaginationList.push({
          item: index,
          active: index === paginationsActive ? true : false,
        });
    }
    updatePaginationItemList(modelPaginationList);
  };
  const onClickItemPagination = async (pagination: any) => {
    totalPageSize = Number(getParameterUrlByName('size', nextPagination))!;
    handleListModel({
      pagination: `?page=${pagination.item}&size=${totalPageSize}`,
      page: parseInt(pagination.item),
      idProvider: idProvider,
    });
  };
  const onClickFirstPagination = async () => {
    const size: string = getParameterUrlByName('size', firstPagination)!;
    handleListModel({
      pagination: `?page=${0}&size=${size}`,
      page: 0,
      idProvider: idProvider,
    });
  };
  const onClickLastPagination = async () => {
    const page: string = getParameterUrlByName('page', lastPagination)!;
    const size: string = getParameterUrlByName('size', lastPagination)!;
    handleListModel({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      idProvider: idProvider,
    });
  };
  const onClickNextPagination = async () => {
    const page: string = getParameterUrlByName('page', nextPagination)!;
    const size: string = getParameterUrlByName('size', nextPagination)!;
    handleListModel({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      idProvider: idProvider,
    });
  };
  const onClickPrevPagination = async () => {
    const page: string = getParameterUrlByName('page', prevPagination)!;
    const size: string = getParameterUrlByName('size', prevPagination)!;
    handleListModel({
      pagination: `?page=${page}&size=${size}`,
      page: parseInt(page),
      idProvider: idProvider,
    });
  };

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />
      {loading && <Loading />}
      {!loading && <CreatePageContent />}

      <Modal
        show={openModal}
        onHide={handleCloseModal}
        size={'xl'}
        fullscreen={'md-down'}
      >
        <Container>
          <form
            onSubmit={handleSubmit(handleCreteOrEdit)}
            className='mt-0'
            noValidate
          >
            <Modal.Header closeButton>
              <Modal.Title
                className='ms-3 lead'
                style={{ fontSize: '30px' }}
              >
                {inEditing ? 'EDITAR' : 'NOVO'}
              </Modal.Title>
            </Modal.Header>
            <AlertModal />
            <Modal.Body>
              <Form.Control type='hidden' {...register('id')} />

              <Row className='mt-3'>
                <Col md={3}>
                  <Form.Label>Fornecedor:</Form.Label>
                  <Col>
                    <Controller
                      name='fornecedor'
                      control={control}
                      render={({
                                 field: { onChange, value },
                               }) => {
                        var defaultValueOptions: SelectOptionInterface =
                          filialOption[value! as any];

                        if (value) {
                          defaultValueOptions =
                            filialOption.filter(
                              (el) =>
                                el.value ===
                                value,
                            )[0];
                          defaultValueOptions.selected =
                            true;
                        }

                        return (
                          <Select
                            defaultValue={
                              defaultValueOptions
                            }
                            onChange={async (
                              val,
                            ) => {
                              onChange(
                                val?.value,
                              );
                              await handleSelectSetores(
                                val?.value,
                              );
                            }}
                            options={filialOption}
                          />
                        );
                      }}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>
                    Código da Filial na Fornecedora:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register(
                        'codigoFilialFornecedor',
                      )}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Nome:</Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('nome')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Setor:</Form.Label>
                  <Col>
                    {setoresOption !== undefined ? (
                      <>
                        <Controller
                          name='codigoSetor'
                          control={control}
                          render={({
                                     field: {
                                       onChange,
                                       value,
                                     },
                                   }) => {
                            let defaultValueOptions =
                              setoresOption!.filter(
                                (el) =>
                                  el.value.toString() ===
                                  value,
                              );

                            return (
                              <Select
                                defaultValue={
                                  defaultValueOptions
                                }
                                onChange={(
                                  val,
                                ) =>
                                  onChange(
                                    val?.value,
                                  )
                                }
                                options={
                                  setoresOption
                                }
                              />
                            );
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Select />
                      </>
                    )}
                  </Col>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={3}>
                  <Form.Label>Cidade:</Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('cidade')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>UF:</Form.Label>
                  <Col>
                    <Controller
                      name='uf'
                      control={control}
                      render={({
                                 field: { onChange, value },
                               }) => {
                        var defaultValueOptions: SelectOptionInterface =
                          ufOption[value! as any];

                        if (value) {
                          defaultValueOptions =
                            ufOption.filter(
                              (el) =>
                                el.value ===
                                value,
                            )[0];
                          defaultValueOptions.selected =
                            true;
                        }

                        return (
                          <Select
                            defaultValue={
                              defaultValueOptions
                            }
                            onChange={(val) =>
                              onChange(val?.value)
                            }
                            options={ufOption}
                          />
                        );
                      }}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>
                    Data Início de Vigência:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='date'
                      {...register('inicioVigencia')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>
                    Data Fim de Vigência:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='date'
                      {...register('fimVigencia')}
                    />
                  </Col>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={3}>
                  <Form.Label>
                    Percentual de Deságio:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('percentualDesagio')}
                      onChange={(event) => {
                        if (
                          Number(event.target.value) >
                          99
                        ) {
                          event.target.value = '99';
                        }
                      }}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>
                    Valor máximo de Scrap:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('valorMaximoScrap')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>
                    ValorScrap já utilizado:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      disabled={true}
                      type='text'
                      {...register('valorScrapUtilizado')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Hold Back:</Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('holdBack')}
                      onChange={(event) => {
                        if (
                          Number(event.target.value) >
                          99
                        ) {
                          event.target.value = '99';
                        }
                      }}
                    />
                  </Col>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-start'>
              <Button variant='primary' size='sm' type='submit'>
                Salvar
              </Button>
              <Button
                variant='outline-primary'
                size='sm'
                onClick={clearField}
              >
                Limpar
              </Button>
            </Modal.Footer>
          </form>
        </Container>
      </Modal>
    </>
  );

  /**
   *
   * CREATE TABLE
   *
   */
  function CreateTable() {
    if (listModel !== undefined)
      return (
        <TableHeaderFixedStyled>
          <Table className='mt-3'>
            <thead>
            <tr>
              {tableDescriptions.map((value, index) => {
                return (
                  <th className='px-1' key={index}>
                    <div>
                      <p
                        style={{
                          whiteSpace: 'nowrap',
                          textAlign: value.align,
                        }}
                        className='m-1'
                      >
                        {value.label}
                      </p>
                    </div>
                  </th>
                );
              })}
            </tr>
            </thead>
            <tbody>
            {listModel.map((value, index) => {
              return (
                <tr
                  key={index}
                  onClick={() =>
                    handleModalEdit(value.id!)
                  }
                >
                  <td align='center'>{value.id}</td>
                  <td align='center'>
                    {value.codigoSetor}
                  </td>
                  <td align='center'>
                    {value.codigoFilialFornecedor}
                  </td>
                  <td align='left'>{value.nome}</td>
                  <td align='left'>{value.cidade}</td>
                  <td align='left'>{value.uf}</td>
                  <td align='center'>
                    {ParseDateToBrV2(
                      value.inicioVigencia,
                    )}
                  </td>
                  <td align='center'>
                    {ParseDateToBrV2(value.fimVigencia)}
                  </td>
                  <td align='center'>{value.holdBack}</td>
                  <td align='right'>
                    {value.percentualDesagio}
                  </td>
                  <td align='right'>
                    {value.valorMaximoScrap.toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      },
                    )}
                  </td>
                  <td align='right'>
                    {value.valorScrapUtilizado.toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      },
                    )}
                  </td>
                </tr>
              );
            })}
            </tbody>
          </Table>
        </TableHeaderFixedStyled>
      );
    else
      return (
        <TableHeaderFixedStyled>
          <Table>
            <thead>
            <tr>
              {tableDescriptions.map((value, index) => {
                return (
                  <th
                    key={index}
                    style={{
                      whiteSpace: 'nowrap',
                      textAlign: value.align,
                    }}
                  >
                    {value.label}
                  </th>
                );
              })}
            </tr>
            </thead>
          </Table>
        </TableHeaderFixedStyled>
      );
  }

  /**
   *
   * CREATE INITIAL PAGE
   *
   */
  function CreatePageContent() {
    return (
      <>
        <div>
          <div className='mx-4'>
            <TitleGroupComponent titlePage={titlePage}>
              <Row>
                <Col className='d-flex align-items-center justify-content-end'>
                  <Button
                    variant='primary'
                    onClick={handleModalCreate}
                    size='sm'
                  >
                    Novo
                  </Button>
                </Col>
              </Row>
            </TitleGroupComponent>
          </div>

          <div className='mx-2'>
            <Card>
              <div className='m-3'>
                <Row className='d-flex align-items-center justify-content-start'>
                  <Col md={1}>
                    <Form.Label>Fornecedor:</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Select
                      defaultValue={provider}
                      options={providerSelect}
                      onChange={(val) =>
                        onChangeSelectProvider(val!)
                      }
                      placeholder='Selecione o Fornecedor'
                    />
                  </Col>
                </Row>
              </div>

              {listModel.length > 0 && (
                <Card.Body>
                  <div>
                    <CreateTable />
                    <div className='mt-4'>
                      <Row className='d-flex align-items-center justify-content-center'>
                        <Col className='d-flex align-items-center justify-content-center'>
                          <Pagination size='sm'>
                            <Pagination.First
                              onClick={
                                onClickFirstPagination
                              }
                            >
                              Primeira
                            </Pagination.First>
                            <Pagination.Prev
                              onClick={
                                onClickPrevPagination
                              }
                            >
                              Anterior
                            </Pagination.Prev>
                            {paginationItemList?.map(
                              (value, key) => {
                                return (
                                  <Pagination.Item
                                    key={
                                      key
                                    }
                                    active={
                                      value.active
                                    }
                                    onClick={() =>
                                      onClickItemPagination(
                                        value,
                                      )
                                    }
                                  >
                                    {value.item +
                                      1}
                                  </Pagination.Item>
                                );
                              },
                            )}
                            <Pagination.Next
                              onClick={
                                onClickNextPagination
                              }
                            >
                              Próxima
                            </Pagination.Next>
                            <Pagination.Last
                              onClick={
                                onClickLastPagination
                              }
                            >
                              Última
                            </Pagination.Last>
                          </Pagination>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card.Body>
              )}

              {listModel.length === 0 && (
                <div className='d-flex align-items-center justify-content-center'>
                  <p className='m-5'>
                    Não há nada a ser exibido, clique em
                    processar ou tente mais tarde
                  </p>
                </div>
              )}
            </Card>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default FilialProviderPage;
