export function ArrayToColums(arr: any, count: number) {
    return [
        ...arr.reduce(
            (
                a: {
                    set: (arg0: number, arg1: any) => any
                    get: (arg0: number) => any
                },
                b: any, i: number
            ) => a.set(i % count, (a.get(i % count) || []).concat(b)), new Map()
        ).values()
    ]
}

export function ArrayseparateEqually(arr: [], count: number) {
    var resultado = [[]];
    var grupo = 0;

    for (var indice = 0; indice < arr.length; indice++) {
        if (resultado[grupo] === undefined) {
            resultado[grupo] = [];
        }

        resultado[grupo].push(arr[indice])

        if ((indice + 1) % count === 0) {
            grupo = grupo + 1;
        }
    }

    return resultado;
}

export function SortByKey(array: any[], key: string | number) {
    return array.sort(function (a, b) {
        var x = a[key]; var y = b[key]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
}

export function ReverseByKey(array: any[], key: string | number) {
    let list = array.sort(function (a, b) {
        var x = a[key]; var y = b[key]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })

    return list.reverse()
}