import { SCDesctiption } from '../../style/typography/titlePage';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { getEnv } from '../../../main/helper/window-helper';
import { RestUseCase } from '../../../data/useCase/rest-usecase';

type Props = {
  titlePage: string,
};

export function TitlePageComponent({ titlePage }: Props) {

  const [showModalInfo, updateShowModalInfo] = useState(false);
  const closeModalInfo = () => updateShowModalInfo(false);
  const showInfo = () => updateShowModalInfo(true);
  const [infoPageContent, updateInfoPageContent] = useState();


  function ModalInfo() {
    return (
      <Modal show={showModalInfo} onHide={closeModalInfo} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title className='ms-3 lead' style={{ fontSize: '20px' }}>
            {`Funcionamento da tela - ${titlePage}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {infoPageContent}
        </Modal.Body>
      </Modal>
    );
  }


  return (
    <>
      <SCDesctiption>{titlePage}<FaRegQuestionCircle onClick={showInfo} color={'#017BFE'} className='ms-2' size={15} /></SCDesctiption>
      <ModalInfo />
    </>
  );

}