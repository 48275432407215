import { CallHttp } from "infra/call/call-http-infra"

export class PanelInfo {

    constructor(private readonly endPoint: string) {
        this.endPoint = endPoint
    }

    async Get() {
        return await CallHttp({ url: this.endPoint, method: "GET" }).then(response => response)
    }
}
