/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { SelectOptionInterface } from 'domain/entity/interface/select-option-interface';
import { EntityField } from 'domain/entity/field/crud/field-crud-entity';
import { SpinnerSC } from 'presentation/style/spinner/spinner-styled';


type Props = {
  title: string;
  entityFields: EntityField[];

  navItemsField: { label: string; key: string; active: boolean }[];

  tableHeader: any[];
  tableBody: any[];

  usersList: SelectOptionInterface[];
  selectItensCurva: SelectOptionInterface[];
  selectItensNivel: SelectOptionInterface[];
  selectItensPontuacao: SelectOptionInterface[];
  selectItensColuns: SelectOptionInterface[];
  isPurchasingManager: Boolean;
  loading: Boolean;

  changeSelectItensCurva: (item: any) => any;
  changeSelectItensNivel: (item: any) => any;
  changeSelectItensPontuacao: (item: any) => any;
  changeSelectItensColuns: (item: any) => any;

  onChangeSaveItens: (value: string) => any;
  applyToOtherUsers: (usersIds: number[]) => any;
};

const CurveTurnPage: React.FC<Props> = ({
  title,
  entityFields,
  navItemsField,
  tableHeader,
  tableBody,
  usersList,
  selectItensCurva,
  selectItensNivel,
  selectItensPontuacao,
  selectItensColuns,
  isPurchasingManager,
  loading,
  changeSelectItensCurva,
  changeSelectItensNivel,
  changeSelectItensPontuacao,
  changeSelectItensColuns,
  onChangeSaveItens,
  applyToOtherUsers
}: Props) => {

  /**
 *
 *
 *
 *
 *  loading */
  function Loading() {
    return (
      <SpinnerSC>
        <div className="spinner-border text-secondary" role="status"></div>
      </SpinnerSC>
    );
  }

  function BoxSaveIten() {
    const [valueChange, updateValueChange] = useState('');

    return (
      <>
        <Col style={{ flex: "2 0 0%" }}>
          <Form.Control type="text" onChange={(event) => updateValueChange(event.target.value)} placeholder="digite um valor " />
        </Col>
        <Col style={{ flex: "0 0 0%" }}>
          <Button onClick={() => onChangeSaveItens(valueChange)}>Salvar</Button>
        </Col>
        {isPurchasingManager ?
          <Col style={{ flex: "4 0 0%" }}>
            <Button onClick={showOtherUser}>Aplicar para outros usuários</Button>
          </Col>
          :
          <></>}
      </>
    );
  }

  function SelectWithLableSelectAll(params: { passOnTheValue: any; defaultValue: SelectOptionInterface[]; placeholder: string }) {
    const [options, updateOption] = useState<SelectOptionInterface[]>(params.defaultValue);
    const [selectedOptions, setSelectedOptions] = useState<SelectOptionInterface[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const handleSelectAll = () => {
      if (selectAll) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(options);
      }
      setSelectAll(!selectAll);
      params.passOnTheValue(options);
    };

    const handleOptionChange = (selected: any) => {
      setSelectedOptions(selected as SelectOptionInterface[]);
      setSelectAll((selected as SelectOptionInterface[]).length === options.length);
      params.passOnTheValue(selected);
    };

    return (
      <>
        <Select
          options={options}
          defaultValue={params.defaultValue}
          onChange={(val) => handleOptionChange(val)}
          placeholder={params.placeholder}
          isMulti={true}
          value={selectedOptions}
        />
        <label>
          <input type="checkbox" checked={selectAll} onChange={handleSelectAll} style={{marginRight: "2px"}}/>
          Todos
        </label>
      </>
    );
  }

  /**
   *
   *
   * Modal Apply to other users
   *
   */
  const [showModalOtherUser, updateShowModalOtherUser] = useState(false);
  const closeModalOtherUser = () => updateShowModalOtherUser(false);
  const showOtherUser = () => updateShowModalOtherUser(true);

  function ModalOtherUser() {
    const [listModelUsers, updateListModelUsers] = useState<SelectOptionInterface[]>(usersList);

    function handleCheckbox(value: number) {
      updateListModelUsers(listModelUsers.map(item => {
        if (item.value === value) {
          item.selected = item.selected === true ? false : true;
        }
        return item;
      }));
    }

    function getUserIds(): number[] {
      let users = listModelUsers.filter(user => user.selected === true)
      let usersId: number[] = users.map(user => user.value)
      return usersId
    }

    return (
      <Modal show={showModalOtherUser} onHide={closeModalOtherUser} size={"xl"} fullscreen={'lg-down'}>
        <Modal.Header closeButton>
          <Modal.Title className="ms-3 lead" style={{ fontSize: '30px' }}>
            Aplicar para outros Usuários
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Selecione os usuarios para aplicar a configuração de curvas:</p>

          <Row>
            {listModelUsers.map((checkboxValue, index) => {
              return (
                <Col key={index} md={6}>
                  <Form.Check label={checkboxValue.label} type='checkbox' value={checkboxValue.value}
                    checked={checkboxValue.selected} onChange={(e) => {
                      handleCheckbox(Number(e.target.value));
                    }} />
                </Col>
              );
            })}
          </Row>

        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              applyToOtherUsers(getUserIds()) 
              closeModalOtherUser()
            }}
            disabled={getUserIds().length === 0}>
            Aplicar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <p className="mb-0 text-center">
            <small>Selecione a(s) Curva(s), o(s) Nível(s) ou a(s) Pontuação(s) desejada, </small>
          </p>
          <p className="mt-0 text-center">
            <small>depois selecione a(s) coluna(s) que deseja alterar, digite um valor e salve</small>
          </p>
          <Row>
            <Col style={{ flex: "2 0 0%" }}>
              <SelectWithLableSelectAll passOnTheValue={changeSelectItensCurva} defaultValue={selectItensCurva} placeholder={'Curva'} />
            </Col>
            <Col style={{ flex: "2 0 0%" }}>
              <SelectWithLableSelectAll passOnTheValue={changeSelectItensNivel} defaultValue={selectItensNivel} placeholder={'Nível'} />
            </Col>
            <Col style={{ flex: "2 0 0%" }}>
              <SelectWithLableSelectAll passOnTheValue={changeSelectItensPontuacao} defaultValue={selectItensPontuacao} placeholder={'Pontuação'} />
            </Col>
            <Col style={{ flex: "2 0 0%" }}>
              <Select options={selectItensColuns} onChange={(val) => changeSelectItensColuns(val)} placeholder="Coluna" isMulti={false} />
            </Col>
            <BoxSaveIten />
          </Row>

          <ModalOtherUser />
        </>
      )}
    </>
  );
};

export default CurveTurnPage;
