import { EntityFieldReport } from "domain/entity/field/report/field-report"
import { ConfigTable } from "domain/interfaces/table-interface"
import { ParseDateToBr } from "main/helper/format-date-helper"
import { ReactNode } from "react"
import { Col, Form, Pagination, Row, Table } from "react-bootstrap"
import { BsCheckLg } from "react-icons/bs"


type PropsTable = {
    tableHeader: any[]
    tableBody: any[],
    entityFields: EntityFieldReport[]
    configTable: ConfigTable
    onClickCheckboxRow?: any
    onClickCheckboxColumn?: any
    onClickSaveRow?: any
    onBlurSaveRow?: any
    onClickOpenModal?: any

    onClickFirstPagination?: any
    onClickLastPagination?: any
    onClickNextPagination?: any
    onClickPrevPagination?: any
    paginationItemList?: any
    onClickItemPagination?: any
    pageDescriptionPagination?: string
}

const CreateModalReport: React.FC<PropsTable> = ({ 
    tableHeader, 
    tableBody, 
    entityFields, 
    configTable,
    onClickCheckboxRow, 
    onClickCheckboxColumn, 
    onClickSaveRow,
    onBlurSaveRow,
    onClickOpenModal, 
    onClickFirstPagination,
    onClickLastPagination,
    onClickNextPagination,
    onClickPrevPagination,
    pageDescriptionPagination,
    paginationItemList,
    onClickItemPagination, }: PropsTable) => {
    return (
        <Table responsive style={{ cursor: "pointer" }}>
            <thead>
                <tr>
                    {configTable.checkboxes ?
                        <th style={{ whiteSpace: "nowrap" }} className="m-0 p-0">
                            <Form.Check type="checkbox" className="ms-1 mb-1" onClick={onClickCheckboxColumn} />
                        </th>
                        :
                        <></>
                    }
                    {tableHeader.map((value, index) => {
                        return <th key={index} style={{ whiteSpace: "nowrap", textAlign: value.align }}>{value.labelTable}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {tableBody.map((value, index) => {

                    return <tr
                        key={index}
                        onClick={() => {
                            if (configTable.openModal) {
                                onClickOpenModal(value)
                            }
                        }}>

                        {configTable.checkboxes ?
                            <td key={index} className="m-0 p-0">
                                <Form.Check type="checkbox" className="ms-1 me-2" onChange={() => { onClickCheckboxRow(value) }} checked={value["checked"]} />
                            </td>
                            :
                            <></>
                        }

                        {tableHeader.map((v, i) => {

                            if (entityFields[i] !== undefined) {

                                let typeField = entityFields[i].typeField
                                let align = entityFields[i].align
                                let fieldValue = value[v.name]

                                if (fieldValue !== null || fieldValue !== undefined) {

                                    let className = `d-flex align-items-center justify-content-${align} m-0 p-0 mx-2`
                                    if (align === "left")
                                        className = `d-flex align-items-center justify-content-start m-0 p-0 mx-2`
                                    if (align === "right")
                                        className = `d-flex align-items-center justify-content-end m-0 p-0 mx-2`

                                    if (typeField === "date") {
                                        return (
                                            <td key={i} className="m-0 p-0">
                                                <div className={className} style={{ whiteSpace: "nowrap", fontWeight: 250 }}>{ParseDateToBr(fieldValue)}</div>
                                            </td>
                                        )
                                    }
                                    else if (typeField === "text") {
                                        return (
                                            <td key={i} className="m-0 p-0">
                                                <div className={className} style={{ whiteSpace: "nowrap", fontWeight: 250 }}>{fieldValue}</div>
                                            </td>
                                        )
                                    }
                                    else if (typeField === "monetary") {
                                        if (fieldValue !== null) {
                                            return (
                                                <td key={i} className="m-0 p-0">
                                                    <div className={className} style={{ whiteSpace: "nowrap", fontWeight: 250 }}>{fieldValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
                                                </td>
                                            )
                                        }
                                    }
                                    else if (typeField === "radio") {
                                        return (
                                            <td key={i} className="m-0 p-0">
                                                <div className={className} style={{ whiteSpace: "nowrap", fontWeight: 250 }}>{fieldValue}</div>
                                            </td>
                                        )
                                    }
                                    else if (typeField === "number") {
                                        return (
                                            <td key={i} className="m-0 p-0">
                                                <div className={className} style={{ whiteSpace: "nowrap", fontWeight: 250 }}>{fieldValue}</div>
                                            </td>
                                        )
                                    }
                                    else if (typeField === "html") {
                                        return (
                                            <td key={i} className="m-0 p-0" >
                                                <div className="d-flex align-items-center justify-content-center m-0 p-0" style={{ whiteSpace: "nowrap", fontWeight: 250 }}>{fieldValue}</div>
                                            </td>
                                        )
                                    }
                                    else if (typeField === "input") {
                                        let quantidade = 0
                                        let id = value["id"]
                                        return (
                                            <td key={i} className="m-0 p-0">
                                                <div className="d-flex align-items-center justify-content-center m-0 p-0">
                                                    <div>
                                                        <Form.Control className="m-0 p-0" type="text" defaultValue={fieldValue} size="sm" disabled={entityFields[i].disabled} 
                                                        onChange={(e) => { quantidade = Number(e.target.value) }}
                                                        onBlur={(e) => {
                                                            onBlurSaveRow({id: id, quantidade: quantidade})
                                                        }}/>
                                                    </div>
                                                    {/* <div className="m-1 ms-2">
                                                        <BsCheckLg size={10} color={"#28A745"} onClick={() => onClickSaveRow({id: id, quantidade: quantidade})} />
                                                    </div> */}
                                                </div>
                                            </td>
                                        )
                                    }
                                    else
                                        return (
                                            <td key={i} className="m-0 p-0">
                                                <div className="d-flex align-items-center justify-content-center m-0 p-0" style={{ whiteSpace: "nowrap", fontWeight: 250 }}>
                                                    {fieldValue}
                                                </div>
                                            </td>
                                        )
                                }
                            }
                        })}
                    </tr>
                })}

                {configTable.pagination
                    ?
                    <PaginationTable 
                        children={<></>}
                        configTable={configTable}
                        onClickFirstPagination={onClickFirstPagination}
                        onClickLastPagination={onClickLastPagination}
                        onClickNextPagination={onClickNextPagination}
                        onClickPrevPagination={onClickPrevPagination}
                        pageDescriptionPagination={pageDescriptionPagination!}
                        itemList={paginationItemList}
                        onClickItem={onClickItemPagination}
                    />
                    :
                    <div></div>
                }
            </tbody>
        </Table>
    )
}

export default CreateModalReport

type PropsPagination = {
    children: ReactNode
    configTable: ConfigTable
    onClickFirstPagination: any,
    onClickLastPagination: any,
    onClickNextPagination: any,
    onClickPrevPagination: any
    pageDescriptionPagination: string,
    itemList: any
    onClickItem: any
}

const PaginationTable: React.FC<PropsPagination> = ({
    children,
    configTable,
    onClickFirstPagination,
    onClickLastPagination,
    onClickNextPagination,
    onClickPrevPagination,
    pageDescriptionPagination,
    itemList,
    onClickItem
}: PropsPagination) => {

    async function handlePaginationFirst() { await onClickFirstPagination() }
    async function handlePaginationPrev() { await onClickPrevPagination() }
    async function handlePaginationNext() { await onClickNextPagination() }
    async function handlePaginationLast() { await onClickLastPagination() }

    return (
        <div className="mt-4">
            <Row className="d-flex align-items-center justify-content-between">

                <Col className="d-flex justify-content-start">
                </Col>
                <Col >
                    <div className="d-flex justify-content-center">
                        <Pagination size="sm">
                            <Pagination.First onClick={onClickFirstPagination}>Primeira</Pagination.First>
                            <Pagination.Prev onClick={onClickPrevPagination} >Anterior</Pagination.Prev>
                            {itemList?.map((value: any, key: any) => {
                                return (<Pagination.Item key={key} active={value.active} onClick={() => onClickItem(value)}>{value.item + 1}</Pagination.Item>)
                            })}
                            <Pagination.Next onClick={onClickNextPagination} >Próxima</Pagination.Next>
                            <Pagination.Last onClick={onClickLastPagination} >Última</Pagination.Last>
                        </Pagination>
                    </div>
                </Col>
                <Col className="d-flex justify-content-end">
                    {configTable.buttonFooter ? children : <></>}
                </Col>
            </Row>
        </div>
    )
}
// ****************************************