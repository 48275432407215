import { getEnv } from "main/helper/window-helper"

export function ServerEnvironment() {

    const serverEnvironment = getEnv("REACT_APP_SERVER_ENVIRONMENT")
    var target = { visible: false, color: "", title: serverEnvironment?.toUpperCase() }

    function Target() {

        switch (serverEnvironment?.toUpperCase()) {
            case "LCL":
            case "LOCAL":
                target.visible = true
                target.color = "#198753"
                target.title = "LOCAL"
                break

            case "HML":
            case "HOMOL":
            case "HOMOLOG":
            case "HOMOLOGAÇÃO":
            case "HOMOLOGACAO":
                target.visible = true
                target.color = "#FFC007"
                target.title = "HOMOL"
                break
        }

        if (target.visible) {
            return (
                <>
                    <div style={{
                        position: "absolute",
                        background: target.color,
                        color: "write",
                        zIndex: 9999,
                        transform: "rotate(-48deg)",
                        width: "150px",
                        textAlign: "center",
                        left: "-48px",
                        top: "22px",
                        fontWeight: 700,
                        cursor: "default"
                    }}>
                        {target.title}
                    </div>
                </>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <>
            <Target />
        </>
    )

}