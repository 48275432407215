import { useEffect, useState } from "react";
import CrudPage from "presentation/pages/crud-page";
import { UseAuth } from "presentation/hook/auth-hook";
import { RestUseCase } from "data/useCase/rest-usecase";
import { EntityField } from "domain/entity/field/crud/field-crud-entity";
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page";
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter";
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface";
import { getEnv } from "main/helper/window-helper"

export const MessageFactory: React.FC = () => {
    /** ENDPOINT */
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_MESSAGE")!

    /** TITULO DA PAGINA */
    const titlePage = "Mensagem";

    /** CAMPOS PARA MODAL E TABELA */
    const entityFields: EntityField[] = [
        {
            name: "id",
            labelField: "",
            labelTable: "ID",
            typeField: "hidden",
            valueField: null,
            sizeField: 0,
            required: false,
            align: "center",
            tableField: { column: "ID", columnVisible: true },
        },
        {
            name: "mensagem",
            labelField: "Mensagem:",
            labelTable: "Mensagem",
            typeField: "text-area",
            valueField: null,
            sizeField: 0,
            required: false,
            align: "left",
            tableField: { column: "Mensagem", columnVisible: true },
        },
        {
            name: "status",
            labelField: "Status:",
            labelTable: "Status",
            valueField: null,
            typeField: "radio",
            listValueField: [
                { label: "Aviso", value: "0" },
                { label: "Inconsistência", value: "1" },
            ],
            sizeField: 0,
            required: false,
            align: "right",
            tableField: { column: "Status", columnVisible: true },
        },
    ];
    const [entity, updateEntity] = useState(entityFields);

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([]);
    const [tableBody, updateTableBody] = useState([]);

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth();
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Configurações"
        )[0].navItem.filter((item) => item.title === "Cadastrar Mensagem")[0]
            .role;

        if (loggedUserData?.role) {
            loggedUserData.role.forEach((value) => {
                if (roleScreen.includes(value)) updateShowScreen(true);
            });
        }
    }, [loggedUserData]);

    const [idCompany, updateIdCompany] = useState<number>();
    const [modelCompany, updateModelCompany] =
        useState<SelectOptionInterface>();
    function handleSelectProvider(filial: { value: number; label: string }) {
        updateModelCompany(filial);
        updateIdCompany(filial.value);
        if (filial.value) {
            getAllV2(filial.value);
        }
    }

    async function getAllV2(company: number) {
        const response = await new RestUseCase(`${ENDPOINT_CRUD}/company`)
            .Get()
            .finally();

        updateTableHeader(
            entityFields.map((value) => {
                return {
                    name: value.name,
                    labelTable: value.labelTable,
                    align: value.align,
                    columnVisible: value.tableField.columnVisible,
                };
            })
        );
        updateTableBody(response.body);
    }

    return (
        <>
            {showScreen ? (
                <CrudPage
                    readonly={true}
                    handleSelectProvider={handleSelectProvider}
                    filterByCompany={false}
                    modelCompany={modelCompany}
                    entityFields={entity}
                    updateEntity={updateEntity}
                    titlePage={titlePage}
                    getAll={async function () {
                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}`
                        )
                            .Get()
                            .finally();

                        updateTableHeader(
                            entityFields.map((value) => {
                                return {
                                    name: value.name,
                                    labelTable: value.labelTable,
                                    align: value.align,
                                    columnVisible:
                                        value.tableField.columnVisible,
                                };
                            })
                        );
                        updateTableBody(response.body);
                    }}
                    getById={async function (id: number) {
                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/${id}`
                        )
                            .GetById()
                            .finally();

                        updateEntity(
                            entityFields.map((value) => {
                                value.valueField = response.body[value.name];
                                return value;
                            })
                        );
                    }}

                    tableHeader={tableHeader}
                    tableBody={tableBody}
                />
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
