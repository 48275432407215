/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select'
import { ReactNode, useEffect, useState } from "react"
import { Button, Card, Col, Form, Row, Table, Pagination, Modal } from "react-bootstrap"
import { Headers } from "presentation/components/header/headers-component"
import HeaderCrud from "presentation/components/header/header-crud-component"
import { ParseDateToBr } from "main/helper/format-date-helper"
import { BsCheckLg, BsXLg } from "react-icons/bs"
import { ConfigTable } from 'domain/interfaces/table-interface'
import { SpinnerSC } from 'presentation/style/spinner/spinner-styled'
import { Footer } from 'presentation/components/footer/footer-component'
import ContainerFilter from 'presentation/components/container/container-filter-component'
import { FiltersDefault } from 'domain/interfaces/filter-interface'
import CreateTableReport from 'presentation/components/table/table-report-component'
import { EntityFieldReport } from 'domain/entity/field/report/field-report'
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip } from 'recharts'
import { getLocalStorage } from 'data/cache/localstorage-cache'
import { TableHeaderFixedStyled } from 'presentation/style/table/table-header-fixed-styled'

// ****************************************

type PropsReport = {
    children: ReactNode[]
    titlePage: string
    tableHeader: any[]
    tableBody: any[]
    entityFields: EntityFieldReport[]
    configTable: ConfigTable
    optionsFiltersDefault?: FiltersDefault[]

    isOpenModalPlanning?: boolean
    showModalPlanning?: any
    openModalPlanning?: any
    closeModalPlanning?: any
    handleSubmitPlanning?: any,
    handleEditPlanning?: any,
    handleListAllPlanning?: any,
    handleCreatePlanning?: any,
    handleRemovePlanning?: any,
    handleApplyPlanning?: any,

    onClickFirstPagination?: any
    onClickLastPagination?: any
    onClickNextPagination?: any
    onClickPrevPagination?: any
    paginationItemList?: any
    onClickItemPagination?: any
    pageDescriptionPagination?: string

    saveRow?: any

    checkboxRow?: any
    checkboxColumn?: any

    withError: boolean
    waiting: boolean

    isOpenModalItem?: any
    graphic?: any[]
    keyLocalStoragePlanning?: string
    changeItem?: any
}

const ReportPage: React.FC<PropsReport> = ({
    titlePage,
    tableHeader,
    tableBody,
    configTable,
    entityFields,

    children,

    optionsFiltersDefault,
    isOpenModalPlanning,
    showModalPlanning,
    openModalPlanning,
    closeModalPlanning,
    handleSubmitPlanning,
    handleEditPlanning,
    handleListAllPlanning,
    handleCreatePlanning,
    handleRemovePlanning,
    handleApplyPlanning,

    onClickFirstPagination,
    onClickLastPagination,
    onClickNextPagination,
    onClickPrevPagination,
    pageDescriptionPagination,
    paginationItemList,
    onClickItemPagination,
    saveRow,

    checkboxRow,
    checkboxColumn,

    withError,
    waiting,

    isOpenModalItem,
    graphic,
    keyLocalStoragePlanning,
    changeItem

}: PropsReport) => {

    const onClickCheckboxRow = (params: any) => {
        checkboxRow(params)
    }

    const onClickCheckboxColumn = (params: any) => {
        checkboxColumn(params)
    }

    return (
        <>
            <Headers />
            <HeaderCrud title={titlePage} children={configTable.buttonHeader ? children[0] : <></>} />
            <div className="mx-1">
                <Card>
                    <Card.Body>

                        {configTable.selectsTable ? children[1] : <></>}
                        {configTable.navegation ? children[2] : <></>}

                        {waiting ?
                            <Loading />
                            :
                            <>
                                <CreateTableReport
                                    tableHeader={tableHeader}
                                    tableBody={tableBody}
                                    entityFields={entityFields}
                                    configTable={configTable}
                                    onClickCheckboxRow={onClickCheckboxRow}
                                    onClickCheckboxColumn={onClickCheckboxColumn}
                                    onClickOpenModal={isOpenModalItem}
                                    changeItem={changeItem}
                                    saveRow={saveRow}
                                />

                                {graphic && graphic?.length > 0
                                    ?
                                    <>
                                        <div className="d-flex align-items-center justify-content-center mt-3">
                                            <BarChart
                                                width={500}
                                                height={500}
                                                data={graphic}
                                            >
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="Disponivel" fill="#8884d8" />
                                                <Bar dataKey="Gasto" fill="#82ca9d" />
                                            </BarChart>
                                        </div>
                                    </>
                                    : <></>
                                }

                                {configTable.pagination
                                    ?
                                    <PaginationTable
                                        children={children[3]}
                                        configTable={configTable}
                                        onClickFirstPagination={onClickFirstPagination}
                                        onClickLastPagination={onClickLastPagination}
                                        onClickNextPagination={onClickNextPagination}
                                        onClickPrevPagination={onClickPrevPagination}
                                        pageDescriptionPagination={pageDescriptionPagination!}
                                        itemList={paginationItemList}
                                        onClickItem={onClickItemPagination}
                                    />
                                    :
                                    <div></div>
                                }
                            </>
                        }

                    </Card.Body>
                </Card>
            </div>
            {children[4]}
            <Footer />
            {configTable.planning ?
                <ModalPlanning
                    isOpen={isOpenModalPlanning!}
                    apply={handleApplyPlanning}
                    listAll={handleListAllPlanning}
                    create={handleCreatePlanning}
                    remove={handleRemovePlanning}
                    open={openModalPlanning}
                    close={closeModalPlanning}
                    optionsFiltersDefault={optionsFiltersDefault!}
                    editPlanning={handleEditPlanning}
                    keyLocalStorage={keyLocalStoragePlanning!}
                />
                :
                <div></div>
            }
        </>
    )
}

export default ReportPage

// ****************************************

type PropsPagination = {
    children: ReactNode
    configTable: ConfigTable
    onClickFirstPagination: any,
    onClickLastPagination: any,
    onClickNextPagination: any,
    onClickPrevPagination: any
    pageDescriptionPagination: string,
    itemList: any
    onClickItem: any
}

const PaginationTable: React.FC<PropsPagination> = ({
    children,
    configTable,
    onClickFirstPagination,
    onClickLastPagination,
    onClickNextPagination,
    onClickPrevPagination,
    pageDescriptionPagination,
    itemList,
    onClickItem
}: PropsPagination) => {

    async function handlePaginationFirst() { await onClickFirstPagination() }
    async function handlePaginationPrev() { await onClickPrevPagination() }
    async function handlePaginationNext() { await onClickNextPagination() }
    async function handlePaginationLast() { await onClickLastPagination() }

    return (
        <div className="mt-4">
            <Row className="d-flex align-items-center justify-content-between">

                <Col className="d-flex justify-content-start">
                </Col>
                <Col >
                    <div className="d-flex justify-content-center">
                        <Pagination size="sm">
                            <Pagination.First onClick={onClickFirstPagination}>Primeira</Pagination.First>
                            <Pagination.Prev onClick={onClickPrevPagination} >Anterior</Pagination.Prev>
                            {itemList?.map((value: any, key: any) => {
                                return (<Pagination.Item key={key} active={value.active} onClick={() => onClickItem(value)}>{value.item + 1}</Pagination.Item>)
                            })}
                            <Pagination.Next onClick={onClickNextPagination} >Próxima</Pagination.Next>
                            <Pagination.Last onClick={onClickLastPagination} >Última</Pagination.Last>
                        </Pagination>
                    </div>
                </Col>
                <Col className="d-flex justify-content-end">
                    {configTable.buttonFooter ? children : <></>}
                </Col>
            </Row>
        </div>
    )
}
// ****************************************
const Loading: React.FC = () => {
    return (<SpinnerSC><div className="spinner-border text-secondary" role="status"></div></SpinnerSC>)
}

// ****************************************

type PropsPlanning = {
    isOpen: boolean,
    optionsFiltersDefault: FiltersDefault[]
    apply: any
    listAll: any
    create: any
    remove: any
    open: any
    close: any
    editPlanning: any
    keyLocalStorage: string
}

const ModalPlanning: React.FC<PropsPlanning> = ({ isOpen, apply, listAll, create, remove, open, close, optionsFiltersDefault, editPlanning, keyLocalStorage }: PropsPlanning) => {
    return (
        <>
            <Modal show={isOpen} onHide={close} size={"xl"} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title className="ms-3 lead" style={{ fontSize: "30px" }}>PLANEJAMENTO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mt-3">
                        <Col>
                            {/* <ContainerFilter
                                apply={apply}
                                filtersDefault={optionsFiltersDefault}
                                listAllPlanning={listAll}
                                listByIdPlanning={function (params?: any): Promise<any> {
                                    throw new Error("Function not implemented.")
                                }}
                                createPlanning={create}
                                editPlanning={function (params?: any): Promise<any> {
                                    throw new Error("Function not implemented.")
                                }}
                                removePlanning={remove}
                            /> */}
                            <ContainerFilter
                                keyLocalStorage={keyLocalStorage}
                                filterLocalStorage={getLocalStorage(keyLocalStorage)}
                                apply={apply}
                                filtersDefault={optionsFiltersDefault}
                                listAllPlanning={listAll}
                                listByIdPlanning={function (params?: any): Promise<any> { throw new Error("listByIdPlanning ==> não foi implementada") }}
                                createPlanning={create}
                                editPlanning={editPlanning}
                                removePlanning={remove}
                                isOnlineConsultant={false}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}