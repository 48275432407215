import { getEnv } from 'main/helper/window-helper';

export function setLocalStorege(key: string, value?: object): void {
    if (value)
        localStorage.setItem(key, JSON.stringify(value))
    else
        localStorage.removeItem(key)
}

export function getLocalStorage(key: string): any {
    return JSON.parse(localStorage.getItem(key)!)
}

export function updateLocalStorege(key: string, value: object): void {
    if (key) {
        localStorage.removeItem(key)
        localStorage.setItem(key, JSON.stringify(value))
    }
}

export function clearLocalStoragePlanning() {
    localStorage.removeItem('planning_name_analytical');
    localStorage.removeItem('planning_name_equalization');
    localStorage.removeItem('planning_name_indicator');
    localStorage.removeItem('planning_name_indicator2');
    localStorage.removeItem('planning_name_managerial');
    localStorage.removeItem('planning_name_synthetic');

    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_COLUMS_ANALYTICAL'));
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_COLUMS_MANAGERIAL'));
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_COLUMS_SYNTHETIC'));
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_COLUMS_EQUALIZATION'));

    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_COLUMS_ANALYTICAL') + '_order');
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_COLUMS_MANAGERIAL') + '_order');
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_COLUMS_SYNTHETIC') + '_order');
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_COLUMS_EQUALIZATION') + '_order');

    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_PLANNING_ANALYTICAL'));
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_PLANNING_SYNTHETIC'));
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_PLANNING_EQUALIZATION'));
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_PLANNING_INDICATOR'));
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_PLANNING_MENAGERIAL'));
    localStorage.removeItem(getEnv('REACT_APP_LOCALSTORAGE_PLANNING_SCRAP'));
}