import { ResponseTypeRequest } from "domain/types/http-type"
import { CallHttp } from "infra/call/call-http-infra"

interface Request {
    data?: any,
    headers?: any,
    responseType?: ResponseTypeRequest
}

export class RestUseCase {

    constructor(private readonly endPoint: string) {
        this.endPoint = endPoint
    }

    async Get(request?: Request) {
        return await CallHttp({
            url: this.endPoint,
            method: "GET",
            headers: request?.headers,
            responseType: request?.responseType
        }).then(response => response)
    }

    async GetById(request?: Request) {
        return await CallHttp({
            url: this.endPoint,
            method: "GET",
            headers: request?.headers
        }).then(response => response)
    }

    async Post(request?: Request) {
        return await CallHttp({
            url: this.endPoint,
            method: "POST",
            body: request?.data,
            headers: request?.headers,
            responseType: request?.responseType
        }).then(response => response)
    }

    async Put(request?: Request) {
        return await CallHttp({
            url: this.endPoint,
            method: "PUT",
            body: request?.data,
            headers: request?.headers
        }).then(response => response)
    }

    async Patch(request?: Request) {
        return await CallHttp({
            url: this.endPoint,
            method: "PATCH",
            body: request?.data,
            headers: request?.headers
        }).then(response => response)
    }

    async Delete(request?: Request) {
        return await CallHttp({
            url: this.endPoint,
            method: "DELETE",
            body: request?.data,
            headers: request?.headers
        }).then(response => response)
    }
}
