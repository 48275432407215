/* eslint-disable react-hooks/exhaustive-deps */
import { Nav } from "react-bootstrap";
import { useEffect, useState } from "react";
import CrudPage from "presentation/pages/crud-page";
import { UseAuth } from "presentation/hook/auth-hook";
import { RestUseCase } from "data/useCase/rest-usecase";
import { RestCrud } from "data/useCase/rest-crud-usecase";
import { EntityField } from "domain/entity/field/crud/field-crud-entity";
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page";
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter";
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface";
import { getEnv } from "main/helper/window-helper"

export const CurveValueFactory: React.FC = () => {
    /** ENDPOINT */
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_GET_CURVE_VALUE")!

    /** TITULO DA PAGINA */
    const titlePage = "Cadastrar Valor da Curva";

    /** CAMPOS PARA MODAL E TABELA */
    //todo: trocado ordem dos campos iniico e fim
    const entityFields: EntityField[] = [
        {
            name: "codigo",
            labelField: "",
            labelTable: "Código",
            typeField: "hidden",
            valueField: null,
            sizeField: 0,
            required: false,
            align: "center",
            tableField: { column: "Código", columnVisible: true },
        },
        {
            name: "curva",
            labelField: "Curva:",
            labelFieldDisable: true,
            labelTable: "Curva",
            typeField: "text",
            valueField: null,
            sizeField: 0,
            required: false,
            align: "left",
            tableField: { column: "Curva", columnVisible: true },
        },
        {
            name: "inicio",
            labelField: "Início:",
            labelTable: "Início",
            typeField: "number",
            valueField: null,
            sizeField: 0,
            required: false,
            align: "right",
            tableField: { column: "Início", columnVisible: true },
        },
        {
            name: "fim",
            labelField: "Fim:",
            labelTable: "Fim",
            typeField: "number",
            valueField: null,
            sizeField: 0,
            required: false,
            align: "right",
            tableField: { column: "Fim", columnVisible: true },
        },
    ];
    const [entity, updateEntity] = useState(entityFields);

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([]);
    const [tableBody, updateTableBody] = useState([]);

    /** NAVEGACAO */
    const [navigationActive, updateNavigationActive] =
        useState<string>("PECAS");
    const changeNavigationActive = async (item: string) => {
        updateNavigationActive(item);
    };

    /** CHAMADA BACKEND */
    const getAll = async (item?: string) => {
        if (item) {
            await new RestCrud(ENDPOINT_CRUD! + "/" + item)
                .Get()
                .then((response) => {
                    updateTableHeader(
                        entityFields.map((value) => {
                            return {
                                name: value.name,
                                labelTable: value.labelTable,
                                align: value.align,
                                columnVisible: value.tableField.columnVisible,
                            };
                        })
                    );
                    updateTableBody(
                        response.body.map((value: any) => {
                            return value;
                        })
                    );
                });
        }
    };

    useEffect(() => {
        if (idCompany !== undefined) {
            getAllV2(idCompany);
        }
    }, [navigationActive]);

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth();
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Configurações"
        )[0].navItem.filter(
            (item) => item.title === "Cadastrar Valor da Curva"
        )[0].role;

        if (loggedUserData?.role) {
            loggedUserData.role.forEach((value) => {
                if (roleScreen.includes(value)) updateShowScreen(true);
            });
        }
    }, [loggedUserData]);

    const [idCompany, updateIdCompany] = useState<number>();
    const [modelCompany, updateModelCompany] =
        useState<SelectOptionInterface>();
    function handleSelectProvider(filial: { value: number; label: string }) {
        updateModelCompany(filial);
        updateIdCompany(filial.value);
        if (filial.value) {
            getAllV2(filial.value);
        }
    }

    async function getAllV2(company: number) {
        const response = await new RestUseCase(
            `${ENDPOINT_CRUD}/${company}/${navigationActive}`
        )
            .Get()
            .finally();

        updateTableHeader(
            entityFields.map((value) => {
                return {
                    name: value.name,
                    labelTable: value.labelTable,
                    align: value.align,
                    columnVisible: value.tableField.columnVisible,
                };
            })
        );
        updateTableBody(response.body);
    }

    return (
        <>
            {showScreen ? (
                <CrudPage
                    handleSelectProvider={handleSelectProvider}
                    readonly={true}
                    filterByCompany={true}
                    modelCompany={modelCompany}
                    entityFields={entity}
                    updateEntity={updateEntity}
                    titlePage={titlePage}
                    getAll={() => getAll(navigationActive)}
                    getById={async function (id: number, model: any) {
                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/${idCompany}/${navigationActive}/${model.curva}/${model.codigo}`
                        )
                            .GetById()
                            .finally();

                        updateEntity(
                            entityFields.map((value) => {
                                value.valueField = response.body[value.name];
                                return value;
                            })
                        );
                    }}
                    put={async function (id: number, model: any) {
                        const curva = model["curva"];
                        const codigo = model["codigo"];
                        model["inicio"] = parseInt(model["inicio"]);
                        model["fim"] = parseInt(model["fim"]);

                        delete model["curva"];
                        delete model["codigo"];

                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/${idCompany}/${navigationActive}/${curva}/${codigo}`
                        )
                            .Put({ data: model })
                            .finally();

                        updateEntity(
                            entityFields.map((value) => {
                                value.valueField = response.body[value.name];
                                return value;
                            })
                        );
                    }}
                    tableHeader={tableHeader}
                    tableBody={tableBody}
                    valueId="codigo"
                    children={{
                        nevigation: (
                            <>
                                <div>
                                    <Nav
                                        className="justify-content-center my-3"
                                        variant="tabs"
                                        defaultActiveKey={navigationActive}
                                    >
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="PECAS"
                                                onClick={() =>
                                                    changeNavigationActive(
                                                        "PECAS"
                                                    )
                                                }
                                            >
                                                Peças
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="VENDAS"
                                                onClick={() =>
                                                    changeNavigationActive(
                                                        "VENDAS"
                                                    )
                                                }
                                            >
                                                Vendas
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="OFICINAS"
                                                onClick={() =>
                                                    changeNavigationActive(
                                                        "OFICINAS"
                                                    )
                                                }
                                            >
                                                Oficinas
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="CAMPANHAS"
                                                onClick={() =>
                                                    changeNavigationActive(
                                                        "CAMPANHAS"
                                                    )
                                                }
                                            >
                                                Campanhas
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="VENDAS_DIRETAS"
                                                onClick={() =>
                                                    changeNavigationActive(
                                                        "VENDAS_DIRETAS"
                                                    )
                                                }
                                            >
                                                Vendas Diretas
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="COMPRAS"
                                                onClick={() =>
                                                    changeNavigationActive(
                                                        "COMPRAS"
                                                    )
                                                }
                                            >
                                                Compras
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="CHAME_O_VENDEDOR"
                                                onClick={() =>
                                                    changeNavigationActive(
                                                        "CHAME_O_VENDEDOR"
                                                    )
                                                }
                                            >
                                                Chame o Vendedor
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="OBSOLETOS"
                                                onClick={() =>
                                                    changeNavigationActive(
                                                        "OBSOLETOS"
                                                    )
                                                }
                                            >
                                                Obsoletos
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </>
                        ),
                    }}
                />
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
