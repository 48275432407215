/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import CrudPage from "presentation/pages/crud-page";
import { UseAuth } from "presentation/hook/auth-hook";
import { RestUseCase } from "data/useCase/rest-usecase";
import { RestCrud } from "data/useCase/rest-crud-usecase";
import { EntityField } from "domain/entity/field/crud/field-crud-entity";
import ErrorNotAuthorizedPage from "presentation/pages/error/error-not-authorized-page";
import { HeaderNavegationList } from "main/adapter/headerNavigation/header-navegation-adapter";
import CurveTurnPage from "presentation/pages/settings/curve-turn-page";
import { SelectOptionInterface } from "domain/entity/interface/select-option-interface";
import { UseAlert } from "presentation/hook/alert-hook";
import { transformStringToSnakeCase } from "main/helper/string-helper";
import { getEnv } from "main/helper/window-helper";

export const CurveTurnFactoryV2: React.FC = () => {
    /** ENDPOINT */
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_GET_CURVE_TURN_USER")!;
    const ENDPOINT_USERS = getEnv('REACT_APP_END_POINT_USER')!;

    /** TITULO DA PAGINA */
    const titlePage = "Giro da Curva";

    /** CAMPOS PARA MODAL E TABELA */
    const navItemsField: { label: string; key: string; active: boolean }[] = [
        { label: "Peças", key: "PECAS", active: true },
        { label: "Vendas", key: "VENDAS", active: false },
        { label: "Oficinas", key: "OFICINAS", active: false },
        { label: "Campanhas", key: "CAMPANHAS", active: false },
        { label: "Venda Direta", key: "VENDAS_DIRETAS", active: false },
        { label: "Compra", key: "COMPRAS", active: false },
        { label: "Chame o Vendedor", key: "CHAME_O_VENDEDOR", active: false },
        { label: "Obsoletos", key: "OBSOLETOS", active: false },
    ];
    const entityFields: EntityField[] = [
        {
            name: "curva",
            labelField: "Curva:",
            labelFieldDisable: true,
            labelTable: "Curva",
            typeField: "button",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "center",
            tableField: {
                column: "Curva",
                columnVisible: true,
                disabled: true,
            },
        },
        {
            name: "nivel",
            labelField: "Nível:",
            labelFieldDisable: true,
            labelTable: "Nível",
            typeField: "button",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "right",
            tableField: {
                column: "Nível",
                columnVisible: true,
                disabled: true,
            },
        },
        {
            name: "pontuacao",
            labelField: "Pontuação:",
            labelFieldDisable: true,
            labelTable: "Pontuação",
            typeField: "button",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "right",
            tableField: {
                column: "Pontuação",
                columnVisible: true,
                disabled: true,
            },
        },
        {
            name: "giro",
            labelField: "Giro:",
            labelFieldDisable: false,
            labelTable: "Giro",
            typeField: "text",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "right",
            tableField: { column: "Giro", columnVisible: true },
        },
        {
            name: "projecaoDias",
            labelField: "Projeção Dias:",
            labelFieldDisable: false,
            labelTable: "Projeção Dias",
            typeField: "text",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "right",
            tableField: { column: "Projeção Dias", columnVisible: true },
        },
        {
            name: "quantidadePrimeiraRegra",
            labelField: "Qtd. 1a. Regra:",
            labelFieldDisable: false,
            labelTable: "Qtd. 1a. Regra",
            typeField: "text",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "right",
            tableField: {
                column: "Qtd. 1a. Regra",
                columnVisible: true,
            },
        },
        {
            name: "quantidadePecasPrimeiraRegra",
            labelField: "Qtd de + Peças 1a. Regra:",
            labelFieldDisable: false,
            labelTable: "Qtd de + Peças 1a. Regra",
            typeField: "text",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "right",
            tableField: {
                column: "Qtd de + Peças 1a. Regra",
                columnVisible: true,
            },
        },
        {
            name: "percSegundaRegra",
            labelField: "% Máximo 2a. Regra:",
            labelFieldDisable: false,
            labelTable: "% Máximo 2a. Regra",
            typeField: "text",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "right",
            tableField: { column: "% Máximo 2a. Regra", columnVisible: true },
        },
        {
            name: "diasReserva",
            labelField: "Dias Reserva:",
            labelFieldDisable: false,
            labelTable: "Dias Reserva",
            typeField: "text",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "right",
            tableField: { column: "Dias Reserva", columnVisible: true },
        },
        {
            name: "pontoPedido",
            labelField: "Ponto Pedido:",
            labelFieldDisable: false,
            labelTable: "Ponto Pedido",
            typeField: "text",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "right",
            tableField: { column: "Ponto Pedido", columnVisible: true },
        },
    ];
    const [entity, updateEntity] = useState(entityFields);

    const selectItensCurva: SelectOptionInterface[] = [
        { label: "A", value: "A" },
        { label: "B", value: "B" },
        { label: "C", value: "C" },
        { label: "D", value: "D" },
        { label: "E", value: "E" },
        { label: "F", value: "F" },
        { label: "N", value: "N" },
    ];

    const selectItensNivel: SelectOptionInterface[] = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
    ];

    const selectItensPontuacao: SelectOptionInterface[] = [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
    ];

    const selectItensColuns: SelectOptionInterface[] = entityFields
        .filter((item) => {
            if (item.labelFieldDisable === false) {
                return item;
            }
        })
        .map((item) => {
            return { label: item.labelTable, value: item.name };
        });
    
    const [isPurchasingManager, updateIsPurchasingManager] = useState(false);
    const [loading, updateLoading] = useState<boolean>(false);

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([]);
    const [tableBody, updateTableBody] = useState([]);

    /** CHAMADA BACKEND */
    const getAll = async () => {
        updateLoading(true)
        await new RestCrud(ENDPOINT_CRUD!).Get().then((response) => {
            updateTableHeader(
                entityFields.map((value) => {
                    return {
                        name: value.name,
                        labelTable: value.labelTable,
                        align: value.align,
                        columnVisible: value.tableField.columnVisible,
                    };
                })
            );
            updateTableBody(
                response.body.map((value: any) => {
                    return value;
                })
            );
        });
        updateLoading(false)
    };

    const [changedItems, updateChangedItems] = useState<{
        curva: any[];
        pontuacao: any[];
        nivel: any[];
        coluna: any[];
    }>({
        curva: [],
        pontuacao: [],
        nivel: [],
        coluna: [],
    });

    function changeSelectItensCurva(item: any[]) {
        changedItems.curva = item.map((item) => item.value);
        updateChangedItems(changedItems);
    }
    function changeSelectItensNivel(item: any[]) {
        changedItems.nivel = item.map((item) => Number(item.value));
        updateChangedItems(changedItems);
    }
    function changeSelectItensPontuacao(item: any[]) {
        changedItems.pontuacao = item.map((item) => Number(item.value));
        updateChangedItems(changedItems);
    }
    function changeSelectItensColuns(item: any) {
        changedItems.coluna = [item.value]; //item.map((item) => item.value);
        updateChangedItems(changedItems);
    }

    async function onChangeSaveItens(value: string) {
        updateLoading(true)
        if (changedItems.coluna.length > 0) {
            await multipleUpdate({
                curvas: changedItems.curva,
                niveis: changedItems.nivel,
                pontuacoes: changedItems.pontuacao,
                column: transformStringToSnakeCase(changedItems.coluna[0]),
                valor: Number(value),
            });
            await getAll();
        } else {
            updateLoading(false)
            showAlert({
                show: true,
                content: "Selecione pelo menos uma coluna",
                color: "danger",
                time: 5000,
            });
        }
    }

    async function multipleUpdate(model: {
        curvas: string[];
        niveis: number[];
        pontuacoes: number[];
        column: string;
        valor: number;
    }) {
        updateLoading(true)
        await new RestUseCase(`${ENDPOINT_CRUD}/multi-update`)
            .Put({ 
                headers: { 'Action-Name': "GirodaCurvaUsuario/Salvar" },
                data: model })
            .finally();
        updateLoading(false)
    }

    const [usersList, updateUsersList] = useState<SelectOptionInterface[]>([]);

    async function listAllUserByCompanies(): Promise<any> {
        updateLoading(true)
        const companiesActive = true;

        let query = `?empresa=${loggedUserData?.companyId}&ativo=${companiesActive}`;
        let queryByRole = `${query}&roles=COMPRAS_GESTOR&roles=COMPRAS_OPERACIONAL`;

        let responseUser = await new RestUseCase(ENDPOINT_USERS! + queryByRole).Get()
            .then((response) => {
                return response.body.map((value: any) => {
                    return { value: value.id, label: value.nome, selected: false };
                });
            });

        updateUsersList(responseUser.sort((a: { label: string; }, b: { label: string; }) => a.label.localeCompare(b.label)));
        updateLoading(false)
    }

    async function applyToOtherUsers(usersIdList: number[]) {
        showAlert({ show: true, content: 'Esta ação pode demorar um pouco, aguarde um momento...', color: 'default', time: 5000, });
        updateLoading(true)
        await new RestUseCase(`${ENDPOINT_CRUD}/other-users`)
            .Put({
                headers: { 'Action-Name': "GirodaCurvaUsuario/AplicarOutrosUsuarios" },
                data: usersIdList
            })
            .then(response => {
                if (response.statusCode === undefined || response.statusCode < 200 || response.statusCode >= 300) {
                    showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
                } else {
                    showAlert({ show: true, content: "Giro de curva aplicado com sucesso aos usuários selecionados!", color: "success", time: 5000 })
                }
            });
        updateLoading(false)
     }

    const { showAlert } = UseAlert();

    useEffect(() => {
        getAll();
    }, []);

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth();
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Configurações"
        )[0].navItem.filter((item) => item.title === titlePage)[0].role;

        if (loggedUserData?.role) {
            loggedUserData.role.forEach((value) => {
                if (roleScreen.includes(value)) updateShowScreen(true);
                if (value === "COMPRAS_GESTOR" || value === "ADMIN") {
                    updateIsPurchasingManager(true)
                    listAllUserByCompanies();
                }
            });
        }
    }, [loggedUserData]);

    return (
        <>
            {showScreen ? (
                <CrudPage
                    entityFields={entity}
                    updateEntity={updateEntity}
                    titlePage={titlePage}
                    getAll={() => getAll()}
                    getById={async function (id: number, model: any) {
                        updateEntity(
                            entityFields.map((value) => {
                                value.valueField = model[value.name];
                                return value;
                            })
                        );
                    }}
                    put={async function (id: number, model: any) {
                        updateLoading(true)
                        const curva = model["curva"];
                        const nivel = model["nivel"];
                        const pontuacao = model["pontuacao"];
                        parseInt(model["inicio"]);
                        parseInt(model["fim"]);

                        delete model["curva"];
                        delete model["codigo"];
                        delete model["nivel"];

                        model["quantidadePecasPrimeiraRegra"] = Number(
                            model["quantidadePecasPrimeiraRegra"]
                        );

                        const modelParseNumber: any = {};

                        for (const key in model) {
                            if (model.hasOwnProperty(key)) {
                                modelParseNumber[key] = Number(model[key]);
                            }
                        }

                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/${curva}/${nivel}/${pontuacao}`
                        )
                            .Put({ 
                                headers: { 'Action-Name': "GirodaCurvaUsuario/Editar" },
                                data: modelParseNumber })
                            .finally();

                        updateEntity(
                            entityFields.map((value) => {
                                value.valueField = response.body[value.name];
                                return value;
                            })
                        );

                        getAll();
                    }}
                    tableHeader={tableHeader}
                    tableBody={tableBody}
                    valueId="curva"
                    children={{
                        nevigation: (
                            <>
                                <CurveTurnPage
                                    title={titlePage}
                                    entityFields={entity}
                                    navItemsField={navItemsField}
                                    tableHeader={tableHeader}
                                    tableBody={tableBody}
                                    usersList={usersList}
                                    selectItensCurva={selectItensCurva}
                                    selectItensNivel={selectItensNivel}
                                    selectItensPontuacao={selectItensPontuacao}
                                    selectItensColuns={selectItensColuns}
                                    isPurchasingManager={isPurchasingManager}
                                    loading={loading}
                                    changeSelectItensCurva={ changeSelectItensCurva }
                                    changeSelectItensNivel={ changeSelectItensNivel }
                                    changeSelectItensPontuacao={ changeSelectItensPontuacao }
                                    changeSelectItensColuns={ changeSelectItensColuns }
                                    onChangeSaveItens={ onChangeSaveItens }
                                    applyToOtherUsers={ applyToOtherUsers }
                                />
                            </>
                        ),
                    }}
                />
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
